import { CollapsibleTable } from "../../Pages/CashupHome/CashupTabs/POS/utils/Panel";
import React, { useEffect, useRef, useState } from "react";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { Space } from "antd";
import { CollapsePanelPOS } from "./POSPanel";
import { CollapsePOSTotal } from "./POSTotal";
import { ExtendedLocationItemWithChildren } from "../../Redux/StateSlices/GroupData/LocationsAPI";
import { useQuery } from "react-query";
import { getLocationsQuery } from "../../ApiV2/Helpers/getLocationsQuery";
import { getAccountsQuery } from "../../ApiV2/Helpers/getAccountsQuery";
import { getClassesQuery } from "../../ApiV2/Helpers/getClassesQuery";
import { CentredSpinner } from "../../Components/Misc/Loading/CentredSpinner";
import { getPosTotals } from "../../ApiV2/Helpers/getPosTotals";
import { usePusher } from "../../hooks/usePusher";
import { Channel } from "pusher-js";
import { getVenueById } from "../../ApiV2/Helpers/getVenueById";
import { LocationType } from "Pages/CashupReport/CashupReportModel";

interface Props {
    setLastTransaction: React.Dispatch<React.SetStateAction<string>>;
    updatePOSData: () => Promise<void>;
}

const POSTab: React.FC<Props> = ({ updatePOSData }) => {
    const {
        venueId,
        formattedDate,
        shiftStatus,
        channelId,
        setActivePanel,
        activePanel,
    } = useVenueSelection();

    const totalPanelId = `${formattedDate}-${3}`;
    const [currentTableInFocus, setCurrenTableInFocus] = useState<string>();
    const { data: venueData, isLoading: venueDataIsLoading } = useQuery(
        ["venueData", venueId, formattedDate],
        () => getVenueById(venueId)
    );

    const { data: posLocations, isLoading: posLocationIsLoading } = useQuery(
        ["collapsablePosLocations", venueId, formattedDate],
        () =>
            getLocationsQuery(
                [LocationType.pos, LocationType.office],
                venueId,
                formattedDate
            )
    );

    const { data: accounts, isLoading: isAccountsLoading } = useQuery(
        ["accounts", venueId],
        () => getAccountsQuery(venueId)
    );
    const { data: classes, isLoading: isClassesLoading } = useQuery(
        ["classes", venueId],
        () => getClassesQuery(venueId)
    );

    const {
        data: posTotals,
        isLoading: isPostTotalsLoading,
        isRefetching: postTotalsIsRefetching,
    } = useQuery(
        ["posTotals", venueId, formattedDate],
        () => getPosTotals(venueId, formattedDate),
        {
            enabled: !!posLocations,
        }
    );
    const [posTotalsLive, setPosTotalsLive] = useState(posTotals);
    const { pusher } = usePusher();
    const topBannerSubscriptionRef = useRef<Channel>();
    useEffect(() => {
        setPosTotalsLive(posTotals);
    }, [posTotals]);

    useEffect(() => {
        if (!pusher) return;
        if (channelId !== "") {
            topBannerSubscriptionRef.current = pusher.subscribe(channelId);
            topBannerSubscriptionRef.current.bind("pos_totals", (data: any) => {
                setPosTotalsLive(data);
            });
        }
    }, [pusher, channelId, venueId]);

    if (
        !posLocations ||
        isClassesLoading ||
        isAccountsLoading ||
        posLocationIsLoading ||
        isPostTotalsLoading ||
        postTotalsIsRefetching ||
        venueDataIsLoading ||
        !posTotalsLive
    ) {
        return (
            <CentredSpinner
                style={{
                    marginTop: "50px",
                    position: "absolute",
                    textAlign: "center",
                    left: "50%",
                }}
                size={"large"}
            />
        );
    }

    return (
        <>
            <Space direction="vertical" style={{ width: "100%" }}>
                {posLocations
                    .filter(
                        (pL: ExtendedLocationItemWithChildren) =>
                            pL.location_type === LocationType.pos
                    )
                    .map(
                        (
                            currentLocation: ExtendedLocationItemWithChildren,
                            index: number
                        ) => {
                            const panelId = `${currentLocation.location_id}-${formattedDate}-${index}`;
                            const posDataFromPosTotal = posTotalsLive
                                ? Object.keys(posTotalsLive).find(
                                      (currentKey) =>
                                          currentKey === currentLocation.location_id
                                  ) !== undefined
                                    ? posTotalsLive[
                                          Object.keys(posTotalsLive).find(
                                              (currentKey) =>
                                                  currentKey ===
                                                  currentLocation.location_id
                                          )!
                                      ]
                                    : undefined
                                : undefined;
                            const posDataFromCashup =
                                Object.keys(posTotalsLive).find(
                                    (currentKey) =>
                                        currentKey === currentLocation.location_id
                                ) !== undefined
                                    ? posTotalsLive[
                                          Object.keys(posTotalsLive).find(
                                              (currentKey) =>
                                                  currentKey ===
                                                  currentLocation.location_id
                                          )!
                                      ]
                                    : undefined;
                            const posData = posDataFromPosTotal ?? posDataFromCashup;
                            return (
                                <CollapsibleTable
                                    name={currentLocation.name}
                                    panelId={panelId}
                                    key={panelId}
                                >
                                    <CollapsePanelPOS
                                        key={currentLocation.name}
                                        classesData={classes}
                                        accountsData={accounts}
                                        // @ts-ignore
                                        subLocations={currentLocation.sub_locations}
                                        locations={posLocations}
                                        onClickHandler={() =>
                                            setActivePanel(panelId)
                                        }
                                        isInFocus={panelId === activePanel}
                                        posData={posData}
                                        submitted={shiftStatus !== "UNSUBMITTED"}
                                        posintegrationFlag={
                                            venueData.pos_enabled_date
                                        }
                                        updatePOSData={updatePOSData}
                                        transferLocations={[
                                            ...posLocations.filter(
                                                (
                                                    pL: ExtendedLocationItemWithChildren
                                                ) =>
                                                    pL.location_type ===
                                                    LocationType.office
                                            ),
                                            currentLocation,
                                        ]}
                                    />
                                </CollapsibleTable>
                            );
                        }
                    )}
                <CollapsibleTable name="" panelId={totalPanelId} key={totalPanelId}>
                    <CollapsePOSTotal
                        classesData={classes}
                        accountsData={accounts}
                        posTotal={posTotalsLive}
                        pos_totals={posTotalsLive}
                        onClickHandler={() => setCurrenTableInFocus?.(totalPanelId)}
                        isInFocus={totalPanelId === currentTableInFocus}
                    />
                </CollapsibleTable>
            </Space>
        </>
    );
};
export default POSTab;
