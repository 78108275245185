import React, { useCallback, useMemo } from "react";
import { TableRows } from "V2/Table/TableBody";
import { TableConfigs } from "V2/Table/TableHeader";
import { PanelHOCComponentProps } from "../../Pages/CashupHome/CashupTabs/POS/utils/PanelHOC";
import cloneDeep from "lodash/cloneDeep";
import {
    InitDataResponseType,
    SafeData,
} from "../../Pages/CashupHome/CashupTabs/POS/@types";
import { TableForSafe } from "Components/TableForSafe/TableForSafe";
import { LocationDetailByCashupWithShiftType } from "utils/utilities";

interface Props extends PanelHOCComponentProps {
    name: string;
    tableData: InitDataResponseType[];
    onTableDataChange: (
        tableData: InitDataResponseType[],
        columnIndex: number
    ) => void;
    tableLength: number;
    safeLocationDetailByCashupId: LocationDetailByCashupWithShiftType;
    disabled: boolean;
    shouldShowLooseChange?: boolean;
}

const validateHelper = (modulus: number) => (value: number) => {
    if (Number.isNaN(modulus) || Number.isNaN(value) || modulus < 0) {
        return false;
    }
    const valueProcessed = parseFloat(value.toFixed(2));
    const scaledModulus = modulus < 1 ? 100 * modulus : modulus;
    const scaledValue = modulus < 1 ? Math.round(100 * valueProcessed) : value;
    return scaledValue % scaledModulus === 0;
};

export const denominationsColumn = (shouldShowLooseChange?: boolean) => {
    const denominationsArray = [
        { columnTitle: "$100", validator: validateHelper(100) },
        { columnTitle: "$50", validator: validateHelper(50) },
        { columnTitle: "$20", validator: validateHelper(20) },
        { columnTitle: "$10", validator: validateHelper(10) },
        { columnTitle: "$5", validator: validateHelper(5) },
        { columnTitle: "$2", validator: validateHelper(2) },
        { columnTitle: "$1", validator: validateHelper(1) },
        { columnTitle: "50¢", validator: validateHelper(0.5) },
        { columnTitle: "20¢", validator: validateHelper(0.2) },
        { columnTitle: "10¢", validator: validateHelper(0.1) },
        { columnTitle: "5¢", validator: validateHelper(0.05) },
    ];

    if (shouldShowLooseChange) {
        denominationsArray.splice(
            denominationsArray.length,
            0,
            {
                columnTitle: "Loose Notes",
                validator: () => true,
            },
            {
                columnTitle: "Loose Change",
                validator: () => true,
            }
        );
    }
    return denominationsArray;
};

// Create a method that sets the denomination value even if it's not in the safe_data
const setDenominationValue = (
    safe_data: SafeData,
    denomination: string,
    value: number
) => {
    const safeData = { ...safe_data };
    if (!safeData.denominations) safeData.denominations = {};
    const denominationValue = safeData.denominations[denomination];
    if (denominationValue) {
        safeData.denominations[denomination] = value;
    }

    return safeData;
};

enum ShiftTypeEnum {
    Start = 1,
    "Change Over" = 2,
    "Close" = 3,
    "Post Cashup" = 4,
    "Mid Shift" = 5,
}

// Create a utility that creates column names based on the length of the table data (min 1 and max 5)
export const createColumnNames = (
    tableData: InitDataResponseType[],
    safeLocationDetailByCashupId: LocationDetailByCashupWithShiftType
) => {
    const columnNames: TableConfigs = [{ columnTitle: "" }];
    for (let i = 0; i < tableData.length; i++) {
        columnNames.push({
            columnTitle: `Cash Count (${
                ShiftTypeEnum[
                    safeLocationDetailByCashupId[tableData[i].cashup_id].shiftType
                ]
            })`,
        });
    }

    return columnNames;
};

export const DenominationsPanel: React.FC<Props> = ({
    onClickHandler,
    isInFocus,
    tableData,
    onTableDataChange,
    tableLength,
    safeLocationDetailByCashupId,
    disabled,
    shouldShowLooseChange,
}) => {
    const denominations = useMemo(
        () => denominationsColumn(shouldShowLooseChange),
        [shouldShowLooseChange]
    );
    const onDenominationChange = useCallback(
        (params: { row: number; column: number; updatedValue: number }) => {
            const amount = Number(params.updatedValue);
            if (isNaN(amount)) {
                return;
            }
            const tableDataDeepClone = cloneDeep(tableData);

            tableDataDeepClone[params.column].safe_data = setDenominationValue(
                tableDataDeepClone[params.column].safe_data,
                denominations[params.row].columnTitle,
                params.updatedValue
            );

            tableDataDeepClone[params.column].safe_data.denominations[
                denominations[params.row].columnTitle
            ] = params.updatedValue;

            const total = Object.values(
                tableDataDeepClone[params.column].safe_data.denominations
            ).reduce((acc, curr) => acc + curr, 0);

            tableDataDeepClone[params.column].safe_data.total_denominations = total;

            onTableDataChange(tableDataDeepClone, params.column);
        },
        [tableData, onTableDataChange]
    );

    const generateColumn = useCallback(
        (columnTitle: string, rowIndex: number) => {
            const tableRows: TableRows = [];
            tableRows.push({
                value: columnTitle,
                readOnly: true,
            });

            for (let i = 0; i < tableLength; i++) {
                tableRows.push({
                    value: tableData[i].safe_data.denominations?.[columnTitle] ?? 0,
                    onSubmit: (data: any) =>
                        onDenominationChange({
                            row: rowIndex,
                            column: i,
                            updatedValue: data,
                        }),
                    readOnly:
                        tableData[i].safe_data.signed_off_by_user_id !== undefined ||
                        disabled,
                    validator: denominations[rowIndex].validator,
                });
            }

            return tableRows;
        },
        [tableData, tableLength, onDenominationChange]
    );

    const dataSources: TableRows[] = useMemo(() => {
        return denominations.map((currentDenomination, rowIndex) =>
            generateColumn(currentDenomination.columnTitle, rowIndex)
        );
    }, [tableData, onDenominationChange]);

    const tableConfigs = useMemo(
        () => createColumnNames(tableData, safeLocationDetailByCashupId),
        [tableLength]
    );

    return (
        <>
            <TableForSafe
                onCellValueChanged={() => null}
                dataSources={dataSources}
                tableConfigs={tableConfigs}
                onClickHandler={onClickHandler}
                onSelectedCellChange={() => null}
                isInFocus={isInFocus}
                evenDistribution
            />
        </>
    );
};
