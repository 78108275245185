import { SubmissionStatus } from "Redux/StateSlices/GroupData/VenuesAPI";
import { createContext, ReactNode, useContext } from "react";

export interface VenueSelectionContextType {
    venueId: string;
    setVenueId: (venueId: string) => void;
    setVenueName: (name: string) => void;
    setChannelId: (name: string) => void;
    setActivePanel: (name: string) => void;
    shiftDate: string;
    onShiftDateChange: (shiftDate: string) => void;
    setShiftStatus: (status: "" | SubmissionStatus) => void;
    shiftStatus: SubmissionStatus | "";
    formattedDate: string;
    channelId: string;
    venueName: string;
    activePanel: string;
}

// Create the context
export const VenueSelectionContext = createContext<VenueSelectionContextType>({
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    onShiftDateChange: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setShiftStatus: () => {},
    shiftDate: "",
    shiftStatus: "",
    venueId: "",
    venueName: "",
    formattedDate: "",
    channelId: "",
    activePanel: "",

    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setVenueId: () => {},
    setActivePanel: () => {},
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    setVenueName: () => {},
    setChannelId: () => {},
});

// Custom hook to use the user context
export const useVenueSelection = () => useContext(VenueSelectionContext);

export interface VenueSelectionProviderProps {
    children: ReactNode; // Use ReactNode for children prop
}
