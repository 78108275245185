import React, { useCallback, useMemo } from "react";
import { TableRows } from "V2/Table/TableBody";
import { PanelHOCComponentProps } from "../../Pages/CashupHome/CashupTabs/POS/utils/PanelHOC";
import cloneDeep from "lodash/cloneDeep";
import { FloatLocation } from "Redux/StateSlices/GroupData/VenuesAPI";
import {
    InitDataResponseType,
    SafeData,
} from "../../Pages/CashupHome/CashupTabs/POS/@types";
import { createColumnNames } from "./SafePanel";
import { LocationDetailByCashupWithShiftType } from "utils/utilities";
import { TableForSafe } from "Components/TableForSafe/TableForSafe";

interface Props extends PanelHOCComponentProps {
    name: string;

    floatLocations: FloatLocation[];
    tableData: InitDataResponseType[];
    onTableDataChange: (
        tableData: InitDataResponseType[],
        columnIndex: number
    ) => void;
    tableLength: number;
    safeLocationDetailByCashupId: LocationDetailByCashupWithShiftType;
    disabled: boolean;
}

// Create a method that sets the float value even if the float is undefined
const setFloatValue = ({
    safe_data,
    value,
    floatLocationId,
}: {
    safe_data: SafeData;
    value: number;
    floatLocationId: string;
}) => {
    const safeData = { ...safe_data };
    if (!safeData.floats) safeData.floats = [];

    const existingFloatValue = safeData.floats.findIndex(
        (float) => float.location_id === floatLocationId
    );

    if (existingFloatValue !== -1) {
        safeData.floats[existingFloatValue].cash_count = value;
    } else {
        safeData.floats.push({
            location_id: floatLocationId,
            cash_count: value,
        });
    }

    return safeData;
};

export const FloatsPanel: React.FC<Props> = ({
    onClickHandler,
    isInFocus,
    tableData,
    floatLocations,
    onTableDataChange,
    tableLength,
    safeLocationDetailByCashupId,
    disabled,
}) => {
    const onFloatChange = useCallback(
        ({
            column,
            updatedValue,
            locationId,
        }: {
            column: number;
            updatedValue: number;
            locationId: string;
        }) => {
            const tableDataDeepClone = cloneDeep(tableData);

            tableDataDeepClone[column].safe_data = setFloatValue({
                safe_data: tableDataDeepClone[column].safe_data,
                value: updatedValue,
                floatLocationId: locationId,
            });

            onTableDataChange(tableDataDeepClone, column);
        },
        [tableData]
    );

    const fetchFloatValueByLocationId = useCallback(
        (locationId: string, row: number) => {
            const float = tableData[row].safe_data.floats?.find(
                (float) => float.location_id === locationId
            );

            return float?.cash_count ?? 0;
        },
        [tableData]
    );

    const extractFloatAmountByName = useCallback(
        (floatName: string, row: number) => {
            const float = tableData[row].safe_data.float_locations?.find(
                (float) => float.name === floatName
            );
            return float?.balance ?? 0;
        },
        [tableData]
    );

    const generateColumn = useCallback(
        (rowIndex: number) => {
            const tableRows: TableRows = [];
            tableRows.push({
                value: floatLocations[rowIndex].name,
                readOnly: true,
                subValue: `$${extractFloatAmountByName(
                    floatLocations[rowIndex].name,
                    0
                )}`,
            });

            for (let i = 0; i < tableLength; i++) {
                tableRows.push({
                    value: fetchFloatValueByLocationId(
                        floatLocations[rowIndex].location_id,
                        i
                    ),
                    onSubmit: (data: any) =>
                        onFloatChange({
                            column: i,
                            updatedValue: data,
                            locationId: floatLocations[rowIndex].location_id,
                        }),
                    readOnly:
                        tableData[i].safe_data.signed_off_by_user_id !== undefined ||
                        disabled,
                });
            }

            return tableRows;
        },
        [
            floatLocations,
            tableData,
            fetchFloatValueByLocationId,
            onFloatChange,
            disabled,
        ]
    );

    const dataSources: TableRows[] = useMemo(() => {
        return floatLocations.map((float, rowIndex) => {
            return generateColumn(rowIndex);
        });
    }, [
        floatLocations,
        tableData,
        fetchFloatValueByLocationId,
        onFloatChange,
        generateColumn,
    ]);

    const tableConfigs = useMemo(
        () => createColumnNames(tableData, safeLocationDetailByCashupId),
        [tableLength]
    );

    return (
        <>
            <TableForSafe
                onCellValueChanged={() => null}
                dataSources={dataSources}
                tableConfigs={tableConfigs}
                onClickHandler={onClickHandler}
                onSelectedCellChange={() => null}
                isInFocus={isInFocus}
                evenDistribution
            />
        </>
    );
};
