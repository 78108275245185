import React, { useCallback, useEffect, useMemo, useState } from "react";
import dayjs from "dayjs";
import {
    Col,
    DatePicker,
    Input,
    InputNumber,
    Row,
    Select,
    Space,
    Spin,
    Typography,
} from "antd";
import styled from "styled-components";
import { SubmissionStatus } from "Redux/StateSlices/GroupData/VenuesAPI";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { putShiftPrompts } from "ApiV2/Helpers/putShiftPrompts";
import { MainContainer, PromptsContainer } from "./ShiftReportStyled";

const { Title, Text } = Typography;

const StyledInput = styled(Input)`
    height: 100%;
`;

const StyledRow = styled(Row)`
    margin-top: 20px;
    margin-bottom: 20px;
`;

interface PromptsAnswerItem {
    id: string;
    body: string;
    status: number;
    date: string;
    prompt: string;
}

interface PromptsItem {
    field_type: number;
    order: number;
    prompt_category: number;
    prompt_category_name: string;
    prompt_id: string;
    prompt_text: string;
    record: PromptsAnswerItem;
}

interface GroupedPromptsByCategory {
    [category: string]: PromptsItem[];
}

interface KeyDown {
    key: string;
    currentTarget: HTMLInputElement;
}

interface OnBlur {
    target: HTMLInputElement;
}

interface ShiftReportPromptsProps {
    promptsData: GroupedPromptsByCategory;
}

const ShiftReportPrompts: React.FC<ShiftReportPromptsProps> = ({ promptsData }) => {
    const { shiftStatus, formattedDate, venueId } = useVenueSelection();
    const [updatedPromptValues, setUpdatedPromptValues] = useState<{
        [key: string]: string;
    }>({});
    const [loadingFields, setLoadingFields] = useState<{ [key: string]: boolean }>(
        {}
    );

    // when cashup submitted or approved, disable form
    const disabled = useMemo(
        () =>
            shiftStatus === SubmissionStatus.APPROVED ||
            shiftStatus === SubmissionStatus.SUBMITTED,
        [shiftStatus]
    );

    const categoryKeys: string[] = useMemo(
        () => Object.keys(promptsData),
        [promptsData]
    );

    useEffect(() => {
        if (promptsData) {
            const initialLoadingStates: { [key: string]: boolean } = {};
            Object.keys(promptsData).forEach((categoryKey) => {
                promptsData[categoryKey].forEach((prompt) => {
                    initialLoadingStates[prompt.prompt_id] = false;
                });
            });
            setLoadingFields(initialLoadingStates);
        }
    }, [promptsData]);

    // on change update db with prompt details
    const onChange = useCallback(async (newValue: string, promptId: string) => {
        if (promptId) {
            setLoadingFields((prev) => ({ ...prev, [promptId]: true }));

            const formData = new FormData();
            formData.append("body", newValue);
            formData.append("shift_date", formattedDate);

            setUpdatedPromptValues((prev) => ({
                ...prev,
                [promptId]: newValue,
            }));
            try {
                await putShiftPrompts(venueId, promptId, formData);
            } catch (errorResponse) {
                console.log("Shift prompt error: ", errorResponse);
            } finally {
                setLoadingFields((prev) => ({ ...prev, [promptId]: false }));
            }
        }
    }, []);

    const FormHelper = ({
        content,
        promptId,
        fieldType,
    }: {
        content: string;
        promptId: string;
        fieldType: 1 | 2 | 3 | 4 | 5 | 6 | 7;
    }) => {
        const displayedValue =
            updatedPromptValues[promptId] !== undefined &&
            updatedPromptValues[promptId] !== null
                ? updatedPromptValues[promptId]
                : content;
        const loading = loadingFields[promptId];
        switch (fieldType) {
            // Text
            case 1:
                return (
                    <Spin spinning={loading} size="small">
                        <StyledInput
                            defaultValue={displayedValue}
                            disabled={disabled}
                            onBlur={({ target }: OnBlur) => {
                                onChange(target.value, promptId);
                            }}
                            onKeyDown={({ key, currentTarget }: KeyDown) => {
                                if (key === "Enter") {
                                    onChange(currentTarget.value, promptId);
                                }
                            }}
                        />
                    </Spin>
                );
                break;
            // Boolean
            case 2:
                return (
                    <Spin spinning={loading} style={{ width: "50%" }} size="small">
                        <Select
                            onChange={(value) => {
                                onChange(value, promptId);
                            }}
                            style={{ width: "50%" }}
                            value={displayedValue}
                            disabled={disabled}
                            options={[
                                { value: "1", label: "YES" },
                                { value: "0", label: "NO" },
                            ]}
                        />
                    </Spin>
                );
                break;
            // Number
            case 3:
                return (
                    <Spin spinning={loading} size="small">
                        <StyledInput
                            defaultValue={displayedValue}
                            disabled={disabled}
                            onBlur={({ target }: OnBlur) => {
                                onChange(target.value, promptId);
                            }}
                            onKeyDown={({ key, currentTarget }: KeyDown) => {
                                if (key === "Enter") {
                                    onChange(currentTarget.value, promptId);
                                }
                            }}
                            type="number"
                            style={{ width: "100%" }}
                        />
                    </Spin>
                );
                break;
            // Currency
            case 4:
                return (
                    <Spin spinning={loading} size="small">
                        <InputNumber
                            addonBefore="$"
                            defaultValue={displayedValue}
                            disabled={disabled}
                            onChange={(value) => {
                                if (value) {
                                    onChange(value.toString(), promptId);
                                } else {
                                    onChange("", promptId);
                                }
                            }}
                            style={{ width: "100%" }}
                        />
                    </Spin>
                );
                break;
            // Percentage
            case 5:
                return (
                    <Spin spinning={loading} size="small">
                        <InputNumber
                            addonAfter="%"
                            defaultValue={displayedValue}
                            disabled={disabled}
                            onChange={(value) => {
                                if (value) {
                                    onChange(value.toString(), promptId);
                                } else {
                                    onChange("", promptId);
                                }
                            }}
                            style={{ width: "100%" }}
                        />
                    </Spin>
                );
                break;
            // Datetime
            case 6:
                return (
                    <Spin spinning={loading} size="small">
                        <DatePicker
                            showTime
                            onChange={(_, dateString: string | string[]) => {
                                onChange(dateString as string, promptId);
                            }}
                            defaultValue={
                                displayedValue ? dayjs(displayedValue) : undefined
                            }
                            disabled={disabled}
                            style={{ width: "100%" }}
                        />
                    </Spin>
                );
            // Textarea
            case 7:
                return (
                    <Spin spinning={loading}>
                        <StyledInput.TextArea
                            defaultValue={displayedValue}
                            disabled={disabled}
                            onBlur={({
                                target,
                            }: {
                                target: HTMLTextAreaElement;
                            }) => {
                                onChange(target.value, promptId);
                            }}
                            onKeyDown={({ key, currentTarget }) => {
                                if (key === "Enter") {
                                    onChange(currentTarget.value, promptId);
                                }
                            }}
                            style={{ width: "100%" }}
                        />
                    </Spin>
                );
                break;
            default:
                throw new Error(`Option is not implemented ${fieldType}`);
        }
    };

    return (
        <MainContainer>
            <Space
                direction="vertical"
                style={{
                    width: "100%",
                }}
            >
                <PromptsContainer>
                    <Row
                        gutter={[20, 20]}
                        justify="center"
                        style={{ marginBottom: "50px" }}
                    >
                        <Title level={4}>Shift Details</Title>
                    </Row>
                    {categoryKeys?.map((category: string) => (
                        <React.Fragment key={category}>
                            <Row gutter={[20, 2]} align="middle" key={`${category}`}>
                                <Col>
                                    <Title level={5}>{category}</Title>
                                </Col>
                            </Row>
                            {promptsData[category]?.map((prompt: PromptsItem) => (
                                <StyledRow
                                    gutter={[20, 20]}
                                    align="middle"
                                    key={`${prompt.prompt_id}`}
                                >
                                    <Col md={10} lg={10} xs={24}>
                                        <Text>{prompt.prompt_text}</Text>
                                    </Col>
                                    <Col md={14} lg={14} xs={24}>
                                        <FormHelper
                                            content={
                                                prompt.record
                                                    ? prompt.record.body
                                                    : ""
                                            }
                                            promptId={prompt.prompt_id}
                                            fieldType={
                                                prompt.field_type as
                                                    | 1
                                                    | 2
                                                    | 3
                                                    | 4
                                                    | 5
                                                    | 6
                                                    | 7
                                            }
                                        />
                                    </Col>
                                </StyledRow>
                            ))}
                        </React.Fragment>
                    ))}
                </PromptsContainer>
            </Space>
        </MainContainer>
    );
};

export default ShiftReportPrompts;
