import { PropsWithChildren, useCallback, useEffect, useMemo, useState } from "react";
import { createSafeContext, useSafeContext } from "./useModal";
import React from "react";

const LoaderContext = createSafeContext<{
    isLoading: boolean;
    showLoader: () => void;
    hideLoader: () => void;
    withLoader: (process: () => Promise<void>) => Promise<void>;
}>();

export const LoaderProvider = (props: PropsWithChildren<{}>) => {
    const { children } = props;
    const [loaderCount, setLoaderCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    useEffect(() => {
        if (loaderCount == 0) {
            setIsLoading(false);
        }
    }, [loaderCount]);
    const showLoader = useCallback(() => {
        setIsLoading(true);
        setLoaderCount((prev) => prev + 1);
    }, []);
    const hideLoader = useCallback(() => setLoaderCount((prev) => prev - 1), []);
    const withLoader = useCallback(
        async (process: () => Promise<void>) => {
            showLoader();
            await process().finally(() => hideLoader());
        },
        [showLoader, hideLoader]
    );
    const value = useMemo(() => {
        return { isLoading, showLoader, hideLoader, withLoader };
    }, [isLoading, showLoader, hideLoader, withLoader]);
    return <LoaderContext.Provider value={value}>{children}</LoaderContext.Provider>;
};

export const useLoader = () => useSafeContext(LoaderContext);
