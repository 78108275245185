import { setSession } from "./auth";
import { Auth0Result, BASE_URL_AUTH } from "./types";
import { getUserToken, isAuthenticated } from "./auth";
import { AuthTokens, AuthTokensInLocalstorage } from "./types";

export const refreshAuthToken = async (): Promise<Auth0Result | null> => {
    const refreshToken = getRefreshToken();
    if (!refreshToken) {
        return null;
    }

    const requestData = {
        client_id: process.env.REACT_APP_AUTH0_CLIENTID,
        grant_type: "refresh_token",
        refresh_token: refreshToken,
    };
    return await fetch(`${BASE_URL_AUTH}/oauth/token`, {
        method: "POST",
        body: JSON.stringify(requestData),
        headers: { "content-type": "application/json" },
    })
        .then((response) => response.json())
        .then((response) => {
            if (response) {
                setSession(response);
            }
            return response;
        })
        .catch((error) => {
            window.dispatchEvent(new Event("token-expire"));
            return error;
        });
};

export const getAuthTokens = async (): Promise<
    AuthTokensInLocalstorage | undefined
> => {
    const access_token = getUserToken(AuthTokens.accessToken);
    const autheticated = isAuthenticated();
    const email = getUserToken(AuthTokens.email);

    if (access_token && autheticated && email) {
        return Promise.resolve({ access_token, email });
    } else {
        window.dispatchEvent(new Event("token-expire"));
        // TODO refresh Token
        // return await refreshAuthToken()
        //     .then((data) => {
        //         if (data) {
        //             //TO BE TESTED
        //             return { ...data, email: getUserToken(AuthTokens.email) };
        //         }
        //         return null;
        //     })
        //     .catch((error) => {
        //         window.dispatchEvent(new Event("token-expire"));
        //         return error;});
    }
};

export const getRefreshToken = () => {
    const refreshToken = getUserToken(AuthTokens.refreshToken);
    return refreshToken ? refreshToken : null;
};

export const sendPasswordResetEmail = async (email: string): Promise<void> => {
    const requestData = {
        client_id: process.env.REACT_APP_APPLICATION_AUTH0_CLIENTID,
        email: email,
        connection: process.env.REACT_APP_AUTH0_REALM,
    };
    return await fetch(`${BASE_URL_AUTH}/dbconnections/change_password`, {
        method: "POST",
        body: JSON.stringify(requestData),
        headers: { "content-type": "application/json" },
    })
        .then((response) => response.json())
        .catch((error) => error);
};
