import React, { useState } from "react";
import { Alert, Layout, Menu, Spin, Tooltip } from "antd";
import styled from "styled-components";
import { useHistory } from "react-router-dom";
import UserAvatarDropdown from "../UserAvatarDropdown";
import SubMenu from "antd/lib/menu/SubMenu";

import { WHITE } from "../../@Constants/theme/constants";
import { getActiveNavOption } from "../../utils/generalFunctions";
import { NavBarOptions } from "./NavBarOptions";
import { ErrorBoundaryWrapper } from "../ErrorBoundary";
import { ToolTips } from "../../tooltip";
import HelpSectionDropdown from "Components/HelpSectionDropdown";
import { useLoader } from "hooks/loaderProvider";
import { LoadingContainerWhole } from "../../Pages/CashupReportNew/Components/CashupReportStyled";
import { ServiceDown } from "./ServiceDown";

const { Header, Content, Footer } = Layout;

export const SiteLayoutContent = styled.div`
    min-height: 280px;
    padding: 24px;
    background: #fff;
`;

const FlexBoxVerticalItems = styled.div`
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: center;
    height: 100%;
`;

const StyledAlert = styled(Alert)`
    height: 53px;
    width: 100%;
    text-align: center;
    font-size: 16px;
    border-radius: 0;
    background-color: rgba(255, 204, 199, 0.4) !important;
    border: 1px solid #ffccc7 !important;
    svg {
        color: red;
    }
`;
const StyledContainer = styled.div`
    height: 53px;
    position: relative;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
`;

const mapTitleToTooltip = (title: string) => {
    const fields = [
        "Cashups",
        "Home",
        "Locations",
        "Transactions",
        "Reports",
        "Setup",
    ];
    if (fields.includes(title)) {
        return ToolTips.NavBar[
            title as
                | "Cashups"
                | "Home"
                | "Locations"
                | "Transactions"
                | "Reports"
                | "Setup"
        ];
    } else {
        return null;
    }
};

export const QuantacoLogoPlatformLink = () => (
    <img
        src={"/android-chrome-192x192.png"}
        height="100%"
        // onClick={() => window.open("https://platform.quantaco.co")}
        // style={{ cursor: "pointer" }}
    />
);

interface Props {
    BodyComponent?: any;
    skipLayoutWrapper?: boolean;
}

const DefaultLayout: React.FC<Props> = ({
    BodyComponent,
    skipLayoutWrapper = false,
    children,
}) => {
    const router = useHistory();

    const [selectedNavOption] = useState<string | undefined>(
        getActiveNavOption({
            pathname: router.location.pathname,
        })
    );

    const onNavBarItemClick = (path: string): void => {
        router.push(path);
    };

    const openCashupHelpPortal = () => {
        window.open(
            "https://quantaco.notion.site/Cashup-47a51c7500f245a59c0c55e084d5ba32"
        );
    };
    const { isLoading } = useLoader();

    return process.env.REACT_APP_SERVICE_DOWN ? (
        <ServiceDown />
    ) : (
        <Layout style={{ minHeight: "100vh" }}>
            <Header style={{ backgroundColor: WHITE, padding: "0px 28px" }}>
                {
                    <>
                        <FlexBoxVerticalItems>
                            <QuantacoLogoPlatformLink />
                            <Menu
                                mode="horizontal"
                                defaultSelectedKeys={
                                    selectedNavOption
                                        ? [selectedNavOption]
                                        : undefined
                                }
                                style={{ width: "70%" }}
                            >
                                {NavBarOptions.map((option) => {
                                    if (option.type === "item") {
                                        if (mapTitleToTooltip(option.title)) {
                                            return (
                                                <Menu.Item
                                                    key={option.route}
                                                    onClick={() =>
                                                        onNavBarItemClick(
                                                            option.route
                                                        )
                                                    }
                                                >
                                                    <Tooltip
                                                        title={mapTitleToTooltip(
                                                            option.title
                                                        )}
                                                        placement="bottom"
                                                        mouseEnterDelay={0.5}
                                                    >
                                                        {option.title}
                                                    </Tooltip>
                                                </Menu.Item>
                                            );
                                        }
                                        return (
                                            <Menu.Item
                                                key={option.route}
                                                onClick={() =>
                                                    onNavBarItemClick(option.route)
                                                }
                                            >
                                                {option.title}
                                            </Menu.Item>
                                        );
                                    } else {
                                        return (
                                            <SubMenu
                                                key={option.route}
                                                title={
                                                    mapTitleToTooltip(
                                                        option.title
                                                    ) ? (
                                                        <Tooltip
                                                            title={mapTitleToTooltip(
                                                                option.title
                                                            )}
                                                            placement="bottom"
                                                            mouseEnterDelay={0.5}
                                                        >
                                                            {option.title}
                                                        </Tooltip>
                                                    ) : (
                                                        option.title
                                                    )
                                                }
                                            >
                                                {option.suboptions!.map(
                                                    (suboption) => (
                                                        <Menu.Item
                                                            key={suboption.title}
                                                            onClick={() =>
                                                                onNavBarItemClick(
                                                                    suboption.route
                                                                )
                                                            }
                                                        >
                                                            {suboption.title}
                                                        </Menu.Item>
                                                    )
                                                )}
                                            </SubMenu>
                                        );
                                    }
                                })}
                            </Menu>

                            <FlexBoxVerticalItems>
                                {/* TEMPORARILY DISABLED TILL FUNCTIONALITY IS REQUIRED */}
                                {/*
                                <Badge count={5}>
                                    <NotificationsDropdown />
                                        </Badge> */}

                                <HelpSectionDropdown
                                    onClick={openCashupHelpPortal}
                                />

                                <UserAvatarDropdown />
                            </FlexBoxVerticalItems>
                        </FlexBoxVerticalItems>
                    </>
                }
            </Header>
            {process.env.REACT_APP_OUTAGE ? (
                <StyledContainer>
                    <StyledAlert
                        type="warning"
                        message={process.env.REACT_APP_OUTAGE}
                    ></StyledAlert>
                </StyledContainer>
            ) : null}
            <Content style={{ padding: skipLayoutWrapper ? "0px" : "16px" }}>
                {skipLayoutWrapper ? (
                    <ErrorBoundaryWrapper>
                        {BodyComponent ? <BodyComponent /> : children}
                    </ErrorBoundaryWrapper>
                ) : (
                    <SiteLayoutContent>
                        <ErrorBoundaryWrapper>
                            {BodyComponent ? <BodyComponent /> : children}
                        </ErrorBoundaryWrapper>
                    </SiteLayoutContent>
                )}
            </Content>
            <Footer style={{ textAlign: "center" }}>
                {`Quantaco ©${new Date().getFullYear()} All rights reserved.`}
            </Footer>
            {isLoading && (
                <LoadingContainerWhole>
                    <Spin size="large" />
                </LoadingContainerWhole>
            )}
        </Layout>
    );
};

export { DefaultLayout };
