import { DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { FieldData } from "@interfaces";
import { AntDFormState, AntDFormStateForGaming } from "@types";
import { Button, Col, Form, Input, Radio, Row, Select, Tooltip } from "antd";
import { DraggableModal } from "Components/DraggableModal";
import { StyledAntDInput } from "Components/MoneyInput/MoneyInputV2";
import cloneDeep from "lodash/cloneDeep";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import {
    clearLastFormFieldIfEmpty,
    convertFormDataToKeyValueWithTouched,
} from "utils/utilities";
import { Field } from "./SalesCountModalFormV2";
import { applyTooltipToModalHeader, positiveNumberOnlyValidator } from "../utils";
import { MoneyFormField } from "../Forms/Components/MoneyFormFields";
import { extractFieldName } from "Pages/CashupHome/CashupTabs/POS/utils";
import { SplitModalField } from "Components/SplitModalField/SplitModalField";
import { useForm } from "antd/lib/form/Form";
import { CustomerInformationModal } from "../CustomerInformationModal";
import { TenderTypes } from "Pages/CashupHome/CashupTabs/POS/@types";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";

export const StyledTitleRow = styled(Row)`
    & > div {
        font-size: 12px;
        font-weight: bold;
        color: #626e84;
    }
`;

export const StyledInput = styled(Input)`
    border-radius: 4px;
    background: #f9fafb;

    &:focus {
        border-color: white;
        background: white;
        border: 1px solid #1a81c7;
        box-shadow: 0 0 1px 2px #dbe7fa;
        border-radius: 4px;
    }
`;

export const StyledRadioGroups = styled(Radio.Group)`
    width: 100%;

    .ant-radio-button-wrapper {
        box-sizing: border-box;
        border-radius: 4px 0px 0px 4px;
        border: 1px solid #1a81c7 !important;
        background: #fffff;
        width: 50%;
        color: #1a81c7;
        font-size: 12px;
        padding: 0px;
        text-align: center;

        &:nth-child(2) {
            border-radius: 0px 4px 4px 0px;
        }
    }

    .ant-radio-button-wrapper-checked {
        background: #dbe7fa !important;
    }
`;

const StyledRow = styled(Row)`
    .ant-form-item {
        margin-bottom: 4px;
        height: 100%;
    }
`;

const StyledSelect = styled(Select)<{ disabled?: boolean }>`
    ${(props) =>
        props.disabled === false &&
        `.ant-select-selector {
        color: #353940 !important;
        background: #f9fafb !important;
        border: 1px solid #dadfe7 !important;
        border-radius: 4px !important;
    }`}

    ${(props) =>
        props.disabled === true &&
        `.ant-select-selector {
            border: 1px solid #dadfe7 !important;
            border-radius: 4px !important;
        }`}
`;

export const StyledEmptyLabel = styled.div`
    height: 100%;
    width: 10px;
`;

interface ActiveButton {
    save: number;
    add: number;
    delete: number;
}

const CustomFormField = ({
    field: { type, changeGST, name, ...rest },
    addNewRow,
    remove,
    openEntityDataModal,
    onAmountChange,
    onTenderTypeChange,
    disabled,
    entityModalValue,
    isLastItem,
    customerInformationNeeded,
}: {
    field: Field;
    addNewRow: (saveOnly?: boolean) => () => void;
    remove?: () => void;
    openEntityDataModal?: () => void;
    onAmountChange?: (data: any) => void;
    onTenderTypeChange: (value: unknown) => void;
    disabled?: boolean;
    entityModalValue?: string;
    isLastItem: boolean;
    customerInformationNeeded?: boolean;
    loading?: boolean;
    activeAdd: boolean;
    activeSave: boolean;
    activeDelete: boolean;
}) => {
    switch (type.name) {
        case "Money":
            return (
                <MoneyFormField
                    name={name}
                    {...rest}
                    disabled={disabled}
                    onChange={changeGST ? onAmountChange : undefined}
                />
            );
        case "GST":
            return <StyledAntDInput prefix="$" disabled={disabled} />;
        case "Text":
            return <StyledInput {...rest} disabled={disabled} />;
        case "Dropdown":
            return (
                <StyledSelect disabled={disabled} onChange={onTenderTypeChange}>
                    {type.options.map((currentOption, index) => (
                        <Select.Option value={currentOption.value} key={index}>
                            {currentOption.label}
                        </Select.Option>
                    ))}
                </StyledSelect>
            );
        case "Modal":
            return (
                <SplitModalField
                    openModal={openEntityDataModal}
                    disabled={disabled}
                    buttonText={entityModalValue}
                    customerInformationNeeded={customerInformationNeeded}
                />
            );
        case "Radio":
            return (
                <StyledRadioGroups
                    options={type.options}
                    optionType="button"
                    {...rest}
                />
            );

        case "Button":
            return (
                <div style={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <Button
                        style={{ backgroundColor: "#1A81C7" }}
                        icon={
                            <Tooltip
                                title="Save this row"
                                placement="top"
                                arrowPointAtCenter
                                autoAdjustOverflow
                            >
                                <SaveOutlined style={{ color: "#FFF" }} />
                            </Tooltip>
                        }
                        onClick={addNewRow(true)}
                        // loading={loading && activeSave}
                        htmlType="submit"
                    />
                    <Button
                        style={{ backgroundColor: "#1A81C7" }}
                        icon={
                            <Tooltip
                                title="Adding new row"
                                placement="top"
                                arrowPointAtCenter
                                autoAdjustOverflow
                            >
                                <PlusOutlined style={{ color: "#FFF" }} />
                            </Tooltip>
                        }
                        onClick={addNewRow()}
                        // loading={loading && activeAdd}
                        disabled={!isLastItem || disabled}
                    />
                    <Button
                        style={{ backgroundColor: "#1A81C7" }}
                        icon={
                            <Tooltip
                                title="Delete this row"
                                placement="top"
                                arrowPointAtCenter
                                autoAdjustOverflow
                            >
                                <DeleteOutlined style={{ color: "#FFF" }} />
                            </Tooltip>
                        }
                        // loading={loading && activeDelete}
                        onClick={remove}
                    />
                </div>
            );

        default:
            throw new Error("This should be impossible");
    }
};

const PaymentFormComponent = ({
    formIndex,
    fields,
    removeForm,
    formState,
    onFormChange,
    addNewRow,
    initialEntityData,
    tenderAccounts,
    isLastItem,
    disabled,
    pushForms,
    loading,
    activeAdd,
    activeSave,
    activeDelete,
    setActive,
}: {
    formIndex: number;
    fields: Field[];
    removeForm: () => void;
    formState: {
        formData: FieldData[];
    };
    onFormChange: (data: FieldData[], EntityData?: FieldData[]) => void;
    initialEntityData: FieldData[];
    addNewRow: () => void;
    tenderAccounts: ExtendedAccountItem[];
    isLastItem: boolean;
    disabled?: boolean;
    pushForms: (formData: FieldData[], entityData: FieldData[]) => void;
    loading?: boolean;
    activeAdd: boolean;
    activeSave: boolean;
    activeDelete: boolean;
    setActive: (key: keyof ActiveButton) => void;
}) => {
    const firstUpdate = useRef(true);
    const extractValueToBalance = useMemo(() => {
        try {
            const Amount = convertFormDataToKeyValueWithTouched(formState.formData)[
                "Amount"
            ];
            if (Amount === undefined) return undefined;
            return parseFloat(Amount.value);
        } catch (e) {
            console.log("Things were breaking check this");
            console.log(formState.formData);
            return undefined;
        }
    }, [formState.formData]);
    const [isEntityDataOpen, setIsEntityDataOpen] = useState(false);
    const [valueToBalance, setValueToBalance] = useState<number | undefined>(
        extractValueToBalance
    );
    const [entityDataFormFields, setEntityDataFormFields] =
        useState<FieldData[]>(initialEntityData);
    const isChequeInitial =
        formState.formData.find((item) => item.name === "Tender") &&
        tenderAccounts.find((account) => account.name === TenderTypes.CHEQUE)
            ? formState.formData.find((item) => item.name === "Tender")!.value ===
              tenderAccounts.find((account) => account.name === TenderTypes.CHEQUE)
                  ?.account_id
            : false;

    const [isTenderTypeCheque, setIsTenderTypeCheque] =
        useState<boolean>(isChequeInitial);
    const [form] = useForm();

    const extractEntityText = useMemo(() => {
        if (entityDataFormFields === undefined) return "";
        let filled = true;
        if (entityDataFormFields.length === 0) {
            filled = false;
        } else {
            entityDataFormFields.forEach((entity) => {
                const name = Array.isArray(entity.name)
                    ? entity.name[0]
                    : entity.name;
                if (!entity.value && name !== "place_id") {
                    filled = false;
                }
            });
        }
        return filled ? "Filled" : "";
    }, [entityDataFormFields]);

    const addNewRowWithValidation = (saveOnly?: boolean) => () => {
        form.validateFields()
            .then(() => {
                form.submit();
                if (!saveOnly) {
                    addNewRow();
                    setActive("add");
                } else {
                    setActive("save");
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        onFormChange(formState.formData, entityDataFormFields);
    }, [entityDataFormFields]);

    const customerInformationNeeded = useMemo(() => {
        if (!valueToBalance) return false;

        return extractEntityText === "";
    }, [extractEntityText, valueToBalance]);

    return (
        <>
            <Form
                layout="vertical"
                name={`PaymentForm-${formIndex}`}
                fields={formState.formData}
                onFieldsChange={(_, allFields) => {
                    onFormChange(allFields, entityDataFormFields);
                }}
                onFinish={(data) => {
                    const result: Array<FieldData> = [];
                    for (const [key, value] of Object.entries(data)) {
                        result.push({
                            name: key,
                            value: value,
                        });
                    }
                    pushForms(result, entityDataFormFields);
                }}
                style={{ width: "100%" }}
                form={form}
                disabled={disabled}
            >
                <StyledRow gutter={8}>
                    {fields.map((field) => (
                        <Col span={field.colSpan} key={field.key}>
                            <Form.Item
                                key={field.key}
                                label={
                                    formIndex === 0 ? (
                                        field.isButton ? (
                                            <StyledEmptyLabel />
                                        ) : (
                                            field.name
                                        )
                                    ) : null
                                }
                                name={field.name}
                                rules={positiveNumberOnlyValidator(field, [
                                    {
                                        required: field.required,
                                        message: "Please enter a value",
                                    },
                                ])}
                            >
                                {CustomFormField({
                                    field: field,
                                    remove: () => {
                                        removeForm();
                                        setActive("delete");
                                    },
                                    openEntityDataModal: () =>
                                        setIsEntityDataOpen(true),
                                    onAmountChange: (data) => {
                                        setValueToBalance(parseFloat(data));
                                    },
                                    onTenderTypeChange: (value) => {
                                        const isCheque = tenderAccounts.find(
                                            (tenderItem) =>
                                                tenderItem.account_id === value
                                        );
                                        if (!isCheque) {
                                            setIsTenderTypeCheque(false);
                                        } else {
                                            setIsTenderTypeCheque(
                                                isCheque.name === TenderTypes.CHEQUE
                                            );
                                        }
                                    },
                                    entityModalValue: extractEntityText,
                                    addNewRow: addNewRowWithValidation,
                                    isLastItem,
                                    customerInformationNeeded,
                                    disabled: disabled,
                                    loading: loading,
                                    activeAdd: activeAdd,
                                    activeDelete: activeDelete,
                                    activeSave: activeSave,
                                })}
                            </Form.Item>
                        </Col>
                    ))}
                </StyledRow>
            </Form>

            <DraggableModal
                title={applyTooltipToModalHeader("Customer Details")}
                visible={isEntityDataOpen}
                onCancel={() => setIsEntityDataOpen(false)}
                onOk={() => setIsEntityDataOpen(false)}
                destroyOnClose
                style={{ top: 30, borderRadius: "12px" }}
                bodyStyle={{ padding: "0px 24px 20px 24px", width: "50vw" }}
                zIndex={1000}
                cancelButtonProps={{ style: { display: "none" } }}
                footer={null}
                noBottomBorder
                okText={"Create User"}
            >
                <CustomerInformationModal
                    closeModal={() => setIsEntityDataOpen(false)}
                    formFields={entityDataFormFields}
                    setFormFields={setEntityDataFormFields}
                    isTenderTypeCheque={isTenderTypeCheque}
                />
            </DraggableModal>
        </>
    );
};
const PaymentForm = React.memo(PaymentFormComponent);

interface Props {
    fields: Field[];
    initialDataForForms: AntDFormStateForGaming[];
    onSubmit: (data: AntDFormStateForGaming[]) => void;
    onRemove: (data: AntDFormStateForGaming[]) => void;
    tenderAccounts: ExtendedAccountItem[];
    disabled?: boolean;
    setFormNeedToSave?: (category: string, isToAdd: boolean) => void;
    loading?: boolean;
}

const initialValue: ActiveButton = { save: -1, add: -1, delete: -1 };

export const PaymentModalFormV3: React.FC<Props> = ({
    fields,
    onSubmit,
    onRemove,
    initialDataForForms,
    tenderAccounts,
    disabled,
    setFormNeedToSave,
    loading,
}) => {
    const [formsData, setFormsData] = useState(initialDataForForms);
    const [activeButton, setActiveButton] = useState(initialValue);
    /**
     * Checks if the Payout transaction passed validation requirements
     *
     * @param formData
     * @param EntityData
     * @returns
     */
    const isPayoutValid = (formData: FieldData[], EntityData?: FieldData[]) => {
        const excludedFields = ["Button", "Customer Details"];
        let inValidFlag = false;

        if (formData.length === 0) {
            return false;
        }

        formData.forEach((currentFormField) => {
            if (
                !excludedFields.includes(extractFieldName(currentFormField)) &&
                currentFormField.value === undefined
            ) {
                inValidFlag = true;
            } else if (
                (Array.isArray(currentFormField.name) &&
                    currentFormField.name[0] === "Amount") ||
                (!Array.isArray(currentFormField.name) &&
                    currentFormField.name === "Amount")
            ) {
                if (isNaN(Number(currentFormField.value))) {
                    inValidFlag = true;
                }
            }
        });
        if (!EntityData || EntityData.length === 0) {
            inValidFlag = true;
        }
        EntityData?.forEach((entity) => {
            const name = Array.isArray(entity.name) ? entity.name[0] : entity.name;
            if (!entity.value && name !== "place_id") {
                inValidFlag = true;
            }
        });
        return !inValidFlag;
    };

    const submissionWrapper = useCallback(
        (formDataDeepClone: AntDFormStateForGaming[]) => {
            const payoutTransactions = [...formDataDeepClone];
            const payoutTransactionsClone: AntDFormStateForGaming[] = [];
            for (let index = 0; index < payoutTransactions.length; index++) {
                const currentTransaction = payoutTransactions[index];
                // Keep the transaction if valid, otherwise exclude
                if (
                    isPayoutValid(
                        currentTransaction.formData,
                        currentTransaction.EntityData
                    )
                ) {
                    payoutTransactionsClone.push(currentTransaction);
                }
            }

            return payoutTransactionsClone;
        },
        []
    );

    /**
     * Triggered on "onSubmit" form events.
     *
     */
    const addNewRow = () => {
        const formDataDeepClone = cloneDeep(formsData);

        formDataDeepClone.push({
            formData: [],
            formID: Math.random().toString(),
            EntityData: [],
        });
        setFormsData(formDataDeepClone);
        onSubmit(formDataDeepClone);
    };

    /**
     *  Removed a form at a specified index
     * @param formIndex
     */
    const removeForm = (formIndex: number) => {
        const formDataDeepClone = cloneDeep(formsData);

        formDataDeepClone.splice(formIndex, 1);
        const currentFormFieldsData = clearLastFormFieldIfEmpty(
            formDataDeepClone
        ) as AntDFormState[];

        if (currentFormFieldsData.length === 0) {
            setFormsData([
                {
                    formData: [],
                    formID: Math.random().toString(),
                    EntityData: [],
                },
            ]);
        } else {
            setFormsData(formDataDeepClone);
        }

        const parsedResults = submissionWrapper(formDataDeepClone);
        onRemove(parsedResults);
        setFormNeedToSave && setFormNeedToSave("Payout", false);
    };

    /**
     * Triggered on "onFieldsChange" form events.
     * @param rowIndex
     * @param formData
     * @param entityData
     */

    const updateFormState = (
        rowIndex: number,
        formData: FieldData[],
        entityData?: FieldData[]
    ) => {
        const formDataDeepClone = cloneDeep(formsData);
        const CurrentItem = formDataDeepClone[rowIndex];
        //because of entityDataParser, for forms not having entityData, it will always be empty array[]
        entityData !== undefined && entityData.length !== 0
            ? formDataDeepClone.splice(rowIndex, 1, {
                  formData: formData,
                  formID: CurrentItem.formID,
                  EntityData: entityData,
              })
            : formDataDeepClone.splice(rowIndex, 1, {
                  formData: formData,
                  formID: CurrentItem.formID,
              });

        setFormsData(formDataDeepClone);
        if (isPayoutValid(formData, entityData)) {
            setFormNeedToSave && setFormNeedToSave("Payout", true);
        }
    };

    const pushForms = useCallback(
        (formData: FieldData[], entityData: FieldData[]) => {
            if (isPayoutValid(formData, entityData)) {
                const formDataDeepClone = cloneDeep(formsData);
                onSubmit(
                    submissionWrapper(
                        formDataDeepClone.length === 0
                            ? [
                                  {
                                      formData: [],
                                      formID: Math.random().toString(),
                                  },
                              ]
                            : formDataDeepClone
                    )
                );
                setFormNeedToSave && setFormNeedToSave("Payout", false);
            }
        },
        [onSubmit, formsData, setFormNeedToSave, submissionWrapper]
    );

    useEffect(() => {
        if (!loading) {
            setActiveButton(initialValue);
        }
    }, [loading]);

    return (
        <>
            <StyledRow>
                {formsData.map((currentFormData, index) => (
                    <PaymentForm
                        key={currentFormData.formID}
                        formIndex={index}
                        removeForm={() => {
                            removeForm(index);
                        }}
                        fields={fields}
                        formState={currentFormData}
                        onFormChange={(data, entityData) =>
                            updateFormState(index, data, entityData)
                        }
                        addNewRow={addNewRow}
                        initialEntityData={formsData[index].EntityData!}
                        tenderAccounts={tenderAccounts}
                        isLastItem={index == formsData.length - 1}
                        disabled={disabled}
                        pushForms={pushForms}
                        loading={loading}
                        activeAdd={activeButton.add === index}
                        activeSave={activeButton.save === index}
                        activeDelete={activeButton.delete === index}
                        setActive={(key: keyof ActiveButton) =>
                            setActiveButton({ ...activeButton, [key]: index })
                        }
                    />
                ))}
            </StyledRow>
        </>
    );
};
