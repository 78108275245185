import React, { useMemo } from "react";
import { TableRows } from "V2/Table/TableBody";
import { Table } from "V2/Table/Table";
import { PanelHOCComponentProps } from "../../Pages/CashupHome/CashupTabs/POS/utils/PanelHOC";
import { InitDataResponseType } from "../../Pages/CashupHome/CashupTabs/POS/@types";
import { createColumnNames } from "./SafePanel";
import { handleNaN, LocationDetailByCashupWithShiftType } from "utils/utilities";

interface Props extends PanelHOCComponentProps {
    tableData: InitDataResponseType[];
    totals: {
        total: number;
        shiftType: number;
    }[];
    tableLength: number;
    safeLocationDetailByCashupId: LocationDetailByCashupWithShiftType;
}

const hardCodedTableColumns = [
    { title: "Total" },
    { title: "Budget" },
    { title: "Variance" },
];

export const TotalBudgetsVarianceTable: React.FC<Props> = ({
    onClickHandler,
    isInFocus,
    tableData,
    totals,
    tableLength,
    safeLocationDetailByCashupId,
}) => {
    const budgets = useMemo(() => {
        const tempBudgets = tableData.map((data) => {
            // @ts-ignore
            return data.safe_data?.budget ?? 0;
        });
        return tempBudgets;
    }, [tableData]);

    const dataSources: TableRows[] = useMemo(() => {
        let tempColumns: {
            value: number | string;
            readOnly: boolean;
        }[][] = totals.map(() => {
            return createColumnNames(tableData, safeLocationDetailByCashupId).map(
                () => {
                    return {
                        value: 0,
                        readOnly: true,
                    };
                }
            );
        });

        if (tempColumns.length > 3) {
            tempColumns = tempColumns.slice(0, 3);
        }

        return tempColumns.map((currentColumn, currentIndex) => {
            currentColumn.splice(0, 1, {
                value: hardCodedTableColumns[currentIndex]?.title ?? "?",
                readOnly: true,
            });

            if (currentIndex === 0) {
                // TODO Assert that totals is same length as columns
                totals.forEach((total, index) => {
                    currentColumn.splice(index + 1, 1, {
                        value: handleNaN(total.total ?? 0),
                        readOnly: true,
                    });
                });
            } else if (currentIndex === 1) {
                // TODO Assert that budgets is same length as columns

                budgets.forEach((budget, index) => {
                    currentColumn.splice(index + 1, 1, {
                        value: handleNaN(budget ?? 0),
                        readOnly: true,
                    });
                });
            } else if (currentIndex === 2) {
                budgets.forEach((budget, index) => {
                    currentColumn.splice(index + 1, 1, {
                        value: handleNaN(totals[index]?.total - budget ?? 0),
                        readOnly: true,
                    });
                });
            }

            return currentColumn;
        });
    }, [tableData]);

    const tableConfigs = useMemo(
        () => createColumnNames(tableData, safeLocationDetailByCashupId),
        [tableLength]
    );

    return (
        <>
            <Table
                onCellValueChanged={() => null}
                dataSources={dataSources}
                tableConfigs={tableConfigs}
                onClickHandler={onClickHandler}
                onSelectedCellChange={() => null}
                isInFocus={isInFocus}
                evenDistribution
                tableWithoutFooter
            />
        </>
    );
};
