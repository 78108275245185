import { ApiCall } from "../apiCall";

export async function getVenueById(venueId: string) {
    return await ApiCall(
        undefined,
        "AUTHENTICATED",
        "VENUES",
        "list",
        "GET",
        {},
        `/${venueId}/`
    )
        .then((res) => res.json())
        .then((res) => res.data.venue);
}
