/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type EntityData={
    first_name: string;
    last_name: string;
    address: string;
    id_document_type:string;
    id_number: string;
    place_id: string;    
}


export type Entity = {
    name: string;
    entity_type: number;
    entity_data: EntityData;
}

export enum IdDoucumentType  {
    AUSTRALIAN_DRIVER_LICENCE = 1,
    "FOREIGN_DRIVER_LICENCE" = 2,
    "PHOTO_ID_CARD" = 3,
    "PASSPORT" = 4,
    "PROOF_OF_AGE_CARD" = 5,
    "OTHER" = 6,
}

export enum EntityTypes {
    EMPLOYEE = 1,
    "PERSON" = 2,
    "ORGANISATION" = 3,
    "KENO" = 4,
    "TAB" = 5
}
