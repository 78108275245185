import {
    getPaymentTypesArrayWithPayout,
    PaymentTypeEnumWithPayout,
} from "@Constants/enum";
import { AntDFormStateForGaming } from "@types";
import { PaymentTransactionInit } from "Pages/CashupHome/CashupTabs/POS/@types";
import { Field } from "Pages/CashupHome/ExcelTable/TransactionForms/SalesCountModalFormV2";
import { entityDataParser } from "Pages/CashupHome/ExcelTable/TransactionsFormPOS";
import { useMemo } from "react";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";

interface UseTransactionGaming {
    accountsData: ExtendedAccountItem[];
    PaymentTransactionData: PaymentTransactionInit[];
}

export const PaymentTransactionParserForGaming = (
    PaymentTransactions: PaymentTransactionInit[]
): AntDFormStateForGaming[] => {
    const ParsedPaymentTransactions: AntDFormStateForGaming[] =
        PaymentTransactions.map((currentPaymentTransaction) => {
            return {
                formData: [
                    {
                        name: "Reason",
                        value: currentPaymentTransaction.payment_type_id.toString(),
                    },
                    {
                        name: "Description",
                        value: currentPaymentTransaction.memo,
                    },
                    {
                        name: "Amount",
                        value: currentPaymentTransaction.amount?.toString(),
                    },
                    {
                        name: "Tender",
                        value: currentPaymentTransaction.account_split[0]
                            ?.account_id,
                    },
                ],
                formID: Math.random().toString(),
                EntityData: entityDataParser(
                    currentPaymentTransaction.entity?.entity_data
                ),
            };
        });
    ParsedPaymentTransactions.push({
        formData: [],
        formID: Math.random().toString(),
        EntityData: [],
    });
    return ParsedPaymentTransactions;
};

const initialPaymentFormData = () => {
    return {
        name: "Reason",
        value: PaymentTypeEnumWithPayout["Payout"].toString(),
    };
};

const useTransactionGaming = ({
    accountsData,
    PaymentTransactionData = [],
}: UseTransactionGaming) => {
    const paymentFieldsHelper = (
        tenderAccounts: ExtendedAccountItem[],
        isPositive?: boolean
    ): Field[] => {
        const paymentFields = [
            {
                name: "Reason",
                required: true,
                type: {
                    name: "Dropdown",
                    options: getPaymentTypesArrayWithPayout()
                        .map((paymentType) => {
                            return {
                                value: paymentType.value,
                                label: paymentType.name,
                            };
                        })
                        .filter((currentPaymentType) => {
                            return currentPaymentType.value === "3";
                        }),
                },
                colSpan: 3,
            },
            {
                name: "Description",
                required: true,
                type: {
                    name: "Text",
                },
                colSpan: 8,
            },
            {
                name: "Amount",
                required: true,
                type: { name: "Money", positiveValueOnly: isPositive },
                colSpan: 3,
                changeGST: true,
            },
            {
                name: "Tender",
                required: true,
                type: {
                    name: "Dropdown",
                    options: tenderAccounts.map((tenderAccount) => {
                        return {
                            value: tenderAccount.account_id,
                            label: tenderAccount.name,
                        };
                    }),
                },
                colSpan: 5,
            },
            {
                name: "Customer Details",
                type: {
                    name: "Modal",
                },
                colSpan: 3,
            },
            { name: "Button", type: { name: "Button" }, colSpan: 2, isButton: true },
        ];

        // Resolve type error
        //@ts-ignore
        return paymentFields;
    };

    const tenderAccounts = useMemo(
        () =>
            accountsData.filter(
                (currentAccountItem) => currentAccountItem.payout_tender_type
            ),
        [accountsData]
    );

    const paymentFields: Field[] = useMemo(
        () => paymentFieldsHelper(tenderAccounts, true),
        [tenderAccounts]
    );

    const initialPaymentTransactionsDataWithAppendedFormID: AntDFormStateForGaming[] =
        useMemo(
            () =>
                PaymentTransactionData.length > 0
                    ? PaymentTransactionParserForGaming(PaymentTransactionData)
                    : [
                          {
                              formData: [initialPaymentFormData()],
                              formID: Math.random().toString(),
                              EntityData: [],
                          },
                      ],
            []
        );

    return {
        paymentFields,
        tenderAccounts,
        initialPaymentTransactionsDataWithAppendedFormID,
    };
};

export default useTransactionGaming;
