import styled from "styled-components";

export const PromptsContainer = styled.div`
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    padding: 30px 80px;
`;

export const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999; /* Ensure it is above the content */
`;

export const MainContainer = styled.div`
    position: relative;
`;
