import { ApiCall } from "../apiCall";

export const getShiftPrompts = async (venueId: string, shiftDate: string) => {
    const requestUrl = `/${venueId}/venue-shift-report-prompts?shift_date=${shiftDate}`;
    return await ApiCall(
        undefined,
        "AUTHENTICATED",
        "VENUES",
        "list",
        "GET",
        {},
        requestUrl
    );
};
