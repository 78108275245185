import { SaleV2Item } from "@generated";
import { Col, Form, Row, Input, Divider, Button } from "antd";
import React, { useCallback } from "react";
import styled from "styled-components";
import { extractGSTValue, isNumber } from "../../../utils/utilities";
import FocusLock, { AutoFocusInside } from "react-focus-lock";

interface Props {
    onModalClose?: () => void;
    onModalSave: (data: any) => void;
    onReset: () => void;
    initialValue: SaleV2Item & {
        transaction_id?: string | undefined;
    };
}

const StyledInput = styled(Input)`
    border-radius: 4px;
    background: #f9fafb;

    &:focus {
        border-color: white;
        background: white;
        border: 1px solid #1a81c7;
        box-shadow: 0 0 1px 2px #dbe7fa;
        border-radius: 4px;
    }
    .ant-input {
        background: #f9fafb;
    }
`;

export const StyledSaveButton = styled(Button)`
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;

    &,
    &:hover,
    &:active,
    &:focus {
        background: #1a81c7;
        border-color: #1a81c7;
        color: white;
    }
`;
export const StyledCancelButton = styled(Button)`
    border-radius: 4px;
    font-size: 14px;
    line-height: 18px;
    margin-right: 8px;

    &,
    &:hover,
    &:active,
    &:focus {
        background: #f0f2f5;
        border-color: #f0f2f5;
        color: #626e84;
    }
`;

export const StyledActionContainer = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: flex-end;
`;

const gstInitialValueHelper = (gstValue: number) => {
    if (!isNaN(gstValue)) {
        return String(gstValue);
    }
};

export const SalesTotalForm: React.FC<Props> = ({
    onModalClose,
    onModalSave,
    onReset,
    initialValue,
}) => {
    const [form] = Form.useForm();

    const onSalesTotalChange = (value: string) => {
        if (isNumber(parseFloat(value))) {
            form.setFieldsValue({
                GST: extractGSTValue(parseFloat(value)).toString(),
            });
        } else if (value === "") {
            form.setFieldsValue({
                GST: "0",
            });
        }
    };
    const onFinishEvent = (data: any) => {
        onModalSave(data);
        onModalClose?.();
    };

    const handleInitialGST = useCallback(() => {
        if (initialValue.tax_amount) {
            return initialValue.tax_amount;
        }
        return gstInitialValueHelper(extractGSTValue(initialValue.amount!));
    }, [initialValue]);
    return (
        <FocusLock>
            <Form
                layout="vertical"
                style={{ padding: "10px 20px" }}
                onFinish={onFinishEvent}
                form={form}
                initialValues={{
                    salesTotal:
                        initialValue.amount === 0 ? undefined : initialValue.amount,
                    GST: handleInitialGST() === 0 ? undefined : handleInitialGST(),
                }}
                autoComplete="off"
            >
                <Row gutter={20}>
                    <Col span={16}>
                        <AutoFocusInside>
                            <Form.Item
                                required
                                label="Sales total"
                                name="salesTotal"
                                className="numberInput"
                            >
                                <StyledInput
                                    prefix="$"
                                    onChange={(event) =>
                                        onSalesTotalChange(event.target.value)
                                    }
                                    type="text"
                                    // This allows for decimal values (React forces "number" fields to be Integers only)
                                    pattern="^(-?[0-9]+([.][0-9]+)?)$"
                                />
                            </Form.Item>
                        </AutoFocusInside>
                    </Col>
                    <Col span={8}>
                        <Form.Item
                            required
                            label="GST"
                            name="GST"
                            className="numberInput"
                        >
                            <StyledInput
                                prefix="$"
                                type="text"
                                // This allows for decimal values (React forces "number" fields to be Integers only)
                                pattern="^(-?[0-9]+([.][0-9]+)?)$"
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Divider style={{ marginTop: 0 }} />
                <StyledActionContainer>
                    <StyledCancelButton onClick={onModalClose}>
                        Cancel
                    </StyledCancelButton>
                    <StyledCancelButton onClick={onReset}>Reset</StyledCancelButton>
                    <StyledSaveButton htmlType="submit">Save</StyledSaveButton>
                </StyledActionContainer>
            </Form>
        </FocusLock>
    );
};
