// Define the initial context state
import React, { useMemo, useState } from "react";
import {
    VenueSelectionContext,
    VenueSelectionProviderProps,
} from "./VenueSelectionContextConstants";
import dayjs from "dayjs";
import { SubmissionStatus } from "Redux/StateSlices/GroupData/VenuesAPI";

export const VenueSelectionProvider: React.FC<VenueSelectionProviderProps> = ({
    children,
}) => {
    const [venueId, setVenueIdVar] = useState<string>(
        localStorage.getItem("selectedVenueId") || ""
    );
    const [channelId, setChannelId] = useState<string>("");
    const [activePanel, setActivePanel] = useState<string>("");
    const [shiftDate, setShiftDate] = useState<string>(
        localStorage.getItem("selectedDate") || dayjs().format("DD-MM-YYYY")
    );
    const [shiftStatus, setShiftStatus] = useState<"" | SubmissionStatus>("");
    const [venueName, setVenueNameVar] = useState<string>(
        localStorage.getItem("selectedVenueName") || ""
    );

    function setVenueId(venueId: string) {
        localStorage.setItem("selectedVenueId", venueId);
        setVenueIdVar(venueId);
    }

    function setVenueName(venueId: string) {
        localStorage.setItem("selectedVenueName", venueId);
        setVenueNameVar(venueId);
    }

    function onShiftDateChange(shiftDate: string) {
        setShiftDate(shiftDate);
        localStorage.setItem("selectedDate", shiftDate);
    }

    const formattedDate = useMemo(() => {
        const parsedDate = dayjs(shiftDate, "DD-MM-YYYY");
        return parsedDate.format("YYYY-MM-DD");
    }, [shiftDate]);
    return (
        <VenueSelectionContext.Provider
            value={{
                venueId,
                setVenueId,
                shiftDate,
                onShiftDateChange,
                setShiftStatus,
                shiftStatus,
                formattedDate,
                venueName,
                setVenueName,
                channelId,
                setChannelId,
                activePanel,
                setActivePanel,
            }}
        >
            {children}
        </VenueSelectionContext.Provider>
    );
};
