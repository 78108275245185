import { ApiCall } from "../apiCall";

export async function getPosIntegrationData(venueId: string, shiftDate: string) {
    return await ApiCall(
        undefined,
        "AUTHENTICATED",
        "POSINTEGRATION",
        "get",
        "POST",
        {},
        `?cashup_date=${shiftDate}&venue_id=${venueId}`
    )
        .then((res) => res.json())
        .then((res) => res);
}
