import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { getDates } from "./getDates";
import {
    DayAndPosClass,
    CARD_FIELD,
    CASH_VARIANCE_FIELD,
    CASH_FIELD,
    addMissingFieldsWithZeroValue,
} from "./CashupDetailedReport";
import { LocationType } from "../CashupReportModel";

export function PosParentDetailedReportTransformer(
    detailedReportData: {
        posSplitByClassTypeData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            account: { __typename: string; name: string };
                            class: {
                                __typename: string;
                                classType: number;
                                name: string;
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        cashVarianceForAllPosLocationsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        pettyCashData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                totalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            transactions: { __typename: string; memo: string };
                        }[]
                      | undefined;
              }
            | undefined;
        depositsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                totalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            transactions: { __typename: string; memo: string };
                            account: { __typename: string; name: string };
                        }[]
                      | undefined;
              }
            | undefined;
        transfersData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          splitDate: { __typename: string; day: string };
                          totalIncTax: number;
                      };
                      transactions: { __typename: string; memo: string; id: string };
                  }[];
              }
            | undefined;
        gamingStatsData:
            | {
                  cube: {
                      __typename: string;
                      gamingStats: {
                          __typename: string;
                          date: { __typename: string; value: string; day: string };
                          turnover: number;
                          totalWins: number;
                          netProfit: number;
                          returnToHouse: number;
                      };
                  }[];
              }
            | undefined;
        gamingVarianceData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                  }[];
              }
            | undefined;
        gamingPayoutsData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                  }[];
              }
            | undefined;
        cashTotalsForAllPosLocationsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        cardTotalsForPosData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        safeData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          safeDataTotal: number;
                          safeDataVariance: number;
                          safeSignedOff: string;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          // detailedReportData.totalSalesForAllPosLocationsData?.cube?.forEach(
                          //     (totalSalesItem) => {
                          //         const { splits, account } = totalSalesItem;
                          //         const { sumTotalIncTax, splitDate } = splits;
                          //         const { day } = splitDate;
                          //
                          //         console.log("Account name: ", account.name, " : ", sumTotalIncTax);
                          //
                          //         if (!DataMap[day]) {
                          //             DataMap[day] = {};
                          //             DataMap[day][account.name] = sumTotalIncTax;
                          //         } else {
                          //             if (!DataMap[day][account.name]) {
                          //                 DataMap[day][account.name] = sumTotalIncTax;
                          //             } else {
                          //                 DataMap[day][account.name] += sumTotalIncTax;
                          //             }
                          //         }
                          //     }
                          // );
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        crtData:
            | {
                  cube: {
                      __typename: string;
                      //                 DataMap[day][account.name] = sumTotalIncTax;
                      //             } else {
                      //                 DataMap[day][account.name] += sumTotalIncTax;
                      //             }
                      //         }
                      //     }
                      // );
                      cashup: {
                          __typename: string;
                          shiftDate: {
                              //                 DataMap[day][account.name] += sumTotalIncTax;
                              //             }
                              //         }
                              //     }
                              // );
                              __typename: string; //                 DataMap[day][account.name] += sumTotalIncTax;

                              //                 DataMap[day][account.name] += sumTotalIncTax;
                              //             }
                              //         }
                              //     }
                              // );
                              day: string;
                          };
                          cashCountCashVariance: number;
                          atmDataRefills: number | null; // );

                          // );
                          atmDataWithdrawls: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        atmData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountCashVariance: number;
                          atmDataRefills: number;
                          atmDataWithdrawls: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        wageringData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;

                          shiftDate: { __typename: string; day: string };
                          wageringDataSalesTotal: number;
                          wageringDataCommission: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;

                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        wageringPayoutsData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillTabData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillKenoData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        posParentLocationsData: {
            [parentLocationId: string]: {
                sales: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate?: { day: string };
                        };
                        account: { name: string };
                        class: { classType: number; name: string };
                    }[];
                };
                card: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
                cashVariance: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
                cash: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
            };
        };
    },
    selectedRange: { start: string; end: string } | undefined
) {
    if (!detailedReportData || !selectedRange) {
        return {};
    }
    const knownPosColumns: string[] = [];

    const DataMap: { [locationId: string]: DayAndPosClass } = {};

    Object.keys(detailedReportData.posParentLocationsData).forEach(
        (posParentLocationId) => {
            if (!DataMap[posParentLocationId]) {
                DataMap[posParentLocationId] = {};
            }
            // TODO Resolve the type issuer
            //@ts-ignore
            detailedReportData.posParentLocationsData?.[
                posParentLocationId
            ].sales?.cube.forEach((split) => {
                const { splits, class: posClass } = split;
                const { sumTotalIncTax, splitDate } = splits;

                const { day } = splitDate ?? { day: selectedRange.start };

                if (knownPosColumns.indexOf(posClass.name) === -1) {
                    knownPosColumns.push(posClass.name);
                }

                if (!DataMap[posParentLocationId][day]) {
                    DataMap[posParentLocationId][day] = {};
                    DataMap[posParentLocationId][day][posClass.name] =
                        sumTotalIncTax;
                } else {
                    if (!DataMap[posParentLocationId][day][posClass.name]) {
                        DataMap[posParentLocationId][day][posClass.name] =
                            sumTotalIncTax;
                    } else {
                        DataMap[posParentLocationId][day][posClass.name] +=
                            sumTotalIncTax;
                    }
                }
            });

            detailedReportData.posParentLocationsData[
                posParentLocationId
            ].card.cube.forEach((split) => {
                const { splits } = split;
                const { sumTotalIncTax, splitDate } = splits;

                const { day } = splitDate;

                if (!DataMap[posParentLocationId][day]) {
                    DataMap[posParentLocationId][day] = {};
                    DataMap[posParentLocationId][day][CARD_FIELD] = sumTotalIncTax;
                } else {
                    if (!DataMap[posParentLocationId][day][CARD_FIELD]) {
                        DataMap[posParentLocationId][day][CARD_FIELD] =
                            sumTotalIncTax;
                    } else {
                        DataMap[posParentLocationId][day][CARD_FIELD] +=
                            sumTotalIncTax;
                    }
                }
            });

            detailedReportData.posParentLocationsData[
                posParentLocationId
            ].cashVariance.cube.forEach((split) => {
                const { splits } = split;
                const { sumTotalIncTax, splitDate } = splits;

                const { day } = splitDate;

                if (!DataMap[posParentLocationId][day]) {
                    DataMap[posParentLocationId][day] = {};
                    DataMap[posParentLocationId][day][CASH_VARIANCE_FIELD] =
                        sumTotalIncTax;
                } else {
                    if (!DataMap[posParentLocationId][day][CASH_VARIANCE_FIELD]) {
                        DataMap[posParentLocationId][day][CASH_VARIANCE_FIELD] =
                            sumTotalIncTax;
                    } else {
                        DataMap[posParentLocationId][day][CASH_VARIANCE_FIELD] +=
                            sumTotalIncTax;
                    }
                }
            });

            detailedReportData.posParentLocationsData[
                posParentLocationId
            ].cash.cube.forEach((split) => {
                const { splits } = split;
                const { sumTotalIncTax, splitDate } = splits;

                const { day } = splitDate;

                if (!DataMap[posParentLocationId][day]) {
                    DataMap[posParentLocationId][day] = {};
                    DataMap[posParentLocationId][day][CASH_FIELD] = sumTotalIncTax;
                } else {
                    if (!DataMap[posParentLocationId][day][CASH_FIELD]) {
                        DataMap[posParentLocationId][day][CASH_FIELD] =
                            sumTotalIncTax;
                    } else {
                        DataMap[posParentLocationId][day][CASH_FIELD] +=
                            sumTotalIncTax;
                    }
                }
            });
        }
    );

    if (knownPosColumns.indexOf(CASH_FIELD) === -1) {
        knownPosColumns.push(CASH_FIELD);
    }

    if (knownPosColumns.indexOf(CARD_FIELD) === -1) {
        knownPosColumns.push(CARD_FIELD);
    }

    if (knownPosColumns.indexOf(CASH_VARIANCE_FIELD) === -1) {
        knownPosColumns.push(CASH_VARIANCE_FIELD);
    }

    const filledInDates = getDates(selectedRange.start, selectedRange.end);
    const newDataMap: { [locationId: string]: any[] } = {};

    Object.keys(DataMap).map((locationId) => {
        const Dates = Object.keys(DataMap[locationId]).sort(function (a, b) {
            return Date.parse(a) - Date.parse(b);
        });
        const filledDataMap = filledInDates.reduce((acc, date) => {
            const tempDateObject = date.toDate();
            tempDateObject.setUTCHours(0, 0, 0, 0);
            const dateString = tempDateObject.toISOString();

            if (Dates.includes(dateString)) {
                acc[dateString] = addMissingFieldsWithZeroValue(
                    knownPosColumns,
                    DataMap[locationId][dateString]
                );
            } else {
                acc[dateString] = addMissingFieldsWithZeroValue(knownPosColumns, {});
            }
            return acc;
        }, {} as any);

        const tableData: any = [];

        Object.keys(filledDataMap).forEach((currentDate) => {
            tableData.push({ currentDate, ...filledDataMap[currentDate] });
        });

        newDataMap[locationId] = tableData;
    });

    const tableColumns: ColumnsType<any> = [
        {
            title: "Date",
            dataIndex: "currentDate",
            key: "currentDate",
            fixed: "left",
            width: 140,
            render: (date: string) => {
                return dayjs(date).format("ddd DD/MM/YYYY");
            },
        },
        ...knownPosColumns.map((column) => {
            return {
                title: column,
                dataIndex: column,
                key: column,
                render: (value: number) => {
                    return value ? `$${value.toFixed(2)}` : "-";
                },
            };
        }),
    ];

    return { DataMap, newDataMap, knownPosColumns, tableColumns };
}
