import DatePicker from "antd/lib/date-picker";
import React, { useEffect, useRef, useState } from "react";
import { dayjsFormat, disabledDate, QTCRange } from "utils/date-utils";
import dayjs from "dayjs";

const { RangePicker: AntdRangePicker } = DatePicker;
interface Props {
    onChange: (newRange?: QTCRange) => void;
    defaultRange?: QTCRange;
    onboarding_date?: string;
}
type RangeValue = [dayjs.Dayjs | null, dayjs.Dayjs | null] | null;

let LimitedRangePicker: React.FC<Props> = ({
    onChange,
    defaultRange,
    onboarding_date,
}) => {
    const [selectedRange, setSelectedRange] = useState<RangeValue>(
        defaultRange ? [defaultRange.start, defaultRange.end] : null
    );
    const [value, setValue] = useState<RangeValue>(
        defaultRange ? [defaultRange.start, defaultRange.end] : null
    );
    const [selectorOpen, setSelectorOpen] = useState<boolean>(false);
    const firstUpdate = useRef(true);

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        if (!selectorOpen) {
            selectedRange &&
                onChange({ start: selectedRange[0]!, end: selectedRange[1]! });
        }
    }, [onChange, selectedRange, selectorOpen]);

    const disabledDateForAudit = (current: dayjs.Dayjs) => {
        if (!selectedRange) return disabledDate(onboarding_date)(current);

        const tooLate =
            selectedRange[0] && current.diff(selectedRange[0], "day") > 6;
        const tooEarly =
            selectedRange[1] && selectedRange[1].diff(current, "day") > 6;

        return !!tooEarly || !!tooLate || disabledDate(onboarding_date)(current);
    };
    const onOpenChange = (open: boolean) => {
        if (open) {
            setSelectedRange([null, null]);
        }
        setSelectorOpen(open);
    };
    return (
        <AntdRangePicker
            showTime
            format={dayjsFormat.dayMonthYearSlashHourMinute.format}
            onChange={(newRange: any) => setValue(newRange)}
            onCalendarChange={(val) => setSelectedRange(val)}
            open={selectorOpen}
            value={selectedRange || value}
            onOpenChange={onOpenChange}
            disabledDate={disabledDateForAudit}
        />
    );
};

LimitedRangePicker = React.memo(LimitedRangePicker);

export { LimitedRangePicker };
