export enum RoutePaths {
    // Standard routes
    HOME = "/",
    LOGIN = "/login",
    USERS = "/users",
    PROFILE = "/profile",
    SET_PASSWORD = "/set-password",
    PAGE_NOT_FOUND = "/page-unavailable",
    UNAUTHORISED = "/403",

    // Cashup Table routes
    POS = "/pos",
    Gaming = "/gaming",
    Keno = "/keno",
    TAB = "/tab",
    Wagering = "/wagering",
    ATM = "/atm",
    Office = "/office",
    CRT = "/CRT",
    CCU = "/CCU",
    Review = "/Review",
    DailyShiftReport = "/shift-report",
    Safe = "/Safe",

    // Cashup routes
    // CASH_UP = "/cashups",
    // CASH_UP_ENTRY = "/cashups/new",

    // Transaction routes
    // TRANSACTION_ENTRY = "/transactions/:id",
    // Reporting routes
    REPORT_AUDIT_LOG = "/reports/audit-logs",
    REPORT_EXPORTS = "/reports/exports",
    // Setup routes
    SETUP = "/setup",
    SETUP_CONFIG = "/setup/config",
    SETUP_USERS = "/setup/users",
    SETUP_LOCATIONS = "/setup/locations",
    SETUP_VENUES = "/setup/venues",
    SETUP_DEPARTMENTS = "/setup/departments",
    SETUP_CLASSES = "/setup/classes",
    CASHUP_REPORT_SUMMARY = "/cashup-report",
    CASHUP_REPORT_DETAIL = "/cashup-report/:venueId/:date",
    AUDIT_REPORT = "/audit-report",
}
