import { RightOutlined } from "@ant-design/icons";
import { Collapse } from "antd";
import Title from "antd/lib/typography/Title";
import { StyledCollapsedPanel } from "Components/StyledCollapsedPanel";
import React, { ReactElement, useState } from "react";

export const CollapsibleTable = ({
    children,
    panelId,
    name,
}: {
    name: string;
    children: ReactElement | ReactElement[];
    panelId: string;
}) => {
    const [openPanel, setOpenPanel] = useState<string | undefined>(panelId);

    return (
        <Collapse
            key={panelId}
            expandIconPosition={"end"}
            ghost
            style={{
                border: "1px solid rgba(0,0,0,0.1)",
                borderRadius: "10px",
                backgroundColor: "#fff",
            }}
            destroyInactivePanel
            defaultActiveKey={["1"]}
            collapsible="disabled"
            expandIcon={(panelProps) => (
                <RightOutlined
                    rotate={panelProps.isActive ? 90 : undefined}
                    onClick={() => {
                        setOpenPanel((previousState) => {
                            return previousState ? undefined : panelId;
                        });
                    }}
                    style={{ color: "#000", fontSize: 18 }}
                />
            )}
            activeKey={panelId === openPanel ? ["1"] : []}
        >
            <StyledCollapsedPanel
                header={
                    <Title level={3} style={{ margin: "0px" }}>
                        {name}
                    </Title>
                }
                key={"1"}
            >
                {children}
            </StyledCollapsedPanel>
        </Collapse>
    );
};
