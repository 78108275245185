import { prependDollarSign } from "@Constants";
import React from "react";
import { StyledCell, StyledCellValue, StyledTableFooter } from "./Table.style";
import { TableRows } from "./TableBody";
import { TableConfigs } from "./TableHeader";

interface Props {
    dataSources: TableRows[];
    tableConfigs: TableConfigs;
    evenDistribution?: boolean;
    totalRowValue?: TableRows;
    totalRowFromBackend?: boolean;
}

export const TableFooter: React.FC<Props> = ({
    dataSources,
    tableConfigs,
    evenDistribution,
    totalRowValue,
    totalRowFromBackend,
}) => {
    const totalRow = totalRowFromBackend
        ? totalRowValue
        : dataSources.reduce((result, data) => {
              if (!result.length) {
                  return data;
              }
              return result.map((total, i) => {
                  if (!data[i].totalValue) {
                      const currentValue = isNaN(Number(data[i].value))
                          ? 0
                          : Number(data[i].value);

                      const value =
                          typeof total.value === "number"
                              ? currentValue + total.value
                              : 0;

                      return {
                          ...total,
                          value,
                      };
                  } else {
                      const currentValue = isNaN(Number(data[i].totalValue))
                          ? 0
                          : Number(data[i].totalValue);
                      const totalValue =
                          typeof total.totalValue === "number"
                              ? currentValue + total.totalValue
                              : 0;

                      return {
                          ...total,
                          totalValue,
                      };
                  }
              });
          }, []);

    return (
        <StyledTableFooter>
            {totalRow!.map(({ readOnly, value, totalValue }, i) => {
                const titleCol = i === 0;
                const cellProps = {
                    readOnly: true,
                    highlightBorder: false,
                    titleCell: titleCol || !readOnly,
                };
                return (
                    <StyledCell
                        key={i}
                        {...cellProps}
                        numberofitems={tableConfigs.length}
                        borderRight={tableConfigs[i]?.separateColumn ?? false}
                        evenDistribution={evenDistribution}
                    >
                        <StyledCellValue
                            {...cellProps}
                            noPaddingLeft={Boolean(titleCol || readOnly)}
                        >
                            <span
                                style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                }}
                            >
                                {titleCol && tableConfigs[i]
                                    ? "Total"
                                    : tableConfigs[i]?.hideTotal
                                    ? ""
                                    : totalValue !== undefined
                                    ? prependDollarSign(totalValue)
                                    : prependDollarSign(value)}
                            </span>
                        </StyledCellValue>
                    </StyledCell>
                );
            })}
        </StyledTableFooter>
    );
};
