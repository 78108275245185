import { DeleteOutlined, PlusOutlined, SaveOutlined } from "@ant-design/icons";
import { FieldData } from "@interfaces";
import { AntDFormState } from "@types";
import { Button, Col, Form, Input, Radio, Row, Select, Tooltip } from "antd";
import { DraggableModal } from "Components/DraggableModal";
import { StyledAntDInput } from "Components/MoneyInput/MoneyInputV2";
import cloneDeep from "lodash/cloneDeep";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import styled from "styled-components";
import {
    clearLastFormFieldIfEmpty,
    convertFormDataToKeyValueWithTouched,
    extractGSTValue,
} from "utils/utilities";
import { SplitModal } from "../TenderSplitModal/TenderSplitModal";
import { Field } from "./SalesCountModalFormV2";
import {
    applyTooltipToModalHeader,
    positiveNumberOnlyValidator,
    updateSpecificFormField,
} from "../utils";
import { initialPaymentSplitData } from "../TransactionsFormPOS";
import { MoneyFormField } from "../Forms/Components/MoneyFormFields";
import { extractFieldName } from "Pages/CashupHome/CashupTabs/POS/utils";
import { SplitModalField } from "Components/SplitModalField/SplitModalField";
import { useForm } from "antd/lib/form/Form";
import {
    GlAccountByType,
    GlAccountTypes,
} from "Redux/StateSlices/GroupData/VenuesAPI";

export const StyledTitleRow = styled(Row)`
    & > div {
        font-size: 12px;
        font-weight: bold;
        color: #626e84;
    }
`;

export const StyledInput = styled(Input)`
    border-radius: 4px;
    background: #f9fafb;

    &:focus {
        border-color: white;
        background: white;
        border: 1px solid #1a81c7;
        box-shadow: 0 0 1px 2px #dbe7fa;
        border-radius: 4px;
    }
`;

export const StyledRadioGroups = styled(Radio.Group)`
    width: 100%;

    .ant-radio-button-wrapper {
        box-sizing: border-box;
        border-radius: 4px 0px 0px 4px;
        border: 1px solid #1a81c7 !important;
        background: #fffff;
        width: 50%;
        color: #1a81c7;
        font-size: 12px;
        padding: 0px;
        text-align: center;

        &:nth-child(2) {
            border-radius: 0px 4px 4px 0px;
        }
    }

    .ant-radio-button-wrapper-checked {
        background: #dbe7fa !important;
    }
`;

const StyledRow = styled(Row)`
    .ant-form-item {
        margin-bottom: 4px;
        height: 100%;
    }
`;

const StyledSelect = styled(Select)<{ disabled?: boolean; isclassneeded: boolean }>`
    ${(props) =>
        props.disabled === false &&
        `.ant-select-selector {
        color: #353940 !important;
        background: #f9fafb !important;
        border: 1px solid #dadfe7 !important;
        border-radius: 4px !important;
    }`}

    ${(props) =>
        props.disabled === true &&
        `.ant-select-selector {
            border: 1px solid #dadfe7 !important;
            border-radius: 4px !important;
        }`}

    ${(props) =>
        props.isclassneeded === true &&
        `.ant-select-selector {
            border: 1px solid #ff5c5c !important;
            box-shadow: 0 0 1px 2px #ff8a8a;
        }`}
`;

export const StyledEmptyLabel = styled.div`
    height: 100%;
    width: 10px;
`;

const enum DropdownType {
    GL = 1,
    Class,
}

interface DropdownNullOptions {
    groupName: string;
    glAccountKeyPairs: {
        [name: string]: string | number;
    }[];
}

const CustomFormField = ({
    field: { type, changeGST, name, ...rest },
    addNewRow,
    remove,
    openAccountSplitModalModal,
    onAmountChange,
    disabled,
    splitModalValue,
    isLastItem,
    isTenderSplitFormNotBalanced,
    onGlAccountsChange,
    isClassNeeded,
    isClassDisabled,
    glAccountGroupKeyPairs,
}: {
    field: Field;
    addNewRow: (saveOnly?: boolean) => () => void;
    remove?: () => void;
    openAccountSplitModalModal?: () => void;
    onAmountChange?: (data: any) => void;
    disabled?: boolean;
    splitModalValue?: string;
    isLastItem: boolean;
    isTenderSplitFormNotBalanced: boolean;
    onGlAccountsChange: (value: DropdownType) => (value: unknown) => void;
    isClassNeeded: boolean;
    isClassDisabled: boolean;
    glAccountGroupKeyPairs?: Array<DropdownNullOptions>;
}) => {
    switch (type.name) {
        case "Money":
            return (
                <MoneyFormField
                    name={name}
                    {...rest}
                    disabled={disabled}
                    onChange={changeGST ? onAmountChange : undefined}
                />
            );
        case "GST":
            return <StyledAntDInput prefix="$" disabled={disabled} />;
        case "Text":
            return <StyledInput {...rest} disabled={disabled} />;
        case "DropdownOrNull":
            return (
                <StyledSelect
                    showSearch
                    isclassneeded={false}
                    onChange={onGlAccountsChange(DropdownType.GL)}
                    optionFilterProp="children"
                    filterOption={(input, options) => {
                        return options?.children
                            ? options?.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                            : false;
                    }}
                    disabled={disabled}
                >
                    {glAccountGroupKeyPairs
                        ? glAccountGroupKeyPairs?.map((glGroupItem, groupIndex) => (
                              <Select.OptGroup
                                  label={glGroupItem.groupName}
                                  key={groupIndex}
                              >
                                  {glGroupItem.glAccountKeyPairs.map(
                                      (glItem, itemIndex) => (
                                          <Select.Option
                                              value={glItem.value}
                                              key={`${groupIndex}-${itemIndex}`}
                                          >
                                              {glItem.label}
                                          </Select.Option>
                                      )
                                  )}
                              </Select.OptGroup>
                          ))
                        : null}
                </StyledSelect>
            );
        case "Dropdown":
            return (
                <StyledSelect
                    disabled={isClassDisabled || disabled}
                    isclassneeded={isClassNeeded}
                    onChange={onGlAccountsChange(DropdownType.Class)}
                >
                    {isClassDisabled
                        ? [{ value: "", label: "" }].map((currentOption, index) => (
                              <Select.Option value={currentOption.value} key={index}>
                                  {currentOption.label}
                              </Select.Option>
                          ))
                        : type.options.map((currentOption, index) => (
                              <Select.Option value={currentOption.value} key={index}>
                                  {currentOption.label}
                              </Select.Option>
                          ))}
                </StyledSelect>
            );

        case "Modal":
            return (
                <SplitModalField
                    openModal={openAccountSplitModalModal}
                    disabled={disabled}
                    buttonText={splitModalValue}
                    isTenderSplitFormNotBalanced={isTenderSplitFormNotBalanced}
                />
            );
        case "Radio":
            return (
                <StyledRadioGroups
                    options={type.options}
                    optionType="button"
                    {...rest}
                />
            );

        case "Button":
            return (
                <div style={{ display: "flex", flexDirection: "row", gap: 2 }}>
                    <Button
                        style={{ backgroundColor: "#1A81C7" }}
                        icon={
                            <Tooltip
                                title="Save this row"
                                placement="top"
                                arrowPointAtCenter
                                autoAdjustOverflow
                            >
                                <SaveOutlined style={{ color: "#FFF" }} />
                            </Tooltip>
                        }
                        onClick={addNewRow(true)}
                        htmlType="submit"
                    />
                    <Button
                        style={{ backgroundColor: "#1A81C7" }}
                        icon={
                            <Tooltip
                                title="Adding new row"
                                placement="top"
                                arrowPointAtCenter
                                autoAdjustOverflow
                            >
                                <PlusOutlined style={{ color: "#FFF" }} />
                            </Tooltip>
                        }
                        onClick={addNewRow()}
                        disabled={!isLastItem || disabled}
                    />
                    <Button
                        style={{ backgroundColor: "#1A81C7" }}
                        icon={
                            <Tooltip
                                title="Delete this row"
                                placement="top"
                                arrowPointAtCenter
                                autoAdjustOverflow
                            >
                                <DeleteOutlined style={{ color: "#FFF" }} />
                            </Tooltip>
                        }
                        onClick={remove}
                    />
                </div>
            );

        default:
            throw new Error("This should be impossible");
    }
};

const parseInitialValueToSplit = (data: FieldData[]) => {
    const sumOfSplits = data.reduce(
        (result, currentSplitItem) => {
            if (Array.isArray(currentSplitItem.name)) {
                result["value"] += currentSplitItem.value;
            } else {
                result["value"] += currentSplitItem.value;
            }
            return result;
        },
        { value: 0 }
    );

    return sumOfSplits.value;
};

const PaymentFormComponent = ({
    formIndex,
    fields,
    removeForm,
    tenderAccounts,
    formState,
    onFormChange,
    initialSplitData,
    addNewRow,
    isLastItem,
    glAccountsByType,
    disabled,
    pushForms,
}: {
    formIndex: number;
    onModalClose: () => void;
    fields: Field[];
    removeForm: () => void;
    tenderAccounts: ExtendedAccountItem[];
    formState: {
        formData: FieldData[];
    };
    onFormChange: (
        data: FieldData[],
        SplitData: FieldData[],
        EntityData?: FieldData[]
    ) => void;
    initialSplitData: FieldData[];
    addNewRow: () => void;
    isLastItem: boolean;
    glAccountsByType: GlAccountByType[];
    disabled?: boolean;
    pushForms: (formData: FieldData[], entityData: FieldData[]) => void;
}) => {
    const firstUpdate = useRef(true);

    const glAccountCostOfGoodsSold = useMemo(
        () =>
            glAccountsByType.find(
                (currentGlAccount) =>
                    currentGlAccount.type === GlAccountTypes.COSTOFGOODSSOLD
            ),
        [glAccountsByType]
    );

    const extractValueToBalance = () => {
        try {
            const Amount = convertFormDataToKeyValueWithTouched(formState.formData)[
                "Amount"
            ];
            if (Amount === undefined) return undefined;
            return parseFloat(Amount.value);
        } catch (e) {
            console.log("Things were breaking check this");
            console.log(formState.formData);
            return undefined;
        }
    };

    const extractValueToClassDisabled = () => {
        try {
            const gl_code = convertFormDataToKeyValueWithTouched(formState.formData)[
                "GL Code"
            ].value;
            if (
                glAccountCostOfGoodsSold &&
                glAccountCostOfGoodsSold.glAccounts.find(
                    (currentAccount) => currentAccount.id === gl_code
                ) !== undefined
            ) {
                return false;
            } else {
                return true;
            }
        } catch (e) {
            console.log(e);
            return false;
        }
    };
    const extractValueToClassNeeded = () => {
        try {
            const classId = convertFormDataToKeyValueWithTouched(formState.formData)[
                "Class"
            ].value;
            if (classId) {
                return false;
            } else {
                const gl_code = convertFormDataToKeyValueWithTouched(
                    formState.formData
                )["GL Code"].value;
                if (
                    glAccountCostOfGoodsSold &&
                    glAccountCostOfGoodsSold.glAccounts.find(
                        (currentAccount) => currentAccount.id === gl_code
                    ) !== undefined
                ) {
                    return true;
                }
                return false;
            }
        } catch (e) {
            console.log(e);
            return false;
        }
    };
    const GLCode: boolean = useMemo(() => {
        return convertFormDataToKeyValueWithTouched(formState.formData)["GL Code"]
            ?.value;
    }, [formState]);

    const isInitialMount = useRef(true);
    const [form] = useForm();

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
        } else {
            form.setFieldsValue({ Class: undefined });
        }
    }, [GLCode]);

    const [isAccountSplitModalOpen, setIsAccountSplitModalOpen] = useState(false);
    const [valueToBalance, setValueToBalance] = useState<number | undefined>(
        extractValueToBalance()
    );

    const [splitFormFields, setSplitFormFields] =
        useState<FieldData[]>(initialSplitData);
    // TODO: Once setup inherit the SplitValue from the passed data (only in cases where it exists.)
    const [currentSplitAmount, setCurrentSplitAmount] = useState(
        parseInitialValueToSplit(initialSplitData)
    );
    const [isClassNeeded, setIsClassNeeded] = useState(extractValueToClassNeeded());
    const [isClassDisabled, setIsClassDisabled] = useState(
        extractValueToClassDisabled()
    );

    const glAccountGroupKeyPairs = useMemo(
        () =>
            glAccountsByType.map((acc) => ({
                groupName: acc.type,
                glAccountKeyPairs: acc.glAccounts.map((a) => ({
                    label: a.gl_code,
                    value: a.id,
                })),
            })),
        [glAccountsByType]
    );

    const updateSelectedSplitValue = (amount: number) => {
        const splitFormFieldsDeepCopy = cloneDeep(splitFormFields);
        const selectedItemName = "cash";
        const matchIndex = splitFormFieldsDeepCopy.findIndex((currentFormItem) => {
            if (Array.isArray(currentFormItem.name)) {
                return (
                    currentFormItem.name[0].toString().toLowerCase().trim() ===
                    selectedItemName
                );
            } else {
                return (
                    currentFormItem.name.toString().toLowerCase().trim() ===
                    selectedItemName
                );
            }
        });
        if (matchIndex === -1) {
            return;
        }
        const match = splitFormFieldsDeepCopy[matchIndex];
        if (match.touched === true) return;

        match.value = amount;
        splitFormFieldsDeepCopy.splice(matchIndex, 1, match);

        let CumulativeSum = 0;
        splitFormFieldsDeepCopy.forEach((currentField) => {
            CumulativeSum = CumulativeSum + parseFloat(currentField.value);
        });
        setSplitFormFields(splitFormFieldsDeepCopy);
        setCurrentSplitAmount(CumulativeSum);
    };

    const extractSplitText = useMemo(() => {
        const nonZeroFields: any[] = [];
        splitFormFields.forEach((currentFormField) => {
            if (
                currentFormField.value !== 0 &&
                currentFormField.value !== "" &&
                currentFormField.value != undefined
            ) {
                if (Array.isArray(currentFormField.name)) {
                    nonZeroFields.push(currentFormField.name[0]);
                } else {
                    nonZeroFields.push(currentFormField.name);
                }
            }
        });
        if (nonZeroFields.length === 1) return nonZeroFields[0];
        if (nonZeroFields.length === 0) return undefined;
        return "Mixed";
    }, [splitFormFields]);

    const isTenderSplitFormNotBalanced = useMemo(
        () => valueToBalance !== undefined && valueToBalance !== currentSplitAmount,
        [valueToBalance, currentSplitAmount]
    );

    const addNewRowWithValidation = (saveOnly?: boolean) => () => {
        form.validateFields()
            .then(() => {
                form.submit();
                if (!saveOnly) {
                    addNewRow();
                }
            })
            .catch((error) => {
                console.log("error", error);
            });
    };

    useEffect(() => {
        if (firstUpdate.current) {
            firstUpdate.current = false;
            return;
        }
        onFormChange(formState.formData, splitFormFields);
    }, [splitFormFields]);

    const dropdownValueChanged = (type: DropdownType) => (value: unknown) => {
        if (type === DropdownType.GL) {
            if (
                glAccountCostOfGoodsSold &&
                glAccountCostOfGoodsSold.glAccounts.find(
                    (currentAccount) => currentAccount.id === value
                ) !== undefined
            ) {
                setIsClassDisabled(false);

                setIsClassNeeded(true);
            } else {
                setIsClassNeeded(false);
                setIsClassDisabled(true);
            }
        } else if (type === DropdownType.Class) {
            if (value !== undefined) {
                setIsClassNeeded(false);
            }
        }
    };

    return (
        <>
            <Form
                layout="vertical"
                name={`PaymentForm-${formIndex}`}
                fields={formState.formData}
                onFieldsChange={(_, allFields) => {
                    onFormChange(allFields, splitFormFields);
                }}
                onFinish={(data) => {
                    const result: Array<FieldData> = [];
                    for (const [key, value] of Object.entries(data)) {
                        result.push({
                            name: key,
                            value: value,
                        });
                    }
                    pushForms(result, splitFormFields);
                }}
                style={{ width: "100%" }}
                form={form}
                disabled={disabled}
            >
                <StyledRow gutter={8}>
                    {fields.map((field) => (
                        <Col span={field.colSpan} key={field.key}>
                            <Form.Item
                                key={field.key}
                                label={
                                    formIndex === 0 ? (
                                        field.isButton ? (
                                            <StyledEmptyLabel />
                                        ) : (
                                            field.name
                                        )
                                    ) : null
                                }
                                name={field.name}
                                rules={positiveNumberOnlyValidator(field, [
                                    {
                                        required: field.required,
                                        message: "Please enter a value",
                                    },
                                ])}
                            >
                                {CustomFormField({
                                    field: field,
                                    remove: removeForm,
                                    openAccountSplitModalModal: () =>
                                        setIsAccountSplitModalOpen(true),
                                    onAmountChange: (data) => {
                                        if (parseFloat(data) === 0) {
                                            setValueToBalance(0);
                                            updateSpecificFormField(
                                                Object.entries(
                                                    form.getFieldsValue()
                                                ).map((entry) => ({
                                                    name: entry[0],
                                                    value: entry[1],
                                                })),
                                                // formState.formData,
                                                (data: FieldData[]) =>
                                                    onFormChange(
                                                        data,
                                                        splitFormFields
                                                    ),
                                                "GST",
                                                0
                                            );
                                            return;
                                        }
                                        setValueToBalance(parseFloat(data));

                                        updateSpecificFormField(
                                            Object.entries(
                                                form.getFieldsValue()
                                            ).map((entry) => ({
                                                name: entry[0],
                                                value: entry[1],
                                            })),
                                            // formState.formData,
                                            (data: FieldData[]) =>
                                                onFormChange(data, splitFormFields),
                                            "GST",
                                            extractGSTValue(parseFloat(data))
                                        );

                                        updateSelectedSplitValue(parseFloat(data));
                                    },
                                    splitModalValue: extractSplitText,
                                    addNewRow: addNewRowWithValidation,
                                    isLastItem,
                                    isTenderSplitFormNotBalanced,
                                    onGlAccountsChange: dropdownValueChanged,
                                    isClassNeeded,
                                    isClassDisabled,
                                    glAccountGroupKeyPairs,
                                    disabled,
                                })}
                            </Form.Item>
                        </Col>
                    ))}
                </StyledRow>
            </Form>
            <DraggableModal
                title={applyTooltipToModalHeader("Tender Split")}
                visible={isAccountSplitModalOpen}
                onCancel={() => setIsAccountSplitModalOpen(false)}
                onOk={() => setIsAccountSplitModalOpen(false)}
                destroyOnClose
                style={{ top: 30, borderRadius: "12px" }}
                bodyStyle={{ padding: "0px 24px 20px 24px", width: "40vw" }}
                zIndex={100000}
                cancelButtonProps={{ style: { display: "none" } }}
                footer={null}
                noBottomBorder
                width={"80%"}
            >
                <SplitModal
                    valueToBalance={valueToBalance}
                    accounts={tenderAccounts}
                    closeModal={() => setIsAccountSplitModalOpen(false)}
                    formFields={splitFormFields}
                    setFormFields={setSplitFormFields}
                    CurrentSplitAmount={currentSplitAmount}
                    setCurrentSplitAmount={setCurrentSplitAmount}
                />
            </DraggableModal>
        </>
    );
};
const PaymentForm = React.memo(PaymentFormComponent);

interface Props {
    fields: Field[];
    onModalClose: () => void;
    initialDataForForms: AntDFormState[];
    tenderAccounts: ExtendedAccountItem[];
    onSubmit: (data: AntDFormState[]) => void;
    onRemove: (data: AntDFormState[]) => void;
    isPayout?: boolean;
    glAccountsByType: GlAccountByType[];
    disabled?: boolean;
    setFormNeedToSave?: (category: string, isToAdd: boolean) => void;
}

export const PaymentModalFormV2: React.FC<Props> = ({
    fields,
    onModalClose,
    onSubmit,
    onRemove,
    initialDataForForms,
    tenderAccounts,
    glAccountsByType,
    disabled,
    setFormNeedToSave,
}) => {
    const [formsData, setFormsData] = useState(initialDataForForms);
    /**
     * Checks if the Payout transaction passed validation requirements
     *
     * @param formData
     * @param SplitData
     * @returns
     */
    const isPayoutValid = (formData: FieldData[], SplitData: FieldData[]) => {
        const excludedFields = ["Tender split", "Button", "Class"];
        let inValidFlag = false;

        if (formData.length === 0) {
            return false;
        }
        if (SplitData.length === 0) {
            return false;
        }

        formData.forEach((currentFormField) => {
            if (
                !excludedFields.includes(extractFieldName(currentFormField)) &&
                currentFormField.value === undefined
            ) {
                inValidFlag = true;
            } else if (
                (Array.isArray(currentFormField.name) &&
                    currentFormField.name[0] === "Amount") ||
                (!Array.isArray(currentFormField.name) &&
                    currentFormField.name === "Amount")
            ) {
                if (isNaN(Number(currentFormField.value))) {
                    inValidFlag = true;
                }
            }
        });
        return !inValidFlag;
    };

    const submissionWrapper = useCallback((formDataDeepClone: AntDFormState[]) => {
        const payoutTransactions = [...formDataDeepClone];
        const payoutTransactionsClone: AntDFormState[] = [];
        for (let index = 0; index < payoutTransactions.length; index++) {
            const currentTransaction = payoutTransactions[index];
            // Keep the transaction if valid, otherwise exclude
            if (
                isPayoutValid(
                    currentTransaction.formData,
                    currentTransaction.SplitData
                )
            ) {
                payoutTransactionsClone.push(currentTransaction);
            }
        }

        return payoutTransactionsClone;
    }, []);

    /**
     * Triggered on "onSubmit" form events.
     *
     * @param formIndex
     * @param newFormData
     */
    const addNewRow = () => {
        const formDataDeepClone = cloneDeep(formsData);

        formDataDeepClone.push({
            formData: [],
            formID: Math.random().toString(),
            SplitData: initialPaymentSplitData(tenderAccounts),
        });
        setFormsData(formDataDeepClone);
        onSubmit(formDataDeepClone);
    };

    /**
     *  Removed a form at a specified index
     * @param formIndex
     */
    const removeForm = (formIndex: number) => {
        const formDataDeepClone = cloneDeep(formsData);

        formDataDeepClone.splice(formIndex, 1);
        const currentFormFieldsData = clearLastFormFieldIfEmpty(
            formDataDeepClone
        ) as AntDFormState[];

        if (currentFormFieldsData.length === 0) {
            setFormsData([
                {
                    formData: [],
                    formID: Math.random().toString(),
                    SplitData: initialPaymentSplitData(tenderAccounts),
                },
            ]);
        } else {
            setFormsData(formDataDeepClone);
        }

        const parsedResults = submissionWrapper(formDataDeepClone);

        onRemove(parsedResults);
        setFormNeedToSave && setFormNeedToSave("Payments", false);
    };

    /**
     * Triggered on "onFieldsChange" form events.
     * @param rowIndex
     * @param formData
     */
    //Linda Wei  need change here

    const updateFormState = (
        rowIndex: number,
        formData: FieldData[],
        splitData: FieldData[]
        // entityData?: FieldData[]
    ) => {
        const formDataDeepClone = cloneDeep(formsData);
        const CurrentItem = formDataDeepClone[rowIndex];

        formDataDeepClone.splice(rowIndex, 1, {
            formData: formData,
            formID: CurrentItem.formID,
            SplitData: splitData,
        });

        setFormsData(formDataDeepClone);
        if (isPayoutValid(formData, splitData)) {
            setFormNeedToSave && setFormNeedToSave("Payments", true);
        }
    };

    const pushForms = useCallback(
        (formData: FieldData[], splitData: FieldData[]) => {
            if (isPayoutValid(formData, splitData)) {
                const formDataDeepClone = cloneDeep(formsData);
                onSubmit(
                    submissionWrapper(
                        formDataDeepClone.length === 0
                            ? [
                                  {
                                      formData: [],
                                      formID: Math.random().toString(),
                                      SplitData:
                                          initialPaymentSplitData(tenderAccounts),
                                  },
                              ]
                            : formDataDeepClone
                    )
                );
                setFormNeedToSave && setFormNeedToSave("Payments", false);
            }
        },
        [onSubmit, formsData, tenderAccounts, setFormNeedToSave, submissionWrapper]
    );

    return (
        <>
            <StyledRow>
                {formsData.map((currentFormData, index) => (
                    <PaymentForm
                        key={currentFormData.formID}
                        formIndex={index}
                        removeForm={() => {
                            removeForm(index);
                        }}
                        fields={fields}
                        onModalClose={onModalClose}
                        tenderAccounts={tenderAccounts}
                        formState={currentFormData}
                        onFormChange={(data, splitData) =>
                            updateFormState(index, data, splitData)
                        }
                        addNewRow={addNewRow}
                        initialSplitData={formsData[index].SplitData}
                        isLastItem={index == formsData.length - 1}
                        glAccountsByType={glAccountsByType}
                        disabled={disabled}
                        pushForms={pushForms}
                    />
                ))}
            </StyledRow>
        </>
    );
};
