import { TableDataType } from "V2/POS/POSPanel";
import { convertFormDataToKeyValueWithTouched } from "utils/utilities";
import { AntDFormStateWithoutSplit } from "@types";
import cloneDeep from "lodash/cloneDeep";
import { TransferV2Item } from "@generated";
import { TransferTransactionInit } from "Pages/CashupHome/CashupTabs/POS/@types";

export const onTransferTransactionSubmission = (
    data: AntDFormStateWithoutSplit[],
    rowIndex: number,
    tableData: TableDataType[],
    onRowDataChange: (rowIndex: number, tableData: TableDataType[]) => void
) => {
    const tableDataDeepCopy = cloneDeep(tableData);
    const TransferTransactions: TransferTransactionInit[] = [];

    data.forEach((currentPaymentTransaction) => {
        const formData = convertFormDataToKeyValueWithTouched(
            currentPaymentTransaction.formData
        );

        try {
            const TransferTransaction: TransferV2Item = {
                from_location_id: formData["From"].value,
                to_location_id: formData["To"].value,
                amount: formData["Amount"].value,
                ref: formData["Ref"] ? formData["Ref"].value : "",
            };
            TransferTransactions.push(TransferTransaction);
        } catch (e) {
            return;
        }
    });

    tableDataDeepCopy[rowIndex].transfer_transactions = TransferTransactions;

    onRowDataChange(rowIndex, tableDataDeepCopy);
};
