import React, { useCallback, useMemo } from "react";
import { usePushCashupMutation } from "Redux/StateSlices/Pusher";
import { InitDataResponseType } from "../../Pages/CashupHome/CashupTabs/POS/@types";
import produce from "immer";
import { CashupSubLocationItem } from "Redux/StateSlices/GroupData/VenuesAPI";
import { useCashupRealtime } from "hooks/useCashupRealtime";
import {
    countTransactions,
    parseTimePeriod,
    sortCashupIDS,
    sortTableData,
    transformLocationsToLocationDetailByCashup,
} from "../../utils/utilities";
import { TableRows } from "V2/Table/TableBody";
import set from "lodash/set";
import { Table } from "V2/Table/Table";
import { TableConfigs } from "V2/Table/TableHeader";
import cloneDeep from "lodash/cloneDeep";
import { DenominationsForm } from "../../Pages/CashupHome/ExcelTable/DenominationsForm";
import {
    AntDFormStateForGaming,
    AntDFormStateWithoutSplit,
    KeyValuePair,
} from "@types";
import { TableDataType } from "../POS/POSPanel";
import { onTransferTransactionSubmission } from "../Gaming/utils";
import {
    ExtendedLocationItem,
    ExtendedLocationItemWithChildren,
} from "Redux/StateSlices/GroupData/LocationsAPI";
import { formatDollarField } from "@Constants";
import { PanelHOCComponentProps } from "../../Pages/CashupHome/CashupTabs/POS/utils/PanelHOC";
import { useDebouncedCallback } from "@hooks/useDebouncedCallback";
import { DEFAULT_DEBOUNCE_TIME } from "@Constants/debounce";
import { TransactionsFormGaming } from "Pages/CashupHome/ExcelTable/TransactionsFormGaming";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import {
    onPaymentTransactionRemove,
    onPaymentTransactionSubmission,
    positiveNumberValidator,
} from "../../Pages/CashupHome/CashupTabs/POS/utils";
import { CentredSpinner } from "../../Components/Misc/Loading/CentredSpinner";

interface Props extends PanelHOCComponentProps {
    name: string;
    cashupIds: string[];
    subLocations: CashupSubLocationItem[];
    locations: ExtendedLocationItem[];
    hierarchicalLocations: ExtendedLocationItemWithChildren[];
    accountsData: ExtendedAccountItem[];
    submitted: boolean;
}

const initializeAtmField = (cashup: InitDataResponseType): InitDataResponseType => {
    const atmData = cashup.atm_data;
    return {
        ...cashup,
        atm_data: {
            refills: atmData?.refills ?? 0,
            withdrawls: atmData?.withdrawls ?? 0,
        },
    };
};

export const CCUPanel: React.FC<Props> = ({
    cashupIds,
    subLocations,
    locations,
    hierarchicalLocations,
    accountsData,
    onClickHandler,
    isInFocus,
    submitted,
}) => {
    const [updateCashup, { isLoading: isUpdating }] = usePushCashupMutation();

    const [tableData, setTableData] = React.useState<InitDataResponseType[]>([]);
    const updateTableData = (cashup: InitDataResponseType) =>
        setTableData((prevTableData) => {
            const updatedTableData = produce(prevTableData, (draft) => {
                const existingCashupIndex = draft.findIndex(
                    ({ cashup_id }) => cashup_id === cashup.cashup_id
                );

                if (existingCashupIndex !== -1) {
                    draft[existingCashupIndex] = cashup;
                } else {
                    draft.push(cashup);

                    draft.sort((cashupA, cashupB) =>
                        sortTableData(cashupA, cashupB, locationDetailByCashupId)
                    );
                }
            });

            return updatedTableData;
        });
    const locationDetailByCashupId = useMemo(
        () => transformLocationsToLocationDetailByCashup(subLocations),
        [subLocations]
    );

    const sortedCashupIDS = useMemo(
        () =>
            cloneDeep(cashupIds).sort((cashupA_ID, cashupB_ID) =>
                sortCashupIDS(cashupA_ID, cashupB_ID, locationDetailByCashupId)
            ),
        [cashupIds, locationDetailByCashupId]
    );

    const { isLoading } = useCashupRealtime({
        cashupIds: sortedCashupIDS,
        onCashupInitialized: updateTableData,
        onCashupUpdated: updateTableData,
    });

    const handleCashupChanged = useCallback(
        (updatedCashup: InitDataResponseType) =>
            updateCashup(updatedCashup).unwrap(),
        [updateCashup]
    );

    /**
     * Method invoked on tableData change to sync with backend
     * @param rowIndex
     * @param currentTableState
     */
    const onRowDataChange = useCallback(
        (rowIndex: number, tableData: TableDataType[]) => {
            //  setTableData(tableData);

            handleCashupChanged(tableData[rowIndex]);
        },
        [handleCashupChanged]
    );

    const updateDenominationsState = useCallback(
        (rowIndex: number) => (data: KeyValuePair) => {
            const tableDataDeepCopy = cloneDeep(tableData);

            const denominations = [];
            let denominationsSum = 0;

            for (const [key, value] of Object.entries(data)) {
                if (value !== undefined) {
                    const dollarValue = Number(
                        typeof value === "number" ? value.toString() : value
                    );
                    const temp: { [name: string]: number } = {};
                    temp[key] = dollarValue;
                    denominations.push(temp);
                    denominationsSum += dollarValue;
                }
            }

            tableDataDeepCopy[rowIndex].ccu_data = {
                ...tableDataDeepCopy[rowIndex]?.ccu_data,
                refillsDenominations: denominations,
                refills: denominationsSum,
            };

            onRowDataChange(rowIndex, tableDataDeepCopy);
        },
        [tableData, onRowDataChange]
    );

    const updateCashCountDenominationsState = useCallback(
        (rowIndex: number) => (data: KeyValuePair) => {
            const tableDataDeepCopy = cloneDeep(tableData);

            const denominations = [];
            let denominationsSum = 0;

            for (const [key, value] of Object.entries(data)) {
                if (value !== undefined) {
                    const dollarValue = Number(
                        typeof value === "number" ? value.toString() : value
                    );
                    const temp: { [name: string]: number } = {};
                    temp[key] = dollarValue;
                    denominations.push(temp);
                    denominationsSum += dollarValue;
                }
            }

            tableDataDeepCopy[rowIndex].cash_count = {
                ...tableDataDeepCopy[rowIndex]?.cash_count,
                actualDenominations: denominations,
                actual: formatDollarField(denominationsSum),
            };

            onRowDataChange(rowIndex, tableDataDeepCopy);
        },
        [tableData, onRowDataChange]
    );
    const resetCashCountAndDenominations = (rowIndex: number) => {
        const tableDataDeepCopy = cloneDeep(tableData);

        tableDataDeepCopy[rowIndex].cash_count.actual = undefined;
        tableDataDeepCopy[rowIndex].cash_count.actualDenominations = [];

        onRowDataChange(rowIndex, tableDataDeepCopy);
    };
    const onTransferTransactionSubmissionDebounce = useDebouncedCallback(
        (data: AntDFormStateWithoutSplit[], rowIndex: number) =>
            onTransferTransactionSubmission(
                data,
                rowIndex,
                tableData,
                onRowDataChange
            ),
        DEFAULT_DEBOUNCE_TIME
    );
    const onPaymentTransactionSubmissionDebounce = useDebouncedCallback(
        (data: AntDFormStateForGaming[], rowIndex: number) =>
            onPaymentTransactionSubmission(
                data,
                rowIndex,
                accountsData,
                tableData,
                onRowDataChange
            ),
        DEFAULT_DEBOUNCE_TIME
    );

    const onCCUPaymentSubmissionDebounce = useDebouncedCallback(
        (data: string, rowIndex: number) => {
            const tableDataDeepCopy = cloneDeep(tableData);
            tableDataDeepCopy[rowIndex].ccu_data = {
                ...tableDataDeepCopy[rowIndex].ccu_data,
                ccu_payout: Number(data),
            };
            onRowDataChange(rowIndex, tableDataDeepCopy);
        },
        DEFAULT_DEBOUNCE_TIME
    );

    if (isLoading || cashupIds.length !== tableData.length) {
        return (
            <CentredSpinner
                style={{
                    marginTop: "-35px",
                    position: "absolute",
                    textAlign: "center",
                    left: "50%",
                }}
                size={"small"}
            />
        );
    }

    const dataSources: TableRows[] = tableData.map((cashup, rowIndex) => {
        const {
            cashup_id,
            ccu_data,
            cash_count,
            transfer_transactions,
            status,
            transfer_transactions_to,
            payment_transactions,
        } = cashup;

        const cashCountErrorFlag = cash_count.actual === null;
        const ccuPayoutErrorFlag = !ccu_data || ccu_data?.ccu_payout === undefined;
        return [
            {
                value: locationDetailByCashupId[cashup_id].name,
                readOnly: true,
                subValue: parseTimePeriod(
                    locationDetailByCashupId[cashup_id].timePeriod
                ),
            },
            {
                value: cash_count.opening_balance ?? 0,
                readOnly: true,
            },
            {
                value: ccu_data?.ccu_payout ?? 0,
                readOnly: submitted,
                errorHighlighting: ccuPayoutErrorFlag,
                onSubmit: (data) => onCCUPaymentSubmissionDebounce(data, rowIndex),
                validator: positiveNumberValidator,
            },
            {
                value: ccu_data?.refills ?? 0,
                editModal: (closeModal) => (
                    <DenominationsForm
                        row={rowIndex}
                        key={
                            "CCU_Refills_" +
                            locationDetailByCashupId[cashup_id].location_id
                        }
                        onSubmit={updateDenominationsState(rowIndex)}
                        onModalClose={closeModal}
                        initialValues={ccu_data?.refillsDenominations ?? []}
                        extendDenominationOptions
                        disabled={submitted}
                    />
                ),
                modalTitle: "Refills",
                hideEditModalFooter: true,
            },
            {
                value: tableData[rowIndex]
                    ? countTransactions([
                          transfer_transactions ?? [],
                          transfer_transactions_to ?? [],
                          payment_transactions ?? [],
                      ])
                    : "",
                editModal: (closeModal) => (
                    <TransactionsFormGaming
                        key={
                            "CCU_" + locationDetailByCashupId[cashup_id].location_id
                        }
                        currentLocationID={
                            locationDetailByCashupId[cashup_id].location_id
                        }
                        onTransferTransactionSubmission={(data) =>
                            onTransferTransactionSubmissionDebounce(data, rowIndex)
                        }
                        TransferTransactions={
                            tableData[rowIndex]
                                ? tableData[rowIndex].transfer_transactions
                                : []
                        }
                        TransferTransactionsReadOnly={transfer_transactions_to ?? []}
                        locations={locations}
                        hierarchicalLocations={hierarchicalLocations}
                        onPaymentTransactionSubmission={(data) =>
                            onPaymentTransactionSubmissionDebounce(data, rowIndex)
                        }
                        onPaymentTransactionRemove={(data) =>
                            onPaymentTransactionRemove(
                                data,
                                rowIndex,
                                accountsData,
                                tableData,
                                onRowDataChange
                            )
                        }
                        PaymentTransactionData={payment_transactions ?? []}
                        accountsData={accountsData}
                        onModalClose={closeModal}
                        disabled={submitted}
                        loading={isUpdating}
                    />
                ),
                modalTitle: "Transactions",
                hideEditModalFooter: true,
            },
            {
                value: cash_count.actual ?? "$0",
                editModal: (closeModal) => (
                    <DenominationsForm
                        row={rowIndex}
                        key={
                            "CCU_CC_" +
                            locationDetailByCashupId[cashup_id].location_id
                        }
                        onSubmit={updateCashCountDenominationsState(rowIndex)}
                        onModalClose={closeModal}
                        initialValues={cash_count?.actualDenominations ?? []}
                        onClear={() => {
                            resetCashCountAndDenominations(rowIndex);
                        }}
                        extendDenominationOptions
                        disabled={submitted}
                    />
                ),
                modalTitle: "Cash Count",
                errorHighlighting: cashCountErrorFlag,
                hideEditModalFooter: true,
            },
            {
                value: formatDollarField(cash_count.expected_cash ?? 0),
                readOnly: true,
                statusField: () =>
                    cash_count.expected_cash === null ? status : null,
            },
            {
                value:
                    typeof cash_count.cash_variance !== "string"
                        ? formatDollarField(cash_count.cash_variance ?? 0)
                        : cash_count.cash_variance,
                readOnly: true,
                statusField: () => status ?? null,
            },
        ];
    });

    const tableConfigs: TableConfigs = [
        { columnTitle: "CCU" },
        { columnTitle: "Float" },
        { columnTitle: "CCU Payouts" },
        { columnTitle: "Refills" },
        { columnTitle: "Cheque & EFT Payments", hideTotal: true },
        { columnTitle: "CCU Cash count", separateColumn: true },
        { columnTitle: "Expected" },
        { columnTitle: "Variance" },
    ];

    return (
        <Table
            onCellValueChanged={({ row, updatedValue, propertyPath }) => {
                const updatedCashup = produce(
                    initializeAtmField(tableData[row]),
                    (draft) => {
                        set(draft, propertyPath, updatedValue);
                    }
                );
                handleCashupChanged(updatedCashup);
            }}
            dataSources={dataSources}
            tableConfigs={tableConfigs}
            onClickHandler={onClickHandler}
            isInFocus={isInFocus}
        />
    );
};
