import React, { useMemo } from "react";
import { Row, Col, Space } from "antd";
import { ReportItem } from "@interfaces/index";
import { LocationContainer, TableTitle } from "../CashupReportStyled";
import CashupReportTable from "../CashUpReportTable";
import CashupReportBarChart from "../CashupReportBarChart";
import { sortDataBasedOnKeys, prepareBarGraphData } from "../../utils";

interface ShiftReportClassesTendersProps {
    reportData: ReportItem[];
    type: string;
}

const ShiftReportClassesTenders: React.FC<ShiftReportClassesTendersProps> = ({
    reportData,
    type,
}) => {
    const sortedData = useMemo(() => sortDataBasedOnKeys(reportData), [reportData]);

    //prepare bar chart data
    const barChartData: {
        graphData: {
            name: string;
            data: number[];
        }[];
        labels: string[];
    } = useMemo(() => prepareBarGraphData(sortedData), [sortedData]);

    return (
        <>
            <Space
                size="middle"
                direction="vertical"
                style={{
                    width: "100%",
                }}
            >
                <Row gutter={[20, 20]}>
                    <Col md={24} lg={24} xs={24}>
                        <LocationContainer>
                            <TableTitle
                                style={{
                                    marginBottom: "15px",
                                }}
                            >{`${type} - All Locations`}</TableTitle>
                            <CashupReportBarChart data={barChartData} />
                        </LocationContainer>
                    </Col>
                </Row>
                {sortedData.map((item: ReportItem) => (
                    <Row
                        gutter={[20, 2]}
                        key={`${item.location_type} - ${item.parent_location_name}`}
                    >
                        <Col xs={24}>
                            <LocationContainer>
                                <TableTitle>{`${type} - ${item.parent_location_name}`}</TableTitle>
                                <CashupReportTable
                                    data={item.data}
                                    sortBoolean={true}
                                />
                            </LocationContainer>
                        </Col>
                    </Row>
                ))}
            </Space>
        </>
    );
};

export default ShiftReportClassesTenders;
