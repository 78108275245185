import React, { useState, useMemo } from "react";
import { Row, Space, Col, Typography, Spin, Tooltip } from "antd";
import { FilePdfOutlined, FileTextOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import CashupReportTable from "./CashUpReportTable";
import CashupReportLineChart from "./CashupReportLineChart";
import { ReportItem } from "@interfaces";
import {
    LocationContainer,
    TotalTableContainer,
    CustomTotalDataTable,
    TableTitle,
    DownloadLoadingContainer,
} from "./CashupReportStyled";
import {
    getAllUniqueKeys,
    columnGenerator,
    totalsForAllLocations,
    prepareLineChartData,
    getAllDates,
    downloadPdfReport,
    downloadCsvReport,
    preparePosCsvData,
} from "../utils";

const { Title } = Typography;

interface PosVarianceSummaryReportProps {
    reportData: ReportItem[];
    areaName: string;
    reportType: string;
    date: { start_date: string; end_date: string };
    venueName: string;
}

const CashupPosVarianceSummaryReport: React.FC<PosVarianceSummaryReportProps> = ({
    reportData,
    areaName,
    reportType,
    date,
    venueName,
}) => {
    const [download, setDownload] = useState(false as boolean);

    //extracting unique keys from report data
    const allKeys: string[] = useMemo(
        () =>
            reportData.length > 0
                ? (getAllUniqueKeys(reportData[0].data) as string[])
                : [],
        [reportData]
    );

    //prepare columns for tables
    const columns = useMemo(
        () => columnGenerator(allKeys, false, null, null),
        [allKeys]
    );

    //compute totals for each column
    const totals: Record<string, number> = useMemo(
        () => (reportData.length > 0 ? totalsForAllLocations(reportData) : {}),
        [reportData]
    );

    //prepare total row
    const totalRow: Record<string, number | string> = {
        date: "Total",
        ...totals,
    };

    //prepare x labels for line chart
    const chartXLabels: string[] = useMemo(
        () => getAllDates(date.start_date, date.end_date),
        [date]
    );

    //prepare line chart data for Cash Variance
    const lineChartDataForCashVariance: {
        name: string;
        data: number[];
    }[] = useMemo(
        () => prepareLineChartData(reportData, "Cash Variance", chartXLabels),
        [reportData, chartXLabels]
    );

    //prepare line chart data for Entry Variance
    const lineChartDataForEntryVariance: {
        name: string;
        data: number[];
    }[] = useMemo(
        () => prepareLineChartData(reportData, "Entry Variance", chartXLabels),
        [reportData, chartXLabels]
    );

    //download pdf report
    const onClick = async () => {
        setDownload(true);
        await new Promise((resolve) => setTimeout(resolve, 0));
        const reportName = `${areaName} ${reportType}`;
        await downloadPdfReport(
            reportName,
            "pos-variance-summary-report",
            venueName,
            date
        );
        setDownload(false);
    };

    //download csv report
    const onCSVClick = async () => {
        setDownload(true);
        await new Promise((resolve) => setTimeout(resolve, 0));
        const csvData = preparePosCsvData(reportData, totals);
        const reportName = `${areaName} ${reportType}`;
        downloadCsvReport(reportName, venueName, date, csvData);
        setDownload(false);
    };

    if (reportData[0].data.length < 1) {
        return (
            <Title level={5}>
                Cashup data is not available for the selected combination of filters.
                Please review and update your selection.
            </Title>
        );
    }

    return (
        <>
            <div
                id="pos-variance-summary-report"
                style={{ backgroundColor: "#f5f5f5" }}
            >
                <Space
                    size="middle"
                    direction="vertical"
                    style={{
                        width: "100%",
                    }}
                >
                    <Row
                        gutter={[20, 2]}
                        justify="space-between"
                        align="middle"
                        className="hide-for-pdf"
                    >
                        <Col>
                            <Title level={4}>{`${areaName} - ${reportType}`}</Title>
                        </Col>
                        <Col>
                            <div style={{ display: "flex", gap: "15px" }}>
                                <Tooltip title="Download PDF">
                                    <FilePdfOutlined
                                        style={{
                                            fontSize: "28px",
                                            color: "#113469",
                                        }}
                                        onClick={onClick}
                                    />
                                </Tooltip>
                                <Tooltip title="Download CSV">
                                    <FileTextOutlined
                                        style={{
                                            fontSize: "28px",
                                            color: "#113469",
                                        }}
                                        onClick={onCSVClick}
                                    />
                                </Tooltip>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col md={12} lg={12} xs={24}>
                            <CashupReportLineChart
                                data={lineChartDataForEntryVariance}
                                labels={chartXLabels}
                                chartType={"Entry Variance"}
                            />
                        </Col>
                        <Col md={12} lg={12} xs={24}>
                            <CashupReportLineChart
                                data={lineChartDataForCashVariance}
                                labels={chartXLabels}
                                chartType={"Cash Variance"}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[20, 2]}>
                        <Col xs={24}>
                            <LocationContainer>
                                <TableTitle>{`Total (${dayjs(
                                    date.start_date,
                                    "DD-MM-YYYY"
                                ).format("DD/MM/YYYY")} - ${dayjs(
                                    date.end_date,
                                    "DD-MM-YYYY"
                                ).format("DD/MM/YYYY")})`}</TableTitle>
                                <TotalTableContainer>
                                    <CustomTotalDataTable
                                        rowKey="date"
                                        dataSource={[totalRow]}
                                        columns={columns}
                                        size="small"
                                        scroll={{ x: true }}
                                        tableLayout="fixed"
                                        pagination={false}
                                    />
                                </TotalTableContainer>
                            </LocationContainer>
                        </Col>
                    </Row>
                    {reportData.map((item: ReportItem) => (
                        <Row
                            gutter={[20, 2]}
                            key={`${item.location_type} - ${item.parent_location_name}`}
                        >
                            <Col xs={24}>
                                <LocationContainer>
                                    <TableTitle>{`${item.location_type} - ${item.parent_location_name}`}</TableTitle>
                                    <CashupReportTable
                                        data={item.data}
                                        sortBoolean={false}
                                        downloading={download}
                                    />
                                </LocationContainer>
                            </Col>
                        </Row>
                    ))}
                </Space>
            </div>
            {download && (
                <DownloadLoadingContainer>
                    <Spin tip="Generating Report... Please wait" />
                </DownloadLoadingContainer>
            )}
        </>
    );
};

export default CashupPosVarianceSummaryReport;
