import React, { ComponentType, useState } from "react";

export type TabHOCComponentProps = {
    currentTableInFocus?: string;
    setCurrenTableInFocus: (tableID?: string) => void;
};

export type PanelHOCComponentProps = {
    onClickHandler: (tableIndex?: number) => void;
    isInFocus?: boolean;
};

/**
 * HOC to handle the logic behind ensuring only a single table is controllable via keystrokes.
 * @param Component
 * @returns
 */
export const CashupTabHOC = (Component: ComponentType<any>) => {
    const WrappedTab = (props: any) => {
        const [currentTableInFocus, setCurrenTableInFocus] = useState<string>();
        return (
            <Component
                {...props}
                currentTableInFocus={currentTableInFocus}
                setCurrenTableInFocus={setCurrenTableInFocus}
            />
        );
    };
    return WrappedTab;
};
