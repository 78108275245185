import { useMemo } from "react";
import { GamingDetailedReportTransformer } from "./GamingDetailedReportTransformer";
import { PosParentDetailedReportTransformer } from "./PosParentDetailedReportTransformer";
import { DepositDetailedReportTransformer } from "./DepositDetailedReportTransformer";
import { PettyCashDetailedReportTransformer } from "./PettyCashDetailedReportTransformer";
import { PosDetailedReportTransformer } from "./PosDetailedReportTransformer";
import { CrtDetailedReportTransformer } from "./CrtDetailedReportTransformer";
import { WageringDetailedReportTransformer } from "./WageringDetailedReportTransformer";
import { SafeDetailedReportTransformer } from "./SafeDetailedReportTransformer";
import { TransfersDetailedReportTransformer } from "./TransfersDetailedReportTransformer";
import { ATMDetailedReportTransformer } from "./ATMDetailedReportTransformer";
import { TradeTillDetailedReportTransformer } from "./TradeTillDetailedReportTransformer";
import { TabTradeTillDetailedReportTransformer } from "./TabTradeTillDetailedReportTransformer";
import { KenoTradeTillDetailedReportTransformer } from "./KenoTradeTillDetailedReportTransformer";
import { LocationType } from "../CashupReportModel";

export const useDetailedReportTransformer = ({
    detailedReportData,
    selectedRange,
}: {
    detailedReportData: {
        posSplitByClassTypeData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            account: { __typename: string; name: string };
                            class: {
                                __typename: string;
                                classType: number;
                                name: string;
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        cashVarianceForAllPosLocationsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        pettyCashData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                totalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            transactions: { __typename: string; memo: string };
                        }[]
                      | undefined;
              }
            | undefined;
        depositsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                totalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            transactions: { __typename: string; memo: string };
                            account: { __typename: string; name: string };
                        }[]
                      | undefined;
              }
            | undefined;
        transfersData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          splitDate: { __typename: string; day: string };
                          totalIncTax: number;
                      };
                      transactions: { __typename: string; memo: string; id: string };
                  }[];
              }
            | undefined;
        gamingStatsData:
            | {
                  cube: {
                      __typename: string;
                      gamingStats: {
                          __typename: string;
                          date: { __typename: string; value: string; day: string };
                          turnover: number;
                          totalWins: number;
                          netProfit: number;
                          returnToHouse: number;
                      };
                  }[];
              }
            | undefined;
        gamingVarianceData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                  }[];
              }
            | undefined;
        gamingPayoutsData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                  }[];
              }
            | undefined;
        cashTotalsForAllPosLocationsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        cardTotalsForPosData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        safeData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          safeDataTotal: number;
                          safeDataVariance: number;
                          safeSignedOff: string;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        crtData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountCashVariance: number;
                          atmDataRefills: number | null;
                          atmDataWithdrawls: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        atmData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountCashVariance: number;
                          atmDataRefills: number;
                          atmDataWithdrawls: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        wageringData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          wageringDataSalesTotal: number;
                          wageringDataCommission: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        wageringPayoutsData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillTabData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillKenoData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        posParentLocationsData: {
            [parentLocationId: string]: {
                sales: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate?: { day: string };
                        };
                        account: { name: string };
                        class: { classType: number; name: string };
                    }[];
                };
                card: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
                cashVariance: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
                cash: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
            };
        };
    };
    selectedRange: { start: string; end: string } | undefined;
}) => {
    const transformedPosParentLocationsData = useMemo(() => {
        return PosParentDetailedReportTransformer(detailedReportData, selectedRange);
    }, [detailedReportData]);

    const transformedPettyCash = useMemo(() => {
        return PettyCashDetailedReportTransformer(detailedReportData, selectedRange);
    }, [detailedReportData]);

    const transformedDeposits = useMemo(() => {
        return DepositDetailedReportTransformer(detailedReportData, selectedRange);
    }, [detailedReportData]);

    const transformedTransfers = useMemo(() => {
        return TransfersDetailedReportTransformer(detailedReportData, selectedRange);
    }, [detailedReportData]);

    const transformedPosData = useMemo(() => {
        return PosDetailedReportTransformer(detailedReportData, selectedRange);
    }, [detailedReportData]);

    const transformedSafeData = useMemo(() => {
        return SafeDetailedReportTransformer(detailedReportData, selectedRange);
    }, [detailedReportData]);

    const transformedGamingData = useMemo(() => {
        return GamingDetailedReportTransformer(detailedReportData, selectedRange);
    }, [detailedReportData, selectedRange]);

    const transformedWageringData = useMemo(() => {
        return WageringDetailedReportTransformer(detailedReportData, selectedRange);
    }, [detailedReportData]);

    const transformedCrtData = useMemo(() => {
        return CrtDetailedReportTransformer(detailedReportData, selectedRange);
    }, [detailedReportData, selectedRange]);

    const transformedAtmData = useMemo(() => {
        return ATMDetailedReportTransformer(detailedReportData, selectedRange);
    }, [detailedReportData, selectedRange]);

    const transformedTradeTillData = useMemo(() => {
        return TradeTillDetailedReportTransformer(detailedReportData, selectedRange);
    }, [detailedReportData, selectedRange]);

    const transformedTabTradeTillData = useMemo(() => {
        return TabTradeTillDetailedReportTransformer(
            detailedReportData,
            selectedRange
        );
    }, [detailedReportData, selectedRange]);

    const transformedKenoTradeTillData = useMemo(() => {
        return KenoTradeTillDetailedReportTransformer(
            detailedReportData,
            selectedRange
        );
    }, [detailedReportData, selectedRange]);
    return {
        transformedAtmData,
        transformedCrtData,
        transformedKenoTradeTillData,
        transformedGamingData,
        transformedWageringData,
        transformedTabTradeTillData,
        transformedPosData,
        transformedTradeTillData,
        transformedPosParentLocationsData,
        transformedPettyCash,
        transformedDeposits,
        transformedTransfers,
        transformedSafeData,
    };
};
