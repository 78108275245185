import React, { useEffect, useState } from "react";
import { useCubejs } from "utils/cubejs-utils";
import { fetchWrapper } from "../../../API";
import { GroupDataState } from "../../../Redux/StateSlices/GroupData";
import { useVenueSelection } from "../../../Context/VenueSelectionContextConstants";

export const useIntializeCashupReport = () => {
    const { cubejsApi, updateCubeJsToken } = useCubejs();
    const { venueId } = useVenueSelection();
    const [groupData, setGroupData] = useState<GroupDataState>();
    const [isGroupDataLoading, setIsGroupDataLoading] = useState<boolean>(true);
    useEffect(() => {
        async function getGroupData() {
            setIsGroupDataLoading(true);
            if (venueId !== null) {
                try {
                    const response = await fetchWrapper(
                        `/api/v2/group/data/${venueId}`,
                        "GET"
                    );

                    if (response.ok) {
                        const data = await response.json();
                        setGroupData(data);
                        setIsGroupDataLoading(false);
                    }
                } catch (error) {
                    console.error("Error fetching venues:", error);
                    throw new Error("Failed to fetch venues");
                }
            }
        }

        getGroupData();
    }, [venueId]);

    React.useEffect(() => {
        if (groupData?.cubejs_token) {
            updateCubeJsToken(groupData.cubejs_token);
            localStorage.setItem("cubejsToken", groupData.cubejs_token);
        }
    }, [groupData, updateCubeJsToken]);

    return {
        ready: !isGroupDataLoading && groupData && cubejsApi,
        groupData,
    };
};
