import { Tooltip } from "antd";
import React from "react";

const HelpSectionDropdown = ({ onClick }: { onClick: () => void }) => (
    <Tooltip
        title={"Press to open the Cashup help portal in a new tab."}
        mouseEnterDelay={0.5}
        placement="bottom"
    >
        <img
            src="/open-book.svg"
            height={32}
            onClick={onClick}
            style={{ cursor: "pointer", marginTop: 4 }}
        />
    </Tooltip>
);

export default HelpSectionDropdown;
