import cubejs, {
    CubejsApi,
    Filter,
    Query,
    TimeDimension,
    TQueryOrderObject,
} from "@cubejs-client/core";
import React, { useContext } from "react";

export const CubeName = {
    cashup: "Cashup",
    location: "Location",
    shiftReportPrompt: "ShiftReportPrompt",
    venueShiftReportPrompt: "VenueShiftReportPrompt",
    shiftReportPromptRecord: "ShiftReportPromptRecord",
};
interface CubejsContextProps {
    cubejsApi?: CubejsApi;
    updateCubeJsToken: (cubejsToken: string) => void;
}

const CubejsContext = React.createContext<any>({});

export const CubejsProvider: React.FC = ({ children }) => {
    const [cubejsApi, setCubejsApi] = React.useState<CubejsApi>();

    const updateCubeJsToken = React.useCallback((cubejsToken: string) => {
        localStorage.setItem("cubejsToken", cubejsToken);
        setCubejsApi(
            cubejs(`Bearer ${cubejsToken}`, {
                apiUrl: process.env.REACT_APP_CUBEJS_BACKEND_API_URL as string,
            })
        );
    }, []);
    return (
        <CubejsContext.Provider value={{ cubejsApi, updateCubeJsToken }}>
            {children}
        </CubejsContext.Provider>
    );
};

export const useCubejs = (): CubejsContextProps => {
    const contextValue = useContext(CubejsContext);

    if (!contextValue) {
        throw "useCubejs needs to be wrapped by the CubejsProvider";
    }

    return contextValue as CubejsContextProps;
};

export const CubeQueryBuilder = (query: Query = {}) => ({
    getResult: () => query,
    addMeasures: (measures: string[], condition = true) =>
        condition
            ? CubeQueryBuilder({
                  ...query,
                  measures: [...(query.measures ?? []), ...measures],
              })
            : CubeQueryBuilder(query),
    addOrder: (order: TQueryOrderObject) =>
        CubeQueryBuilder({
            ...query,
            order,
        }),
    addFilters: (filters: Filter[], condition = true) =>
        condition
            ? CubeQueryBuilder({
                  ...query,
                  filters: [...(query.filters ?? []), ...filters],
              })
            : CubeQueryBuilder(query),
    addFilter: (filter: Filter, condition = true) =>
        condition
            ? CubeQueryBuilder({
                  ...query,
                  filters: [...(query.filters ?? []), filter],
              })
            : CubeQueryBuilder(query),
    addTimeDimensions: (timeDimensions: TimeDimension[], condition = true) =>
        condition
            ? CubeQueryBuilder({
                  ...query,
                  timeDimensions: [
                      ...(query.timeDimensions ?? []),
                      ...timeDimensions,
                  ],
              })
            : CubeQueryBuilder(query),
    addDimensions: (dimensions: string[], condition = true) =>
        condition
            ? CubeQueryBuilder({
                  ...query,
                  dimensions: [...(query.dimensions ?? []), ...dimensions],
              })
            : CubeQueryBuilder(query),
});
