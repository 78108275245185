import React, { useState, useEffect } from "react";
import dayjs from "dayjs";
import type { Dayjs } from "dayjs";
import styled from "styled-components";
import { dayjsFormat } from "utils/date-utils";
import type { TimeRangePickerProps, DatePickerProps } from "antd";
import "../datePickerMobile.css";

import { DatePicker } from "antd";

const { RangePicker } = DatePicker;

interface Props {
    onboardingDate?: string;
    setDate: React.Dispatch<
        React.SetStateAction<{ start_date: string; end_date: string }>
    >;
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    reportType: string;
    defaultDateRange: [Dayjs, Dayjs];
    setCalendarCheck: React.Dispatch<React.SetStateAction<boolean>>;
}
//adjust range picker for mobile view
const CustomRangePicker = styled.div`
    @media (max-width: 576px) {
        .ant-picker-panels {
            flex-direction: column !important;
        }
        .ant-picker-presets {
            display: none !important;
        }
    }
`;

const CashUpReportDatePicker: React.FC<Props> = ({
    onboardingDate,
    setDate,
    setLoading,
    reportType,
    defaultDateRange,
    setCalendarCheck,
}) => {
    const [fromDate, setFromDate] = useState<Dayjs | null>(null);
    const [selectedRange, setSelectedRange] = useState<[Dayjs, Dayjs] | null>(
        defaultDateRange
    );
    const [dateStrings, setDateStrings] = useState<string[]>([
        dayjs(defaultDateRange[0]).format(
            dayjsFormat.dayMonthYearSeparateByDash.format
        ),
        dayjs(defaultDateRange[1]).format(
            dayjsFormat.dayMonthYearSeparateByDash.format
        ),
    ]);

    useEffect(() => {
        setSelectedRange(defaultDateRange);
    }, [defaultDateRange]);

    let rangePresets: TimeRangePickerProps["presets"] = [
        {
            label: "Today",
            value: [dayjs().startOf("day"), dayjs().endOf("day")],
        },
        {
            label: "This Week",
            value: [dayjs().startOf("week"), dayjs().endOf("week")],
        },
        {
            label: "Last Week",
            value: [
                dayjs().subtract(1, "week").startOf("week"),
                dayjs().subtract(1, "week").endOf("week"),
            ],
        },
    ];

    if (reportType !== "shiftReport" && reportType !== "bankable") {
        // Add additional presets for non-shift reports
        rangePresets = [
            ...rangePresets,
            {
                label: "This Month",
                value: [dayjs().startOf("month"), dayjs().endOf("month")],
            },
            {
                label: "Last Month",
                value: [
                    dayjs().subtract(1, "month").startOf("month"),
                    dayjs().subtract(1, "month").endOf("month"),
                ],
            },
        ];
    }

    const disabledRangeDate: DatePickerProps["disabledDate"] = (date: Dayjs) => {
        // Disable dates before onboarding date
        if (onboardingDate && date.isBefore(dayjs(onboardingDate), "day")) {
            return true;
        }
        // Allow 60 days at max for selection for non shift reports
        // Allow 7 days at max for selection for shift reports
        if (fromDate) {
            const daysDifference = Math.abs(date.diff(fromDate, "days"));
            // Disable dates based on report type
            let threshold = 60;
            switch (reportType) {
                case "shiftReport":
                    threshold = 7;
                    break;
                case "bankable":
                    threshold = 14;
                    break;
                default:
                    threshold = 60;
            }

            if (daysDifference >= threshold) {
                return true;
            }
        }
        return false;
    };

    const onRangeChange = (
        dates: null | (Dayjs | null)[],
        dateStrings: string[]
    ) => {
        if (dates && dates[0] && dates[1]) {
            const selectedFromDate = dates[0];
            setFromDate(selectedFromDate);
            setSelectedRange(dates as [Dayjs, Dayjs]);
            setDateStrings(dateStrings);
            const start = dates[0];
            const end = dates[1];
            const startDateString = start.format(
                dayjsFormat.dayMonthYearSeparateByDash.format
            );
            const endDateString = end.format(
                dayjsFormat.dayMonthYearSeparateByDash.format
            );
            setDate({
                start_date: startDateString,
                end_date: endDateString,
            });
        } else {
            setSelectedRange(null);
            setDateStrings(["", ""]);
            setDate({
                start_date: "",
                end_date: "",
            });
        }
    };

    const handleCalendarOpenChange = (open: boolean) => {
        setCalendarCheck(open);
        if (!open) {
            setFromDate(null);
            setLoading(true);
        } else {
            setLoading(false);
        }
    };

    return (
        <>
            <RangePicker
                value={selectedRange}
                panelRender={(panelNode) => (
                    <CustomRangePicker>{panelNode}</CustomRangePicker>
                )}
                presets={rangePresets}
                onChange={onRangeChange}
                onOpenChange={handleCalendarOpenChange}
                disabledDate={disabledRangeDate}
                format={dayjsFormat.dayMonthYearSeparateByDash.format}
                //popupStyle={{ width: "100%" }}
            />
        </>
    );
};

export default CashUpReportDatePicker;
