import { changeVenue } from "Redux/StateSlices/ShiftReportState";
import { useAppDispatch } from "Redux/TypedReduxFunctions";
import { useLocalStorage } from "../@hooks/useLocalStorage";

export const useVenue = () => {
    const [_, setSelectedVenue] = useLocalStorage<string | null>(
        "selectedVenue",
        null
    );
    const dispatch = useAppDispatch();

    const setVenue = (result: any) => {
        if (result != undefined) {
            setSelectedVenue(result);
            dispatch(
                changeVenue({
                    newVenue: result.toString(),
                })
            );
        }
    };
    return { setVenue };
};
