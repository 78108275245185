import { Checkbox, Row, Table } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox/Checkbox";
import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import React, {
    PropsWithChildren,
    ReactElement,
    useCallback,
    useState,
} from "react";
import { VenuesCashupsResponse } from "Redux/StateSlices/GroupData/VenuesAPI";
import styled from "styled-components";
import {
    DayAndPosClass,
    DetailedReportSections,
    StyledDetailedReportCard,
    StyledH2,
} from "./CashupDetailedReport";

const StyledCheckbox = styled(Checkbox)`
    & .ant-checkbox-inner {
        width: 20px;
        height: 20px;
    }
`;

const StyledSpan = styled.span`
    position: absolute;
    zindex: 10;
    top: -50%;
    white-space: nowrap;
    color: #ff5c5c;
    font-weight: bold;
`;

const CustomerisedCheckBox = (
    props: PropsWithChildren<{
        onCheckboxClicked: (clicked: boolean) => void;
        isCheckBoxSelected: boolean;
    }>
): ReactElement => {
    const [isHovered, SetIsHovered] = useState(false);
    const { children, isCheckBoxSelected, onCheckboxClicked } = props;
    return (
        <StyledCheckbox
            onMouseEnter={() => SetIsHovered(true)}
            onMouseLeave={() => SetIsHovered(false)}
            onChange={(e: CheckboxChangeEvent) =>
                onCheckboxClicked(e.target.checked)
            }
            style={{ position: "relative" }}
            defaultChecked
        >
            {children}
            {isHovered ? (
                isCheckBoxSelected ? (
                    <StyledSpan>
                        Unselect checkbox will not download this part
                    </StyledSpan>
                ) : (
                    <StyledSpan>Select checkbox to download this part</StyledSpan>
                )
            ) : null}
        </StyledCheckbox>
    );
};

const SummariseEachNumericalColumn = (
    pageData: readonly any[],
    forceOrder?: string[],
    reverse = true
) => {
    const summary: any = {};

    const data = reverse ? [...pageData].reverse() : pageData;
    data.forEach((row) => {
        // Remove the "Date" column
        const newRow = { ...row };
        delete newRow["currentDate"];
        delete newRow["Date"];
        delete newRow["key"];

        Object.keys(newRow).forEach((key) => {
            if (typeof newRow[key] === "number") {
                if (!(key in summary)) {
                    summary[key] = 0;
                }
                if (newRow[key] !== 0) {
                    summary[key] += newRow[key];
                }
            } else {
                if (!summary[key]) {
                    summary[key] = 0;
                }
            }
        });
    });

    if (forceOrder) {
        const orderedSummary: any = {};
        forceOrder.forEach((key) => {
            if (key === "Return to House") {
                orderedSummary[key] = isNaN(
                    summary["Net Profit"] / summary["Turnover"].toFixed(2)
                )
                    ? 0
                    : summary["Net Profit"] / summary["Turnover"].toFixed(2);
            } else {
                orderedSummary[key] = summary[key];
            }
        });
        return orderedSummary;
    }

    return summary;
};

const RenderDollarAmount = (amount: number) => {
    return amount ? `$${amount.toFixed(2)}` : "-";
};

export const MainComponentHelper = ({
    transformedPosData,
    minimumWidth,
    transformedGamingData,
    transformedWageringData,
    transformedAtmData,
    transformedCrtData,
    transformedTradeTillData,
    transformedTabTradeTillData,
    transformedKenoTradeTillData,
    transformedPosParentLocationsData,
    transformedPettyCash,
    transformedDeposits,
    transformedTransfers,
    transformedSafeData,
    venuesCashupData,
    selectedSelections,
    isMobile,
    convertPosIdToLocationName,
    onCheckboxClicked,
    selectedToDownload,
}: {
    transformedPosData:
        | {
              filledDataMap?: undefined;
              knownPosColumns?: undefined;
              tableData?: undefined;
              tableColumns?: undefined;
          }
        | {
              filledDataMap: any;
              knownPosColumns: string[];
              tableData: any;
              tableColumns: ColumnsType<any>;
          };
    minimumWidth: string | number;
    transformedGamingData:
        | {
              filledDataMap?: undefined;
              knownGamingColumns?: undefined;
              tableData?: undefined;
              tableColumns?: undefined;
          }
        | {
              filledDataMap: any;
              knownGamingColumns: string[];
              tableData: any;
              tableColumns: ColumnsType<any>;
          };
    transformedWageringData:
        | {
              filledInKenoDataMap?: undefined;
              knownWageringColumns?: undefined;
              filledInTabDataMap?: undefined;
              kenoTableData?: undefined;
              tabTableData?: undefined;
          }
        | {
              filledInKenoDataMap: any;
              knownWageringColumns: string[];
              filledInTabDataMap: any;
              kenoTableData: any[];
              tabTableData: any[];
          };
    transformedAtmData:
        | { tableData?: undefined; knownArtColumns?: undefined }
        | { tableData: any[]; knownArtColumns: string[] };
    transformedCrtData:
        | { tableData?: undefined; knownCrtColumns?: undefined }
        | { tableData: any[]; knownCrtColumns: string[] };
    transformedTradeTillData:
        | { tableData?: undefined; knownTradeTillColumns?: undefined }
        | { tableData: any[]; knownTradeTillColumns: string[] };
    transformedTabTradeTillData:
        | { tableData?: undefined; knownTradeTillColumns?: undefined }
        | { tableData: any[]; knownTradeTillColumns: string[] };
    transformedKenoTradeTillData:
        | { tableData?: undefined; knownTradeTillColumns?: undefined }
        | { tableData: any[]; knownTradeTillColumns: string[] };
    // TODO !!
    transformedPosParentLocationsData: {
        DataMap?: {
            [locationId: string]: DayAndPosClass;
        };
        newDataMap?: { [locationId: string]: any[] };
        knownPosColumns?: string[];
        tableColumns?: ColumnsType<any>;
    };
    transformedPettyCash: {
        DataMap?: {
            [day: string]: any[];
        };
        tableData?: any;
        tableColumns?: ColumnsType<any>;
    };
    transformedDeposits: {
        DataMap?: {
            [day: string]: any[];
        };
        tableData?: any;
        tableColumns?: ColumnsType<any>;
    };
    transformedTransfers: {
        DataMap?: {
            [day: string]: any[];
        };
        tableData?: any;
        tableColumns?: ColumnsType<any>;
    };
    transformedSafeData: {
        filledDataMap?: any;
        tableData?: any;
        tableColumns?: ColumnsType<any>;
    };
    venuesCashupData: VenuesCashupsResponse | undefined;
    selectedSelections: string[];
    isMobile: boolean;
    convertPosIdToLocationName: (posId: string) => string;
    onCheckboxClicked: (checkboxLabel: string) => (clicked: boolean) => void;
    selectedToDownload: Array<string>;
}) => {
    const isTabExisting = useCallback(
        (selection: string) => {
            switch (selection) {
                case "POS":
                    return venuesCashupData?.locations.pos_locations.some(
                        (loction) => loction.sub_locations.length > 0
                    );
                case "Gaming":
                    return venuesCashupData?.locations?.gaming_locations?.some(
                        (loction) => loction.sub_locations.length > 0
                    );
                case "Wagering":
                    return venuesCashupData?.locations?.wagering_locations?.some(
                        (location) => location.sub_locations.length > 0
                    );
                case "ATM/CRT":
                    return (
                        venuesCashupData?.locations?.atm_locations?.some(
                            (location) => location.sub_locations.length > 0
                        ) ||
                        venuesCashupData?.locations?.crt_locations?.some(
                            (location) => location.sub_locations.length > 0
                        )
                    );
                case "Office":
                    return venuesCashupData?.locations?.office_locations?.some(
                        (location) => location.sub_locations.length > 0
                    );
                case "Floats":
                    return venuesCashupData?.locations?.safe_locations?.some(
                        (location) => location.sub_locations.length > 0
                    );
                case "Transactions":
                    return true;
                default:
                    throw new Error("wrong filter tab name");
            }
        },
        [venuesCashupData]
    );

    const ShouldShowSelection = useCallback(
        (selection: string) => {
            if (selectedSelections.length === 0) return isTabExisting(selection);
            return (
                selectedSelections.includes(selection) && isTabExisting(selection)
            );
        },
        [selectedSelections, isTabExisting]
    );

    return (
        <div
            style={{
                display: "flex",
                gap: 20,
                flexDirection: "column",
                paddingTop: 20,
            }}
        >
            {ShouldShowSelection("POS") && (
                <StyledDetailedReportCard className="table-row-light">
                    <StyledH2>Point of Sale</StyledH2>
                </StyledDetailedReportCard>
            )}

            {ShouldShowSelection("POS") && (
                <StyledDetailedReportCard>
                    <CustomerisedCheckBox
                        onCheckboxClicked={onCheckboxClicked(
                            DetailedReportSections.POS_ALL_LOCATIONS
                        )}
                        isCheckBoxSelected={selectedToDownload.includes(
                            DetailedReportSections.POS_ALL_LOCATIONS
                        )}
                    >
                        <StyledH2 bottomPadding>POS - All Locations</StyledH2>
                    </CustomerisedCheckBox>
                    <Row style={{ width: "100%", gap: 12 }}>
                        <Table
                            dataSource={transformedPosData.tableData}
                            columns={transformedPosData.tableColumns}
                            pagination={false}
                            // TODO MAKE THIS DYNAMIC WITH A MINIMUM WIDTH
                            scroll={{ x: minimumWidth }}
                            size={isMobile ? "small" : "middle"}
                            summary={(data) =>
                                SummariseTable(
                                    data,
                                    transformedPosData.knownPosColumns
                                )
                            }
                        />
                    </Row>
                </StyledDetailedReportCard>
            )}

            {/* TODO Rework this so that is is more dynamic */}
            {ShouldShowSelection("POS") &&
                transformedPosParentLocationsData.newDataMap &&
                Object.keys(transformedPosParentLocationsData.newDataMap).map(
                    (currentLocationId, index) => {
                        return (
                            <StyledDetailedReportCard key={index}>
                                <CustomerisedCheckBox
                                    onCheckboxClicked={onCheckboxClicked(
                                        `POS - ${convertPosIdToLocationName(
                                            currentLocationId
                                        )}`
                                    )}
                                    isCheckBoxSelected={selectedToDownload.includes(
                                        `POS - ${convertPosIdToLocationName(
                                            currentLocationId
                                        )}`
                                    )}
                                >
                                    <StyledH2
                                        bottomPadding
                                    >{`POS - ${convertPosIdToLocationName(
                                        currentLocationId
                                    )}`}</StyledH2>
                                </CustomerisedCheckBox>
                                <Row style={{ width: "100%", gap: 12 }}>
                                    <Table
                                        dataSource={
                                            transformedPosParentLocationsData
                                                ?.newDataMap?.[currentLocationId] ??
                                            ([] as any[])
                                        }
                                        columns={
                                            transformedPosParentLocationsData.tableColumns
                                        }
                                        pagination={false}
                                        // TODO MAKE THIS DYNAMIC WITH A MINIMUM WIDTH
                                        scroll={{ x: minimumWidth }}
                                        size={isMobile ? "small" : "middle"}
                                        summary={(data) =>
                                            SummariseTable(
                                                data,
                                                transformedPosParentLocationsData.knownPosColumns
                                            )
                                        }
                                    />
                                </Row>
                            </StyledDetailedReportCard>
                        );
                    }
                )}
            {ShouldShowSelection("Transactions") && (
                <StyledDetailedReportCard className="table-row-light">
                    <StyledH2>Transactions</StyledH2>
                </StyledDetailedReportCard>
            )}

            {ShouldShowSelection("Transactions") && (
                <StyledDetailedReportCard>
                    <CustomerisedCheckBox
                        onCheckboxClicked={onCheckboxClicked(
                            DetailedReportSections.PERRY_CASH
                        )}
                        isCheckBoxSelected={selectedToDownload.includes(
                            DetailedReportSections.PERRY_CASH
                        )}
                    >
                        <StyledH2 bottomPadding>Petty Cash</StyledH2>
                    </CustomerisedCheckBox>

                    <Row style={{ width: "100%", gap: 12 }}>
                        <Table
                            dataSource={transformedPettyCash.tableData}
                            columns={transformedPettyCash.tableColumns}
                            pagination={false}
                            scroll={{ x: minimumWidth }}
                            size={isMobile ? "small" : "middle"}
                            summary={(data) =>
                                SummariseTable(
                                    data,
                                    ["Amount", "Description"],
                                    ["Description"]
                                )
                            }
                        />
                    </Row>
                </StyledDetailedReportCard>
            )}

            {ShouldShowSelection("Transactions") && (
                <StyledDetailedReportCard>
                    <CustomerisedCheckBox
                        onCheckboxClicked={onCheckboxClicked(
                            DetailedReportSections.DEPOSITS
                        )}
                        isCheckBoxSelected={selectedToDownload.includes(
                            DetailedReportSections.DEPOSITS
                        )}
                    >
                        <StyledH2 bottomPadding>Deposits</StyledH2>
                    </CustomerisedCheckBox>
                    <Row style={{ width: "100%", gap: 12 }}>
                        <Table
                            dataSource={transformedDeposits.tableData}
                            columns={transformedDeposits.tableColumns}
                            pagination={false}
                            scroll={{ x: minimumWidth }}
                            size={isMobile ? "small" : "middle"}
                            summary={(data) =>
                                SummariseTable(
                                    data,
                                    ["Amount", "Description", "Tender"],
                                    ["Description", "Tender"]
                                )
                            }
                        />
                    </Row>
                </StyledDetailedReportCard>
            )}

            {ShouldShowSelection("Transactions") && (
                <StyledDetailedReportCard>
                    <CustomerisedCheckBox
                        onCheckboxClicked={onCheckboxClicked(
                            DetailedReportSections.TRANSFERS
                        )}
                        isCheckBoxSelected={selectedToDownload.includes(
                            DetailedReportSections.TRANSFERS
                        )}
                    >
                        <StyledH2 bottomPadding>Transfers</StyledH2>
                    </CustomerisedCheckBox>

                    <Row style={{ width: "100%", gap: 12 }}>
                        <Table
                            dataSource={transformedTransfers.tableData}
                            columns={transformedTransfers.tableColumns}
                            pagination={false}
                            scroll={{ x: minimumWidth }}
                            size={isMobile ? "small" : "middle"}
                        />
                    </Row>
                </StyledDetailedReportCard>
            )}

            {ShouldShowSelection("Gaming") && (
                <StyledDetailedReportCard className="table-row-light">
                    <StyledH2>Gaming</StyledH2>
                </StyledDetailedReportCard>
            )}
            {ShouldShowSelection("Gaming") && (
                <StyledDetailedReportCard>
                    <CustomerisedCheckBox
                        onCheckboxClicked={onCheckboxClicked(
                            DetailedReportSections.GAMING_ALL_LOCATIONS
                        )}
                        isCheckBoxSelected={selectedToDownload.includes(
                            DetailedReportSections.GAMING_ALL_LOCATIONS
                        )}
                    >
                        <StyledH2 bottomPadding>Gaming - All Locations</StyledH2>
                    </CustomerisedCheckBox>
                    <Row style={{ width: "100%", gap: 12 }}>
                        <Table
                            dataSource={transformedGamingData.tableData}
                            columns={transformedGamingData.tableColumns}
                            pagination={false}
                            scroll={{ x: minimumWidth }}
                            size={isMobile ? "small" : "middle"}
                            summary={(data) =>
                                SummariseTable(
                                    data,
                                    transformedGamingData.knownGamingColumns
                                    // ["Return to House"]
                                )
                            }
                        />
                    </Row>
                </StyledDetailedReportCard>
            )}

            {ShouldShowSelection("Wagering") && (
                <StyledDetailedReportCard className="table-row-light">
                    <StyledH2>Wagering</StyledH2>
                </StyledDetailedReportCard>
            )}

            {ShouldShowSelection("Wagering") && (
                <StyledDetailedReportCard>
                    <CustomerisedCheckBox
                        onCheckboxClicked={onCheckboxClicked(
                            DetailedReportSections.TAB
                        )}
                        isCheckBoxSelected={selectedToDownload.includes(
                            DetailedReportSections.TAB
                        )}
                    >
                        <StyledH2 bottomPadding>TAB</StyledH2>
                    </CustomerisedCheckBox>
                    <Row style={{ width: "100%", gap: 12 }}>
                        <Table
                            dataSource={transformedWageringData.tabTableData}
                            columns={[
                                {
                                    title: "Date",
                                    dataIndex: "key",
                                    key: "key",

                                    fixed: "left",
                                    width: 140,
                                    render: (date: string) => {
                                        return dayjs(date).format("ddd DD/MM/YYYY");
                                    },
                                },

                                {
                                    title: "Imbalance  Total",
                                    dataIndex: "Sales",
                                    key: "Sales",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                                {
                                    title: "Commission",
                                    dataIndex: "Commission",
                                    key: "commission",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                                {
                                    title: "Payout",
                                    dataIndex: "Payout",
                                    key: "Payout",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                                {
                                    title: "Variance",
                                    dataIndex: "Variance",
                                    key: "Variance",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                            ]}
                            pagination={false}
                            size={isMobile ? "small" : "middle"}
                            scroll={{ x: minimumWidth }}
                            summary={(data) =>
                                SummariseTable(data, [
                                    "Sales",
                                    "Commission",
                                    "Payout",
                                    "Variance",
                                ])
                            }
                        />
                    </Row>
                </StyledDetailedReportCard>
            )}
            {ShouldShowSelection("Wagering") && (
                <StyledDetailedReportCard>
                    <CustomerisedCheckBox
                        onCheckboxClicked={onCheckboxClicked(
                            DetailedReportSections.KENO
                        )}
                        isCheckBoxSelected={selectedToDownload.includes(
                            DetailedReportSections.KENO
                        )}
                    >
                        <StyledH2 bottomPadding>Keno</StyledH2>
                    </CustomerisedCheckBox>
                    <Row style={{ width: "100%", gap: 12 }}>
                        <Table
                            dataSource={transformedWageringData.kenoTableData}
                            columns={[
                                {
                                    title: "Date",
                                    dataIndex: "key",
                                    key: "key",

                                    fixed: "left",
                                    width: 140,
                                    render: (date: string) => {
                                        return dayjs(date).format("ddd DD/MM/YYYY");
                                    },
                                },

                                {
                                    title: "Cash Total",
                                    dataIndex: "Sales",
                                    key: "Sales",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                                {
                                    title: "Commission",
                                    dataIndex: "Commission",
                                    key: "Commission",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                                {
                                    title: "Payout",
                                    dataIndex: "Payout",
                                    key: "Payout",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                                {
                                    title: "Variance",
                                    dataIndex: "Variance",
                                    key: "Variance",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                            ]}
                            pagination={false}
                            scroll={{ x: minimumWidth }}
                            size={isMobile ? "small" : "middle"}
                            summary={(data) =>
                                SummariseTable(data, [
                                    "Sales",
                                    "Commission",
                                    "Payout",
                                    "Variance",
                                ])
                            }
                        />
                    </Row>
                </StyledDetailedReportCard>
            )}

            {ShouldShowSelection("ATM/CRT") && (
                <StyledDetailedReportCard className="table-row-light">
                    <StyledH2>ATM & CRT</StyledH2>
                </StyledDetailedReportCard>
            )}
            {ShouldShowSelection("ATM/CRT") && (
                <StyledDetailedReportCard>
                    <CustomerisedCheckBox
                        onCheckboxClicked={onCheckboxClicked(
                            DetailedReportSections.ATM
                        )}
                        isCheckBoxSelected={selectedToDownload.includes(
                            DetailedReportSections.ATM
                        )}
                    >
                        <StyledH2 bottomPadding>ATM</StyledH2>
                    </CustomerisedCheckBox>
                    <Row style={{ width: "100%", gap: 12 }}>
                        <Table
                            dataSource={transformedAtmData.tableData}
                            columns={[
                                {
                                    title: "Date",
                                    dataIndex: "key",
                                    key: "key",

                                    fixed: "left",
                                    width: 140,
                                    render: (date: string) => {
                                        return dayjs(date).format("ddd DD/MM/YYYY");
                                    },
                                },
                                {
                                    title: "Withdrawals",
                                    dataIndex: "Withdrawls",
                                    key: "Withdrawls",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                                {
                                    title: "Refills",
                                    dataIndex: "Refills",
                                    key: "Refills",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                                {
                                    title: "Variance",
                                    dataIndex: "Variance",
                                    key: "Variance",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                            ]}
                            pagination={false}
                            scroll={{ x: minimumWidth }}
                            size={isMobile ? "small" : "middle"}
                            summary={(data) =>
                                SummariseTable(
                                    data,
                                    ["Withdrawls", "Refills", "Variance"],
                                    [],

                                    false
                                )
                            }
                        />
                    </Row>
                </StyledDetailedReportCard>
            )}

            {ShouldShowSelection("ATM/CRT") && (
                <StyledDetailedReportCard>
                    <CustomerisedCheckBox
                        onCheckboxClicked={onCheckboxClicked(
                            DetailedReportSections.CRT
                        )}
                        isCheckBoxSelected={selectedToDownload.includes(
                            DetailedReportSections.CRT
                        )}
                    >
                        <StyledH2 bottomPadding>CRT</StyledH2>
                    </CustomerisedCheckBox>
                    <Row style={{ width: "100%", gap: 12 }}>
                        <Table
                            dataSource={transformedCrtData.tableData}
                            columns={[
                                {
                                    title: "Date",
                                    dataIndex: "key",
                                    key: "key",

                                    fixed: "left",
                                    width: 140,
                                    render: (date: string) => {
                                        return dayjs(date).format("ddd DD/MM/YYYY");
                                    },
                                },
                                {
                                    title: "Withdrawals",
                                    dataIndex: "Withdrawls",
                                    key: "Withdrawls",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                                {
                                    title: "Refills",
                                    dataIndex: "Refills",
                                    key: "Refills",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                                {
                                    title: "Variance",
                                    dataIndex: "Variance",
                                    key: "Variance",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                            ]}
                            pagination={false}
                            scroll={{ x: minimumWidth }}
                            size={isMobile ? "small" : "middle"}
                            summary={(data) =>
                                SummariseTable(
                                    data,
                                    ["Withdrawls", "Refills", "Variance"],
                                    [],
                                    false
                                )
                            }
                        />
                    </Row>
                </StyledDetailedReportCard>
            )}

            {ShouldShowSelection("Office") && (
                <StyledDetailedReportCard className="table-row-light">
                    <StyledH2>Office / Bankable</StyledH2>
                </StyledDetailedReportCard>
            )}

            {ShouldShowSelection("Office") && (
                <StyledDetailedReportCard>
                    <CustomerisedCheckBox
                        onCheckboxClicked={onCheckboxClicked(
                            DetailedReportSections.TRADE_TILL
                        )}
                        isCheckBoxSelected={selectedToDownload.includes(
                            DetailedReportSections.TRADE_TILL
                        )}
                    >
                        <StyledH2 bottomPadding>Trade Till</StyledH2>
                    </CustomerisedCheckBox>
                    <Row style={{ width: "100%", gap: 12 }}>
                        <Table
                            dataSource={transformedTradeTillData.tableData}
                            columns={[
                                {
                                    title: "Date",
                                    dataIndex: "key",
                                    key: "key",

                                    fixed: "left",
                                    width: 140,
                                    render: (date: string) => {
                                        return dayjs(date).format("ddd DD/MM/YYYY");
                                    },
                                },
                                {
                                    title: "Bankable",
                                    dataIndex: "Bankable",
                                    key: "Bankable",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                                {
                                    title: "Variance",
                                    dataIndex: "Variance",
                                    key: "Variance",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                            ]}
                            pagination={false}
                            scroll={{ x: minimumWidth }}
                            size={isMobile ? "small" : "middle"}
                            summary={(data) =>
                                SummariseTable(data, ["Bankable", "Variance"])
                            }
                        />
                    </Row>
                </StyledDetailedReportCard>
            )}

            {ShouldShowSelection("Office") && (
                <StyledDetailedReportCard>
                    <CustomerisedCheckBox
                        onCheckboxClicked={onCheckboxClicked(
                            DetailedReportSections.TAB_TRADE_TILL
                        )}
                        isCheckBoxSelected={selectedToDownload.includes(
                            DetailedReportSections.TAB_TRADE_TILL
                        )}
                    >
                        <StyledH2 bottomPadding> TAB Trade Till</StyledH2>
                    </CustomerisedCheckBox>
                    <Row style={{ width: "100%", gap: 12 }}>
                        <Table
                            dataSource={transformedTabTradeTillData.tableData}
                            columns={[
                                {
                                    title: "Date",
                                    dataIndex: "key",
                                    key: "key",
                                    fixed: "left",
                                    width: 140,
                                    render: (date: string) => {
                                        return dayjs(date).format("ddd DD/MM/YYYY");
                                    },
                                },
                                {
                                    title: "Bankable",
                                    dataIndex: "Bankable",
                                    key: "Bankable",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                                {
                                    title: "Variance",
                                    dataIndex: "Variance",
                                    key: "Variance",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                            ]}
                            pagination={false}
                            scroll={{ x: minimumWidth }}
                            size={isMobile ? "small" : "middle"}
                            summary={(data) =>
                                SummariseTable(data, ["Bankable", "Variance"])
                            }
                        />
                    </Row>
                </StyledDetailedReportCard>
            )}

            {ShouldShowSelection("Office") && (
                <StyledDetailedReportCard>
                    <CustomerisedCheckBox
                        onCheckboxClicked={onCheckboxClicked(
                            DetailedReportSections.KENO_TRADE_TILL
                        )}
                        isCheckBoxSelected={selectedToDownload.includes(
                            DetailedReportSections.KENO_TRADE_TILL
                        )}
                    >
                        <StyledH2 bottomPadding>KENO Trade Till</StyledH2>
                    </CustomerisedCheckBox>
                    <Row style={{ width: "100%", gap: 12 }}>
                        <Table
                            dataSource={transformedKenoTradeTillData.tableData}
                            columns={[
                                {
                                    title: "Date",
                                    dataIndex: "key",
                                    key: "key",

                                    fixed: "left",
                                    width: 140,
                                    render: (date: string) => {
                                        return dayjs(date).format("ddd DD/MM/YYYY");
                                    },
                                },
                                {
                                    title: "Bankable",
                                    dataIndex: "Bankable",
                                    key: "Bankable",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                                {
                                    title: "Variance",
                                    dataIndex: "Variance",
                                    key: "Variance",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                            ]}
                            pagination={false}
                            scroll={{ x: minimumWidth }}
                            size={isMobile ? "small" : "middle"}
                            summary={(data) =>
                                SummariseTable(data, ["Bankable", "Variance"])
                            }
                        />
                    </Row>
                </StyledDetailedReportCard>
            )}

            {ShouldShowSelection("Floats") && (
                <StyledDetailedReportCard className="table-row-light">
                    <StyledH2>Safe</StyledH2>
                </StyledDetailedReportCard>
            )}

            {ShouldShowSelection("Floats") && (
                <StyledDetailedReportCard>
                    <CustomerisedCheckBox
                        onCheckboxClicked={onCheckboxClicked(
                            DetailedReportSections.SAFE
                        )}
                        isCheckBoxSelected={selectedToDownload.includes(
                            DetailedReportSections.SAFE
                        )}
                    >
                        <StyledH2 bottomPadding>Safe</StyledH2>
                    </CustomerisedCheckBox>
                    <Row style={{ width: "100%", gap: 12 }}>
                        <Table
                            dataSource={transformedSafeData.tableData}
                            columns={transformedSafeData.tableColumns}
                            pagination={false}
                            scroll={{ x: minimumWidth }}
                            size={isMobile ? "small" : "middle"}
                            summary={SummariseTable}
                        />
                    </Row>
                </StyledDetailedReportCard>
            )}
        </div>
    );
};

const SummariseTable = (
    data: readonly any[],
    forceOrder?: string[],
    fieldsToHide?: string[],
    reverse?: boolean
) => {
    const response = SummariseEachNumericalColumn(data, forceOrder, reverse);
    return (
        <>
            <Table.Summary.Row>
                <Table.Summary.Cell index={0}>Total</Table.Summary.Cell>
                {Object.keys(response).map((key, index) => {
                    if (fieldsToHide && fieldsToHide.includes(key))
                        return (
                            <Table.Summary.Cell index={index + 1} key={index}>
                                {" "}
                            </Table.Summary.Cell>
                        );
                    else if (key === "Return to House" && response[key] !== 0)
                        return (
                            <Table.Summary.Cell index={index + 1} key={index}>
                                {`${(Number(response[key]) * 100).toFixed(2)}%`}
                            </Table.Summary.Cell>
                        );
                    return (
                        <Table.Summary.Cell index={index + 1} key={index}>
                            {RenderDollarAmount(response[key])}
                        </Table.Summary.Cell>
                    );
                })}
            </Table.Summary.Row>
        </>
    );
};
