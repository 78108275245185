interface PosData {
    [name: string]: string | number;
}
export const transformDateToCsvdata = (
    columnsInPosTable: string[],
    data: PosData[]
) => {
    const result = columnsInPosTable.reduce<{ [name: string]: number | string }>(
        (accumulated, current) => {
            if (current !== "currentDate") {
                accumulated[current] = 0;
            }
            return accumulated;
        },
        {}
    );

    let csvdata = "";
    data.forEach((currentRow) => {
        columnsInPosTable.forEach((property) => {
            if (property === "currentDate") {
                const currentDate = new Date(
                    currentRow[property] !== undefined
                        ? currentRow[property]
                        : currentRow["key"]
                );
                csvdata +=
                    ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"][
                        currentDate.getDay()
                    ] +
                    " " +
                    currentDate.toLocaleDateString("en-GB") +
                    ",";
            } else if (currentRow[property] !== undefined) {
                if (typeof currentRow[property] === "number") {
                    currentRow[property] === 0
                        ? (csvdata += "-,")
                        : (csvdata += currentRow[property] + ",");
                    (result[property] as number) += Number(currentRow[property]);
                } else {
                    csvdata += currentRow[property] + ",";
                    result[property] = "";
                }
            } else {
                csvdata += "-,";
            }
        });
        csvdata += "\r\n";
    });
    csvdata += "Total,";
    Object.keys(result).forEach((key) => {
        csvdata += result[key] + ",";
    });
    csvdata += "\r\n";
    return csvdata;
};
