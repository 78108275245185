import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { getDates } from "./getDates";
import { VARIANCE, addMissingFieldsWithZeroValue } from "./CashupDetailedReport";
import { LocationType } from "../CashupReportModel";

export function SafeDetailedReportTransformer(
    detailedReportData: {
        posSplitByClassTypeData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            account: { __typename: string; name: string };
                            class: {
                                __typename: string;
                                classType: number;
                                name: string;
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        cashVarianceForAllPosLocationsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        pettyCashData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                totalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            transactions: { __typename: string; memo: string };
                        }[]
                      | undefined;
              }
            | undefined;
        depositsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                totalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            transactions: { __typename: string; memo: string };
                            account: { __typename: string; name: string };
                        }[]
                      | undefined;
              }
            | undefined;
        transfersData:
            | {
                  cube: {
                      // TODO ADD Minimum width to the buttons
                      __typename: string;
                      splits: {
                          __typename: string;
                          splitDate: { __typename: string; day: string };
                          totalIncTax: number;
                      };
                      transactions: { __typename: string; memo: string; id: string };
                  }[];
              }
            | undefined;
        gamingStatsData:
            | {
                  cube: {
                      __typename: string;
                      gamingStats: {
                          __typename: string;
                          date: { __typename: string; value: string; day: string };
                          turnover: number;
                          totalWins: number;
                          netProfit: number;
                          returnToHouse: number;
                      };
                  }[];
              }
            | undefined;
        gamingVarianceData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                  }[];
              }
            | undefined;
        gamingPayoutsData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                  }[];
              }
            | undefined;
        cashTotalsForAllPosLocationsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        cardTotalsForPosData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        safeData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          safeDataTotal: number;
                          safeDataVariance: number;
                          safeSignedOff: string;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        crtData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountCashVariance: number;
                          atmDataRefills: number | null;
                          atmDataWithdrawls: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        atmData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountCashVariance: number;
                          atmDataRefills: number;
                          atmDataWithdrawls: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        wageringData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          wageringDataSalesTotal: number;
                          wageringDataCommission: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        wageringPayoutsData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillTabData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillKenoData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        posParentLocationsData: {
            [parentLocationId: string]: {
                sales: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate?: { day: string };
                        };
                        account: { name: string };
                        class: { classType: number; name: string };
                    }[];
                };
                card: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
                cashVariance: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
                cash: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
            };
        };
    },
    selectedRange: { start: string; end: string } | undefined
) {
    if (!detailedReportData || !selectedRange) {
        return {};
    }

    const DataMap: {
        [name: string]: {
            [name: string]: number | null;
        };
    }[] = [];

    detailedReportData.safeData?.cube.forEach((safeItem) => {
        const { cashup } = safeItem;
        const { safeDataVariance, safeDataTotal, shiftDate, safeSignedOff } = cashup;
        const { day } = shiftDate;

        if (safeSignedOff === "true") {
            DataMap.push({
                [day]: { TOTAL: safeDataTotal, [VARIANCE]: safeDataVariance },
            });
        }

        // if (!DataMap[day]) {
        //     DataMap[day] = {};
        //     DataMap[day]["TOTAL"] = safeDataTotal;
        //     DataMap[day][VARIANCE] = safeDataVariance;
        // } else {
        //     if (!DataMap[day]["TOTAL"]) {
        //         DataMap[day]["TOTAL"] = safeDataTotal;
        //     } else {
        //         DataMap[day]["TOTAL"] += safeDataTotal;
        //     }

        //     if (!DataMap[day][VARIANCE]) {
        //         DataMap[day][VARIANCE] = safeDataVariance;
        //     } else {
        //         DataMap[day][VARIANCE] += safeDataVariance;
        //     }
        // }
    });

    const Dates = DataMap.map((dataMapItem) => Object.keys(dataMapItem)[0]).sort(
        function (a, b) {
            return Date.parse(a) - Date.parse(b);
        }
    );
    const filledInDates = getDates(selectedRange.start, selectedRange.end);

    const filledDataMap = filledInDates.reduce<
        {
            [name: string]: {
                [name: string]: number | null;
            };
        }[]
    >((acc, date) => {
        const tempDateObject = date.toDate();
        tempDateObject.setUTCHours(0, 0, 0, 0);
        const dateString = tempDateObject.toISOString();
        if (Dates.includes(dateString)) {
            DataMap.filter(
                (dataMapItem) => Object.keys(dataMapItem)[0] === dateString
            ).forEach((filteredDataMapItem) => {
                acc.push({
                    [dateString]: addMissingFieldsWithZeroValue(
                        ["TOTAL", VARIANCE],
                        filteredDataMapItem[dateString]
                    ),
                });
            });
        } else {
            acc.push({
                [dateString]: addMissingFieldsWithZeroValue(["TOTAL", VARIANCE], {}),
            });
        }
        return acc;
    }, []);

    const tableData: any = [];

    filledDataMap.forEach((currentfilledData) => {
        tableData.push({
            currentDate: Object.keys(currentfilledData)[0],
            ...currentfilledData[Object.keys(currentfilledData)[0]],
        });
    });
    const tableColumns: ColumnsType<any> = [
        {
            title: "Date",
            dataIndex: "currentDate",
            key: "currentDate",
            fixed: "left",
            width: 140,
            render: (date: string) => {
                return dayjs(date).format("ddd DD/MM/YYYY");
            },
        },
        {
            title: "Total Safe Count",
            dataIndex: "TOTAL",
            key: "TOTAL",
            render: (value: number) => {
                return value ? `$${value.toFixed(2)}` : "-";
            },
        },
        {
            title: "Variance",
            dataIndex: VARIANCE,
            key: VARIANCE,
            render: (value: number) => {
                return value ? `$${value.toFixed(2)}` : "-";
            },
        },
    ];
    return { filledDataMap, tableData, tableColumns };
}
