import { Input } from "antd";
import styled from "styled-components";

export const StyledAntDInput = styled(Input)`
    border-radius: 4px;
    background: #f9fafb;

    &:focus {
        border-color: white;
        background: white;
        border: 1px solid #1a81c7;
        box-shadow: 0 0 1px 2px #dbe7fa;
        border-radius: 4px;
    }

    .ant-input {
        background: #f9fafb;
    }
`;

// interface Props {
//     onChange?: (updatedValue?: number) => void;
//     disabled: boolean;
// }
//
// export const MoneyInputV2: React.FC<Props> = ({ onChange, disabled }) => {
//     return (
//         <StyledAntDInput
//             disabled={disabled}
//             onChange={({ currentTarget: { value } }) => {
//                 if (!value) {
//                     onChange?.(0);
//                 }
//
//                 const parsedValue = Number(value);
//
//                 if (isFinite(parsedValue) === false) return; // Infinity input Ignore this.
//                 if (!Number.isNaN(parsedValue)) {
//                     onChange?.(parsedValue ?? 0);
//                 }
//             }}
//         />
//     );
// };
