import React from "react";
import { StyledAntDInput } from "Components/MoneyInput/MoneyInputV2";
import { FieldWithOutType } from "../../TransactionForms/SalesCountModalFormV2";

export const DenominationFormField = ({
    disabled,
    onChange,
    ...rest
}: FieldWithOutType) => {
    const handleValueChange = (value: string) => {
        if (!value) {
            onChange?.("");
        } else {
            const transformedValue = value.startsWith(".") ? `0${value}` : value;
            onChange?.(transformedValue);
        }
    };

    return (
        <StyledAntDInput
            className="numberInputField"
            {...rest}
            disabled={disabled ?? false}
            onChange={({ currentTarget: { value } }) => {
                handleValueChange(value);
            }}
            onFocus={(e) => {
                e.target.select();
            }}
        />
    );
};
