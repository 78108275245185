import React, { ReactElement } from "react";
import { useBegoneWidget } from "utils/widget";
import { LoginForm } from "../../Components/LoginScreen";
import { ServiceDown } from "Components/Layout/ServiceDown";

const LoginPage = (): ReactElement => {
    useBegoneWidget();
    return process.env.REACT_APP_SERVICE_DOWN ? <ServiceDown /> : <LoginForm />;
};

export default LoginPage;
