import { formatDollarField } from "@Constants";
import { CashupTextStatus } from "Pages/CashupHome/CashupTabs/POS/@types";
import { PanelHOCComponentProps } from "../../Pages/CashupHome/CashupTabs/POS/utils/PanelHOC"; // "Pages/POC/POS/utils/PanelHOC";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Maybe } from "types";
import { Cell } from "./Cell";
import { StyledTableRow } from "./Table.style";
import { TableConfigs } from "./TableHeader";

export type TableRow = {
    value: string | number;
    subValue?: string;
    totalValue?: number;
    isExplicitlyNotTitleCell?: boolean;
    readOnly?: boolean;
    editModal?: (closeModal: () => void) => JSX.Element;
    modalTitle?: string;
    propertyPath?: string;
    statusField?: (row: number) => CashupTextStatus;
    onSubmit?: (data: any) => void;
    hideEditModalFooter?: boolean;
    applyDollarFormatting?: boolean;
    // Highlighting area when there is a split imbalance.
    errorHighlighting?: boolean;
    nanInsteadOfZeroOut?: boolean;
    validator?: (value: number) => boolean;
    manuallyChangedData?: boolean;
    editPopover?: JSX.Element;
};

export type TableRows = Array<TableRow>;

interface Props extends PanelHOCComponentProps {
    dataSources: TableRows[];
    tableConfigs: TableConfigs;
    onCellValueChanged?: (params: {
        row: number;
        propertyPath: string;
        updatedValue: number;
    }) => void;
    onSelectedCellChange?: (params: Maybe<{ row: number; col: number }>) => void;
    evenDistribution?: boolean;
}

export const TableBody: FC<Props> = ({
    dataSources,
    tableConfigs,
    onCellValueChanged,
    isInFocus,
    onClickHandler,
    onSelectedCellChange,
    evenDistribution,
}) => {
    const [selectedCell, setSelectedCell] =
        useState<Maybe<{ row: number; col: number }>>();
    const [editing, setEditing] = useState<boolean>(false);
    const editingValue = useRef<number>();
    const initialEditingValue = useRef<string>();

    useEffect(() => {
        onSelectedCellChange?.(selectedCell);
    }, [selectedCell, onSelectedCellChange]);

    useEffect(() => {
        if (!isInFocus) {
            setSelectedCell(undefined);
            setEditing(false);
            editingValue.current = undefined;
            initialEditingValue.current = undefined;
        }
    }, [isInFocus]);

    const moveSelectedCellToRight = useCallback(() => {
        setEditing(false);

        setSelectedCell((prevSelectedCell) =>
            prevSelectedCell && prevSelectedCell.col < tableConfigs.length - 1
                ? {
                      row: prevSelectedCell.row,
                      col: prevSelectedCell.col + 1,
                  }
                : prevSelectedCell
        );
        editingValue.current = undefined;
        initialEditingValue.current = undefined;
    }, [tableConfigs.length]);

    const moveSelectedCellToLeft = useCallback(() => {
        setEditing(false);

        setSelectedCell((prevSelectedCell) =>
            prevSelectedCell && prevSelectedCell.col !== 0
                ? {
                      row: prevSelectedCell.row,
                      col: prevSelectedCell.col - 1,
                  }
                : prevSelectedCell
        );
        editingValue.current = undefined;
        initialEditingValue.current = undefined;
    }, []);

    const moveSelectedCellUp = useCallback((e: KeyboardEvent) => {
        setSelectedCell((prevSelectedCell) => {
            if (prevSelectedCell && prevSelectedCell.row !== 0) {
                // We prevent the page from scrolling unless the cell destination is out of bounds.
                e.preventDefault();
                return {
                    row: prevSelectedCell.row - 1,
                    col: prevSelectedCell.col,
                };
            } else {
                return prevSelectedCell;
            }
        });
        setEditing(false);
        editingValue.current = undefined;
        initialEditingValue.current = undefined;
    }, []);

    const moveSelectedCellDown = useCallback(
        (e: KeyboardEvent) => {
            setSelectedCell((prevSelectedCell) => {
                if (
                    prevSelectedCell &&
                    prevSelectedCell.row < dataSources.length - 1
                ) {
                    // We prevent the page from scrolling unless the cell destination is out of bounds.
                    e.preventDefault();
                    return {
                        row: prevSelectedCell.row + 1,
                        col: prevSelectedCell.col,
                    };
                } else {
                    return prevSelectedCell;
                }
            });
            setEditing(false);
            editingValue.current = undefined;
            initialEditingValue.current = undefined;
        },
        [dataSources.length]
    );

    const submitChanges = useCallback(
        (params: {
            value: number;
            row: number;
            col: number;
            nanZeroValues?: boolean;
        }) => {
            const { value, row, col, nanZeroValues } = params;
            const selectedCell = dataSources[row][col];
            const parsedValue = Number(value);

            const dashValue = Number.isNaN(parsedValue) && nanZeroValues === true;

            const formattedValue = dashValue
                ? Number.NaN
                : selectedCell.applyDollarFormatting
                ? formatDollarField(parsedValue)
                : parsedValue;
            if (
                selectedCell.validator !== undefined &&
                !selectedCell.validator(formattedValue)
            ) {
                return;
            }
            if (selectedCell.onSubmit) {
                selectedCell.onSubmit?.(formattedValue);
            } else {
                onCellValueChanged?.({
                    row,
                    updatedValue: formattedValue,
                    propertyPath: selectedCell["propertyPath"]!,
                });
            }
        },
        [dataSources, onCellValueChanged]
    );

    const keyDownHandler = useCallback(
        (e: KeyboardEvent) => {
            const controlKey = navigator.platform.match(/Mac/i)
                ? e.metaKey
                : e.ctrlKey;
            if (selectedCell?.row == undefined || selectedCell?.col == undefined) {
                return;
            }
            if (!isInFocus) return;

            if (/^\d+$|^-$/.test(e.key) && !editing) {
                initialEditingValue.current = e.key;
                editingValue.current = Number(e.key);
                setEditing(true);
                return;
            }
            if (controlKey && e.key.toLowerCase() === "v") {
                if (
                    dataSources[selectedCell.row][selectedCell.col].readOnly ||
                    dataSources[selectedCell.row][selectedCell.col].editModal
                ) {
                    return;
                }
                navigator.clipboard
                    .readText()
                    .then((text) => {
                        initialEditingValue.current = text;
                        editingValue.current = Number(text.replaceAll(",", ""));
                        setEditing(true);
                        return;
                    })
                    .catch((e) => e);
            }
            switch (e.key) {
                case "Tab":
                    if (
                        editing &&
                        dataSources[selectedCell.row][selectedCell.col].editModal
                    ) {
                        return;
                    }
                    e.preventDefault();
                    if (editingValue.current != undefined) {
                        submitChanges({
                            value: editingValue.current,
                            row: selectedCell.row,
                            col: selectedCell.col,
                            nanZeroValues:
                                dataSources[selectedCell.row][selectedCell.col]
                                    .nanInsteadOfZeroOut,
                        });
                    } else {
                        if (
                            dataSources[selectedCell.row][selectedCell.col].onSubmit
                        ) {
                            dataSources[selectedCell.row][
                                selectedCell.col
                            ]?.onSubmit?.(initialEditingValue.current);
                        }
                    }
                    if (e.shiftKey) {
                        moveSelectedCellToLeft();
                    } else {
                        moveSelectedCellToRight();
                    }
                    break;
                case "Enter":
                    if (
                        !editing &&
                        !dataSources[selectedCell.row][selectedCell.col].readOnly
                    ) {
                        initialEditingValue.current = undefined;
                        // This is to prevent the "Enter" key from submitting the form on editModal opening. This bug has only be replicated when a form is empty.
                        // Forms with actual initial values don't submit on open when "Enter" key is pressed.
                        e.preventDefault();
                        setEditing(true);
                    } else {
                        if (
                            editing &&
                            dataSources[selectedCell.row][selectedCell.col].editModal
                        ) {
                            // We wish to allow for the currently opened modal to have access to the "Enter" key for form submission purposes
                            return;
                        }
                        // This is to prevent side-effect caused by the "enter" key such as unintended form submission events.
                        e.preventDefault();
                        if (editingValue.current != undefined) {
                            submitChanges({
                                value: editingValue.current,
                                row: selectedCell.row,
                                col: selectedCell.col,
                                nanZeroValues:
                                    dataSources[selectedCell.row][selectedCell.col]
                                        .nanInsteadOfZeroOut,
                            });
                        } else {
                            if (
                                dataSources[selectedCell.row][selectedCell.col]
                                    .onSubmit
                            ) {
                                dataSources[selectedCell.row][
                                    selectedCell.col
                                ]?.onSubmit?.(initialEditingValue.current);
                            }
                        }
                        if (e.shiftKey) {
                            moveSelectedCellToLeft();
                        } else {
                            moveSelectedCellToRight();
                        }
                    }
                    break;
                case "ArrowRight":
                    if (!editing) {
                        moveSelectedCellToRight();
                    }
                    break;
                case "ArrowLeft":
                    if (!editing) {
                        moveSelectedCellToLeft();
                    }
                    break;
                case "ArrowUp":
                    if (!editing) {
                        moveSelectedCellUp(e);
                    }
                    break;
                case "ArrowDown":
                    if (!editing) {
                        moveSelectedCellDown(e);
                    }
                    break;
                case "Escape":
                    if (editing) {
                        setEditing(false);
                        editingValue.current = undefined;
                    }
                    break;
                default:
                    break;
            }
        },
        [
            dataSources,
            editing,
            moveSelectedCellDown,
            moveSelectedCellToLeft,
            moveSelectedCellToRight,
            moveSelectedCellUp,
            selectedCell?.col,
            selectedCell?.row,
            submitChanges,
            isInFocus,
        ]
    );

    React.useEffect(() => {
        window.addEventListener("keydown", keyDownHandler, true);
        return () => {
            window.removeEventListener("keydown", keyDownHandler, true);
        };
    }, [keyDownHandler]);

    return (
        <div>
            {dataSources.map((dataSource, row) => (
                <StyledTableRow key={row}>
                    {dataSource.map(
                        (
                            {
                                value,
                                editModal,
                                readOnly = false,
                                modalTitle,
                                subValue,
                                statusField,
                                hideEditModalFooter,
                                isExplicitlyNotTitleCell,
                                errorHighlighting,
                                nanInsteadOfZeroOut,
                                manuallyChangedData,
                                editPopover,
                                validator,
                            },
                            col
                        ) => (
                            <Cell
                                tableConfigs={tableConfigs}
                                key={col}
                                evenDistribution={evenDistribution}
                                onClick={() => {
                                    onClickHandler?.();

                                    if (
                                        selectedCell?.row === row &&
                                        selectedCell?.col === col
                                    ) {
                                        return;
                                    }

                                    if (selectedCell && editingValue.current) {
                                        submitChanges({
                                            value: editingValue.current,
                                            row: selectedCell.row,
                                            col: selectedCell.col,
                                            nanZeroValues:
                                                dataSources[selectedCell.row][
                                                    selectedCell.col
                                                ].nanInsteadOfZeroOut,
                                        });
                                    }
                                    editingValue.current = undefined;
                                    initialEditingValue.current = undefined;
                                    setSelectedCell({ row, col });
                                    setEditing(false);
                                }}
                                onDoubleClick={() => {
                                    onClickHandler?.();

                                    if (editing && selectedCell) {
                                        if (
                                            selectedCell.row === row &&
                                            selectedCell.col === col
                                        ) {
                                            // If the user double clicks on the current cell being edited we ignore this input as it can clear the cell in some cases.
                                            return;
                                        }
                                    }
                                    initialEditingValue.current = undefined;
                                    editingValue.current = undefined;
                                    setSelectedCell({ row, col });
                                    setEditing(true);
                                }}
                                selected={
                                    selectedCell?.row === row &&
                                    selectedCell?.col === col
                                }
                                onValueChanged={(updatedValue) => {
                                    editingValue.current = updatedValue;
                                }}
                                editing={
                                    selectedCell?.row === row &&
                                    selectedCell?.col === col &&
                                    editing
                                }
                                value={value}
                                subValue={subValue}
                                editModal={editModal}
                                modalTitle={modalTitle}
                                readOnly={readOnly}
                                onCloseEditModal={() => setEditing(false)}
                                col={col}
                                row={row}
                                statusField={statusField?.(row)}
                                hideEditModalFooter={hideEditModalFooter}
                                initialEditingValue={initialEditingValue.current}
                                isExplicitlyNotTitleCell={isExplicitlyNotTitleCell}
                                errorHighlighting={errorHighlighting}
                                nanEmptyInput={nanInsteadOfZeroOut}
                                manuallyChangedData={manuallyChangedData}
                                editPopover={editPopover}
                                validator={validator}
                            />
                        )
                    )}
                </StyledTableRow>
            ))}
        </div>
    );
};
