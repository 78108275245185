import { Button, Result } from "antd";
import React, { ReactNode } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ENV } from "../../@Constants";

const ErrorBoundaryWrapper = ({ children }: { children: ReactNode }) => {
    return (
        <ErrorBoundary
            fallbackRender={({ error }) => (
                <div>
                    <Result
                        status="error"
                        title="Something went wrong"
                        // Only show extended error details in dev environments
                        subTitle={
                            ENV === "dev" ? (
                                <details
                                    style={{
                                        whiteSpace: "pre-wrap",
                                    }}
                                >
                                    {error.message}
                                    <br />
                                    <br />
                                    {error.stack}
                                </details>
                            ) : null
                        }
                        extra={
                            <Button
                                type="primary"
                                key="console"
                                onClick={() => {
                                    window.location.reload();
                                }}
                            >
                                Refresh Page
                            </Button>
                        }
                    />
                </div>
            )}
        >
            {children}
        </ErrorBoundary>
    );
};

export { ErrorBoundaryWrapper };
