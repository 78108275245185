import { useMemo } from "react";
import { AntDFormStateWithSplitFlag } from "@types";
import {
    DepositInTransactionInit,
    DepositOutTransactionInit,
} from "Pages/CashupHome/CashupTabs/POS/@types";
import { Field } from "Pages/CashupHome/ExcelTable/TransactionForms/SalesCountModalFormV2";

export const depositInFields: Field[] = [
    {
        name: "Amount",
        required: true,
        type: { name: "Money", positiveValueOnly: true },
        colSpan: 6,
    },
    {
        name: "Note",
        type: { name: "Text" },
        required: true,
        colSpan: 10,
    },
    {
        name: "Tender split",
        required: true,
        type: {
            name: "DropdownLazy",
        },
        colSpan: 6,
    },
    { name: "Button", type: { name: "Button" }, colSpan: 2, isButton: true },
];

export const depositOutFields: Field[] = [
    {
        name: "Amount",
        required: true,
        type: { name: "Money", positiveValueOnly: true },
        colSpan: 6,
    },
    {
        name: "Note",
        type: { name: "Text" },
        required: true,
        colSpan: 10,
    },
    {
        name: "Tender split",
        required: true,
        type: {
            name: "DropdownLazy",
        },
        colSpan: 6,
    },
    { name: "Button", type: { name: "Button" }, colSpan: 2, isButton: true },
];

interface UseTransactionDepositProps {
    DepositInTransactionData: DepositInTransactionInit[];
    DepositOutTransactionData: DepositOutTransactionInit[];
}

const createInitialData = (
    data: (DepositInTransactionInit | DepositOutTransactionInit)[] = []
) => {
    const parsedData: AntDFormStateWithSplitFlag[] = data.map(
        (currentDepositInTransaction) => {
            return {
                formData: [
                    { name: "Amount", value: currentDepositInTransaction.amount },
                    { name: "Note", value: currentDepositInTransaction.memo },
                    {
                        name: "Tender split",
                        value: currentDepositInTransaction.account_split![0]
                            .account_id,
                    },
                ],
                formID: Math.random().toString(),
            };
        }
    );

    parsedData.push({
        formData: [],
        formID: Math.random().toString(),
    });

    return parsedData;
};

const useTransactionDeposit = ({
    DepositInTransactionData,
    DepositOutTransactionData,
}: UseTransactionDepositProps) => {
    const initialDepositInTransactionsDataWithAppendedFormID: AntDFormStateWithSplitFlag[] =
        useMemo(() => createInitialData(DepositInTransactionData), []);

    const initialDepositOutTransactionsDataWithAppendedFormID: AntDFormStateWithSplitFlag[] =
        useMemo(() => createInitialData(DepositOutTransactionData), []);

    return {
        initialDepositInTransactionsDataWithAppendedFormID,
        initialDepositOutTransactionsDataWithAppendedFormID,
    };
};

export default useTransactionDeposit;
