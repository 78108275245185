import { Button, Col, DatePicker, Row, Select } from "antd";
import { SelectValue } from "antd/lib/select";
import { RangePicker } from "Components/RangePicker/RangePicker";
import React, { useCallback, useMemo } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { getVenues } from "ApiV2/Helpers/getVenues";
import { Venue } from "Types/Venue";
import {
    changeDate,
    changeRange,
    changeSelectedReport,
} from "Redux/StateSlices/ShiftReportState";
import { useAppDispatch, useAppSelector } from "Redux/TypedReduxFunctions";
import styled from "styled-components";
import {
    dayjsFormat,
    disabledDate,
    QTCDate,
    QTCRange,
    toQTCRange,
} from "utils/date-utils";
import dayjs from "dayjs";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { GroupDataState } from "../../../Redux/StateSlices/GroupData";
import { useVenue } from "../../../hooks/useVenue";

const { Option } = Select;

interface Props {
    singleDateSelector?: boolean;
    onExport?: () => void;
    exportBtnDisabled?: boolean;
    isDetailedReport?: boolean;
    shouldShowReportSelector?: boolean;
    groupData: GroupDataState;
}

let CashupReportSelector: React.FC<Props> = ({
    singleDateSelector = false,
    exportBtnDisabled = false,
    onExport,
    isDetailedReport = false,
    shouldShowReportSelector = false,
    groupData,
}) => {
    const { selectedDate, selectedVenueId, selectedRange, selectedReport } =
        useAppSelector((state) => state.shiftReportSlice);
    const [selectedVenue, _] = useLocalStorage<string | null>("selectedVenue", null);

    const selectedVenueToUse = useMemo(
        () => (selectedVenueId ? selectedVenueId : selectedVenue),
        [selectedVenueId, selectedVenue]
    );
    const { setVenue } = useVenue();
    const [localSelectedDate, setSelectedDate] = useLocalStorage<string | null>(
        "selectedDate",
        null
    );
    const { parsedDate, parsedRange } = useMemo(() => {
        return {
            parsedDate: selectedDate
                ? dayjs(selectedDate, "DD-MM-YYYY")
                : localSelectedDate
                ? dayjs(localSelectedDate, "DD-MM-YYYY")
                : undefined,
            parsedRange: selectedRange
                ? toQTCRange(selectedRange?.start, selectedRange?.end)
                : undefined,
        };
    }, [selectedDate, selectedRange]);

    const dispatch = useAppDispatch();

    const [token] = React.useState<string | null>(
        localStorage.getItem("access_token")
    );
    //get venues list
    const { data: venueData, isLoading: isVenueDataLoading } = useQuery(
        ["venuesData", token],
        () => getVenues()
    );

    const history = useHistory();

    const onboardingDate = useMemo(() => {
        if (!selectedVenueToUse) {
            return undefined;
        } else {
            return groupData?.venues.find(
                (venue) => venue.venue_id === selectedVenueToUse
            )?.onboarding_date;
        }
    }, [groupData, selectedVenueToUse]);

    const onSelectedRangeChanged = useCallback(
        (newRange?: QTCRange) =>
            newRange &&
            dispatch(
                changeRange({
                    newRange: {
                        start: newRange.start.format(),
                        end: newRange.end.format(),
                    },
                })
            ),
        [dispatch]
    );

    const onSelectedDateChanged = useCallback(
        (newDate: QTCDate | null) =>
            newDate &&
            dispatch(
                changeDate({
                    newDate: newDate.format("DD-MM-YYYY").toString(),
                })
            ) &&
            setSelectedDate(newDate.format("DD-MM-YYYY").toString()),

        [dispatch, setSelectedDate]
    );

    const venueOptions =
        venueData?.map(({ venue_id, name }: Venue) => ({ id: venue_id, name })) ??
        [];

    if (isVenueDataLoading) {
        return null;
    }

    return (
        <Col>
            <Row>
                <InputSelector>
                    <Col style={{ flexWrap: "wrap" }}>
                        <Row style={{ gap: 12 }}>
                            <Select
                                size="middle"
                                style={{
                                    width: 200,
                                    marginBottom: 6,
                                }}
                                placeholder="Venue"
                                onChange={(value: SelectValue) => {
                                    setVenue(value);
                                }}
                                value={selectedVenueToUse}
                            >
                                {venueOptions.map(({ id, name }: any) => (
                                    <Option key={id} value={id}>
                                        {name}
                                    </Option>
                                ))}
                            </Select>
                            <div style={{ display: "inline" }}>
                                {singleDateSelector ||
                                selectedReport == "Gaming Report" ||
                                selectedReport == "Bankable" ? (
                                    <DatePicker
                                        value={parsedDate}
                                        format={
                                            dayjsFormat.dayMonthYearSeparateByDash
                                                .format
                                        }
                                        onChange={onSelectedDateChanged}
                                        disabledDate={disabledDate(onboardingDate)}
                                    />
                                ) : (
                                    <RangePicker
                                        onChange={onSelectedRangeChanged}
                                        defaultRange={parsedRange}
                                        disabledDate={disabledDate(onboardingDate)}
                                    />
                                )}
                            </div>
                            {onExport ? (
                                <Button
                                    onClick={onExport}
                                    disabled={exportBtnDisabled}
                                    style={{
                                        width: 200,
                                        marginRight: 12,
                                        marginTop: 6,
                                        marginBottom: 6,
                                    }}
                                >
                                    Export
                                </Button>
                            ) : null}

                            {isDetailedReport && (
                                <Button
                                    onClick={() => history.goBack()}
                                    disabled={exportBtnDisabled}
                                    style={{ width: 200 }}
                                >
                                    Return to summary
                                </Button>
                            )}
                        </Row>
                    </Col>
                </InputSelector>
            </Row>
            {shouldShowReportSelector && (
                <Row
                    style={{
                        alignContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                        gap: 12,
                        padding: 12,
                        height: "100%",
                    }}
                >
                    <div>{"Current Report"}</div>
                    <Button
                        type={selectedReport === "Summary" ? "primary" : "default"}
                        onClick={() => {
                            dispatch(
                                changeSelectedReport({
                                    newReport: "Summary",
                                })
                            );
                        }}
                    >
                        Summary
                    </Button>
                    <Button
                        type={selectedReport === "Detailed" ? "primary" : "default"}
                        onClick={() => {
                            dispatch(
                                changeSelectedReport({
                                    newReport: "Detailed",
                                })
                            );
                        }}
                    >
                        Detailed
                    </Button>
                    <Button
                        type={selectedReport === "Bankable" ? "primary" : "default"}
                        onClick={() => {
                            dispatch(
                                changeSelectedReport({
                                    newReport: "Bankable",
                                })
                            );
                        }}
                    >
                        Bankable
                    </Button>
                    <Button
                        type={
                            selectedReport === "Gaming Report"
                                ? "primary"
                                : "default"
                        }
                        onClick={() => {
                            dispatch(
                                changeSelectedReport({
                                    newReport: "Gaming Report",
                                })
                            );
                        }}
                    >
                        Gaming Report
                    </Button>
                </Row>
            )}
        </Col>
    );
};

CashupReportSelector = React.memo(CashupReportSelector);

export { CashupReportSelector };

const InputSelector = styled(Col)`
    margin-right: auto;
`;
