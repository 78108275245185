const ToolTips = {
    NavBar: {
        Cashups: "Cashups show all Transactions at a Location for a period of time",
        Home: "The home screen will show you any cashups where you are either assigned or the approver",
        Locations: "Locations are the points in your venue where cash can be held",
        Transactions: "Transactions are movements of money",
        Reports:
            "Reports is the section where you can extract a journal for import into your financial system",
        Setup: "Setup is the section where you can configure the system for your business",
    },
    TransactionFormTableHeadings: {
        Class: "The applicable Class (Sales Category)",
        Amount: "The Amount sold for the Class",
    },
    TransactionFormHeadings: {
        "New Sale Transaction":
            "Sales (aka Z-Reads) are generally used to record sales from the POS at the end of a shift",
        "Class Split":
            "Class splits allow you to breakdown the total by sale type (what amount per sales category)",
    },
    TransactionFormFields: {
        "Tender Split":
            "Tender splits allow you to breakdown the total by tender type (how the money was paid)",
        Amount: "The total amount of this transaction",
        "Class Splits":
            "Class splits allow you to breakdown the total by sale type (what amount per sales category)",
        Description:
            "A brief note that describes what this transaction is for, it should provide enough information others to understand why it was created",
        "Track Balances":
            "Optional - We can choose to track the balance against an entity record. This way we can track how much deposit they hold with us",
        "In POS as sale":
            "We must select whether the deposit has already been recorded in the POS, followed by more detail based on this selection",
        "Who (Track Balance Modal)":
            "The entity whose balance we are tracking this deposit against",
        "Balance (Track Balance Modal)":
            "The entity's Deposit current balance (before this transaction takes effect)",
        "In POS as Sale (POS Modal)":
            "We must select whether the deposit has already been recorded as a sale in the POS",
        "Class (POS Modal)":
            "If It has not already been recorded, we must declare how the money was received",
        "Tender (POS Modal)":
            "If It has not already been recorded, we must declare how the money was received",
        "Out Type":
            "We must select if the deposit was used or refunded, followed by more detail based on this selection",
        "Out Type (Out Modal)": "We must select if the deposit was used or refunded",
        "In POS as Sale (Out Modal)":
            "We must select whether the use of this deposit has already been recorded as a sale in the POS",
        "Class (Use Deposit) (Out Modal)":
            "The Deposit has been used and not yet recorded as a sale. We need to select the Class (sales category) in order to properly record this as a sale",
        "Tender (Use Deposit) (Out Modal)":
            "The Deposit has been used but already recorded as a sale, we need to declare what tender account was used in the POS to record the sale so we can prevent double counting",
        "Tender (Refund) (Out Modal)":
            "The Deposit has been refunded and not recorded in the POS as a negative sale. We need to select what tender was used to process this refund to properly record it",
        "Class (Refund) (Out Modal)":
            "The Deposit has been refunded and recorded in the POS as a negative sale, we need to declare what class account was used in the POS to record the refund so we can prevent double counting",
        From: "The location money was moved from (reduces balance)",
        To: "The location money was moved to (increases balance)",
        Expected:
            "The Expected amount is auto calculated and is the cash expected at this location based on the Datestamp selected - it sums the cash impact of all transactions to this point",
        Actual: "The Actual amount is the cash you have counted at this location, it should ideally equal the Expected amount",
        Variance:
            "The Variance amount is the difference between the Expected amount and the Actual amount",
        Account: "The Account the Variance should be posted to",
        Who: "The entity who received the payment (eg. Employees, Suppliers etc)",
        Reason: "The reason for the payment",
    },
    ModalHeaderFields: {
        "New Sale Transaction":
            "Sales (aka Z-Reads) are generally used to record sales from the POS at the end of a shift",
        "New Deposit In Transaction":
            "Records money received from a customer as a Deposit. Deposits (aka function deposits aka gift cards) are used to track money given to us by our customers for future use",
        "New Deposit Out Transaction":
            "Records money used against an existing Deposit by a customer. Deposits (aka function deposits aka gift cards) are used to track money given to us by our customers for future use.",
        "New Transfer Transaction":
            "Transfers are used to record money moving to another location inside the venue (eg. refloat a till from the safe)",
        "New Cash Count Transaction":
            "Cash counts are used to reconcile the actual cash at a location back to what is expected",
        "New Payment Transaction":
            "Payments are used to record any other money leaving the venue (eg. petty cash, supplier payments)",
        "New Eftpos Count Transaction": "",
        "Out Type":
            "We must select if the deposit was used or refunded, followed by more detail based on this selection",
        "Type Select":
            "We must select whether the deposit has already been recorded in the POS, followed by more detail based on this selection",
        "Track Balances":
            "We can choose to track the balance against an entity record. This way we can track how much deposit they hold with us",
        "Tender Split":
            "Tender splits allow you to breakdown the total by tender type (how the money was paid)",
        "Class Split":
            "Class splits allow you to breakdown the total by sale type (what amount per sales category)",
        "Customer Details": "Customer Details",
    },
    AddTransactionButtons: {
        "Add Transaction":
            "Transactions are movements of money, when they are logged they will showup in the transactions list",
        options: {
            Sale: "Sales (aka Z-Reads) are generally used to record sales from the POS at the end of a shift",
            Transfer:
                "Transfers are used to record money moving to another location inside the venue (eg. refloat a till from the safe)",
            "Cash Count":
                "Cash counts are used to reconcile the actual cash at a location back to what is expected",
            Payment:
                "Payments are used to record any other money leaving the venue (eg. petty cash, supplier payments)",
            "Deposit In":
                "Records money received from a customer as a Deposit. Deposits (aka function deposits aka gift cards) are used to track money given to us by our customers for future use.",
            "Deposit Out":
                "Records money used against an existing Deposit by a customer. Deposits (aka function deposits aka gift cards) are used to track money given to us by our customers for future use.",
            "Eftpos Count": "",
        },
    },
    TenderModal: {
        Tender: "The applicable Tender type",
        Amount: "The Amount received for the Tender",
        "Amount / Remaining / Total":
            "The Amount you have already broken down, the amount Remaining to be broken down and the Total amount to be broken down ",
    },
    ClassModal: {
        Class: "The applicable Class (Sales Category)",
        Amount: "The Amount sold for the Class",
        "Amount / Remaining / Total":
            "The Amount you have already broken down, the amount Remaining to be broken down and the Total amount to be broken down ",
    },

    CurrentCashupPage: {
        title: "Cashups show all Transactions at a Location for a period of time.",
        tabs: {
            Transactions:
                "The movements of money for this location during the cashup period",
            History:
                "The logs of user changes for this cashup and its associated transactions",
        },
        transactionsTable: {
            Description: "The user-entered note for the transaction",
            Type: "The type of the transaction",
            Time: "The time of the transaction ",
            "Cash Δ":
                "The impact on the cash balance at this location, some transactions will have a $0 impact eg. Function Deposit received via credit card",
            Balance: "The new cash balance at this location",
        },
        transactionTableRows: {
            "Opening balance":
                "The cash holding at this location at the beginning of the cashup period - this will often be the default float value",
            "Closing balance":
                "The cash holding at this location at the end of the cashup period - this will often be the default float value",
        },
        CurrentCashupFormField: {
            Name: "The name of this cashup",
            From: "The start time for this cashup - all transactions from this time (up until the end time) for this location will show on this cashup",
            To: "The end time for this cashup - all transactions to this time (from the start time) for this location will show on this cashup",
            Location:
                "The location for this cashup - all transactions for this location (between the start and end times) will show on this cashup",
            Assignee: "The employee responsible for this cashup",
            Approver: "The employee responsible for approving this cashup",
            "Shift Notes": "Details what happened during the shift",
        },
    },
};

export { ToolTips };
