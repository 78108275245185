import React, { useState, useMemo } from "react";
import styled from "styled-components";
import dayjs from "dayjs";
import { ReportDynamicDataItem } from "@interfaces";
import { CustomDataTable, CustomTotalDataTable } from "./CashupReportStyled";
import {
    getAllUniqueKeys,
    columnGenerator,
    totalsForTable,
    calculateTotalGamingReturnToHouse,
} from "../utils";

const TableContainer = styled.div`
    border: 1px solid #f0f0f0;
    width: 100%;
    margin-top: 15px;
    border-radius: 8px 8px 0 0;
`;

interface ReportTableProps {
    data: ReportDynamicDataItem[];
    sortBoolean: boolean;
    gamingBoolean?: boolean;
    downloading?: boolean;
}

const CashupReportTable: React.FC<ReportTableProps> = ({
    data,
    sortBoolean,
    gamingBoolean,
    downloading,
}) => {
    const [sortedColumn, setSortedColumn] = useState<string>("");
    const [sortDirection, setSortDirection] = useState<"ascend" | "descend" | null>(
        null
    );

    //sort data by date
    const sortedData = useMemo(() => {
        return data.sort((a, b) => {
            const dateA = dayjs(a.date, "DD/MM/YYYY");
            const dateB = dayjs(b.date, "DD/MM/YYYY");
            return dateA.diff(dateB);
        });
    }, [data]);

    //extracting unique keys from all data object
    const allKeys: string[] = useMemo(
        () => getAllUniqueKeys(sortedData) as string[],
        [sortedData]
    );
    //sorting keys
    const sortedKeys = sortBoolean ? allKeys.sort() : allKeys;
    //prepare columns
    const columns = useMemo(
        () => columnGenerator(sortedKeys, true, sortedColumn, sortDirection),
        [sortedKeys, sortedColumn, sortDirection]
    );
    //compute totals for each column
    const totals: Record<string, number> = useMemo(
        () => totalsForTable(sortedData),
        [sortedData]
    );
    //update return to house for totals in gaming report
    const updatedTotals: Record<string, number> = useMemo(
        () => (gamingBoolean ? calculateTotalGamingReturnToHouse(totals) : totals),
        [totals, gamingBoolean]
    );
    //prepare total row
    const totalRow: Record<string, number | string> = {
        date: "Total",
        ...updatedTotals,
    };
    //prepare totals columns
    const totalColumns = useMemo(
        () => columnGenerator(sortedKeys, false, sortedColumn, sortDirection),
        [sortedKeys, sortedColumn, sortDirection]
    );
    //handle sorting
    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setSortedColumn(sorter.columnKey);
        setSortDirection(sorter.order as "ascend" | "descend" | null);
    };

    const paginationConfig = {
        pageSize: 10,
        total: data.length,
        showSizeChanger: false,
        showQuickJumper: false,
    };

    return (
        <>
            <TableContainer>
                <CustomDataTable
                    rowKey="date"
                    dataSource={sortedData}
                    columns={columns}
                    size="small"
                    scroll={{ x: true }}
                    tableLayout="fixed"
                    pagination={downloading ? false : paginationConfig}
                    onChange={handleTableChange}
                />
            </TableContainer>
            <TableContainer>
                <CustomTotalDataTable
                    columns={totalColumns}
                    dataSource={[totalRow]}
                    size="small"
                    scroll={{ x: true }}
                    tableLayout="fixed"
                    pagination={false}
                />
            </TableContainer>
        </>
    );
};

export default CashupReportTable;
