import React from "react";
import "./index.css";

// TODO Get rid of the inline CSS replace with CSS classes.
// TODO ensure the classnames are unique to the component (e.g. QuantacoLoaderPulse)

export const QuantacoLoader = ({ size = 400 }: { size?: number }) => {
    return (
        <div
            style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
            }}
        >
            <div
                className="pulse"
                style={{
                    justifyContent: "center",
                    alignItems: "center",
                    display: "flex",
                    color: "#fff",
                    height: size,
                    width: size,
                }}
            >
                <div className="logoText" style={{ fontSize: `${size / 1.5}px` }}>
                    Q
                </div>
            </div>
        </div>
    );
};
