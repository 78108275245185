import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface LastTransactionState {
    lastTransactionState: string;
}
const initialState: LastTransactionState = {
    lastTransactionState: "",
};
const lastTransactionSlice = createSlice({
    name: "lastTransactionSlice",
    initialState,
    reducers: {
        changeLastTransactionState(
            state,
            payload: PayloadAction<{ newState: string }>
        ) {
            state.lastTransactionState = payload.payload.newState;
        },
    },
});

export const { changeLastTransactionState } = lastTransactionSlice.actions;
export default lastTransactionSlice.reducer;
