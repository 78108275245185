import { Button, Statistic, Tabs } from "antd";
import { Tab } from "rc-tabs/lib/interface";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import POSTab from "../POS/POSTab";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import WageringTab from "../Wagering/WageringTab";
import ATMTab from "../ATM/ATMTab";
import OfficeTab from "../Office/OfficeTab";
import VarianceSummary from "../Variance/VarianceSummary";
import ShiftReportTab from "../ShiftReport/ShiftReportTab";
import { useIsMobile } from "../../Pages/CashupHome/CollaborationTable/useIsMobile";
import { useQuery } from "react-query";
import { getTabs } from "../../ApiV2/Helpers/getTabs";
import { QuantacoLoader } from "../../Components/QuantacoLoader/QuantacoLoader";
import { StyledButton, UpdateSection } from "../StyledComponents";
import { DownloadOutlined, SyncOutlined, UploadOutlined } from "@ant-design/icons";
import { getPosIntegrationData } from "../../ApiV2/Helpers/getPosIntegrationData";
import {
    MODAL_GENERIC_ERROR,
    MODAL_GENERIC_SUCCESS,
} from "../../Pages/CashupHome/ExcelTable/utils";
import { useContextModal } from "../../hooks/useModal";
import { getVenueById } from "../../ApiV2/Helpers/getVenueById";
import { downloadPdf } from "utils/dom-utils";
import { getGamingDownload } from "ApiV2/Helpers/getGamingDownload";
import { useLoader } from "hooks/loaderProvider";
import dayjs from "dayjs";
import { postGamingUpload } from "ApiV2/Helpers/postGamingUpload";
import { getLastTransaction } from "ApiV2/Helpers/getLastTransaction";
import { GamingTabContents } from "../Gaming/GamingTabContents";
import { GamingKeyFigures } from "../Gaming/GamingKeyFigures";
import { SafeTabWrapper } from "../Safe/SafeTabWrapper";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

export const CashupTabs = () => {
    dayjs.extend(utc);
    dayjs.extend(timezone);
    const { openModal, closeModal } = useContextModal();
    const [fileUploadCount, setFileUploadCount] = useState(0);
    const [updatePOS, setUpdatePOS] = useState(false);
    const [gamingFileUploadIsLoading, setGamingFileUploadIsLoading] =
        useState(false);
    const onChange = (key: string) => {
        // @ts-ignore
        window.history.pushState({}, "", `/${key.toLowerCase().replace(" ", "-")}`);
        document.title = `Cashup - ${(
            key.charAt(0).toUpperCase() + key.slice(1)
        ).replace(" ", "-")}`;

        setActive(key.toLowerCase().replace(" ", "-"));
    };
    const [active, setActive] = useState<string>(
        window.location.pathname.slice(1) !== ""
            ? window.location.pathname.slice(1)
            : "pos"
    );
    const [selectedFile, setSelectedFile] = useState<File | null>(null);
    const inputRef = useRef<null | HTMLInputElement>(null);

    const { withLoader } = useLoader();
    const isMobile = useIsMobile();
    const { venueId, formattedDate, shiftDate, shiftStatus } = useVenueSelection();

    const [gamingDownloadStatus, setGamingDownloadStatus] = useState(false);
    const [lastTransaction, setLastTransaction] = useState("");
    const { data: tabs, isLoading: tabsIsLoading } = useQuery(
        ["tabs", venueId],
        () => getTabs(venueId, formattedDate),
        {
            enabled: venueId != null && venueId !== "",
        }
    );

    const disabled = useMemo(() => shiftStatus !== "UNSUBMITTED", [shiftStatus]);
    const { data: venueData } = useQuery(
        ["venueData", venueId, formattedDate],
        () => getVenueById(venueId),
        {
            enabled: venueId != null && venueId !== "",
        }
    );
    const handleFileUpload = useCallback(() => {
        if (inputRef.current) {
            inputRef.current.click();
        }
    }, []);
    const handleFileSelection = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files && event.target.files[0];
        if (file && file.type === "application/pdf") {
            setSelectedFile(file);
            event.target.value = "";
        } else {
            MODAL_GENERIC_ERROR(
                openModal,
                closeModal,
                undefined,
                <>
                    <h3>Please select a PDF file</h3>
                    <p>Gaming upload only accept PDF files</p>
                </>,
                undefined
            );
            event.target.value = "";
            setSelectedFile(null);
        }
    };
    const downloadGaming = async () => {
        withLoader(async () => {
            const response = await getGamingDownload(venueId, formattedDate);
            if (response.ok) {
                const data = await response.json();
                downloadPdf(data.url, `Global-Gaming-Summary`);
            } else {
                const error = await response.json();
                setGamingDownloadStatus(false);
                MODAL_GENERIC_ERROR(
                    openModal,
                    closeModal,
                    undefined,
                    <>
                        <h3>PDF file download failed.</h3>
                        <p>{Object.values(error)[0]}</p>
                    </>
                );
            }
            setSelectedFile(null);
        });
    };

    const isPosIntegrationEnabled = useMemo(() => {
        if (venueData)
            return (
                !!venueId &&
                !!formattedDate &&
                !!venueData.pos_integration_enabled &&
                !dayjs(formattedDate, "YYYY-MM-DD").isBefore(
                    dayjs(venueData.pos_enabled_date, "YYYY-MM-DD")
                )
            );
        else return undefined;
    }, [venueId, formattedDate, venueData]);
    const isGamingUploadEnabled = useMemo(() => {
        if (venueData)
            return !!(
                venueId &&
                formattedDate &&
                venueData.gaming_enabled_date &&
                !dayjs(formattedDate, "YYYY-MM-DD").isBefore(
                    dayjs(venueData.gaming_enabled_date, "YYYY-MM-DD")
                )
            );
        else return false;
    }, [venueData, venueId, formattedDate, shiftDate]);
    useEffect(() => {
        if (active !== "" && tabs) {
            const tabNotAvailableForVenue = (tabs as string[]).some(
                (t) => t.toLowerCase().replace(" ", "-") === active
            );

            if (!tabNotAvailableForVenue) {
                window.location.href = "/";
            }
        }
    }, [active, tabs]);
    type PositionType = "right";
    useEffect(() => {
        if (updatePOS) {
            withLoader(async () => {
                try {
                    const data = await getPosIntegrationData(venueId, shiftDate); // dynamically using the latest shiftDate
                    const lastTransaction = data?.pos_last_updated_transaction
                        ? dayjs(data.pos_last_updated_transaction.date_time).format(
                              "ddd DD-MM-YYYY hh:mm"
                          )
                        : "";
                    setLastTransaction(lastTransaction);
                    MODAL_GENERIC_SUCCESS(
                        openModal,
                        closeModal,
                        "Updated successfully"
                    );
                    setUpdatePOS(false);
                } catch (error) {
                    setLastTransaction("");
                    setUpdatePOS(false);
                    MODAL_GENERIC_ERROR(
                        openModal,
                        closeModal,
                        undefined,
                        <>
                            <h3>Update failed</h3>
                            <p>
                                {typeof error === "string"
                                    ? error
                                    : //@ts-ignore
                                      error?.status ?? ""}
                            </p>
                        </>,
                        5000
                    );
                }
            });
        }
    }, [shiftDate, venueId, updatePOS, openModal, closeModal]);

    const updatePOSData = useCallback(async () => {
        setUpdatePOS(true);
    }, []); // Ensure shiftDate is included in dependencies

    const OperationsSlot = useCallback(
        (tab: "Pos" | "Gaming"): Record<PositionType, React.ReactNode> | null => {
            switch (tab) {
                case "Pos":
                    return {
                        right: (
                            <UpdateSection>
                                {lastTransaction !== "" ? (
                                    <Statistic
                                        title="Last Transaction"
                                        value={lastTransaction}
                                        valueStyle={{ fontSize: "14px" }}
                                        style={{
                                            padding: "0px 8px",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "flex-end",
                                            marginRight: "10px",
                                        }}
                                    />
                                ) : null}
                                <Button
                                    type="primary"
                                    size="large"
                                    icon={<SyncOutlined />}
                                    disabled={disabled}
                                    onClick={() => updatePOSData()}
                                >
                                    Update
                                </Button>
                            </UpdateSection>
                        ),
                    };
                case "Gaming":
                    return {
                        right: (
                            <UpdateSection>
                                <StyledButton
                                    type="primary"
                                    size="large"
                                    icon={<UploadOutlined />}
                                    disabled={disabled}
                                    onClick={() => handleFileUpload()}
                                    numberofitems={1}
                                    loading={gamingFileUploadIsLoading}
                                >
                                    Upload File
                                </StyledButton>
                                <input
                                    type="file"
                                    style={{ display: "none" }}
                                    accept="application/pdf"
                                    ref={inputRef}
                                    onChange={handleFileSelection}
                                />
                                <StyledButton
                                    type="primary"
                                    size="large"
                                    icon={<DownloadOutlined />}
                                    disabled={!gamingDownloadStatus}
                                    style={{ marginLeft: "20px" }}
                                    onClick={() => downloadGaming()}
                                    numberofitems={1}
                                >
                                    Download File
                                </StyledButton>
                            </UpdateSection>
                        ),
                    };
                default:
                    return null;
            }
        },
        [disabled, lastTransaction, gamingDownloadStatus, venueId]
    );

    // @ts-ignore
    const items = useMemo(
        () =>
            tabs !== undefined
                ? (tabs as string[]).map((tab) => {
                      let children: React.ReactNode;
                      switch (tab) {
                          case "POS":
                              children = (
                                  <POSTab
                                      setLastTransaction={setLastTransaction}
                                      updatePOSData={updatePOSData}
                                  />
                              );
                              break;
                          case "Wagering":
                              children = <WageringTab />;
                              break;
                          case "Gaming":
                              children = (
                                  <div>
                                      <GamingKeyFigures />
                                      <GamingTabContents
                                          // @ts-ignore
                                          isGamingUploadEnabled={
                                              isGamingUploadEnabled
                                          }
                                          setGamingDownloadStatus={
                                              setGamingDownloadStatus
                                          }
                                      />
                                  </div>
                              );
                              break;
                          case "ATM":
                              children = <ATMTab />;
                              break;
                          case "Office":
                              children = <OfficeTab />;
                              break;
                          case "Safe":
                              children = <SafeTabWrapper />;
                              break;
                          case "Review":
                              children = <VarianceSummary />;
                              break;
                          case "Shift Report":
                              children = <ShiftReportTab />;
                              break;
                          // default:
                          //     children = null;
                      }
                      return {
                          key: tab.toLowerCase().replace(" ", "-"),
                          label: tab,
                          children: children,
                          destroyInactiveTabPane: true,
                      } as Tab;
                  })
                : undefined,
        [tabs]
    );
    const mobileItems = useMemo(
        () => [
            {
                key: "shift-report",
                label: "Shift Report",
                children: <ShiftReportTab />,
            },
        ],
        []
    );

    useEffect(() => {
        const uploadFile = async (file: File) => {
            const formData = new FormData();
            formData.append("file", file);
            formData.append("venue_id", venueId);
            formData.append("shift_date", formattedDate);
            withLoader(async () => {
                setGamingFileUploadIsLoading(true);
                await postGamingUpload(formData)
                    .then(() => {
                        MODAL_GENERIC_SUCCESS(
                            openModal,
                            closeModal,
                            "PDF file upload successfully"
                        );
                    })
                    .catch((errorResponse) => {
                        MODAL_GENERIC_ERROR(
                            openModal,
                            closeModal,
                            undefined,
                            <>
                                <h3>PDF file upload failed.</h3>
                                <p>{Object.values(errorResponse)[0]}</p>
                            </>
                        );
                    })
                    .finally(() => setGamingFileUploadIsLoading(false));

                setFileUploadCount((prev) => prev + 1);
                setSelectedFile(null);
            });
        };
        if (selectedFile) {
            uploadFile(selectedFile);
        }
    }, [selectedFile, venueId, formattedDate]);
    useEffect(() => {
        const gamingDownloadCheck = async () => {
            try {
                const response = await getGamingDownload(venueId, formattedDate);
                if (response.ok) {
                    const data = await response.json();
                    if (typeof data.url === "string") setGamingDownloadStatus(true);
                    else setGamingDownloadStatus(false);
                } else setGamingDownloadStatus(false);
            } catch (error) {
                console.log(error);
            }
        };
        if (
            isGamingUploadEnabled &&
            window.location.pathname.slice(1) === "gaming"
        ) {
            gamingDownloadCheck();
        }
    }, [
        fileUploadCount,
        venueId,
        isGamingUploadEnabled,
        formattedDate,
        window.location.pathname,
    ]);

    useEffect(() => {
        const fetchLastPOSTransaction = async () => {
            try {
                const response = await getLastTransaction(venueId, formattedDate);
                if (response.ok) {
                    const res = await response.json();
                    setLastTransaction(
                        res.data?.pos_last_updated_transaction
                            ? dayjs(res.data.pos_last_updated_transaction.date_time)
                                  .utc() // Convert from UTC
                                  .tz("Australia/Sydney") // Convert to Australia/Sydney timezone
                                  .format("ddd DD-MM-YYYY hh:mm A") // Use A for AM/PM format
                            : ""
                    );
                } else {
                    setLastTransaction("");
                }
            } catch (error) {
                console.log("error", error);
            }
        };
        if (
            isPosIntegrationEnabled &&
            (window.location.pathname.slice(1) === "" ||
                window.location.pathname.slice(1) === "pos")
        ) {
            fetchLastPOSTransaction();
        }
    }, [isPosIntegrationEnabled, window.location.pathname, venueId, formattedDate]);

    if (tabsIsLoading || !tabs) {
        return <QuantacoLoader size={150} />;
    }
    if (!venueId) return <></>; //TODO:this means venues data fetching failed, need display error information

    return (
        <>
            <Tabs
                style={{ marginLeft: "30px", marginTop: "20px", width: "97%" }}
                activeKey={active}
                items={isMobile ? mobileItems : items}
                onChange={onChange}
                tabBarExtraContent={
                    isPosIntegrationEnabled &&
                    (window.location.pathname.slice(1) === "pos" ||
                        window.location.pathname.slice(1) === "")
                        ? OperationsSlot("Pos")
                        : isGamingUploadEnabled &&
                          window.location.pathname.slice(1) === "gaming"
                        ? OperationsSlot("Gaming")
                        : undefined
                }
            ></Tabs>
        </>
    );
};
