import React, { useState, useMemo } from "react";
import { ShiftReportMetricsItem } from "@interfaces/index";
import CashupReportBarChart from "../../CashupReportBarChart";
import {
    LocationContainer,
    TableTitle,
    CustomDataTable,
    ShiftMetricsTableContainer,
} from "../../CashupReportStyled";
import {
    getAllUniqueKeys,
    shiftMetricsColumnGenerator,
    prepareShiftGraphData,
} from "../../../utils";

interface ShiftReportMetricsProps {
    revenueData: { data: ShiftReportMetricsItem[]; title: string };
    chartLabal: string;
}

const GroupShiftReportMetrics: React.FC<ShiftReportMetricsProps> = ({
    revenueData,
    chartLabal,
}) => {
    const [sortedColumn, setSortedColumn] = useState<string>("");
    const [sortDirection, setSortDirection] = useState<"ascend" | "descend" | null>(
        null
    );

    const sortedData = useMemo(() => revenueData.data, [revenueData]);

    //extract unique keys
    const allKeys: string[] = useMemo(
        () => getAllUniqueKeys(sortedData) as string[],
        [sortedData]
    );

    const columns = useMemo(
        () => shiftMetricsColumnGenerator(allKeys, sortedColumn, sortDirection),
        [allKeys, sortedColumn, sortDirection]
    );

    //prepare bar chart data
    const barChartData: {
        graphData: {
            name: string;
            data: number[];
        }[];
        labels: string[];
    } = useMemo(
        () => prepareShiftGraphData(sortedData, allKeys, chartLabal),
        [sortedData, allKeys, chartLabal]
    );

    //handle sorting
    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setSortedColumn(sorter.columnKey);
        setSortDirection(sorter.order as "ascend" | "descend" | null);
    };

    const paginationConfig = {
        pageSize: 5,
        total: sortedData.length,
        showSizeChanger: false,
        showQuickJumper: false,
    };

    return (
        <>
            <LocationContainer>
                <TableTitle
                    style={{
                        marginBottom: "15px",
                    }}
                >
                    {revenueData.title}
                </TableTitle>
                <CashupReportBarChart data={barChartData} />
                <ShiftMetricsTableContainer>
                    <CustomDataTable
                        dataSource={sortedData}
                        columns={columns}
                        size="small"
                        scroll={{ x: true }}
                        tableLayout="fixed"
                        pagination={false}
                        onChange={handleTableChange}
                    />
                </ShiftMetricsTableContainer>
            </LocationContainer>
        </>
    );
};

export default GroupShiftReportMetrics;
