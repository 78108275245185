import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
// import reportWebVitals from "./reportWebVitals";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { QueryClient, QueryClientProvider } from "react-query";
import { CubejsProvider } from "utils/cubejs-utils";
import {
    ApolloClient,
    ApolloProvider,
    createHttpLink,
    InMemoryCache,
} from "@apollo/client";
import { hijackEffects } from "stop-runaway-react-effects";
import { setContext } from "@apollo/client/link/context";

import "./css/embla.css";
import { VenueSelectionProvider } from "./Context/VenueSelectionContext";

if (process.env.NODE_ENV === "development") {
    // See https://github.com/kentcdodds/stop-runaway-react-effects
    hijackEffects();
}

// Disable sentry in dev environments to prevent filling up sentry with low quality data. (non-optimised builds)
if (process.env.REACT_APP_ENV !== "dev")
    Sentry.init({
        dsn: "https://067d43e6498e4c17bb55ec2b3e2c8f5d@o934474.ingest.sentry.io/5901342",
        integrations: [new Integrations.BrowserTracing()],
        environment: process.env.REACT_APP_ENV,
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.1,
    });

const authLink = setContext((_, { headers }) => {
    // get the authentication token from local storage if it exists
    const token = localStorage.getItem("cubejsToken");

    // return the headers to the context so httpLink can read them
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : "",
        },
    };
});

const httpLink = createHttpLink({
    uri: process.env.REACT_APP_CUBEJS_BACKEND_API_URL?.replace("/v1", "/graphql"),
});

const apolloClient = new ApolloClient({
    link: authLink.concat(httpLink),
    cache: new InMemoryCache(),
});
const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            refetchOnMount: false,
        },
    },
}); // 3. Pass the `theme` prop to the `ChakraProvider`
ReactDOM.render(
    <QueryClientProvider client={queryClient}>
        <ApolloProvider client={apolloClient}>
            <CubejsProvider>
                <VenueSelectionProvider>
                    <App />
                </VenueSelectionProvider>
            </CubejsProvider>
        </ApolloProvider>
    </QueryClientProvider>,
    document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
