import React, { useCallback, useEffect, useMemo, useState } from "react";
import { DefaultLayout } from "Components/Layout";
import { Space } from "StyledComponents/Space";
import { dayjsFormat, QTCRange } from "utils/date-utils";
import { Button, Col, Form, message, Select } from "antd";
import styled from "styled-components";
import { DownloadOutlined } from "@ant-design/icons";
import { useMutation, useQuery } from "react-query";
import { fetchUserToken } from "utils/utilities";
import { downloadCSV } from "utils/dom-utils";
import dayjs, { Dayjs } from "dayjs";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { Venue } from "../../Types/Venue";
import { getVenues } from "../../ApiV2/Helpers/getVenues";
import { QuantacoLoader } from "../../Components/QuantacoLoader/QuantacoLoader";
import { LimitedRangePicker } from "../../Components/RangePicker/LimitedRangePicker";

export const AuditReportPage = () => {
    useEffect(() => {
        document.title = "Cashup - Summary Report";
    }, []);

    return (
        <DefaultLayout>
            <Space $fluid size="large" direction="vertical">
                <AuditReportComponent />
            </Space>
        </DefaultLayout>
    );
};

const AuditReportComponent = () => {
    const [token] = React.useState<string | null>(
        localStorage.getItem("access_token")
    );
    const { venueId, setVenueId, setVenueName, venueName } = useVenueSelection();
    const { data: venues, isLoading: venuesDataIsLoading } = useQuery(
        ["venuesData", token],
        () => getVenues()
    );

    const { mutate: fetchAuditReport, isLoading: isFetchAuditReportLoading } =
        useMutation(
            async () => {
                const ID_TOKEN = await fetchUserToken();
                if (ID_TOKEN === undefined) {
                    throw Error("Auth token not available");
                }
                return fetch(
                    `${
                        process.env.REACT_APP_BACKEND_API_URL
                    }/api/v1/cashup/history?venue_id=${venueId}&from_date=${selectedRange?.start.toISOString()}&to_date=${selectedRange?.end.toISOString()}`,
                    { headers: { Authorization: `Bearer ${ID_TOKEN}` } }
                );
            },
            {
                onSuccess: async (response) => {
                    const parsedText = await response.text();
                    if (parsedText.length > 1) {
                        downloadCSV(
                            parsedText,
                            `Audit-report-from-${selectedRange?.start.format(
                                dayjsFormat.dayMonthYearSlashHourMinute.format
                            )}-to-${selectedRange?.end.format(
                                dayjsFormat.dayMonthYearSlashHourMinute.format
                            )}-${venueName}.csv`
                        );
                    } else {
                        message.error(
                            "No Audit report for this selected Date & Venue"
                        );
                    }
                },
            }
        );
    const [selectedRange, setSelectedRange] = useState<
        { start: Dayjs; end: Dayjs } | undefined
    >({ start: dayjs().startOf("week"), end: dayjs().endOf("week") });
    const onSelectedRangeChanged = useCallback(
        (newRange?: QTCRange) =>
            newRange && newRange.start && newRange.end
                ? setSelectedRange({ start: newRange.start, end: newRange.end })
                : setSelectedRange(undefined),
        []
    );
    const selectedVenue = useMemo(
        () => (venues ? venues : []).find((v: Venue) => v.venue_id === venueId),
        [venueId, venues]
    );
    if (venuesDataIsLoading) {
        return <QuantacoLoader size={150} />;
    }
    return (
        <InputSelector>
            <Form.Item label="Report venue">
                <Select
                    style={{ width: "200px" }}
                    showSearch={true}
                    optionFilterProp="label"
                    value={venueId}
                    filterSort={(optionA, optionB) =>
                        (optionA?.label ?? "")
                            .toString()
                            .toLowerCase()
                            .localeCompare(
                                (optionB?.label ?? "").toString().toLowerCase()
                            )
                    }
                    onSelect={(e, opt) => {
                        setVenueId(e);
                        //@ts-ignore
                        setVenueName(opt.label);
                        //@ts-ignore
                    }}
                    options={venues.map((venue: Venue) => ({
                        label: venue.name,
                        value: venue.venue_id,
                    }))}
                />
            </Form.Item>
            <Form.Item label="Report date range">
                <LimitedRangePicker
                    onChange={onSelectedRangeChanged}
                    defaultRange={selectedRange}
                    onboarding_date={selectedVenue.onboarding_date}
                />
            </Form.Item>

            <Button
                type="primary"
                icon={<DownloadOutlined />}
                disabled={!selectedRange || !venueId}
                onClick={() => fetchAuditReport()}
                loading={isFetchAuditReportLoading}
                style={{ marginRight: 8 }}
            >
                Download
            </Button>
        </InputSelector>
    );
};
const InputSelector = styled(Col)`
    margin-right: auto;
`;
