import { AntDFormStateWithoutSplit } from "@types";
import { Button, Collapse, Result, Row } from "antd";
import React from "react";
import {
    ExtendedLocationItem,
    ExtendedLocationItemWithChildren,
} from "Redux/StateSlices/GroupData/LocationsAPI";
import styled from "styled-components";
import { TransferTransactionInit } from "../CashupTabs/POS/@types";
import { TransferModalFormV2 } from "./TransactionForms/TransferModalFormV2";
import { StyledFormContainer } from "./TransactionForms/Components/FormContainer";
import { ModalFooter } from "Components/Form/ModalFooter";
import { DraggableModal } from "Components/DraggableModal";
import useTransactionModal from "hooks/useTransactionModal";
import useTransactionTransfer from "hooks/useTransactionTransfer";

const { Panel } = Collapse;

interface Props {
    onModalClose: () => void;
    locations: ExtendedLocationItem[];
    onTransferTransactionSubmission: (data: AntDFormStateWithoutSplit[]) => void;
    TransferTransactions: TransferTransactionInit[];
    hierarchicalLocations: ExtendedLocationItemWithChildren[];
    currentLocationID: string;
    TransferTransactionsReadOnly: TransferTransactionInit[];
    defaultActiveKey?: string | number | (string | number)[] | undefined;
    disabled?: boolean;
}

const StyledPanelHeader = styled.div`
    font-weight: bold;
`;

const TransactionsFormTransferOnlyComponent: React.FC<Props> = ({
    onModalClose,
    currentLocationID,
    TransferTransactionsReadOnly,
    defaultActiveKey,
    disabled,
    hierarchicalLocations,
}) => {
    const { closeModal, formNamesNeedToSave, isVisible, setIsVisible } =
        useTransactionModal({ onModalClose });

    const {
        transferFieldsReadOnly,
        initialTransfersReadOnlyTransactionsDataWithAppendedFormID,
    } = useTransactionTransfer({
        currentLocationID,
        TransferTransactionsReadOnly,
        allLocations: hierarchicalLocations,
    });

    return (
        <StyledFormContainer>
            <Collapse
                accordion
                expandIconPosition="end"
                defaultActiveKey={defaultActiveKey}
            >
                <Panel
                    header={<StyledPanelHeader>Transfers</StyledPanelHeader>}
                    key="2"
                >
                    <TransferModalFormV2
                        fields={transferFieldsReadOnly}
                        initialDataForForms={
                            initialTransfersReadOnlyTransactionsDataWithAppendedFormID
                        }
                        onChange={() => null}
                        currentLocationID={currentLocationID}
                        disabled={disabled}
                    />
                </Panel>
            </Collapse>
            <ModalFooter
                onClose={closeModal}
                primaryButtonText="Close"
                hideCancelButton={true}
            />
            <DraggableModal
                open={isVisible}
                onCancel={() => setIsVisible(false)}
                onOk={() => setIsVisible(false)}
                destroyOnClose
                style={{ top: 30, borderRadius: "12px" }}
                bodyStyle={{ padding: "0px 24px 20px 24px" }}
                zIndex={100000}
                cancelButtonProps={{ style: { display: "none" } }}
                footer={null}
                noBottomBorder
                width={"80%"}
            >
                <Result
                    status="warning"
                    title={`${formNamesNeedToSave} forms not save, please click on save icon before you leave`}
                    extra={
                        <Row style={{ justifyContent: "center" }}>
                            <Button onClick={() => setIsVisible(false)}>OK</Button>
                        </Row>
                    }
                />
            </DraggableModal>
        </StyledFormContainer>
    );
};

export const TransactionsFormTransferOnly = React.memo(
    TransactionsFormTransferOnlyComponent
);
