import { ApiCall } from "../apiCall";

export async function getAccountsQuery(venueId: string) {
    return await ApiCall(
        undefined,
        "AUTHENTICATED",
        "ACCOUNTS",
        "list",
        "GET",
        {},
        `?venue_id=${venueId}`
    )
        .then((res) => res.json())
        .then((res) => res.data.accounts);
}
