import { CloseOutlined } from "@ant-design/icons";
import { Modal, ModalProps, Tooltip } from "antd";
import React, { ReactElement, ReactNode, useState } from "react";
import Draggable from "react-draggable";
import styled from "styled-components";

const StyledModal = styled(Modal)<{ noBottomBorder?: boolean }>`
    .ant-modal-header {
        ${(props) =>
            props.noBottomBorder &&
            `
            border-bottom: none;
            border-radius: 8px;   
  
        `}
    }
    .ant-modal-content {
        ${(props) =>
            props.noBottomBorder &&
            `
            border-radius: 8px;   
        `}
    }
`;

/**
 * Draggable Ant design modal boilerplate
 *
 * Same API as Ant design modal excluding the "modalRender" parameter and the "title" parameter is adjusted to have a lister for drag (click) events.
 *
 * @param props
 * @returns
 */
export const DraggableModalComponent: React.FC<
    ModalProps & { children: ReactElement; noBottomBorder?: boolean }
> = (props) => {
    // Modal Dragging related state variables
    const [bounds, setBounds] = useState({ left: 0, top: 0, bottom: 0, right: 0 });
    const [disabled, setDisabled] = useState(true);
    const draggleRef = React.createRef<any>();

    const onDragStart = (event: any, uiData: any) => {
        const currentDocumentElement = window?.document?.documentElement;
        if (!currentDocumentElement) return;
        const { clientWidth, clientHeight } = currentDocumentElement;
        const targetRect = draggleRef?.current?.getBoundingClientRect();
        setBounds({
            left: -targetRect?.left + uiData?.x,
            right: clientWidth - (targetRect?.right - uiData?.x),
            top: -targetRect?.top + uiData?.y,
            bottom: clientHeight - (targetRect?.bottom - uiData?.y),
        });
    };
    const dragableTitle = (text: ReactNode) => (
        <div
            style={{
                width: "100%",
                cursor: "move",
            }}
            onMouseOver={() => {
                if (disabled) {
                    setDisabled(false);
                }
            }}
            onMouseOut={() => {
                setDisabled(true);
            }}
        >
            {text}
        </div>
    );
    return (
        <StyledModal
            {...props}
            title={dragableTitle(props.title)}
            modalRender={(modal) => (
                <Draggable
                    disabled={disabled}
                    bounds={bounds}
                    onStart={(event, uiData) => onDragStart(event, uiData)}
                >
                    <div ref={draggleRef}>{modal}</div>
                </Draggable>
            )}
            closeIcon={
                <Tooltip
                    title="Tip: You can also press the ESC key to close this modal."
                    placement="bottom"
                    mouseEnterDelay={1}
                >
                    <CloseOutlined />
                </Tooltip>
            }
        />
    );
};

export const DraggableModal = React.memo(DraggableModalComponent);
