import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SubmissionStatus } from "./GroupData/VenuesAPI";

interface SubmitApproveState {
    submitApproveState: SubmissionStatus | "";
}
const initialState: SubmitApproveState = {
    submitApproveState: "",
};
const submitApproveSlice = createSlice({
    name: "submitApproveSlice",
    initialState,
    reducers: {
        changeState(state, payload: PayloadAction<{ newState: SubmissionStatus }>) {
            state.submitApproveState = payload.payload.newState;
        },
    },
});

export const { changeState } = submitApproveSlice.actions;
export default submitApproveSlice.reducer;
