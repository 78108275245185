/**
 *    FILTERING FUNCTIONS
 */
export const renderDollarColumn = (
    TEMP_VALUE: number,
    decimalPlaces = 2
): string | null => {
    // TODO APPLY EDGE CASE HANDLING TO ALL TABLE COLUMN HELPER FUNCTION
    if (TEMP_VALUE === null || TEMP_VALUE === undefined) {
        return null;
    }
    return TEMP_VALUE >= 0
        ? `$${TEMP_VALUE.toFixed(decimalPlaces)}`
        : `-$${Math.abs(TEMP_VALUE).toFixed(decimalPlaces)}`;
};
