import { ApiCall } from "../apiCall";

export async function getTabs(venueId: string, shiftDate: string) {
    return await ApiCall(
        undefined,
        "AUTHENTICATED",
        "TABS",
        "list",
        "GET",
        {},
        `?venue_id=${venueId}&shift_date=${shiftDate}`
    )
        .then((res) => res.json())
        .then((res) => res.data.tabs);
}
