import { KeyValuePair } from "@types";
import { Col, Divider, Form, Input, Radio, Row } from "antd";
import React, { useMemo } from "react";
import styled from "styled-components";
import { level3Spacing } from "utils/style-utils";
import { DropdownSelector } from "../../../Components/DropdownSelector/DropdownSelector";
import { Field } from "./TransactionForms/SalesCountModalFormV2";
import FocusLock, { AutoFocusInside } from "react-focus-lock";
import { FormInstance, Rule } from "antd/lib/form";
import { MoneyFormField } from "./Forms/Components/MoneyFormFields";
import { ModalFooter } from "Components/Form/ModalFooter";
import { DenominationFormField } from "./Forms/Components/DenominationFormField";

export const StyledTitleRow = styled(Row)`
    & > div {
        font-size: 12px;
        font-weight: bold;
        color: #626e84;
    }
`;

export const StyledInput = styled(Input)`
    border-radius: 4px;
    background: #f9fafb;
    &:focus {
        border-color: white;
        background: white;
        border: 1px solid #1a81c7;
        box-shadow: 0 0 1px 2px #dbe7fa;
        border-radius: 4px;
    }
`;

export const StyledRadioGroups = styled(Radio.Group)`
    width: 100%;
    .ant-radio-button-wrapper {
        box-sizing: border-box;
        border-radius: 4px 0px 0px 4px;
        border: 1px solid #1a81c7 !important;
        background: #fffff;
        width: 50%;
        color: #1a81c7;
        font-size: 12px;
        padding: 0px;
        text-align: center;

        &:nth-child(2) {
            border-radius: 0px 4px 4px 0px;
        }
    }

    .ant-radio-button-wrapper-checked {
        background: #dbe7fa !important;
    }
`;

const StyledRow = styled(Row)`
    .ant-form-item {
        margin-bottom: 4px;
    }
`;

export enum MoneyAndDenomination {
    IntegerOnly = "IntegerOnly",
    DevisibleBy100Dollar = "DevisibleBy100Dollar",
    DevisibleBy50Dollar = "DevisibleBy50Dollar",
    DevisibleBy20Dollar = "DevisibleBy20Dollar",
    DevisibleBy10Dollar = "DevisibleBy10Dollar",
    DevisibleBy5Dollar = "DevisibleBy5Dollar",
    DevisibleBy2Dollar = "DevisibleBy2Dollar",
    DevisibleBy50Cent = "DevisibleBy50Cent",
    DevisibleBy20Cent = "DevisibleBy20Cent",
    DevisibleBy10Cent = "DevisibleBy10Cent",
    DevisibleBy5Cent = "DevisibleBy5Cent",
}

export interface DenominationsValidate {
    IntegerOnly: number;
    DevisibleBy100Dollar: number;
    DevisibleBy50Dollar: number;
    DevisibleBy20Dollar: number;
    DevisibleBy10Dollar: number;
    DevisibleBy5Dollar: number;
    DevisibleBy2Dollar: number;
    DevisibleBy50Cent: number;
    DevisibleBy20Cent: number;
    DevisibleBy10Cent: number;
    DevisibleBy5Cent: number;
}

const renderModalFormField = (
    { type, onChange, ...rest }: Field,
    disabled?: boolean
) => {
    switch (type.name) {
        case "Money":
            return (
                <MoneyFormField {...rest} onChange={onChange} disabled={disabled} />
            );

        case MoneyAndDenomination.IntegerOnly:
        case MoneyAndDenomination.DevisibleBy100Dollar:
        case MoneyAndDenomination.DevisibleBy50Dollar:
        case MoneyAndDenomination.DevisibleBy20Dollar:
        case MoneyAndDenomination.DevisibleBy10Dollar:
        case MoneyAndDenomination.DevisibleBy5Dollar:
        case MoneyAndDenomination.DevisibleBy2Dollar:
        case MoneyAndDenomination.DevisibleBy50Cent:
        case MoneyAndDenomination.DevisibleBy20Cent:
        case MoneyAndDenomination.DevisibleBy10Cent:
        case MoneyAndDenomination.DevisibleBy5Cent:
            return (
                <DenominationFormField
                    {...rest}
                    onChange={onChange}
                    disabled={disabled}
                />
            );
        case "Text":
            return <StyledInput {...rest} disabled={disabled} />;
        case "Dropdown":
            return (
                <DropdownSelector
                    options={type.options}
                    {...rest}
                    disabled={disabled}
                />
            );
        case "Radio":
            return (
                <StyledRadioGroups
                    options={type.options}
                    optionType="button"
                    {...rest}
                    disabled={disabled}
                />
            );
        default:
            throw new Error("This should be impossible");
    }
};

const validateHelper = (modulus: number) => (value: number) => {
    if (Number.isNaN(modulus) || Number.isNaN(value) || modulus < 0) {
        return false;
    }
    const valueProcessed = parseFloat(value.toFixed(2));
    const scaledModulus = modulus < 1 ? 100 * modulus : modulus;
    const scaledValue = modulus < 1 ? Math.round(100 * valueProcessed) : value;
    return scaledValue % scaledModulus === 0;
};

const DenominationsValidator = {
    IntegerOnly: validateHelper(1),
    DevisibleBy100Dollar: validateHelper(100),
    DevisibleBy50Dollar: validateHelper(50),
    DevisibleBy20Dollar: validateHelper(20),
    DevisibleBy10Dollar: validateHelper(10),
    DevisibleBy5Dollar: validateHelper(5),
    DevisibleBy2Dollar: validateHelper(2),
    DevisibleBy50Cent: validateHelper(0.5),
    DevisibleBy20Cent: validateHelper(0.2),
    DevisibleBy10Cent: validateHelper(0.1),
    DevisibleBy5Cent: validateHelper(0.05),
};

const validateDenominationValue = (field: Field, value: string) => {
    const denomination = field.type.name as keyof typeof MoneyAndDenomination;
    if (Number(value) === 0) return true;

    const validator = DenominationsValidator[denomination];

    if (!validator) {
        return false;
    }
    return validator(Number(value));
};

const positiveNumberOnlyValidator = (field: Field, otherRules: Rule[]) => {
    if (field.type.name === "Money" && field.type.positiveValueOnly) {
        return [
            ...otherRules,
            {
                validator: (_: any, value: any) => {
                    if (Number(value) >= 0) {
                        return Promise.resolve();
                    }
                    return Promise.reject(
                        new Error("Please enter a positive number")
                    );
                },
            },
        ] as Rule[];
    } else if (
        Object.keys(MoneyAndDenomination).includes(field.type.name as string)
    ) {
        const positiveValueCheck = (value: any) =>
            //@ts-ignore Based on the above condition we can safely assume that the field type is MoneyAndDenomination (aka keyof DenominationsValidate;)
            field.type?.positiveValueOnly === true ? Number(value) >= 0 : true;

        return [
            ...otherRules,
            {
                validator: (_: any, value: any) => {
                    if (
                        value === undefined ||
                        value === null ||
                        isNaN(Number(value)) ||
                        !positiveValueCheck(value)
                    ) {
                        return Promise.reject(
                            new Error("Please enter a valid number")
                        );
                    }

                    if (validateDenominationValue(field, value)) {
                        return Promise.resolve();
                    }
                    return Promise.reject(new Error("Invalid denomination"));
                },
            },
        ];
    } else return otherRules;
};

interface Props {
    fields: Field[];
    formName?: string;
    onSubmission: (data: KeyValuePair) => void;
    onModalClose?: () => void;
    onClear?: (form: FormInstance<any>) => void;
    disabled?: boolean;
}

export const ModalFormV2: React.FC<Props> = ({
    fields,
    formName = "form",
    onSubmission,
    onModalClose,
    onClear,
    disabled,
}) => {
    const [form] = Form.useForm();

    const emptyRow = useMemo(
        () =>
            fields.reduce<any>((result, field) => {
                result[field.name] = field.defaultValue;
                return result;
            }, {}),
        [fields]
    );

    const onClearHelper = () => {
        onClear?.(form);
    };

    const submitWrapper = (data: any) => {
        onSubmission(data);
        onModalClose?.();
    };

    return (
        <>
            <StyledRow>
                <Form
                    form={form}
                    onFinish={(data) => {
                        submitWrapper(data);
                    }}
                    onFinishFailed={(error) => {
                        console.log(error);
                    }}
                    layout="vertical"
                    name={formName}
                    initialValues={emptyRow}
                    disabled={disabled}
                >
                    <FocusLock>
                        <StyledRow gutter={8}>
                            {fields.map((field, index) =>
                                index === 0 ? (
                                    <Col span={field.colSpan} key={field.key}>
                                        <AutoFocusInside>
                                            <Form.Item
                                                label={field.name}
                                                name={field.name}
                                                rules={positiveNumberOnlyValidator(
                                                    field,
                                                    [
                                                        {
                                                            required: field.required,
                                                            message:
                                                                "Please enter a value",
                                                        },
                                                    ]
                                                )}
                                            >
                                                {renderModalFormField(
                                                    field,
                                                    disabled
                                                )}
                                            </Form.Item>
                                        </AutoFocusInside>
                                    </Col>
                                ) : (
                                    <Col span={field.colSpan} key={field.key}>
                                        <Form.Item
                                            label={field.name}
                                            name={field.name}
                                            rules={positiveNumberOnlyValidator(
                                                field,
                                                [
                                                    {
                                                        required: field.required,
                                                        message:
                                                            "Please enter a value",
                                                    },
                                                ]
                                            )}
                                        >
                                            {renderModalFormField(field, disabled)}
                                        </Form.Item>
                                    </Col>
                                )
                            )}
                        </StyledRow>
                    </FocusLock>
                    <Divider style={{ margin: 0, marginTop: level3Spacing }} />
                    <ModalFooter
                        onClose={onModalClose}
                        onClear={onClear ? onClearHelper : undefined}
                    />
                </Form>
            </StyledRow>
        </>
    );
};
