import styled from "styled-components";

export const CardComponent = styled.div`
    background-color: #fff;
    box-shadow: -10px -10px 30px 4px rgb(0 0 0 / 10%),
        10px 10px 30px 4px rgb(45 78 255 / 15%);
    color: #4a4a4a;
    max-width: 100%;
    position: relative;
    border-radius: 6px;
    border: 1px solid rgba(24, 28, 33, 0.06);
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 30px;
    width: 40%;
    min-width: 350px;
`;

export const CenteredSingleDiv = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
`;

export const CenteredSingleDivNoHeight = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const LogoComponent = styled.div`
    text-align: center;
`;
