/**
 * Note: This doesn't playwell with Antd forms. Suggestion: Opt for the "Select" component rather than a Dropdown.
 * This component is temporary depreciated but is open to be revisited at a later point.
 *
 */

import { DownOutlined } from "@ant-design/icons";
import { Button, Dropdown, Menu } from "antd";
import React from "react";
import styled from "styled-components";
import { ValueLabelOption } from "types";

interface Props {
    options: ValueLabelOption[];
    defaultSelectedOption?: ValueLabelOption;
    onChange?: (selectedValue: any) => void;
    trigger?: ("click" | "hover" | "contextMenu")[];
    disabled?: boolean;
}

const StyledButtonDropdown = styled(Button)`
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    padding: 0 8px;
    color: #353940;
    background: #f9fafb;
    border: 1px solid #dadfe7;
    border-radius: 4px;

    &:not(:first-child) {
        margin-top: 8px;
    }
`;

export const DropdownSelector: React.FC<Props> = ({
    options,
    trigger = ["click"],
    defaultSelectedOption,
    onChange,
    disabled = false,
}) => {
    const [selectedItem, setSelectedItem] = React.useState<
        ValueLabelOption | undefined
    >(defaultSelectedOption);

    const menu = React.useMemo(() => {
        return (
            <Menu
                onClick={({ key }) => {
                    const option = options.find(({ value }) => value === key);
                    setSelectedItem(option!);
                    onChange?.(key);
                }}
                style={{ maxHeight: 200, overflow: "auto" }}
            >
                {options.map(({ label, value }) => (
                    <Menu.Item key={value}>{label}</Menu.Item>
                ))}
            </Menu>
        );
    }, [onChange, options]);

    return (
        <Dropdown disabled={disabled} trigger={trigger} overlay={menu}>
            <StyledButtonDropdown>
                {selectedItem?.label} <DownOutlined />
            </StyledButtonDropdown>
        </Dropdown>
    );
};
