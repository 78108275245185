import React, { useState, useMemo } from "react";
import dayjs from "dayjs";
import { ShiftReportPromptsAnswerItem } from "@interfaces/index";
import { shiftPromptsColumnGenerator } from "../../utils";
import {
    PromptTitle,
    TotalTableContainer,
    CustomDataTable,
    PromptAnswer,
} from "../CashupReportStyled";

interface ShiftPromptsItemProps {
    promptsItemData: ShiftReportPromptsAnswerItem;
}

const ShiftPromptsItem: React.FC<ShiftPromptsItemProps> = ({ promptsItemData }) => {
    const [sortedColumn, setSortedColumn] = useState<string>("");
    const [sortDirection, setSortDirection] = useState<"ascend" | "descend" | null>(
        null
    );

    //sort data by date
    const sortedData = useMemo(() => {
        return promptsItemData.data.sort((a, b) => {
            const dateA = dayjs(a.date, "DD/MM/YYYY");
            const dateB = dayjs(b.date, "DD/MM/YYYY");
            return dateA.diff(dateB);
        });
    }, [promptsItemData]);

    //column generator
    const columns = useMemo(
        () => shiftPromptsColumnGenerator(sortedColumn, sortDirection),
        [sortedColumn, sortDirection]
    );

    //handle sorting
    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setSortedColumn(sorter.columnKey);
        setSortDirection(sorter.order as "ascend" | "descend" | null);
    };

    const paginationConfig = {
        pageSize: 10,
        total: sortedData.length,
        showSizeChanger: false,
        showQuickJumper: false,
    };

    return (
        <>
            {promptsItemData.data.length > 0 ? (
                <>
                    <PromptTitle
                        style={{
                            marginBottom: "15px",
                        }}
                    >
                        {promptsItemData.prompt}
                    </PromptTitle>
                    <TotalTableContainer>
                        <CustomDataTable
                            dataSource={sortedData}
                            columns={columns}
                            size="small"
                            scroll={{ x: true }}
                            tableLayout="fixed"
                            pagination={paginationConfig}
                            onChange={handleTableChange}
                        />
                    </TotalTableContainer>
                </>
            ) : (
                <>
                    <PromptTitle
                        style={{
                            marginBottom: "15px",
                        }}
                    >
                        {promptsItemData.prompt}
                    </PromptTitle>
                    <PromptAnswer
                        style={{
                            marginBottom: "15px",
                        }}
                    >
                        {"No user reponse entered"}
                    </PromptAnswer>
                </>
            )}
        </>
    );
};

export default ShiftPromptsItem;
