import {
    AntDFormState,
    AntDFormStateEftposCountOnly,
    AntDFormStateWithoutSplit,
    AntDFormStateWithSplitFlag,
} from "@types";
import { Button, Collapse, Result, Row, Typography } from "antd";
import { ModalFooter } from "Components/Form/ModalFooter";
import React from "react";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import { ExtendedClassItem } from "Redux/StateSlices/GroupData/ClassesAPI";
import {
    ExtendedLocationItem,
    ExtendedLocationItemWithChildren,
} from "Redux/StateSlices/GroupData/LocationsAPI";
import { GlAccountByType } from "Redux/StateSlices/GroupData/VenuesAPI";
import styled from "styled-components";
import { level2Spacing, level3Spacing } from "utils/style-utils";
import {
    DepositInTransactionInit,
    DepositOutTransactionInit,
    EftposCountTransactionInit,
    PaymentTransactionInit,
    TransferTransactionInit,
} from "../CashupTabs/POS/@types";
import { DepositInModalFormV2 } from "./TransactionForms/DepositInModalFormV2";
import { DepositOutModalFormV2 } from "./TransactionForms/DepositOutModalFormV2";
import { PaymentModalFormV2 } from "./TransactionForms/PaymentModalFormV2";
import { TransferModalFormV2 } from "./TransactionForms/TransferModalFormV2";
import "./TransactionsFormPOS.css";
import { EntityData } from "@generated/models/EntityData";
import { StyledFormContainer } from "./TransactionForms/Components/FormContainer";
import { DraggableModal } from "Components/DraggableModal";
import { useQuery } from "react-query";
import { getVenueGlAccountsById } from "../../../ApiV2/Helpers/getVenueGlAccountsById";
import { useVenueSelection } from "../../../Context/VenueSelectionContextConstants";
import useTransactionModal from "hooks/useTransactionModal";
import useTransactionDeposit, {
    depositInFields,
    depositOutFields,
} from "hooks/useTransactionDepost";
import useTransactionTransfer from "hooks/useTransactionTransfer";
import useTransactionPayment from "hooks/useTransactionPayment";

const { Panel } = Collapse;

interface Props {
    row: number;
    col?: number; // TODO Remove (depreciated)
    onModalClose?: () => void;
    accountsData: ExtendedAccountItem[];
    classesData: ExtendedClassItem[];
    onPaymentTransactionSubmission: (data: AntDFormState[]) => void;
    onPaymentTransactionRemove: (data: AntDFormState[]) => void;
    onDepositInTransactionSubmission: (data: AntDFormStateWithSplitFlag[]) => void;
    onDepositOutTransactionSubmission: (data: AntDFormStateWithSplitFlag[]) => void;
    onEftposCountTransactionSubmission: (
        data: AntDFormStateEftposCountOnly[]
    ) => void;
    onTransferTransactionSubmission: (data: AntDFormStateWithoutSplit[]) => void;
    PaymentTransactionData: PaymentTransactionInit[];
    TransferTransactionsReadOnly: TransferTransactionInit[];

    DepositInTransactionData: DepositInTransactionInit[];
    DepositOutTransactionData: DepositOutTransactionInit[];
    EftposBalance: number | undefined;
    EftposCountTransactions: EftposCountTransactionInit[];
    locations: ExtendedLocationItem[];
    TransferTransactions: TransferTransactionInit[];
    hierarchicalLocations: ExtendedLocationItemWithChildren[];
    currentLocationID: string;
    eftposErrorHighlighter?: boolean;
    disabled: boolean;
}

const StyledPanelHeader = styled.div`
    font-weight: bold;
`;

export const parseStringToCapitalStart = (
    stringToProcess: string,
    stringToBeReplaced: string,
    stringToReplace: string
) => {
    if (!stringToProcess.trim()) throw new Error("string can't be empty");
    return stringToProcess
        .toLowerCase()
        .split(stringToBeReplaced)
        .map(
            (word) => word.charAt(0).toUpperCase() + word.substring(1).toLowerCase()
        )
        .join(stringToReplace);
};

export const entityDataParser = (entityData: EntityData | undefined) => {
    if (entityData === undefined) return [];
    const result = Object.keys(entityData).map((key) => ({
        name: key,
        value: entityData[key as keyof EntityData],
    }));
    return result;
};

export const initialPaymentSplitData = (tenderAccounts: ExtendedAccountItem[]) => {
    const CashTenderAccount = tenderAccounts.find(
        (currentTenderAccount) => currentTenderAccount.name.toLowerCase() === "cash"
    );
    const ChequeTenderAccount = tenderAccounts.find(
        (currentTenderAccount) =>
            currentTenderAccount.name.toLowerCase() === "cheque"
    );
    if (CashTenderAccount && ChequeTenderAccount) {
        return [
            { name: CashTenderAccount?.name, value: 0 },
            { name: ChequeTenderAccount?.name, value: 0 },
        ];
    } else if (CashTenderAccount) {
        return [{ name: CashTenderAccount?.name, value: 0 }];
    } else if (ChequeTenderAccount) {
        return [{ name: ChequeTenderAccount?.name, value: 0 }];
    } else {
        return [];
    }
};

const TransactionsFormPOSComponent: React.FC<Props> = ({
    onModalClose,
    accountsData,
    classesData,
    onPaymentTransactionSubmission,
    onPaymentTransactionRemove,
    onDepositInTransactionSubmission,
    onDepositOutTransactionSubmission,
    PaymentTransactionData,
    DepositInTransactionData,
    DepositOutTransactionData,
    TransferTransactionsReadOnly,
    currentLocationID,
    disabled,
}) => {
    const {
        isVisible,
        closeModal,
        setIsVisible,
        setFormNeedToSave,
        formNamesNeedToSave,
    } = useTransactionModal({ onModalClose });

    const {
        initialTransfersReadOnlyTransactionsDataWithAppendedFormID,
        transferFieldsReadOnly,
    } = useTransactionTransfer({
        currentLocationID,
        TransferTransactionsReadOnly,
    });

    const {
        initialDepositInTransactionsDataWithAppendedFormID,
        initialDepositOutTransactionsDataWithAppendedFormID,
    } = useTransactionDeposit({
        DepositInTransactionData,
        DepositOutTransactionData,
    });

    const {
        paymentFields,
        tenderAccounts,
        initialPaymentTransactionsDataWithAppendedFormID,
    } = useTransactionPayment({
        classesData,
        accountsData,
        PaymentTransactionData,
    });

    const { venueId } = useVenueSelection();

    const { data: glAccounts } = useQuery(["glAccounts", venueId], () =>
        getVenueGlAccountsById(venueId)
    );

    // @ts-ignore
    const glAccountGroupsByType = (glAccounts ? glAccounts : []).reduce<
        GlAccountByType[]
        // @ts-ignore
    >((acc, currentGlAccount, index) => {
        const matchGroup = acc.find(
            // @ts-ignore
            (acc) => acc.type === currentGlAccount.gl_account.type
        );
        if (index === 0 || !matchGroup) {
            acc.push({
                type: currentGlAccount.gl_account.type,
                glAccounts: [currentGlAccount.gl_account],
            });
        } else {
            matchGroup.glAccounts.push(currentGlAccount.gl_account);
        }
        return acc;
    }, []);

    return (
        <StyledFormContainer>
            <Collapse accordion expandIconPosition="end">
                <Panel
                    header={<StyledPanelHeader>Payments</StyledPanelHeader>}
                    key="1"
                >
                    <Typography
                        style={{ color: "#626E84", marginBottom: level2Spacing }}
                    >
                        Payments will include petty cash and other expenses
                    </Typography>

                    <PaymentModalFormV2
                        fields={paymentFields}
                        onModalClose={() => null}
                        initialDataForForms={
                            initialPaymentTransactionsDataWithAppendedFormID
                        }
                        tenderAccounts={tenderAccounts}
                        onSubmit={onPaymentTransactionSubmission}
                        onRemove={onPaymentTransactionRemove}
                        glAccountsByType={glAccountGroupsByType!}
                        disabled={disabled}
                        setFormNeedToSave={setFormNeedToSave}
                    />
                </Panel>
                <Panel
                    header={<StyledPanelHeader>Deposits</StyledPanelHeader>}
                    key="2"
                >
                    <Typography
                        style={{ color: "#626E84", marginBottom: level2Spacing }}
                    >
                        {" "}
                        Record deposits not entered in POS here
                    </Typography>
                    <Typography
                        style={{
                            color: "#626E84",
                            marginBottom: level2Spacing,
                            fontSize: 11,
                            fontWeight: "bold",
                        }}
                    >
                        DEPOSITS IN
                    </Typography>
                    <DepositInModalFormV2
                        fields={depositInFields}
                        initialDataForForms={
                            initialDepositInTransactionsDataWithAppendedFormID
                        }
                        tenderAccounts={tenderAccounts}
                        onChange={onDepositInTransactionSubmission}
                        disabled={disabled}
                        setFormNeedToSave={setFormNeedToSave}
                    />
                    <Typography
                        style={{
                            color: "#626E84",
                            marginBottom: level2Spacing,
                            marginTop: level3Spacing,
                            fontSize: 11,
                            fontWeight: "bold",
                        }}
                    >
                        DEPOSITS OUT
                    </Typography>
                    <DepositOutModalFormV2
                        fields={depositOutFields}
                        initialDataForForms={
                            initialDepositOutTransactionsDataWithAppendedFormID
                        }
                        tenderAccounts={tenderAccounts}
                        onChange={onDepositOutTransactionSubmission}
                        disabled={disabled}
                        setFormNeedToSave={setFormNeedToSave}
                    />
                </Panel>

                {initialTransfersReadOnlyTransactionsDataWithAppendedFormID.length >
                    0 && (
                    <Panel
                        header={<StyledPanelHeader>Transfers</StyledPanelHeader>}
                        key="5"
                    >
                        <TransferModalFormV2
                            fields={transferFieldsReadOnly}
                            initialDataForForms={
                                initialTransfersReadOnlyTransactionsDataWithAppendedFormID
                            }
                            onChange={() => null}
                            currentLocationID={currentLocationID}
                            disabled={disabled}
                        />
                    </Panel>
                )}
            </Collapse>
            <ModalFooter
                onClose={closeModal}
                primaryButtonText="Close"
                hideCancelButton={true}
            />
            <DraggableModal
                open={isVisible}
                onCancel={() => setIsVisible(false)}
                onOk={() => setIsVisible(false)}
                destroyOnClose
                style={{ top: 30, borderRadius: "12px" }}
                bodyStyle={{ padding: "0px 24px 20px 24px" }}
                zIndex={100000}
                cancelButtonProps={{ style: { display: "none" } }}
                footer={null}
                noBottomBorder
                width={"80%"}
            >
                <Result
                    status="warning"
                    title={`${formNamesNeedToSave} forms not save, please click on save icon before you leave`}
                    extra={
                        <Row style={{ justifyContent: "center" }}>
                            <Button onClick={() => setIsVisible(false)}>OK</Button>
                        </Row>
                    }
                />
            </DraggableModal>
        </StyledFormContainer>
    );
};

export const TransactionsFormPOS = React.memo(TransactionsFormPOSComponent);
