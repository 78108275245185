import { getLocationsQuery } from "ApiV2/Helpers/getLocationsQuery";
import { useVenueSelection } from "Context/VenueSelectionContextConstants";
import { LocationType } from "Pages/CashupReport/CashupReportModel";
import { useMemo } from "react";
import { useQuery } from "react-query";
import { ExtendedLocationItemWithChildren } from "Redux/StateSlices/GroupData/LocationsAPI";

const useGamingTab = () => {
    const { venueId, formattedDate } = useVenueSelection();

    const {
        data: gamingLocations = [],
        isLoading,
        isFetching,
    } = useQuery<ExtendedLocationItemWithChildren[]>(
        ["gamingCRTCCULocations", venueId, formattedDate],
        () =>
            getLocationsQuery(
                [
                    LocationType.gaming,
                    LocationType.ccu,
                    LocationType.crt,
                    LocationType.office,
                ],
                venueId,
                formattedDate
            )
    );

    const { gaming, ccu, crt } = useMemo(() => {
        const gaming = gamingLocations?.filter(
            (x) =>
                x.location_type === LocationType.gaming ||
                x.location_type === LocationType.office
        );

        const ccu = gamingLocations?.filter(
            (x) =>
                x.location_type === LocationType.ccu ||
                x.location_type === LocationType.office
        );

        const crt = gamingLocations?.filter(
            (x) =>
                x.location_type === LocationType.crt ||
                x.location_type === LocationType.office
        );

        return { gaming, ccu, crt };
    }, [gamingLocations]);

    const loading = isLoading || isFetching;

    return { gaming, ccu, crt, loading };
};

export default useGamingTab;
