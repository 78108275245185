import { getDates } from "./getDates";
import {
    PAYOUT_FIELD,
    WAGERING_COMMISSION,
    WAGERING_SALES,
    VARIANCE,
    addMissingFieldsWithZeroValue,
} from "./CashupDetailedReport";
import { LocationType } from "../CashupReportModel";

export function WageringDetailedReportTransformer(
    detailedReportData: {
        posSplitByClassTypeData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            account: { __typename: string; name: string };
                            class: {
                                __typename: string;
                                classType: number;
                                name: string;
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        cashVarianceForAllPosLocationsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        pettyCashData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                totalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            transactions: { __typename: string; memo: string };
                        }[]
                      | undefined;
              }
            | undefined;
        depositsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                totalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            transactions: { __typename: string; memo: string };
                            account: { __typename: string; name: string };
                        }[]
                      | undefined;
              }
            | undefined;
        transfersData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          splitDate: { __typename: string; day: string };
                          totalIncTax: number;
                      };
                      transactions: { __typename: string; memo: string; id: string };
                  }[];
              }
            | undefined;
        gamingStatsData:
            | {
                  cube: {
                      __typename: string;
                      gamingStats: {
                          __typename: string;
                          date: { __typename: string; value: string; day: string };
                          turnover: number;
                          totalWins: number;
                          netProfit: number;
                          returnToHouse: number;
                      };
                  }[];
              }
            | undefined;
        gamingVarianceData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                  }[];
              }
            | undefined;
        gamingPayoutsData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                  }[];
              }
            | undefined;
        cashTotalsForAllPosLocationsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        cardTotalsForPosData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        safeData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          safeDataTotal: number;
                          safeDataVariance: number;
                          safeSignedOff: string;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        crtData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountCashVariance: number;
                          atmDataRefills: number | null;
                          atmDataWithdrawls: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        atmData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountCashVariance: number;
                          atmDataRefills: number;
                          atmDataWithdrawls: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        wageringData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          wageringDataSalesTotal: number;
                          wageringDataCommission: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        wageringPayoutsData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillTabData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillKenoData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        posParentLocationsData: {
            [parentLocationId: string]: {
                sales: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate?: { day: string };
                        };
                        account: { name: string };
                        class: { classType: number; name: string };
                    }[];
                };
                card: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
                cashVariance: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
                cash: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
            };
        };
    },
    selectedRange: { start: string; end: string } | undefined
) {
    if (!detailedReportData || !selectedRange) {
        return {};
    }
    const DataMap: any = {};
    const knownWageringColumns: string[] = [];

    detailedReportData.wageringPayoutsData?.cube.forEach((wageringPayoutsItem) => {
        const { splits, location } = wageringPayoutsItem;
        const { sumTotalIncTax, splitDate } = splits;
        const { day } = splitDate;
        const locationName = location.name.toLowerCase();
        if (knownWageringColumns.indexOf(PAYOUT_FIELD) === -1) {
            knownWageringColumns.push(PAYOUT_FIELD);
        }

        if (!DataMap[locationName]) {
            DataMap[locationName] = {};
        }

        if (!DataMap[locationName][day]) {
            DataMap[locationName][day] = {};
            DataMap[locationName][day][PAYOUT_FIELD] = -sumTotalIncTax;
        } else {
            if (!DataMap[locationName][day][PAYOUT_FIELD]) {
                DataMap[locationName][day][PAYOUT_FIELD] = -sumTotalIncTax;
            } else {
                DataMap[locationName][day][PAYOUT_FIELD] += -sumTotalIncTax;
            }
        }
    });

    detailedReportData.wageringData?.cube.forEach((wageringItem) => {
        const { cashup, location } = wageringItem;

        const {
            wageringDataCommission,
            wageringDataSalesTotal,
            cashCountCashVariance,
            shiftDate,
        } = cashup;

        if (!location) {
            return;
        }
        const { day } = shiftDate;
        const locationName = location.name.toLowerCase();
        if (knownWageringColumns.indexOf(WAGERING_COMMISSION) === -1) {
            knownWageringColumns.push(WAGERING_COMMISSION);
        }

        if (knownWageringColumns.indexOf(WAGERING_SALES) === -1) {
            knownWageringColumns.push(WAGERING_SALES);
        }

        if (knownWageringColumns.indexOf(VARIANCE) === -1) {
            knownWageringColumns.push(VARIANCE);
        }

        if (!DataMap[locationName]) {
            DataMap[locationName] = {};
        }

        if (!DataMap[locationName][day]) {
            DataMap[locationName][day] = {};
            DataMap[locationName][day][WAGERING_COMMISSION] = wageringDataCommission;
            DataMap[locationName][day][WAGERING_SALES] = wageringDataSalesTotal;
            DataMap[locationName][day][VARIANCE] = cashCountCashVariance;
        } else {
            if (!DataMap[locationName][day][WAGERING_COMMISSION]) {
                DataMap[locationName][day][WAGERING_COMMISSION] =
                    wageringDataCommission;
            } else {
                DataMap[locationName][day][WAGERING_COMMISSION] +=
                    wageringDataCommission;
            }

            if (!DataMap[locationName][day][WAGERING_SALES]) {
                DataMap[locationName][day][WAGERING_SALES] = wageringDataSalesTotal;
            } else {
                DataMap[locationName][day][WAGERING_SALES] += wageringDataSalesTotal;
            }

            if (!DataMap[locationName][day][VARIANCE]) {
                DataMap[locationName][day][VARIANCE] = cashCountCashVariance;
            } else {
                DataMap[locationName][day][VARIANCE] += cashCountCashVariance;
            }
        }
    });

    const KenoDates =
        Object.keys(DataMap["keno"] ?? []).sort(function (a, b) {
            return Date.parse(a) - Date.parse(b);
        }) ?? [];

    const TabDates =
        Object.keys(DataMap["tab"] ?? []).sort(function (a, b) {
            return Date.parse(a) - Date.parse(b);
        }) ?? [];

    const filledInDates = getDates(selectedRange.start, selectedRange.end);

    const filledInKenoDataMap = filledInDates.reduce((acc, date) => {
        const tempDateObject = date.toDate();
        tempDateObject.setUTCHours(0, 0, 0, 0);
        const dateString = tempDateObject.toISOString();
        if (KenoDates.includes(dateString)) {
            acc[dateString] = addMissingFieldsWithZeroValue(
                knownWageringColumns,
                DataMap["keno"][dateString]
            );
        } else {
            acc[dateString] = addMissingFieldsWithZeroValue(
                knownWageringColumns,
                {}
            );
        }
        return acc;
    }, {} as any);

    const filledInTabDataMap = filledInDates.reduce((acc, date) => {
        const tempDateObject = date.toDate();
        tempDateObject.setUTCHours(0, 0, 0, 0);
        const dateString = tempDateObject.toISOString();
        if (TabDates.includes(dateString)) {
            acc[dateString] = addMissingFieldsWithZeroValue(
                knownWageringColumns,
                DataMap["tab"][dateString]
            );
        } else {
            acc[dateString] = addMissingFieldsWithZeroValue(
                knownWageringColumns,
                {}
            );
        }
        return acc;
    }, {} as any);
    const kenoTableData = Object.keys(filledInKenoDataMap).map((date) => {
        return {
            key: date,
            ...filledInKenoDataMap[date],
        };
    });

    const tabTableData = Object.keys(filledInTabDataMap).map((date) => {
        return {
            key: date,
            ...filledInTabDataMap[date],
        };
    });
    return {
        filledInKenoDataMap,
        knownWageringColumns,
        filledInTabDataMap,
        kenoTableData,
        tabTableData,
    };
}
