import dayjs from "dayjs";

export const getDates = (startDate: string, endDate: string) => {
    // We ensure that the start date is before the end date
    if (dayjs(startDate).isAfter(dayjs(endDate))) {
        throw new Error("Start date is after end date");
    }
    const dates = [];
    let currentDate = dayjs(startDate).add(1, "days");
    // We add one second to the end date to ensure that the last date is included
    const finishDate = dayjs(endDate).add(1, "day");
    const addDays = (tempDate: dayjs.Dayjs, days: number) => {
        const date = dayjs(tempDate);
        return date.add(days, "days");
    };
    while (!currentDate.isAfter(finishDate)) {
        dates.push(currentDate);
        currentDate = addDays(currentDate, 1);
    }

    return dates;
};
