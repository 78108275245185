import React from "react";
import { Spin, Statistic } from "antd";
import { StatisticSection } from "../StyledComponents";
import { LoadingOutlined } from "@ant-design/icons";

interface TopBannerProps {
    total_sales: number;
    cash_increase: number;
    bankable: number;
    isLoading: boolean;
}

const TopBannerStats: React.FC<TopBannerProps> = ({
    total_sales,
    bankable,
    cash_increase,
    isLoading,
}) => {
    return (
        <StatisticSection>
            {!isLoading ? (
                <>
                    <Statistic
                        title="Total sales"
                        value={total_sales}
                        valueStyle={{ fontSize: "14px" }}
                        style={{ padding: "0px 8px" }}
                        prefix={"$"}
                    />

                    <Statistic
                        title="Cash increase"
                        value={cash_increase}
                        valueStyle={{ fontSize: "14px" }}
                        style={{ padding: "0px 8px" }}
                        prefix={"$"}
                    />
                    <Statistic
                        title="Bankable"
                        value={bankable}
                        valueStyle={{ fontSize: "14px" }}
                        style={{ padding: "0px 8px" }}
                        prefix={"$"}
                    />
                </>
            ) : (
                <Spin
                    style={{
                        textAlign: "center",
                        width: "100%",
                    }}
                    indicator={<LoadingOutlined spin />}
                />
            )}
        </StatisticSection>
    );
};
export default TopBannerStats;
