import { KeyValuePair } from "@types";
import { CentredSpinner } from "Components/Misc/Loading/CentredSpinner";
import { useAppSelector } from "Redux/TypedReduxFunctions";
import { Card, Row, Table } from "antd";
import { debounce } from "lodash";
import React, { useMemo } from "react";
import styled from "styled-components";
import { CashupReportTableHOC } from "../utils/CashupReportTableHOC";
import "./CashupReportTable.css";
import { getWindowDimensions } from "./getWindowDimensions";
import { useWindowDimensions } from "./useWindowDimensions";
import { useLocalStorage } from "@hooks/useLocalStorage";
import { ColumnsType } from "antd/lib/table";
import { StyledH2 } from "./CashupDetailedReport";
import { useGetBankableDataQuery } from "Redux/StateSlices/BankableDataAPI";
import dayjs from "dayjs";
import { useGetBankableDetailQuery } from "Redux/StateSlices/BankableDetailAPI";

export const addMissingFieldsWithZeroValue = (
    fields: string[],
    currentObject: KeyValuePair
) => {
    const result = { ...currentObject };
    fields.forEach((field) => {
        if (!result[field]) {
            result[field] = 0;
        }
    });
    return result;
};

const StyledMainContainer = styled.div`
    @media (max-width: 768px) {
        max-width: 100vw;
    }
`;

interface DetailTableProps {
    sectionName: string;
    dataSource: {
        [name: string]: any;
    }[];
    tableColumn: ColumnsType<any>;
    minimumWidth: number | string;
    isMobile: boolean;
}

const SectionTable: React.FC<DetailTableProps> = (props) => {
    const { sectionName, dataSource, tableColumn, minimumWidth, isMobile } = props;
    return (
        <Card>
            <StyledH2 bottomPadding>{sectionName}</StyledH2>
            <Row>
                <Table
                    dataSource={dataSource}
                    columns={tableColumn}
                    pagination={false}
                    scroll={{ x: minimumWidth }}
                    size={isMobile ? "small" : "middle"}
                />
            </Row>
        </Card>
    );
};

const CashupReportTableComponent: React.FC = () => {
    const { selectedDate, selectedVenueId } = useAppSelector(
        (state) => state.shiftReportSlice
    );
    const [selectedDateinLocal] = useLocalStorage<string | null>(
        "selectedDate",
        null
    );
    const [selectedVenue, _] = useLocalStorage<string | null>("selectedVenue", null);
    const selectedVenueToUse = useMemo(
        () => (selectedVenueId ? selectedVenueId : selectedVenue),
        [selectedVenueId, selectedVenue]
    );

    const parsedDate = useMemo(
        () =>
            selectedDate
                ? selectedDate
                : selectedDateinLocal
                ? selectedDateinLocal
                : undefined,
        [selectedDate, selectedDateinLocal]
    );

    const { data: bankableData, isLoading: bankableDataLoading } =
        useGetBankableDataQuery(
            {
                venue_id: selectedVenueToUse!,
                from_date: dayjs(parsedDate!, "DD-MM-YYYY")
                    .subtract(1, "day")
                    .format("DD-MM-YYYY")
                    .toString(),
                to_date: parsedDate!,
            },
            { refetchOnMountOrArgChange: true }
        );

    const { data: bankableDetail, isLoading: bankableDetailLoading } =
        useGetBankableDetailQuery(
            {
                venue_id: selectedVenueToUse!,
                from_date: parsedDate!,
                to_date: parsedDate!,
            },
            { refetchOnMountOrArgChange: true }
        );

    const tableColumn: ColumnsType<any> = [
        {
            title: "Till",
            dataIndex: "locationName",
            key: "locationName",
            fixed: "left",
            width: 200,
        },
        {
            title: "Cash In",
            dataIndex: "cashIn",
            key: "cashIn",
            render: (value: number) => {
                return value ? `$${value.toFixed(2)}` : "-";
            },
        },
        {
            title: "Cash Out",
            dataIndex: "cashOut",
            key: "cashOut",
            render: (value: number) => {
                return value ? `$${value.toFixed(2)}` : "-";
            },
        },
    ];

    const bankableTableData = useMemo(() => {
        const bankable: Array<{ [name: string]: any }> = [];
        let cashInTotal = 0,
            cashOutTotal = 0;
        const match = (sectionName: string) =>
            bankable.findIndex(
                (bankableData) => Object.keys(bankableData)[0] === sectionName
            );
        if (bankableDetail) {
            Object.entries(bankableDetail).forEach(([key, value]) => {
                const index = match(key);

                if (index === -1) {
                    bankable.push({ [key]: [] });
                }
                const matchIndex = index !== -1 ? index : bankable.length - 1;
                Object.entries(value).forEach(([tillKey, tillValue]) => {
                    if (tillValue.cash_in || tillValue.cash_out) {
                        bankable[matchIndex][key].push({
                            locationName: tillKey,
                            cashIn: tillValue.cash_in ?? 0,
                            cashOut: tillValue.cash_out ?? 0,
                        });
                        cashInTotal += tillValue.cash_in ?? 0;
                        cashOutTotal += tillValue.cash_out ?? 0;
                    }
                });
            });
        }
        return { bankable, total: { cashIn: cashInTotal, cashOut: cashOutTotal } };
    }, [bankableDetail]);

    const generalTableData: Array<{
        openBalance: number;
        cashIncrease: number;
        bankable: number;
    }> = useMemo(() => {
        if (!bankableData) {
            return [];
        } else {
            return [
                {
                    openBalance: Number(bankableData[0].bankable),
                    cashIncrease: Number(bankableData[1].cash_increase),
                    bankable: Number(bankableData[1].bankable),
                },
            ];
        }
    }, [bankableData]);
    const debouncedWindowDimensions = debounce(getWindowDimensions, 100);

    const { width } = useWindowDimensions({
        fetchDimensions: debouncedWindowDimensions,
    });

    const minimumWidth = useMemo(() => {
        if (width < 792) {
            return 792;
        }
        return "100%";
    }, [width]);

    const isMobile = useMemo(() => {
        return width < 792;
    }, [width]);

    if (bankableDataLoading || bankableDetailLoading) {
        return <CentredSpinner size="large" />;
    }

    return (
        <StyledMainContainer>
            {/* <Row style={{ width: "100%", justifyContent: "flex-end" }}>
                <Button
                    type="primary"
                    icon={<DownloadOutlined />}
                    style={{ marginBottom: 4 }}
                    onClick={() => {
                        console.log("download");
                    }}
                >
                    Download
                </Button>
            </Row> */}
            <div
                style={{
                    display: "flex",
                    gap: 20,
                    flexDirection: "column",
                    paddingTop: 20,
                }}
            >
                <Card className="table-row-light">
                    <StyledH2>Details</StyledH2>
                </Card>
                {bankableTableData.bankable.map((data, index) => (
                    <SectionTable
                        key={index}
                        sectionName={Object.keys(data)[0]}
                        dataSource={Object.values(data)[0]}
                        tableColumn={tableColumn}
                        minimumWidth={minimumWidth}
                        isMobile={isMobile}
                    />
                ))}

                <Card className="table-row-light">
                    <StyledH2>Summary</StyledH2>
                </Card>
                <Card>
                    <StyledH2 bottomPadding>Total</StyledH2>
                    <Row style={{ width: "100%", gap: 12 }}>
                        <Table
                            dataSource={[bankableTableData.total]}
                            columns={[
                                {
                                    title: "Cash In",
                                    dataIndex: "cashIn",
                                    fixed: "left",
                                    key: "cashIn",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                                {
                                    title: "Cash Out",
                                    dataIndex: "cashOut",
                                    width: "50%",
                                    key: "cashOut",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                            ]}
                            pagination={false}
                            scroll={{ x: minimumWidth }}
                            size={isMobile ? "small" : "middle"}
                        />
                    </Row>
                    <StyledH2 bottomPadding topPadding>
                        Overall
                    </StyledH2>
                    <Row style={{ width: "100%", gap: 12 }}>
                        <Table
                            dataSource={generalTableData}
                            columns={[
                                {
                                    title: "Open Balance",
                                    dataIndex: "openBalance",
                                    key: "openBalance",
                                    fixed: "left",
                                    width: 200,
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                                {
                                    title: "Cash increase",
                                    dataIndex: "cashIncrease",
                                    key: "cashIncrease",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                                {
                                    title: "Bankable",
                                    dataIndex: "bankable",
                                    key: "bankable",
                                    render: (value: number) => {
                                        return value ? `$${value.toFixed(2)}` : "-";
                                    },
                                },
                            ]}
                            pagination={false}
                            scroll={{ x: minimumWidth }}
                            size={isMobile ? "small" : "middle"}
                        />
                    </Row>
                </Card>
            </div>
        </StyledMainContainer>
    );
};

export const CashupBankableReport = CashupReportTableHOC(CashupReportTableComponent);
