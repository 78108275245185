import { debounce } from "lodash";
import { useRef, useCallback } from "react";

type generalFunction = (...args: any) => void;

const useDebouncedCallback = (callback: (...args: any) => void, delay: number) => {
    const callbackRef = useRef<generalFunction | undefined>();
    callbackRef.current = callback;
    return useCallback(
        debounce((...args) => callbackRef.current?.(...args), delay),
        []
    );
};

export { useDebouncedCallback };
