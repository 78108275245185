import { DeleteOutlined } from "@ant-design/icons";
import React, { ReactElement } from "react";
import { NavBarOptions } from "../../Components/Layout/NavBarOptions";

/**
 * Conditionally returns text with/without an edit icon.
 *
 * @param text the string to display
 * @param hideIconForStrings array of string to hide the icon from
 * @returns
 */
export const TextWithDeleteIcon = (
    text: string,
    valueToPass: any = null,
    onClick: (input?: any) => void = () => null,
    hideIcon = false
): string | ReactElement => {
    if (hideIcon) {
        return text;
    }
    //if (hideIconForStrings) {
    //    if (hideIconForStrings.indexOf(text.toLocaleLowerCase()) > -1) {
    //        return `${text}`;
    //    }
    //}
    return (
        <div>
            <DeleteOutlined
                style={{ paddingRight: "8px" }}
                onClick={() => onClick(valueToPass)}
            />
            {`${text}`}
        </div>
    );
};

export const getActiveNavOption = ({
    pathname,
}: {
    pathname: string;
}): string | undefined => {
    const INDEX = NavBarOptions.findIndex(
        (item: { route: string; title: string }) => {
            // For routes such as /transactions/512123z (e.g. Having URL Params.)
            if (pathname.split("/").length > 2) {
                // Gets the main route (e.g. transactions, cashup)
                const PATH_NAME = `/${pathname.split("/")[1]}`;
                // Checks if the Navigation option is the current pathname.
                return item.route === PATH_NAME ? true : false;
            }
            // For cases where there to strip URL params from the pathname.
            return item.route === pathname ? true : false;
        }
    );

    // INDEX == -1 Means nothing found
    if (INDEX >= 0) {
        return NavBarOptions[INDEX].route;
    }
    return;
};

export function capitaliseFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
}
