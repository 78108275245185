import { ApiCall } from "../apiCall";

export async function getKeyGamingFiguresQuery(venueId: string, shiftDate: string) {
    return await ApiCall(
        undefined,
        "AUTHENTICATED",
        "REPORT",
        "gaming-key-figures",
        "GET",
        {},
        `?venue_id=${venueId}&cashup_date=${shiftDate}`
    )
        .then((res) => res.json())
        .then((res) => res);
}
