import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { getDates } from "./getDates";
import {
    CASH_FIELD,
    CARD_FIELD,
    CASH_VARIANCE_FIELD,
    addMissingFieldsWithZeroValue,
} from "./CashupDetailedReport";
import { LocationType } from "../CashupReportModel";

export function PosDetailedReportTransformer(
    detailedReportData: {
        posSplitByClassTypeData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            account: { __typename: string; name: string };
                            class: {
                                __typename: string;
                                classType: number;
                                name: string;
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        cashVarianceForAllPosLocationsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        pettyCashData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                totalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            transactions: { __typename: string; memo: string };
                        }[]
                      | undefined;
              }
            | undefined;
        depositsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                totalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            transactions: { __typename: string; memo: string };
                            account: { __typename: string; name: string };
                        }[]
                      | undefined;
              }
            | undefined;
        transfersData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          splitDate: { __typename: string; day: string };
                          totalIncTax: number;
                      };
                      transactions: { __typename: string; memo: string; id: string };
                  }[];
              }
            | undefined;
        gamingStatsData:
            | {
                  cube: {
                      __typename: string;
                      gamingStats: {
                          __typename: string;
                          date: { __typename: string; value: string; day: string };
                          turnover: number;
                          totalWins: number;
                          netProfit: number;
                          returnToHouse: number;
                      };
                  }[];
              }
            | undefined;
        gamingVarianceData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                  }[];
              }
            | undefined;
        gamingPayoutsData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                  }[];
              }
            | undefined;
        cashTotalsForAllPosLocationsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        cardTotalsForPosData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        safeData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          safeDataTotal: number;
                          safeDataVariance: number;
                          safeSignedOff: string;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        crtData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountCashVariance: number;
                          atmDataRefills: number | null;
                          atmDataWithdrawls: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        atmData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountCashVariance: number;
                          atmDataRefills: number;
                          atmDataWithdrawls: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        wageringData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          wageringDataSalesTotal: number;
                          wageringDataCommission: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        wageringPayoutsData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillTabData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillKenoData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        posParentLocationsData: {
            [parentLocationId: string]: {
                sales: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate?: { day: string };
                        };
                        account: { name: string };
                        class: { classType: number; name: string };
                    }[];
                };
                card: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
                cashVariance: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
                cash: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
            };
        };
    },
    selectedRange: { start: string; end: string } | undefined
) {
    if (!detailedReportData || !selectedRange) {
        return {};
    }
    const knownPosColumns: string[] = [];

    // TODO CREATE A MAP FOR THE POS SECTION
    const DataMap: any = {};

    detailedReportData.posSplitByClassTypeData?.cube?.forEach((posSplitItem) => {
        const { splits, class: posClass } = posSplitItem;
        const { sumTotalIncTax, splitDate } = splits;

        const { day } = splitDate;

        if (knownPosColumns.indexOf(posClass.name) === -1) {
            knownPosColumns.push(posClass.name);
        }

        if (!DataMap[day]) {
            DataMap[day] = {};
            DataMap[day][posClass.name] = sumTotalIncTax;
        } else {
            if (!DataMap[day][posClass.name]) {
                DataMap[day][posClass.name] = sumTotalIncTax;
            } else {
                DataMap[day][posClass.name] += sumTotalIncTax;
            }
        }
    });

    detailedReportData.cashTotalsForAllPosLocationsData?.cube?.forEach(
        (cashTotalItem) => {
            const { splits } = cashTotalItem;
            const { sumTotalIncTax, splitDate } = splits;
            const { day } = splitDate;

            if (!DataMap[day]) {
                DataMap[day] = {};
                DataMap[day][CASH_FIELD] = sumTotalIncTax;
            } else {
                if (!DataMap[day][CASH_FIELD]) {
                    DataMap[day][CASH_FIELD] = sumTotalIncTax;
                } else {
                    DataMap[day][CASH_FIELD] += sumTotalIncTax;
                }
            }
        }
    );

    detailedReportData.cardTotalsForPosData?.cube?.forEach((cardTotalItem) => {
        const { splits } = cardTotalItem;
        const { sumTotalIncTax, splitDate } = splits;
        const { day } = splitDate;

        if (!DataMap[day]) {
            DataMap[day] = {};
            DataMap[day][CARD_FIELD] = sumTotalIncTax;
        } else {
            if (!DataMap[day][CARD_FIELD]) {
                DataMap[day][CARD_FIELD] = sumTotalIncTax;
            } else {
                DataMap[day][CARD_FIELD] += sumTotalIncTax;
            }
        }
    });
    detailedReportData.cashVarianceForAllPosLocationsData?.cube?.forEach(
        (cashVarianceItem) => {
            const { splits } = cashVarianceItem;
            const { sumTotalIncTax, splitDate } = splits;
            const { day } = splitDate;

            if (!DataMap[day]) {
                DataMap[day] = {};
                DataMap[day][CASH_VARIANCE_FIELD] = sumTotalIncTax;
            } else {
                if (!DataMap[day][CASH_VARIANCE_FIELD]) {
                    DataMap[day][CASH_VARIANCE_FIELD] = sumTotalIncTax;
                } else {
                    DataMap[day][CASH_VARIANCE_FIELD] += sumTotalIncTax;
                }
            }
        }
    );

    if (knownPosColumns.indexOf(CASH_FIELD) === -1) {
        knownPosColumns.push(CASH_FIELD);
    }

    if (knownPosColumns.indexOf(CARD_FIELD) === -1) {
        knownPosColumns.push(CARD_FIELD);
    }

    if (knownPosColumns.indexOf(CASH_VARIANCE_FIELD) === -1) {
        knownPosColumns.push(CASH_VARIANCE_FIELD);
    }

    const Dates = Object.keys(DataMap).sort(function (a, b) {
        return Date.parse(a) - Date.parse(b);
    });

    const filledInDates = getDates(selectedRange.start, selectedRange.end);

    const filledDataMap = filledInDates.reduce((acc, date) => {
        const tempDateObject = date.toDate();
        tempDateObject.setUTCHours(0, 0, 0, 0);
        const dateString = tempDateObject.toISOString();
        if (Dates.includes(dateString)) {
            acc[dateString] = addMissingFieldsWithZeroValue(
                knownPosColumns,
                DataMap[dateString]
            );
        } else {
            acc[dateString] = addMissingFieldsWithZeroValue(knownPosColumns, {});
        }
        return acc;
    }, {} as any);

    const tableData: any = [];

    Object.keys(filledDataMap).forEach((currentDate) => {
        tableData.push({ currentDate, ...filledDataMap[currentDate] });
    });

    const tableColumns: ColumnsType<any> = [
        {
            title: "Date",
            dataIndex: "currentDate",
            key: "currentDate",
            fixed: "left",
            width: 140,
            render: (date: string) => {
                return dayjs(date).format("ddd DD/MM/YYYY");
            },
        },
        ...knownPosColumns.map((column) => {
            return {
                title: column,
                dataIndex: column,
                key: column,
                render: (value: number) => {
                    return value ? `$${value.toFixed(2)}` : "-";
                },
            };
        }),
    ];

    return { filledDataMap, knownPosColumns, tableData, tableColumns };
}
