import { useDetailedReport } from "@hooks/useDetailedReport";
import { KeyValuePair } from "@types";
import { CentredSpinner } from "Components/Misc/Loading/CentredSpinner";
import { useAppSelector } from "Redux/TypedReduxFunctions";
import { Button, Card, Col, Row } from "antd";
import { debounce } from "lodash";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import styled from "styled-components";
import {
    dayjsFormat,
    formatDateStringForDisplay,
    toQTCRange,
} from "utils/date-utils";
import { LocationType } from "../CashupReportModel";
import { CashupReportTableHOC } from "../utils/CashupReportTableHOC";
import "./CashupReportTable.css";
import { getWindowDimensions } from "./getWindowDimensions";
import { useWindowDimensions } from "./useWindowDimensions";
import { MainComponentHelper } from "./MainComponentHelper";
import { useDetailedReportTransformer } from "./useDetailedReportTransformer";
import { useGetCashupsForVenueQuery } from "Redux/StateSlices/GroupData/VenuesAPI";
import { DownloadOutlined } from "@ant-design/icons";
import { downloadCSV } from "utils/dom-utils";
import { transformDateToCsvdata } from "./CashupDailyReport/utilities";
import { ColumnsType } from "antd/lib/table";
import { useVenue } from "hooks/useVenue";
import { GroupDataState } from "../../../Redux/StateSlices/GroupData";

export const CASH_FIELD = "Cash";
export const CARD_FIELD = "Card";
export const CASH_VARIANCE_FIELD = "Cash Variance";
export const PAYOUT_FIELD = "Payout";
export const WIN_FIELD = "Wins";
export const TURNOVER_FIELD = "Turnover";
export const NET_PROFIT_FIELD = "Net Profit";
export const RETURN_TO_HOUSE = "Return to House";
export const GAMING_VARIANCE = "Variance";
export const WAGERING_COMMISSION = "Commission";
export const WAGERING_SALES = "Sales";
export const REFILLS = "Refills";
export const WITHDRAWLS = "Withdrawls";
export const BANKABLE = "Bankable";
export const VARIANCE = "Variance";

interface TableRow {
    key: string;
    date: string;
    food: string;
    beverage: string;
    accommodation: string;
    turnover: string;
    net_profit: string;
    total: string;
    other: string;
}

export const addMissingFieldsWithZeroValue = (
    fields: string[],
    currentObject: KeyValuePair
) => {
    const result = { ...currentObject };
    fields.forEach((field) => {
        if (!result[field]) {
            result[field] = 0;
        }
    });
    return result;
};

export type DayAndPosClass = { [day: string]: { [posClass: string]: number } };

export const StyledH2 = styled.h2<{ bottomPadding?: boolean; topPadding?: boolean }>`
    font-weight: bold;
    padding: 0px;
    margin: 0px;
    padding-bottom: ${({ bottomPadding }) => (bottomPadding ? "12px" : "0px")};
    padding-top: ${({ topPadding }) => (topPadding ? "24px" : "0px")};
`;

const StyledMainContainer = styled.div`
    @media (max-width: 768px) {
        max-width: 100vw;
    }
`;
const sectionTitleInTable: { [name: string]: string } = {
    POS: "Point of Sale",
    "ATM/CRT": "ATM & CRT",
    Office: "Office / Bankable",
    Floats: "Safe",
};
const FilterButton = ({
    text,
    section,
    isButtonSelected,
    onButtonPress,
}: {
    text: string;
    section: string;
    isButtonSelected: (sectionName: string) => boolean;
    onButtonPress: (sectionName: string) => void;
}) => {
    return (
        <Button
            type={isButtonSelected(section) ? "primary" : "default"}
            style={{ marginBottom: 4, minWidth: 100 }}
            onClick={() => {
                onButtonPress(section);
            }}
        >
            {text}
        </Button>
    );
};

export const enum DetailedReportSections {
    POS_ALL_LOCATIONS = "POS - All Locations",
    PERRY_CASH = "Petty Cash",
    DEPOSITS = "Deposits",
    TRANSFERS = "Transfers",
    GAMING_ALL_LOCATIONS = "Gaming - All Locations",
    TAB = "TAB",
    KENO = "Keno",
    ATM = "ATM",
    CRT = "CRT",
    TRADE_TILL = "Trade Till",
    TAB_TRADE_TILL = "TAB Trade Till",
    KENO_TRADE_TILL = "KENO Trade Till",
    SAFE = "Safe",
}

const initialCheckedBox = [
    DetailedReportSections.POS_ALL_LOCATIONS,
    DetailedReportSections.PERRY_CASH,
    DetailedReportSections.DEPOSITS,
    DetailedReportSections.TRANSFERS,
    DetailedReportSections.GAMING_ALL_LOCATIONS,
    DetailedReportSections.TAB,
    DetailedReportSections.KENO,
    DetailedReportSections.ATM,
    DetailedReportSections.CRT,
    DetailedReportSections.TRADE_TILL,
    DetailedReportSections.TAB_TRADE_TILL,
    DetailedReportSections.KENO_TRADE_TILL,
    DetailedReportSections.SAFE,
];
export type CashupReportColumnName = keyof TableRow;

export const StyledDetailedReportCard = styled(Card)``;

const CashupReportTableComponent: React.FC<{ groupData: GroupDataState }> = ({
    groupData,
}) => {
    const { selectedRange, selectedVenueId } = useAppSelector(
        (state) => state.shiftReportSlice
    );

    const venue = useMemo(
        () => selectedVenueId ?? groupData?.venues[0].venue_id,
        [selectedVenueId, groupData]
    );

    const { setVenue } = useVenue();
    const parentLocations = useMemo(() => {
        if (!groupData) {
            return [];
        }
        return groupData.locations_hierarchy
            .filter((location) => {
                return (
                    location.venue === venue &&
                    location.location_type === LocationType.pos
                );
            })
            .map((currentLocation) => {
                return currentLocation.location_id;
            });
    }, [groupData, venue]);

    const getPosParentNameById = useCallback(
        (id: string) => {
            if (!groupData) {
                return "";
            }
            const parent = groupData.locations_hierarchy.find(
                (location) => location.location_id === id
            );
            return parent?.name || "";
        },
        [groupData]
    );

    const [selectedButtons, setSelectedButtons] = useState<string[]>([]);

    const [selectedToDownload, setSelectedToDownload] =
        useState<string[]>(initialCheckedBox);

    const parsedRange = useMemo(
        () =>
            selectedRange
                ? toQTCRange(selectedRange.start, selectedRange.end)
                : undefined,
        [selectedRange]
    );

    const { data: venuesCashupData } = useGetCashupsForVenueQuery({
        venue_id: venue!,
        cashup_date: parsedRange!.start.format("YYYY-MM-DD"),
    });

    const onButtonPress = useCallback(
        (buttonText: string) => {
            if (selectedButtons.indexOf(buttonText) === -1) {
                setSelectedButtons([...selectedButtons, buttonText]);
            } else {
                setSelectedButtons(
                    selectedButtons.filter((button) => button !== buttonText)
                );
            }
        },
        [selectedButtons]
    );

    const onCheckboxClicked = (checkboxLable: string) => (clicked: boolean) => {
        if (clicked) {
            if (!selectedToDownload.includes(checkboxLable)) {
                setSelectedToDownload([...selectedToDownload, checkboxLable]);
            }
        } else {
            if (selectedToDownload.includes(checkboxLable)) {
                setSelectedToDownload(
                    selectedToDownload.filter(
                        (selected) => selected !== checkboxLable
                    )
                );
            }
        }
    };

    const { data: detailedReportData, isLoading: isDetailedReportLoading } =
        useDetailedReport({
            startDate: parsedRange?.start.format("YYYY-MM-DD"),
            endDate: parsedRange?.end.format("YYYY-MM-DD"),
            venueId: venue,
            parentLocations,
            cubejsToken: groupData?.cubejs_token,
        });

    const {
        transformedAtmData,
        transformedCrtData,
        transformedKenoTradeTillData,
        transformedGamingData,
        transformedWageringData,
        transformedTabTradeTillData,
        transformedPosData,
        transformedTradeTillData,
        transformedPosParentLocationsData,
        transformedPettyCash,
        transformedDeposits,
        transformedTransfers,
        transformedSafeData,
    } = useDetailedReportTransformer({ detailedReportData, selectedRange });
    const firstMount = useRef<boolean>(true);

    useEffect(() => {
        if (
            transformedPosParentLocationsData.newDataMap &&
            Object.keys(transformedPosParentLocationsData.newDataMap).length !== 0 &&
            !isDetailedReportLoading
        ) {
            if (firstMount.current) {
                const newSelectedToDownload = [...selectedToDownload];
                Object.keys(transformedPosParentLocationsData.newDataMap).forEach(
                    (current) => {
                        newSelectedToDownload.push(
                            `POS - ${getPosParentNameById(current)}`
                        );
                    }
                );
                setSelectedToDownload(newSelectedToDownload);
                firstMount.current = false;
            }
        }
    }, [
        getPosParentNameById,
        selectedToDownload,
        transformedPosParentLocationsData.newDataMap,
        isDetailedReportLoading,
    ]);

    const debouncedWindowDimensions = debounce(getWindowDimensions, 100);

    const { width } = useWindowDimensions({
        fetchDimensions: debouncedWindowDimensions,
    });

    const minimumWidth = useMemo(() => {
        if (width < 792) {
            return 792;
        }
        return "100%";
    }, [width]);

    const isMobile = useMemo(() => {
        return width < 792;
    }, [width]);

    const isButtonSelected = useCallback(
        (buttonName: string) => {
            return selectedButtons.includes(buttonName);
        },
        [selectedButtons]
    );

    const isButtonExist = useCallback(
        (sectionName: string) => {
            switch (sectionName) {
                case "POS":
                    return venuesCashupData?.locations.pos_locations.some(
                        (loction) => loction.sub_locations.length > 0
                    );
                case "Gaming":
                    return venuesCashupData?.locations?.gaming_locations?.some(
                        (loction) => loction.sub_locations.length > 0
                    );
                case "Transactions":
                    return true;
                case "Wagering":
                    return venuesCashupData?.locations?.wagering_locations?.some(
                        (location) => location.sub_locations.length > 0
                    );
                case "ATM/CRT":
                    return (
                        venuesCashupData?.locations?.atm_locations?.some(
                            (location) => location.sub_locations.length > 0
                        ) ||
                        venuesCashupData?.locations?.crt_locations?.some(
                            (location) => location.sub_locations.length > 0
                        )
                    );
                case "Office":
                    return venuesCashupData?.locations?.office_locations?.some(
                        (location) => location.sub_locations.length > 0
                    );
                case "Floats":
                    return venuesCashupData?.locations?.safe_locations?.some(
                        (location) => location.sub_locations.length > 0
                    );
                default:
                    console.warn("error, wrong section title");
            }
        },
        [
            venuesCashupData?.locations.atm_locations,
            venuesCashupData?.locations.crt_locations,
            venuesCashupData?.locations.gaming_locations,
            venuesCashupData?.locations.office_locations,
            venuesCashupData?.locations.pos_locations,
            venuesCashupData?.locations.safe_locations,
            venuesCashupData?.locations.wagering_locations,
        ]
    );
    const shouldDownLoadSection = useCallback(
        (section: string) => {
            if (selectedButtons.length === 0) {
                return isButtonExist(section);
            } else {
                return isButtonSelected(section);
            }
        },
        [selectedButtons, isButtonSelected, isButtonExist]
    );
    const refresh = useRef(true);
    useEffect(() => {
        if (refresh.current) {
            setVenue(venue!);
            refresh.current = false;
        }
    }, []);

    const venueIndex = useMemo(
        () =>
            groupData?.venues?.find(({ venue_id }) => venue_id === selectedVenueId),
        [groupData]
    );

    interface PosData {
        [name: string]: string | number;
    }

    interface TitleColumn {
        dataIndex: string;
    }

    interface ColumnsName extends TitleColumn {
        [name: string]: string;
    }

    const columnsInTable = useCallback((tableColumns: ColumnsName[]) => {
        return tableColumns
            ? tableColumns.map((currentColumn) => currentColumn.dataIndex)
            : undefined;
    }, []);

    const appendSectionTitle = useCallback(
        (sectionTitle: string, Setion: Array<string>) => {
            let sectionTiltleResult = "";
            if (
                shouldDownLoadSection(sectionTitle) &&
                Setion.some((currentSection) =>
                    selectedToDownload.includes(currentSection)
                )
            ) {
                sectionTiltleResult +=
                    sectionTitleInTable[sectionTitle] ?? sectionTitle;
                sectionTiltleResult += "\r\n";
            }
            return sectionTiltleResult;
        },
        [selectedToDownload, shouldDownLoadSection]
    );
    const whetherToDownloadSubsection = useCallback(
        (subsectionName: string, sectionName: string) =>
            shouldDownLoadSection(sectionName) &&
            selectedToDownload.includes(subsectionName),
        [selectedToDownload, shouldDownLoadSection]
    );
    const appendSectionData = useCallback(
        (
            sectionTitle: string,
            sectionColumns: ColumnsType<unknown> | string[],
            sectionData: any
        ) => {
            let sectionDataResult = "";
            sectionDataResult += sectionTitle + "\r\n";
            sectionColumns &&
                sectionColumns.forEach((currentColumn) => {
                    if (typeof currentColumn === "string") {
                        sectionDataResult += currentColumn + ",";
                    } else {
                        sectionDataResult += currentColumn.title + ",";
                    }
                });
            sectionDataResult += "\r\n";
            if (typeof sectionColumns![0] === "string") {
                //@ts-ignore
                const index = sectionColumns?.indexOf("Date");
                if (index !== -1) {
                    sectionColumns?.splice(index, 1, "currentDate");
                }
            }

            sectionDataResult += transformDateToCsvdata(
                typeof sectionColumns![0] === "string"
                    ? (sectionColumns as string[])
                    : (columnsInTable(sectionColumns as ColumnsName[]) as string[]),
                sectionData as PosData[]
            );
            return sectionDataResult;
        },
        [columnsInTable]
    );

    const convertDetailedReportToCsv = useCallback(() => {
        let result = "";
        const posSubsections: Array<string> = [
            DetailedReportSections.POS_ALL_LOCATIONS,
        ];
        Object.keys(transformedPosParentLocationsData.newDataMap!).forEach(
            (currentPosParentLocation) =>
                posSubsections.push(
                    `POS - ${getPosParentNameById(currentPosParentLocation)}`
                )
        );
        result += appendSectionTitle("POS", posSubsections);
        if (
            whetherToDownloadSubsection(
                DetailedReportSections.POS_ALL_LOCATIONS,
                "POS"
            )
        ) {
            result += appendSectionData(
                DetailedReportSections.POS_ALL_LOCATIONS,
                transformedPosData.tableColumns!,
                transformedPosData.tableData
            );
        }
        transformedPosParentLocationsData &&
            Object.keys(transformedPosParentLocationsData.newDataMap!).forEach(
                (currentPosParentLocation) => {
                    if (
                        whetherToDownloadSubsection(
                            `POS - ${getPosParentNameById(
                                currentPosParentLocation
                            )}`,
                            "POS"
                        )
                    ) {
                        result += appendSectionData(
                            `POS - ${getPosParentNameById(
                                currentPosParentLocation
                            )}`,
                            transformedPosParentLocationsData.tableColumns!,
                            transformedPosParentLocationsData.newDataMap![
                                currentPosParentLocation
                            ]
                        );
                    }
                }
            );
        result += appendSectionTitle("Transactions", [
            DetailedReportSections.PERRY_CASH,
            DetailedReportSections.DEPOSITS,
            DetailedReportSections.TRANSFERS,
        ]);
        if (
            whetherToDownloadSubsection(
                DetailedReportSections.PERRY_CASH,
                "Transactions"
            )
        ) {
            result += appendSectionData(
                DetailedReportSections.PERRY_CASH,
                transformedPettyCash.tableColumns!,
                transformedPettyCash.tableData
            );
        }
        if (
            whetherToDownloadSubsection(
                DetailedReportSections.DEPOSITS,
                "Transactions"
            )
        ) {
            result += appendSectionData(
                DetailedReportSections.DEPOSITS,
                transformedDeposits.tableColumns!,
                transformedDeposits.tableData
            );
        }
        if (
            whetherToDownloadSubsection(
                DetailedReportSections.TRANSFERS,
                "Transactions"
            )
            // selectedToDownload.includes(DetailedReportSections.TRANSFERS) &&
            // shouldDownLoadSection("Transactions")
        ) {
            result += appendSectionData(
                DetailedReportSections.TRANSFERS,
                transformedTransfers.tableColumns!,
                transformedTransfers.tableData
            );
        }

        result += appendSectionTitle("Gaming", [
            DetailedReportSections.GAMING_ALL_LOCATIONS,
        ]);
        if (
            whetherToDownloadSubsection(
                DetailedReportSections.GAMING_ALL_LOCATIONS,
                "Gaming"
            )
        ) {
            result += appendSectionData(
                DetailedReportSections.GAMING_ALL_LOCATIONS,
                transformedGamingData.tableColumns!,
                transformedGamingData.tableData
            );
        }
        result += appendSectionTitle("Wagering", [
            DetailedReportSections.TAB,
            DetailedReportSections.KENO,
        ]);
        if (whetherToDownloadSubsection(DetailedReportSections.TAB, "Wagering")) {
            result += appendSectionData(
                DetailedReportSections.TAB,
                ["Date", "Imbalance  Total", "Commission", "Payout", "Variance"],
                transformedWageringData.tabTableData
            );
        }
        if (whetherToDownloadSubsection(DetailedReportSections.KENO, "Wagering")) {
            result += appendSectionData(
                DetailedReportSections.KENO,
                ["Date", "Cash Total", "Commission", "Payout", "Variance"],
                transformedWageringData.kenoTableData
            );
        }
        result += appendSectionTitle("ATM/CRT", [
            DetailedReportSections.ATM,
            DetailedReportSections.CRT,
        ]);

        if (whetherToDownloadSubsection(DetailedReportSections.ATM, "ATM/CRT")) {
            result += appendSectionData(
                DetailedReportSections.ATM,
                ["Date", "Withdrawls", "Refills", "Variance"],
                transformedAtmData.tableData
            );
        }
        if (whetherToDownloadSubsection(DetailedReportSections.CRT, "ATM/CRT")) {
            result += appendSectionData(
                DetailedReportSections.CRT,
                ["Date", "Withdrawls", "Refills", "Variance"],
                transformedCrtData.tableData
            );
        }
        result += appendSectionTitle("Office", [
            DetailedReportSections.TRADE_TILL,
            DetailedReportSections.TAB_TRADE_TILL,
            DetailedReportSections.KENO_TRADE_TILL,
        ]);

        if (
            whetherToDownloadSubsection(DetailedReportSections.TRADE_TILL, "Office")
        ) {
            result += appendSectionData(
                DetailedReportSections.TRADE_TILL,
                ["Date", "Bankable", "Variance"],
                transformedTradeTillData.tableData
            );
        }
        if (
            whetherToDownloadSubsection(
                DetailedReportSections.TAB_TRADE_TILL,
                "Office"
            )
        ) {
            result += appendSectionData(
                DetailedReportSections.TAB_TRADE_TILL,
                ["Date", "Bankable", "Variance"],
                transformedTabTradeTillData.tableData
            );
        }
        if (
            whetherToDownloadSubsection(
                DetailedReportSections.KENO_TRADE_TILL,
                "Office"
            )
        ) {
            result += appendSectionData(
                DetailedReportSections.KENO_TRADE_TILL,
                ["Date", "Bankable", "Variance"],
                transformedKenoTradeTillData.tableData
            );
        }
        result += appendSectionTitle("Floats", [DetailedReportSections.SAFE]);
        if (whetherToDownloadSubsection(DetailedReportSections.SAFE, "Floats")) {
            result += appendSectionData(
                DetailedReportSections.SAFE,
                transformedSafeData.tableColumns!,
                transformedSafeData.tableData
            );
        }
        return result;
    }, [
        appendSectionData,
        appendSectionTitle,
        getPosParentNameById,
        transformedAtmData.tableData,
        transformedCrtData.tableData,
        transformedDeposits.tableColumns,
        transformedDeposits.tableData,
        transformedGamingData.tableColumns,
        transformedGamingData.tableData,
        transformedKenoTradeTillData.tableData,
        transformedPettyCash.tableColumns,
        transformedPettyCash.tableData,
        transformedPosData.tableColumns,
        transformedPosData.tableData,
        transformedPosParentLocationsData,
        transformedSafeData.tableColumns,
        transformedSafeData.tableData,
        transformedTabTradeTillData.tableData,
        transformedTradeTillData.tableData,
        transformedTransfers.tableColumns,
        transformedTransfers.tableData,
        transformedWageringData.kenoTableData,
        transformedWageringData.tabTableData,
        whetherToDownloadSubsection,
    ]);

    // TODO ADD A LOADING STATE

    if (isDetailedReportLoading) {
        return <CentredSpinner size="large" />;
    }

    return (
        <StyledMainContainer>
            <Col>
                <Col>
                    <Row>
                        <Button
                            type="primary"
                            icon={<DownloadOutlined />}
                            style={{ marginBottom: 4 }}
                            onClick={() => {
                                downloadCSV(
                                    convertDetailedReportToCsv(),
                                    `${formatDateStringForDisplay(
                                        selectedRange!.start,
                                        dayjsFormat.dayMonthYearSeparateByDash
                                    )}-${formatDateStringForDisplay(
                                        selectedRange!.end,
                                        dayjsFormat.dayMonthYearSeparateByDash
                                    )}-${
                                        venueIndex ? venueIndex.name : undefined
                                    }.csv`
                                );
                            }}
                        >
                            Download
                        </Button>
                    </Row>
                </Col>

                <StyledDetailedReportCard
                    style={{
                        width: "fit-content",
                        height: "fit-content",
                    }}
                >
                    <StyledH2 bottomPadding>Filters</StyledH2>
                    <Row
                        style={{
                            width: "100%",
                            justifyContent: "space-between",
                            paddingBottom: 12,
                        }}
                    >
                        <Row
                            style={{ justifyContent: "space-between", gap: 16 }}
                            // TODO ADD Minimum width to the buttons
                        >
                            {isButtonExist("POS") ? (
                                <FilterButton
                                    text={"POS"}
                                    section={"POS"}
                                    isButtonSelected={isButtonSelected}
                                    onButtonPress={onButtonPress}
                                />
                            ) : null}
                            {isButtonExist("Gaming") ? (
                                <FilterButton
                                    text={"Gaming"}
                                    section={"Gaming"}
                                    isButtonSelected={isButtonSelected}
                                    onButtonPress={onButtonPress}
                                />
                            ) : null}
                            <FilterButton
                                text={"Transactions"}
                                section={"Transactions"}
                                isButtonSelected={isButtonSelected}
                                onButtonPress={onButtonPress}
                            />
                            {isButtonExist("Wagering") ? (
                                <FilterButton
                                    text={"Wagering"}
                                    section={"Wagering"}
                                    isButtonSelected={isButtonSelected}
                                    onButtonPress={onButtonPress}
                                />
                            ) : null}
                            {isButtonExist("ATM/CRT") ? (
                                <FilterButton
                                    text={"ATM / CRT"}
                                    section={"ATM/CRT"}
                                    isButtonSelected={isButtonSelected}
                                    onButtonPress={onButtonPress}
                                />
                            ) : null}
                            {isButtonExist("Office") ? (
                                <FilterButton
                                    text={"Office"}
                                    section={"Office"}
                                    isButtonSelected={isButtonSelected}
                                    onButtonPress={onButtonPress}
                                />
                            ) : null}
                            {isButtonExist("Floats") ? (
                                <FilterButton
                                    text={"Safe"}
                                    section={"Floats"}
                                    isButtonSelected={isButtonSelected}
                                    onButtonPress={onButtonPress}
                                />
                            ) : null}
                        </Row>
                    </Row>
                </StyledDetailedReportCard>
                <MainComponentHelper
                    transformedAtmData={transformedAtmData}
                    transformedCrtData={transformedCrtData}
                    transformedKenoTradeTillData={transformedKenoTradeTillData}
                    transformedGamingData={transformedGamingData}
                    transformedWageringData={transformedWageringData}
                    transformedTabTradeTillData={transformedTabTradeTillData}
                    transformedPosData={transformedPosData}
                    transformedTradeTillData={transformedTradeTillData}
                    minimumWidth={minimumWidth}
                    selectedSelections={selectedButtons}
                    isMobile={isMobile}
                    transformedPosParentLocationsData={
                        transformedPosParentLocationsData
                    }
                    convertPosIdToLocationName={getPosParentNameById}
                    transformedPettyCash={transformedPettyCash}
                    transformedDeposits={transformedDeposits}
                    transformedTransfers={transformedTransfers}
                    transformedSafeData={transformedSafeData}
                    venuesCashupData={venuesCashupData}
                    onCheckboxClicked={onCheckboxClicked}
                    selectedToDownload={selectedToDownload}
                />
            </Col>
        </StyledMainContainer>
    );
};

export const CashupDetailedReport = CashupReportTableHOC(CashupReportTableComponent);
