import { AntDFormStateWithoutSplit, ExtendedTreeData } from "@types";
import { TransferTransactionInit } from "Pages/CashupHome/CashupTabs/POS/@types";
import { Field } from "Pages/CashupHome/ExcelTable/TransactionForms/SalesCountModalFormV2";
import { checkForSublocations } from "Pages/CashupHome/ExcelTable/TransactionForms/TransferModalFormV2";
import { useCallback, useMemo } from "react";
import { ExtendedLocationItemWithChildren } from "Redux/StateSlices/GroupData/LocationsAPI";
import { extractSubLocation } from "utils/utilities";

interface UseTransactionTransferProps {
    TransferTransactions?: TransferTransactionInit[];
    TransferTransactionsReadOnly?: TransferTransactionInit[];
    toLocations?: ExtendedLocationItemWithChildren[];
    fromLocations?: ExtendedLocationItemWithChildren[];
    allLocations?: ExtendedLocationItemWithChildren[];
    currentLocationID?: string;
    includeDefaultReadonlyValue?: boolean;
    forceIncludeBank?: boolean;
}

const TransfersTransactionParser = (
    PaymentTransactions: TransferTransactionInit[],
    currentLocationID?: string,
    readOnly = false
): AntDFormStateWithoutSplit[] => {
    const ParsedPaymentTransactions: AntDFormStateWithoutSplit[] =
        PaymentTransactions.map((currentPaymentTransaction) => {
            return {
                formData: [
                    {
                        name: "From",
                        value: currentPaymentTransaction.from_location_id,
                    },
                    {
                        name: "To",
                        value: currentPaymentTransaction.to_location_id,
                    },
                    {
                        name: "Amount",
                        value: currentPaymentTransaction?.amount?.toString(),
                    },
                    {
                        name: "Ref",
                        value: currentPaymentTransaction?.ref?.toString(),
                    },
                ],
                formID: Math.random().toString(),
            };
        });
    if (!readOnly && currentLocationID !== undefined)
        ParsedPaymentTransactions.push({
            formData: [{ name: "From", value: currentLocationID }],
            formID: Math.random().toString(),
        });
    return ParsedPaymentTransactions;
};

const createTransformLocationsHelper = (
    locations: ExtendedLocationItemWithChildren[]
) => {
    const result: ExtendedTreeData[] = [];
    const floatLocations: ExtendedLocationItemWithChildren[] = [];

    const processLocation = (location: ExtendedLocationItemWithChildren) => {
        if (location.location_type === 14) {
            floatLocations.push(location);
            return;
        }

        const previousTitle = location.name;
        result.push({
            key: location.location_id,
            title: location.name,
            value: location.location_id,
            children: location.sub_locations
                ? extractSubLocation(location.sub_locations, previousTitle).sort(
                      (subLocationA, subLocationB) =>
                          subLocationA.title.localeCompare(subLocationB.title)
                  )
                : [],
            disabled: checkForSublocations(location),
            venue: location.venue,
        });
    };

    const processFloatLocations = () => {
        const floatLocationsChildren = extractSubLocation(floatLocations).sort(
            (subLocationA, subLocationB) =>
                subLocationA.title.localeCompare(subLocationB.title)
        );

        result.forEach((location) => {
            if (!location.title.includes("Safe")) return;

            const itemsWithMatchingVenueId = floatLocationsChildren.filter(
                (item) => item.venue === location.venue
            );
            location.children = location.children?.concat(itemsWithMatchingVenueId);
            location.disabled = true;
        });
    };

    locations.forEach(processLocation);

    if (floatLocations.length > 0) processFloatLocations();

    return result.sort((locationA, locationB) =>
        locationA.title.localeCompare(locationB.title)
    );
};

const useTransactionTransfer = ({
    toLocations = [],
    fromLocations = [],
    allLocations,
    currentLocationID,
    TransferTransactions = [],
    TransferTransactionsReadOnly = [],
    includeDefaultReadonlyValue = false,
}: UseTransactionTransferProps) => {
    const processTransformLocations = useCallback(
        (locations: ExtendedLocationItemWithChildren[] = []) => {
            const result = locations.map((location, index) => ({
                key: index,
                title: location.name,
                value: location.location_id,
                children: location.sub_locations
                    ? extractSubLocation(location.sub_locations, location.name).sort(
                          (subLocationA, subLocationB) =>
                              subLocationA.title.localeCompare(subLocationB.title)
                      )
                    : [],
                disabled: checkForSublocations(location),
                venue: location.venue,
            }));

            return result;
        },
        []
    );

    const transformFromLocations = useMemo(
        () => createTransformLocationsHelper(fromLocations),
        [fromLocations]
    );

    const transformToLocation = useMemo(
        () =>
            createTransformLocationsHelper(
                toLocations.map((location) => ({
                    ...location,
                    sub_locations: location.sub_locations,
                }))
            ),
        [toLocations]
    );

    const transformLocationsToReadonly = useMemo(
        () => processTransformLocations(allLocations || toLocations),
        [processTransformLocations]
    );

    const transformLocationsFromReadonly = useMemo(
        () => processTransformLocations(allLocations || fromLocations),
        [processTransformLocations]
    );

    const transferFields: Field[] = useMemo(
        () => [
            {
                name: "From",
                required: true,
                type: {
                    name: currentLocationID ? "TreeSelectLocked" : "TreeSelect",
                    locations: transformFromLocations,
                    key: currentLocationID || "",
                },
                colSpan: 7,
            },
            {
                name: "To",
                required: true,
                type: {
                    name: "TreeSelect",
                    locations: transformToLocation,
                },
                colSpan: 7,
            },
            {
                name: "Ref",
                required: false,
                type: { name: "Text" },
                colSpan: 3,
            },
            {
                name: "Amount",
                required: true,
                type: { name: "Money", positiveValueOnly: true },
                colSpan: 4,
            },

            { name: "Button", type: { name: "Button" }, colSpan: 2, isButton: true },
        ],
        [currentLocationID, transformLocationsFromReadonly, transformToLocation]
    );

    const transferFieldsReadOnly: Field[] = useMemo(() => {
        return [
            {
                name: "From",
                required: true,
                disabled: true,
                type: {
                    name: "TreeSelect",
                    locations: transformLocationsFromReadonly,
                    key: currentLocationID,
                },
                colSpan: 8,
            },
            {
                name: "To",
                disabled: true,
                required: true,

                type: {
                    name: "TreeSelect",
                    locations: transformLocationsToReadonly,
                },
                colSpan: 8,
            },
            {
                name: "Amount",
                disabled: true,
                required: true,
                type: { name: "Money" },
                colSpan: 6,
            },
        ];
    }, [
        currentLocationID,
        transformLocationsToReadonly,
        transformLocationsFromReadonly,
    ]);

    const initialTransfersTransactionsDataWithAppendedFormID = useMemo(() => {
        if (TransferTransactions.length === 0)
            return [
                {
                    formData: [{ name: "From", value: currentLocationID }],
                    formID: Math.random().toString(),
                },
            ];

        return TransfersTransactionParser(TransferTransactions, currentLocationID);
    }, [TransferTransactions]);

    const initialTransfersReadOnlyTransactionsDataWithAppendedFormID: AntDFormStateWithoutSplit[] =
        useMemo(() => {
            if (TransferTransactionsReadOnly.length === 0)
                return includeDefaultReadonlyValue
                    ? [
                          {
                              formData: [{ name: "From", value: currentLocationID }],
                              formID: Math.random().toString(),
                          },
                      ]
                    : [];

            return TransfersTransactionParser(
                TransferTransactionsReadOnly,
                currentLocationID,
                true
            );
        }, [transferFieldsReadOnly, includeDefaultReadonlyValue]);

    return {
        transferFields,
        transferFieldsReadOnly,
        initialTransfersTransactionsDataWithAppendedFormID,
        initialTransfersReadOnlyTransactionsDataWithAppendedFormID,
    };
};

export default useTransactionTransfer;
