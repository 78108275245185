import React from "react";
import { DefaultLayout } from "Components/Layout";
import { Tabs } from "antd";
import styled from "styled-components";
import CashupReport from "Pages/CashupReportNew/CashupReport";
import { CashupReportSummaryPage } from "../CashupReport/CashupReportSummaryPage";

const { TabPane } = Tabs;

const CustomTabs = styled(Tabs)`
    .ant-tabs-tab {
        margin-left: 28px;
    }

    .ant-tabs-nav {
        background-color: #e5ebf2;
    }
`;

const CashupReportOverview: React.FC = () => {
    return (
        <DefaultLayout skipLayoutWrapper>
            <CustomTabs defaultActiveKey="old">
                <TabPane tab="Current" key="old">
                    <CashupReportSummaryPage />
                </TabPane>
                <TabPane tab="New (Beta)" key="new">
                    <CashupReport />
                </TabPane>
            </CustomTabs>
        </DefaultLayout>
    );
};

export default CashupReportOverview;
