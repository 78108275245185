import { useWebviewLogin } from "@hooks/useWebviewLogin";
import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useGetUserEmail } from "../@hooks/auth/getUserEmail";
import { QuantacoLoader } from "../Components/QuantacoLoader/QuantacoLoader";
import { RoutePaths } from "./RoutesEnum";

export function AuthProtectedRoute({ component: Component, ...rest }: RouteProps) {
    const isLoading = useWebviewLogin();
    const { auth0User, loaded } = useGetUserEmail(isLoading);

    if (loaded === false || isLoading) {
        return <QuantacoLoader size={200} />;
    }
    if (auth0User) {
        return <Route {...rest} component={Component} />;
    }

    // Redirect to login or 403 screen
    return (
        <Route
            {...rest}
            render={({ location }) =>
                location.pathname === "/" ? (
                    <Redirect
                        to={{
                            pathname: RoutePaths.LOGIN,
                        }}
                    />
                ) : (
                    <Redirect
                        to={{
                            pathname: RoutePaths.UNAUTHORISED,
                            // Adds the referring page to redirect back to on successful authentication
                            search: `?referrer=${location.pathname.replace(
                                "/",
                                ""
                            )}`,
                        }}
                    />
                )
            }
        />
    );
}
