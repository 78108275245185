import { ApiCall } from "../apiCall";

export const getShiftGroupReport = async (
    startDate: string,
    endDate: string,
    groupId?: string
) => {
    let requestUrl = `?from_date=${startDate}&to_date=${endDate}`;
    if (groupId && groupId.length > 0) {
        requestUrl += `&group_id=${groupId}`;
    }
    return await ApiCall(
        undefined,
        "AUTHENTICATED",
        "GROUPLEVELREPORT",
        "admin-shift",
        "GET",
        {},
        requestUrl
    );
};
