import { fetchUserToken } from "utils/utilities";

export const fetchWrapper = async (
    path: string,
    requestType: "GET" | "POST" | "PUT" | "DELETE",
    body?: string
): Promise<Response> => {
    const ID_TOKEN = await fetchUserToken();

    const url = `${process.env.REACT_APP_BACKEND_API_URL}${path}`;

    const headers = new Headers();
    headers.append("Authorization", `bearer ${ID_TOKEN}`);
    headers.append("Content-Type", "application/json");

    const requestOptions: RequestInit = {
        method: requestType,
        headers: headers,
    };

    if (body) requestOptions["body"] = body;
    return fetch(url, requestOptions);
};

/**
 * Fetch wrapper with content-type removed. For compatibility with legacy endpoints (wranglr copies)
 *
 * @param path
 * @param requestType
 * @param body
 * @returns
 */
export const fetchWrapperNoContentHeader = async (
    path: string,
    requestType: "GET" | "POST" | "PUT" | "DELETE",
    body?: string
): Promise<Response> => {
    const ID_TOKEN = await fetchUserToken();

    const url = `${process.env.REACT_APP_BACKEND_API_URL}${path}`;

    const headers = new Headers();
    headers.append("Authorization", `bearer ${ID_TOKEN}`);
    //headers.append("Content-Type", "application/json");

    const requestOptions: RequestInit = {
        method: requestType,
        headers: headers,
    };

    if (body) requestOptions["body"] = body;
    return fetch(url, requestOptions);
};
export const fetchWrapperAnyContentHeader = async (
    path: string,
    requestType: "GET" | "POST" | "PUT" | "DELETE",
    body?: string
): Promise<Response> => {
    const ID_TOKEN = await fetchUserToken();

    const url = `${process.env.REACT_APP_BACKEND_API_URL}${path}`;

    const headers = new Headers();
    headers.append("Authorization", `bearer ${ID_TOKEN}`);
    headers.append("Content-Type", "*/*");

    const requestOptions: RequestInit = {
        method: requestType,
        headers: headers,
    };

    if (body) requestOptions["body"] = body;
    return fetch(url, requestOptions);
};

/**
 * This is a temporary method for the /group/users GET method (faulty endpoint) to be depreciated immediately following a endpoint solution
 *
 * @param path
 * @param requestType
 * @param body
 * @returns
 */
export const fetchWrapperNoAuth = async (
    path: string,
    requestType: "GET" | "POST" | "PUT" | "DELETE",
    body?: string
): Promise<Response> => {
    const url = `${process.env.REACT_APP_BACKEND_API_URL}${path}`;

    const headers = new Headers();
    headers.append("Content-Type", "application/json");

    const requestOptions: RequestInit = {
        method: requestType,
    };

    if (body) requestOptions["body"] = body;
    return fetch(url, requestOptions);
};

export const fetchWrapperWithFormBody = async (
    path: string,
    requestType: "GET" | "POST" | "PUT" | "DELETE",
    body?: FormData
): Promise<Response> => {
    const ID_TOKEN = await fetchUserToken();

    const url = `${process.env.REACT_APP_BACKEND_API_URL}${path}`;

    const headers = new Headers();
    headers.append("Authorization", `Bearer ${ID_TOKEN}`);

    const requestOptions: RequestInit = {
        method: requestType,
        headers: headers,
    };

    if (body) requestOptions["body"] = body;
    return fetch(url, requestOptions);
};
