import { useAppSelector } from "Redux/TypedReduxFunctions";
import { Button, message } from "antd";
import React, { useMemo, useState } from "react";
import "./CashupReportTable.css";
import { useMutation } from "react-query";
import { fetchUserToken } from "../../../utils/utilities";
import { downloadCSV } from "../../../utils/dom-utils";
import { DownloadOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useLocalStorage } from "../../../@hooks/useLocalStorage";

export const CashupGamingReport: React.FC = () => {
    const { selectedDate, selectedVenueId } = useAppSelector(
        (state) => state.shiftReportSlice
    );
    const [selectedDateinLocal, setSelectedDate] = useLocalStorage<string | null>(
        "selectedDate",
        null
    );
    const parsedDate = useMemo(
        () =>
            selectedDate
                ? selectedDate
                : selectedDateinLocal
                ? selectedDateinLocal
                : undefined,
        [selectedDate, selectedDateinLocal]
    );

    const [isFetchGamingReportLoading, setIsFetchGamingReportLoading] =
        useState(false);
    const dateToGet = dayjs(parsedDate!, "DD-MM-YYYY")
        .format("DD-MM-YYYY")
        .toString();
    const { mutate: fetchCSV } = useMutation(
        async () => {
            setIsFetchGamingReportLoading(true);
            const ID_TOKEN = await fetchUserToken();
            if (ID_TOKEN === undefined) {
                throw Error("Auth token not available");
            }

            return fetch(
                `${process.env.REACT_APP_BACKEND_API_URL}/api/v2/analytics/gaming-report/csv?from_date=${dateToGet}&to_date=${dateToGet}&venue_id=${selectedVenueId}&content_type=csv`,
                { headers: { Authorization: `Bearer ${ID_TOKEN}` } }
            );
        },
        {
            onSuccess: async (response) => {
                setIsFetchGamingReportLoading(false);
                const parsedText = await response.text();
                if (parsedText.length > 1) {
                    downloadCSV(parsedText, `Gaming-Report-${dateToGet}.csv`);
                } else {
                    message.error("No Gaming Report for the selected Date & Venue");
                }
            },
        }
    );
    return (
        <Button
            type="primary"
            icon={<DownloadOutlined />}
            onClick={() => fetchCSV()}
            style={{ marginRight: 8 }}
            loading={isFetchGamingReportLoading}
        >
            Download Gaming Report
        </Button>
    );
};
