import React, { useMemo } from "react";
import { Row, Col, Space } from "antd";
import { ReportItem } from "@interfaces/index";
import {
    LocationContainer,
    TableTitle,
} from "Pages/CashupReportNew/Components/CashupReportStyled";
import CashupReportBarChart from "Pages/CashupReportNew/Components/CashupReportBarChart";
import {
    sortDataBasedOnKeys,
    prepareBarGraphData,
} from "Pages/CashupReportNew/utils";

interface ShiftReportClassesProps {
    reportData: ReportItem[];
}

const ShiftReportClasses: React.FC<ShiftReportClassesProps> = ({ reportData }) => {
    const sortedData = useMemo(() => sortDataBasedOnKeys(reportData), [reportData]);

    //prepare bar chart data
    const barChartData: {
        graphData: {
            name: string;
            data: number[];
        }[];
        labels: string[];
    } = useMemo(() => prepareBarGraphData(sortedData), [sortedData]);

    return (
        <>
            <Space
                direction="vertical"
                style={{
                    width: "100%",
                }}
            >
                <Row gutter={[20, 20]}>
                    <Col md={24} lg={24} xs={24}>
                        <LocationContainer>
                            <TableTitle
                                style={{
                                    marginBottom: "15px",
                                }}
                            >{`Classes - All Locations`}</TableTitle>
                            <CashupReportBarChart data={barChartData} />
                        </LocationContainer>
                    </Col>
                </Row>
            </Space>
        </>
    );
};

export default ShiftReportClasses;
