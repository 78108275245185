import { Spin } from "antd";
import React from "react";

export const CentredSpinner = ({
    size,
    style,
}: {
    size?: "small" | "large" | "default";
    style?: any;
}) => {
    return (
        <div style={{ textAlign: "center", ...style, zIndex: 10 }}>
            <Spin size={size ?? "large"} />
        </div>
    );
};
