import React, { useState, useEffect, useMemo } from "react";
import { Row, Col, Space, Typography, Spin, Tooltip } from "antd";
import { FilePdfOutlined, FileTextOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import dayjs from "dayjs";
import { CentredSpinner } from "Components/Misc/Loading/CentredSpinner";
import CashupReportBarChart from "../CashupReportBarChart";
import CashupReportPieChart from "../CashupReportPieChart";
import { getCashupActualNetProfitGroupReport } from "../../../../ApiV2/Helpers/getActualNetProfitGroupReport";
import { ReportItem } from "@interfaces";
import { contactSupport } from "utils/utilities";
import {
    LocationContainer,
    TotalTableContainer,
    CustomTotalDataTable,
    TableTitle,
    CustomDataTable,
    DownloadLoadingContainer,
    GroupLoadingContainer,
} from "../CashupReportStyled";
import {
    getAllUniqueKeys,
    gamingGroupColumnGenerator,
    totalsForAllLocations,
    preparePosGroupBarGraphData,
    checkDateRange,
    downloadPdfReport,
    downloadCsvReport,
    prepareGamingGroupCsvData,
    calculateGamingActualNetProfitPercent,
} from "../../utils";

const { Title } = Typography;

interface GroupActualNetProfitReportProps {
    reportType: string;
    date: { start_date: string; end_date: string };
    group: string;
    groupName: string;
    areaName: string;
    calendarStatus: boolean;
}

const CashupGroupGamingActualNetProfitReport: React.FC<
    GroupActualNetProfitReportProps
> = ({ reportType, date, group, groupName, areaName, calendarStatus }) => {
    const [download, setDownload] = useState(false as boolean);
    const [sortedColumn, setSortedColumn] = useState<string>("");
    const [sortDirection, setSortDirection] = useState<"ascend" | "descend" | null>(
        null
    );

    //get report data
    const fetchReportData = async () => {
        if (!calendarStatus && !checkDateRange(date, 60)) {
            const response = await getCashupActualNetProfitGroupReport(
                date.start_date,
                date.end_date,
                group
            );
            return response.json();
        }
    };

    const {
        data: reportData = [],
        isFetching: reportDataLoading,
        error: reportError,
        refetch: refetchActualProfit,
    } = useQuery(["groupActualNetProfitReportData"], fetchReportData, {
        enabled:
            date.start_date.length > 0 &&
            date.end_date.length > 0 &&
            !checkDateRange(date, 60),
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        refetchActualProfit();
    }, [refetchActualProfit]);

    //extract unique keys from report data
    const allKeys: string[] = useMemo(() => {
        if (reportData.length === 0 || !reportData[0].data) {
            return [];
        }
        return getAllUniqueKeys(reportData[0].data) as string[];
    }, [reportData]);

    //prepare columns
    const columns = useMemo(
        () => gamingGroupColumnGenerator(allKeys, true, sortedColumn, sortDirection),
        [allKeys, sortedColumn, sortDirection]
    );

    //prepare totalcolumns
    const totalColumns = useMemo(
        () => gamingGroupColumnGenerator(allKeys, false, null, null),
        [allKeys]
    );

    //compute totals for each column
    const totals: Record<string, number> = useMemo(
        () => (reportData.length > 0 ? totalsForAllLocations(reportData) : {}),
        [reportData]
    );

    //prepare total row
    const totalRow: Record<string, number | string> = useMemo(
        () => ({
            venue: "Total",
            ...totals,
        }),
        [totals]
    );

    //prepare bar chart data
    const barChartData: {
        graphData: {
            name: string;
            data: number[];
        }[];
        labels: string[];
    } = useMemo(() => {
        if (reportData.length === 0 || !reportData[0].data) {
            return null;
        }
        return preparePosGroupBarGraphData(reportData);
    }, [reportData]);

    //prepare pie chart data
    const pieChartData: number[] = useMemo(() => {
        if (reportData.length === 0 || !reportData[0].data) {
            return [];
        }
        const profitArrList = reportData[0].data.map((item: any) => {
            return calculateGamingActualNetProfitPercent(item)[0];
        });
        return profitArrList;
    }, [reportData]);

    //handle sorting
    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setSortedColumn(sorter.columnKey);
        setSortDirection(sorter.order as "ascend" | "descend" | null);
    };

    const paginationConfig = {
        pageSize: 10,
        total:
            reportData.length > 0 && reportData[0].data
                ? reportData[0].data.length
                : 0,
        showSizeChanger: false,
        showQuickJumper: false,
    };

    //open quantaco support modal
    const supportClick = () => {
        contactSupport();
    };

    //download pdf report
    const onClick = async () => {
        setDownload(true);
        await new Promise((resolve) => setTimeout(resolve, 0));
        const reportName = `${areaName} ${reportType}`;
        await downloadPdfReport(
            reportName,
            "gaming-actual-net-profit-group-report",
            groupName,
            date,
            true
        );
        setDownload(false);
    };

    //download csv report
    const onCSVClick = async () => {
        setDownload(true);
        await new Promise((resolve) => setTimeout(resolve, 0));
        const csvData = prepareGamingGroupCsvData(reportData, totals);
        const reportName = `${areaName} ${reportType}`;
        downloadCsvReport(reportName, groupName, date, csvData, true);
        setDownload(false);
    };

    if (reportDataLoading) {
        return (
            <GroupLoadingContainer>
                <CentredSpinner size="large" />
            </GroupLoadingContainer>
        );
    }

    if (checkDateRange(date, 60)) {
        return (
            <Title level={5}>
                Cashup reports are limited to a 60-day date range. Please adjust your
                date range selection.
            </Title>
        );
    }

    if (reportError) {
        return (
            <Title level={5}>
                There was an error when trying to retrieve report data. Please
                contact <a onClick={supportClick}>Quantaco Support</a> for
                assistance.
            </Title>
        );
    }

    if (
        reportData.length === 0 ||
        !reportData[0]?.data ||
        reportData[0]?.data.length < 1
    ) {
        return (
            <Title level={5}>
                Cashup data is not available for the selected combination of filters.
                Please review and update your selection.
            </Title>
        );
    }

    return (
        <>
            <div
                id="gaming-actual-net-profit-group-report"
                style={{ backgroundColor: "#f5f5f5" }}
            >
                <Space
                    size="middle"
                    direction="vertical"
                    style={{
                        width: "100%",
                    }}
                >
                    <Row
                        gutter={[20, 2]}
                        justify="space-between"
                        align="middle"
                        className="hide-for-pdf"
                    >
                        <Col>
                            <Title level={4}>{`${areaName} - ${reportType}`}</Title>
                        </Col>
                        <Col>
                            <div style={{ display: "flex", gap: "15px" }}>
                                <Tooltip title="Download PDF">
                                    <FilePdfOutlined
                                        style={{
                                            fontSize: "28px",
                                            color: "#113469",
                                        }}
                                        onClick={onClick}
                                    />
                                </Tooltip>
                                <Tooltip title="Download CSV">
                                    <FileTextOutlined
                                        style={{
                                            fontSize: "28px",
                                            color: "#113469",
                                        }}
                                        onClick={onCSVClick}
                                    />
                                </Tooltip>
                            </div>
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col md={12} lg={12} xs={24}>
                            <CashupReportBarChart data={barChartData} />
                        </Col>
                        <Col md={12} lg={12} xs={24}>
                            <CashupReportPieChart
                                data={pieChartData}
                                labels={barChartData.labels}
                                chartType={"Actual Net/Clearance"}
                            />
                        </Col>
                    </Row>
                    <Row gutter={[20, 2]}>
                        <Col xs={24}>
                            <LocationContainer>
                                <TableTitle>{`Total (${dayjs(
                                    date.start_date,
                                    "DD-MM-YYYY"
                                ).format("DD/MM/YYYY")} - ${dayjs(
                                    date.end_date,
                                    "DD-MM-YYYY"
                                ).format("DD/MM/YYYY")})`}</TableTitle>
                                <TotalTableContainer>
                                    <CustomTotalDataTable
                                        rowKey="venue"
                                        dataSource={[totalRow]}
                                        columns={totalColumns}
                                        size="small"
                                        scroll={{ x: true }}
                                        tableLayout="fixed"
                                        pagination={false}
                                    />
                                </TotalTableContainer>
                            </LocationContainer>
                        </Col>
                    </Row>
                    {reportData.map((item: ReportItem) => (
                        <Row gutter={[20, 2]} key={`${item.location_type}`}>
                            <Col xs={24}>
                                <LocationContainer>
                                    <TableTitle>{`${item.location_type} - ${groupName}`}</TableTitle>
                                    <TotalTableContainer>
                                        <CustomDataTable
                                            rowKey="venue"
                                            dataSource={item.data}
                                            columns={columns}
                                            size="small"
                                            scroll={{ x: true }}
                                            tableLayout="fixed"
                                            pagination={
                                                download ? false : paginationConfig
                                            }
                                            onChange={handleTableChange}
                                        />
                                    </TotalTableContainer>
                                    <TotalTableContainer>
                                        <CustomTotalDataTable
                                            dataSource={[totalRow]}
                                            columns={totalColumns}
                                            size="small"
                                            scroll={{ x: true }}
                                            tableLayout="fixed"
                                            pagination={false}
                                        />
                                    </TotalTableContainer>
                                </LocationContainer>
                            </Col>
                        </Row>
                    ))}
                </Space>
            </div>
            {download && (
                <DownloadLoadingContainer>
                    <Spin tip="Generating Report... Please wait" />
                </DownloadLoadingContainer>
            )}
        </>
    );
};

export default CashupGroupGamingActualNetProfitReport;
