import React, { useState, useMemo } from "react";
import { ReportItem, ReportDynamicDataItem } from "@interfaces";
import {
    TotalTableContainer,
    CustomTotalDataTable,
    CustomDataTable,
} from "../CashupReportStyled";
import {
    sortDataByDate,
    bankableColumnGenerator,
    totalsForPayouts,
} from "../../utils";

interface BankableTableProps {
    bankableData: ReportItem;
    downloading?: boolean;
}

const BankableReportTable: React.FC<BankableTableProps> = ({
    bankableData,
    downloading,
}) => {
    const [sortedColumn, setSortedColumn] = useState<string>("");
    const [sortDirection, setSortDirection] = useState<"ascend" | "descend" | null>(
        null
    );

    //sort data by date
    const sortedData: ReportDynamicDataItem[] = useMemo(() => {
        const sortedCopy = [...bankableData.data];
        return bankableData.data.length > 0 ? sortDataByDate(sortedCopy) : [];
    }, [bankableData]);

    //prepare data columns
    const columns = useMemo(
        () => bankableColumnGenerator(true, sortedColumn, sortDirection),
        [sortedColumn, sortDirection]
    );

    //prepare total columns
    const totalColumns = useMemo(
        () => bankableColumnGenerator(false, null, null),
        []
    );

    //compute totals for each column
    const totals: Record<string, number> = useMemo(
        () => totalsForPayouts([bankableData]),
        [sortedData]
    );

    //prepare total row
    const totalRow: Record<string, number | string> = {
        date: "Total",
        ...totals,
    };

    //handle sorting
    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setSortedColumn(sorter.columnKey);
        setSortDirection(sorter.order as "ascend" | "descend" | null);
    };

    const paginationConfig = {
        pageSize: 10,
        total: sortedData.length,
        showSizeChanger: false,
        showQuickJumper: false,
    };

    return (
        <>
            <TotalTableContainer>
                <CustomDataTable
                    rowKey="key"
                    dataSource={sortedData}
                    columns={columns}
                    size="small"
                    scroll={{ x: true }}
                    tableLayout="fixed"
                    pagination={downloading ? false : paginationConfig}
                    onChange={handleTableChange}
                />
            </TotalTableContainer>
            <TotalTableContainer>
                <CustomTotalDataTable
                    rowKey="date"
                    columns={totalColumns}
                    dataSource={[totalRow]}
                    size="small"
                    scroll={{ x: true }}
                    tableLayout="fixed"
                    pagination={false}
                />
            </TotalTableContainer>
        </>
    );
};

export default BankableReportTable;
