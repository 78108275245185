import { ApiCall } from "../apiCall";

export async function getVenueGlAccountsById(venueId: string) {
    return await ApiCall(
        undefined,
        "AUTHENTICATED",
        "VENUES",
        "list",
        "GET",
        {},
        `/${venueId}/glaccounts`
    )
        .then((res) => res.json())
        .then((res) => res.data.glAccounts);
}
