import { ApiCall } from "../apiCall";

export const getCashupReport = async (
    reportType: string,
    venueId: string,
    startDate: string,
    endDate: string,
    location?: string
) => {
    let requestUrl = `/${reportType}?from_date=${startDate}&venue_id=${venueId}&to_date=${endDate}`;
    if (location && location.length > 0) {
        requestUrl += `&location_type=${location}`;
    }
    return await ApiCall(
        undefined,
        "AUTHENTICATED",
        "CASHUPREPORT",
        "list",
        "GET",
        {},
        requestUrl
    );
};
