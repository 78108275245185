import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { InitDataResponseType } from "Pages/CashupHome/CashupTabs/POS/@types";
import { getAuthTokens } from "utils/auth0/auth0API";
import { AppendContentTypeHeader } from "./GroupData/UsersAPI";

export const pusherAPI = createApi({
    reducerPath: "PusherAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_API_URL}`,
        prepareHeaders: async (headers) => {
            const data = await getAuthTokens();
            const access_token = data?.access_token;
            // TODO Implement a logging solution to emit no-token events.
            if (access_token) {
                headers.set("authorization", `Bearer ${access_token}`);
                AppendContentTypeHeader(headers);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getAppKey: builder.query<{ pusher_app_key: string }, null>({
            query: () => ({
                url: "/pusher/key",
                method: "GET",
                headers: { "Content-Type": "*/*" },
            }),
        }),
        sendPusherMessage: builder.mutation<
            {
                channels: string[];
                event_name: string;
                data: any;
            },
            any
        >({
            query(body) {
                return {
                    url: "/pusher",
                    method: "POST",
                    body,
                };
            },
        }),
        pusherSubscribe: builder.mutation<{ cashup_ids: string[] }, any>({
            query(body) {
                return {
                    url: "/pusher/subscribe",
                    method: "POST",
                    body,
                };
            },
        }),
        pushCashup: builder.mutation<InitDataResponseType, any>({
            query(body) {
                return {
                    url: "/pusher/cashup",
                    method: "PUT",
                    body,
                };
            },
        }),
    }),
});

export const {
    useGetAppKeyQuery,
    useSendPusherMessageMutation,
    usePusherSubscribeMutation,
    usePushCashupMutation,
} = pusherAPI;
