import { useLocation } from "react-router";

/**
 * Helper function to append query parameters to a route.
 * Skips any query params that are null
 *
 * @param route
 * @param params
 * @returns
 */
export const RouteStringWithParam = (
    route: string,
    params: { [key: string]: string | null }
): string => {
    let Route = route;

    for (const [key, value] of Object.entries(params)) {
        if (value) {
            Route = Route.concat(`?${key}=${value}`);
        }
    }
    return Route;
};

export function useQuery(): URLSearchParams {
    return new URLSearchParams(useLocation().search);
}
