import { AccountItemSplit } from "@generated";
import { AntDFormState } from "@types";
import { PaymentTransactionInit } from "Pages/CashupHome/CashupTabs/POS/@types";
import { Field } from "Pages/CashupHome/ExcelTable/TransactionForms/SalesCountModalFormV2";
import { initialPaymentSplitData } from "Pages/CashupHome/ExcelTable/TransactionsFormPOS";
import { useMemo } from "react";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import { ExtendedClassItem } from "Redux/StateSlices/GroupData/ClassesAPI";
import { extractGSTValue } from "utils/utilities";

interface UseTransactionPaymentProps {
    classesData?: ExtendedClassItem[];
    accountsData: ExtendedAccountItem[];
    PaymentTransactionData: PaymentTransactionInit[];
    isPaymentNegative?: boolean;
}

const accountSplitDataParser = (
    account_split: AccountItemSplit[] | undefined,
    tenderAccounts: ExtendedAccountItem[]
) => {
    let chequeNumber: string | undefined = undefined;

    if (account_split === undefined) return [];
    const result = account_split.map((currentTenderItem) => {
        if (currentTenderItem.cheque_no) {
            chequeNumber = currentTenderItem.cheque_no;
        }
        return {
            name: tenderAccounts.find(
                (localTenderItem) =>
                    localTenderItem.account_id === currentTenderItem.account_id
            )!.name,
            value: currentTenderItem.amount,
        };
    });
    if (chequeNumber !== undefined) {
        result.push({
            name: "cheque_no",
            value: chequeNumber,
        });
    }
    return result;
};

export const PaymentTransactionParser = (
    PaymentTransactions: PaymentTransactionInit[],
    tenderAccounts: ExtendedAccountItem[]
): AntDFormState[] => {
    const ParsedPaymentTransactions: AntDFormState[] = PaymentTransactions.map(
        (currentPaymentTransaction) => {
            return {
                formData: [
                    {
                        name: "GL Code",
                        value: currentPaymentTransaction.gl_account_id,
                    },
                    {
                        name: "Description",
                        value: currentPaymentTransaction.memo,
                    },
                    {
                        name: "Class",
                        value: currentPaymentTransaction.class_id,
                    },
                    {
                        name: "Amount",
                        value: currentPaymentTransaction.amount?.toString(),
                    },
                    {
                        name: "GST",
                        value:
                            currentPaymentTransaction.tax_amount?.toString() ??
                            extractGSTValue(
                                currentPaymentTransaction.amount
                            ).toString(),
                    },
                ],
                formID: Math.random().toString(),
                SplitData: accountSplitDataParser(
                    currentPaymentTransaction.account_split,
                    tenderAccounts
                ),
            };
        }
    );
    ParsedPaymentTransactions.push({
        formData: [],
        formID: Math.random().toString(),
        SplitData: initialPaymentSplitData(tenderAccounts),
    });
    return ParsedPaymentTransactions;
};

const useTransactionPayment = ({
    classesData = [],
    accountsData,
    PaymentTransactionData = [],
    isPaymentNegative = true,
}: UseTransactionPaymentProps) => {
    const paymentFields: Field[] = [
        {
            name: "GL Code",
            required: true,
            type: {
                name: "DropdownOrNull",
            },
            colSpan: 8,
        },
        {
            name: "Class",
            type: {
                name: "Dropdown",
                options: classesData.map((currentClass) => ({
                    label: currentClass.name,
                    value: currentClass.class_id,
                })),
            },
            colSpan: 4,
        },
        {
            name: "Description",
            required: true,
            type: {
                name: "Text",
            },
            colSpan: 6,
        },
        {
            name: "Amount",
            required: true,
            type: { name: "Money", positiveValueOnly: !isPaymentNegative },
            colSpan: 4,
            changeGST: true,
        },
        {
            name: "GST",
            type: { name: "GST" },
            colSpan: 3,
        },
        {
            name: "Tender split",
            type: {
                name: "Modal",
            },
            colSpan: 4,
        },
        { name: "Button", type: { name: "Button" }, colSpan: 2, isButton: true },
    ];

    const tenderAccounts = useMemo(
        () =>
            accountsData.filter(
                (currentAccountItem) => currentAccountItem.tender_type
            ),
        [accountsData]
    );

    const initialPaymentTransactionsDataWithAppendedFormID: AntDFormState[] =
        useMemo(() => {
            if (PaymentTransactionData.length === 0)
                return [
                    {
                        formData: [],
                        formID: Math.random().toString(),
                        SplitData: initialPaymentSplitData(tenderAccounts),
                    },
                ];

            return PaymentTransactionParser(PaymentTransactionData, tenderAccounts);
        }, []);

    return {
        paymentFields,
        initialPaymentTransactionsDataWithAppendedFormID,
        tenderAccounts,
    };
};

export default useTransactionPayment;
