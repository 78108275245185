import React, { useCallback, useEffect, useRef, useState } from "react";
import { Result, Row } from "antd";
import { DraggableModal } from "Components/DraggableModal";
import { ModalContextProps, ModalOptions } from "hooks/useModal";
// A modal context to display a modal that can be customised
const Modal = React.forwardRef<ModalContextProps, {}>((props, ref) => {
    // State to control the visibility of the modal
    const [isVisible, setIsVisible] = useState<boolean>(false);
    const [title, setTitle] = useState<React.ReactNode | string>("");
    const [options, setOptions] = useState<ModalOptions | null>(null);
    const [onClose, setOnclose] = useState<CallableFunction | null>(null);
    const timer = useRef<NodeJS.Timeout | null>(null);

    if (ref) {
        //@ts-ignore
        ref.current = {
            openModal: (
                newTitle: React.ReactNode | string,
                options: ModalOptions
            ) => {
                setIsVisible(true);
                setTitle(newTitle);
                setOptions(options);
            },
            closeModal: (callback?: () => void) => {
                setIsVisible(false);
                callback && setOnclose(callback);
            },
        };
    }

    useEffect(() => {
        if (options?.timed || options?.timeout) {
            timer.current = setTimeout(() => {
                setIsVisible(false);
            }, options?.timeout || 3000);
        }
    }, [options]);
    const handleClose = useCallback(() => {
        if (timer.current) {
            clearTimeout(timer.current);
        }
        if (onClose) {
            onClose();
        }
    }, []);

    return (
        <DraggableModal
            title=""
            open={isVisible}
            onCancel={() => setIsVisible(false)}
            onOk={() => setIsVisible(false)}
            destroyOnClose
            style={{ top: 30, borderRadius: "12px" }}
            bodyStyle={{ padding: "0px 24px 20px 24px" }}
            zIndex={100000}
            cancelButtonProps={{ style: { display: "none" } }}
            footer={null}
            noBottomBorder
            width={"80%"}
            afterClose={handleClose}
        >
            <Result
                status={options?.status}
                title={title}
                extra={
                    <Row style={{ justifyContent: "center" }}>
                        {options?.buttons.map((button, index) => {
                            if (index === 0) return <div key={index}>{button}</div>;
                            else
                                return (
                                    <div key={index} style={{ marginLeft: 8 }}>
                                        {button}
                                    </div>
                                );
                        })}
                    </Row>
                }
            />
        </DraggableModal>
    );
});
Modal.displayName = "Modal";
export default Modal;
