import { Button } from "antd";

import React, { useEffect, useMemo } from "react";
import { fetchWrapper } from "../../API";
import dayjs from "dayjs";
import { useMutation, useQuery } from "react-query";
import {
    MODAL_GENERIC_ERROR,
    MODAL_GENERIC_SUCCESS,
    MODAL_GENERIC_WARNING,
} from "../../Pages/CashupHome/ExcelTable/utils";
import { useContextModal } from "../../hooks/useModal";
import { StyledStatistic, SubmitSection } from "../StyledComponents";
import styled from "styled-components";
import DownloadJournalButton from "./DownloadJournalButton";
import { getLoggedInUser } from "../../ApiV2/Helpers/getLoggedInUser";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { QUANTACO_ORANGE } from "../../@Constants/theme/constants";
import { SubmissionStatus } from "Redux/StateSlices/GroupData/VenuesAPI";

const StyledButton = styled(Button)`
    margin-right: 20px;
    font-weight: bold;
    font-size: 16px;
    line-height: 16px;
`;

const SubmitButton = styled(StyledButton)<{
    backgroundcolor: string;
    bordcolor: string;
}>`
    background-color: ${(props) => props.backgroundcolor};
    border: 2px solid ${(props) => props.bordcolor};
    width: 110px;
    box-shadow: none;
`;
const SubmitApproveSection: React.FC<{
    submitted_by: string;
    status: string;
    approved_by: string;
    selectedVenueId: string;
    selectedDate: string;
}> = ({ status, selectedVenueId, selectedDate }) => {
    const [token] = React.useState<string | null>(
        localStorage.getItem("access_token")
    );
    const { setShiftStatus, shiftStatus: stat } = useVenueSelection();
    const { data: activeUser } = useQuery(["activeUser", token], () =>
        getLoggedInUser()
    );
    const capitalizedStatus = useMemo(() => {
        return stat.charAt(0).toUpperCase() + stat.slice(1).toLowerCase();
    }, [stat]);
    useEffect(() => {
        console.log("");
    }, [status]);
    const { openModal, closeModal } = useContextModal();

    const { mutate: submitTable } = useMutation(
        async () =>
            await fetchWrapper(
                `/api/shift-dates/${dayjs(selectedDate, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                )}/${selectedVenueId}/submit/`,
                "POST",
                JSON.stringify({ confirm_submission: "True" })
            ),
        {
            onSuccess: (response) => {
                if (response.ok) {
                    MODAL_GENERIC_SUCCESS(
                        openModal,
                        closeModal,
                        "Cashup submitted successfully"
                    );
                    setShiftStatus(SubmissionStatus.SUBMITTED);
                } else {
                    MODAL_GENERIC_ERROR(
                        openModal,
                        closeModal,
                        submitTable,
                        "Cashup submission failed, try again?"
                    );
                }
            },
        }
    );

    const { mutate: unsubmitTable } = useMutation(
        async () =>
            await fetchWrapper(
                `/api/shift-dates/${dayjs(selectedDate, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                )}/${selectedVenueId}/unsubmit/`,
                "POST"
            ),
        {
            onSuccess: async (response) => {
                if (response.ok) {
                    MODAL_GENERIC_SUCCESS(
                        openModal,
                        closeModal,
                        "Cashup unsubmitted successfully"
                    );
                    setShiftStatus(SubmissionStatus.UNSUBMITTED);
                } else {
                    MODAL_GENERIC_ERROR(
                        openModal,
                        closeModal,
                        unsubmitTable,
                        "Cashup unsubmission failed, try again?"
                    );
                }
            },
        }
    );
    const { mutate: approveTable } = useMutation(
        async () =>
            await fetchWrapper(
                `/api/shift-dates/${dayjs(selectedDate, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                )}/${selectedVenueId}/approve/`,
                "POST"
            ),
        {
            onSuccess: async (response) => {
                if (response.ok) {
                    MODAL_GENERIC_SUCCESS(
                        openModal,
                        closeModal,
                        "Cashup approved successfully"
                    );
                    setShiftStatus(SubmissionStatus.APPROVED);
                } else {
                    MODAL_GENERIC_ERROR(
                        openModal,
                        closeModal,
                        approveTable,
                        "Cashup approve failed, try again?"
                    );
                }
            },
        }
    );

    const { mutate: unapproveTable } = useMutation(
        async () =>
            fetchWrapper(
                `/api/shift-dates/${dayjs(selectedDate, "DD-MM-YYYY").format(
                    "YYYY-MM-DD"
                )}/${selectedVenueId}/unapprove/`,
                "POST"
            ),
        {
            onSuccess: async (response) => {
                if (response.ok) {
                    MODAL_GENERIC_SUCCESS(
                        openModal,
                        closeModal,
                        "Cashup unapproved successfully"
                    );
                    setShiftStatus(SubmissionStatus.UNSUBMITTED);
                } else {
                    MODAL_GENERIC_ERROR(
                        openModal,
                        closeModal,
                        unapproveTable,
                        "Cashup unapprove failed, try again?"
                    );
                }
            },
        }
    );

    const onClickSubmit = () =>
        MODAL_GENERIC_WARNING(
            openModal,
            closeModal,
            <div>
                Please ensure you have inspected all outstanding variances in the
                Review Tab, before submitting your Cashup.
                <br />
                Click continue to submit.
            </div>,
            submitTable
        );
    const onClickApprove = () =>
        MODAL_GENERIC_WARNING(
            openModal,
            closeModal,
            <div>
                Please ensure that all information on this Cashup is complete and
                accurate.
                <br />
                The Review Tab can assist you in locating variances.
                <br />
                Click continue to Approve
            </div>,
            approveTable
        );

    return (
        <SubmitSection>
            <StyledStatistic
                title="Status"
                value={capitalizedStatus}
                valueStyle={{ fontSize: "14px" }}
            />

            {capitalizedStatus === "Unsubmitted" && (
                <>
                    <SubmitButton
                        backgroundcolor="#00225D"
                        bordcolor="#000"
                        size="large"
                        type="primary"
                        onClick={onClickSubmit}
                    >
                        Submit
                    </SubmitButton>
                    <SubmitButton
                        backgroundcolor={QUANTACO_ORANGE}
                        disabled={true}
                        bordcolor="#fff"
                        size="large"
                        type="primary"
                    >
                        Approve
                    </SubmitButton>
                </>
            )}
            {capitalizedStatus === "Submitted" && (
                <>
                    <SubmitButton
                        backgroundcolor="#00225D"
                        bordcolor="#000"
                        size="large"
                        type="primary"
                        disabled={false}
                        onClick={() => unsubmitTable()}
                    >
                        Unsubmit
                    </SubmitButton>
                    <SubmitButton
                        backgroundcolor={QUANTACO_ORANGE}
                        disabled={activeUser?.role === "EMPLOYEE"}
                        bordcolor={QUANTACO_ORANGE}
                        size="large"
                        type="primary"
                        onClick={onClickApprove}
                    >
                        Approve
                    </SubmitButton>
                </>
            )}
            {capitalizedStatus === "Approved" && (
                <>
                    <SubmitButton
                        backgroundcolor="#00225D"
                        bordcolor="#000"
                        size="large"
                        type="primary"
                        disabled={true}
                    >
                        Unsubmit
                    </SubmitButton>
                    <SubmitButton
                        backgroundcolor={QUANTACO_ORANGE}
                        disabled={
                            activeUser?.role !== "SUPER_ADMIN" &&
                            activeUser?.role !== "QUANTACO_ADMIN"
                        }
                        bordcolor={QUANTACO_ORANGE}
                        size="large"
                        type="primary"
                        onClick={() => unapproveTable()}
                    >
                        Unapprove
                    </SubmitButton>
                </>
            )}
            {(activeUser?.role === "SUPER_ADMIN" ||
                activeUser?.role === "QUANTACO_ADMIN") && <DownloadJournalButton />}
        </SubmitSection>
    );
};
export default SubmitApproveSection;
