import { DebouncedFunc } from "lodash";
import { useState, useEffect, useMemo } from "react";

export function getWindowDimensions(): { width: number; height: number } {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export function useWindowDimensions({
    fetchDimensions,
}: {
    fetchDimensions: DebouncedFunc<() => { width: number; height: number }>;
}) {
    const [windowDimensions, setWindowDimensions] = useState<{
        width: number;
        height: number;
    }>({ width: 1080, height: 1920 });

    useEffect(() => {
        setWindowDimensions(getWindowDimensions());
    }, []);

    useEffect(() => {
        function handleResize() {
            const result = fetchDimensions();
            if (result) setWindowDimensions(result);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [fetchDimensions]);

    return useMemo(() => windowDimensions, [windowDimensions]);
}
