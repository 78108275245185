import { PanelHOCComponentProps } from "../../Pages/CashupHome/CashupTabs/POS/utils/PanelHOC";
import React, { FC } from "react";
import { StyledTable } from "../../V2/Table/Table.style";
import { TableBodyForSafe, TableRows } from "./TableBodyForSafe";
import { TableFooter } from "../../V2/Table/TableFooter";
import { TableConfigs, TableHeader } from "../../V2/Table/TableHeader";
import { Maybe } from "types";

interface Props extends PanelHOCComponentProps {
    onCellValueChanged?: (params: {
        row: number;
        propertyPath: string;
        updatedValue: number;
    }) => void;
    dataSources: TableRows[];
    tableConfigs: TableConfigs;
    onSelectedCellChange?: (params: Maybe<{ row: number; col: number }>) => void;
    evenDistribution?: boolean;
}

export const TableForSafe: FC<Props> = ({
    onCellValueChanged,
    dataSources,
    tableConfigs,
    isInFocus,
    onClickHandler,
    onSelectedCellChange,
    evenDistribution,
}) => (
    <StyledTable>
        <TableHeader
            tableConfigs={tableConfigs}
            evenDistribution={evenDistribution}
        />
        <TableBodyForSafe
            onCellValueChanged={onCellValueChanged}
            tableConfigs={tableConfigs}
            dataSources={dataSources}
            onClickHandler={onClickHandler}
            isInFocus={isInFocus}
            onSelectedCellChange={onSelectedCellChange}
            evenDistribution={evenDistribution}
        />
        <TableFooter
            tableConfigs={tableConfigs}
            dataSources={dataSources}
            evenDistribution={evenDistribution}
        />
    </StyledTable>
);
