import React, { memo, useMemo, useState } from "react";

import { Button } from "antd";
import { DeleteOutlined, SaveOutlined } from "@ant-design/icons";
import styled from "styled-components";
import { EmptyDiv, StyledContainer, StyledTextArea } from "../StyledComponents";

const StyledButton = styled(Button)<{ hide: boolean }>`
     {
        ${(props) => (props.hide ? `opacity:1;` : "opacity:0;")}
    }
`;

const StyledButtonContainer = styled.div`
    position: absolute;
    bottom: -20px;
    right: 12px;
`;

const CommentSectionComponent = ({
    onFocus,
    onChange,
    comments,
    tableLength,
    onCommentClear,
    onCommentSubmit,
}: {
    onFocus: () => void;
    onChange: (value: string, index: number) => void;
    comments: {
        content: string;
        mentions: string[];
        disabled?: boolean;
    }[];
    tableLength: number;
    onCommentClear: (index: number) => void;
    onCommentSubmit: (index: number) => void;
}) => {
    const emptyArray = useMemo(() => {
        const tempArray = new Array(tableLength + 1);
        tempArray.fill(0);
        return tempArray;
    }, [tableLength]);

    const initialArry = useMemo(() => {
        const result = new Array(tableLength);
        result.fill(false);
        return result;
    }, [tableLength]);
    const [inputFocused, setInputFocused] = useState<Array<boolean>>(initialArry);
    return (
        <div
            style={{
                width: "100%",
                marginTop: 20,
                marginBottom: 20,
                display: "flex",
                minWidth: "1200px",
            }}
        >
            {emptyArray.map((item, key) => {
                if (key === 0)
                    return (
                        <EmptyDiv numberofitems={emptyArray.length} key={key}>
                            Comments
                        </EmptyDiv>
                    );
                return (
                    <StyledContainer numberofitems={emptyArray.length} key={key}>
                        <StyledTextArea
                            numberofitems={emptyArray.length}
                            key={key}
                            autoSize
                            onFocus={onFocus}
                            disabled={comments[key - 1]?.disabled}
                            value={
                                comments[key - 1] ? comments[key - 1].content : ""
                            }
                            onChange={(e) => {
                                onChange(e.target.value, key - 1);
                            }}
                            onFocusCapture={() => {
                                setInputFocused((prev) =>
                                    prev.map((item, i) =>
                                        i == key - 1 ? true : item
                                    )
                                );
                            }}
                            onBlur={() => {
                                onCommentSubmit(key - 1);
                                setInputFocused((prev) =>
                                    prev.map((item, i) =>
                                        i == key - 1 ? false : item
                                    )
                                );
                            }}
                            style={{ minHeight: "64px" }}
                        />
                        <StyledButtonContainer>
                            <StyledButton
                                hide={inputFocused[key - 1]}
                                icon={<SaveOutlined />}
                                size="small"
                                style={{
                                    backgroundColor: `#1a81c7`,
                                    color: "white",
                                }}
                                onClick={() => {
                                    setInputFocused((prev) =>
                                        prev.map((item, i) =>
                                            i == key - 1 ? false : item
                                        )
                                    );
                                    onCommentSubmit(key - 1);
                                }}
                            />
                            <StyledButton
                                hide={inputFocused[key - 1]}
                                icon={<DeleteOutlined />}
                                size="small"
                                style={{
                                    backgroundColor: `#1a81c7`,
                                    color: "white",
                                    marginLeft: "10px",
                                }}
                                onClick={() => {
                                    setInputFocused((prev) =>
                                        prev.map((item, i) =>
                                            i == key - 1 ? false : item
                                        )
                                    );
                                    onCommentClear(key - 1);
                                }}
                            />
                        </StyledButtonContainer>
                    </StyledContainer>
                );
            })}
        </div>
    );
};
export const CommentSection = memo(CommentSectionComponent);
