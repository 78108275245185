import { LocationType } from "Pages/CashupReport/CashupReportModel";
import { ApiCall } from "../apiCall";

export async function getLocationsQuery(
    locationType: LocationType | LocationType[],
    venueId: string,
    formattedDate: string
) {
    return await ApiCall(
        undefined,
        "AUTHENTICATED",
        "LOCATIONS",
        "list",
        "GET",
        {},
        `?venue_id=${venueId}&shift_date=${formattedDate}&location_types=${locationType}`
    )
        .then((res) => res.json())
        .then((res) => res.data.locations);
}
