import { useEffect, useState } from "react";

const DEFAULT_INTERVAL_PERIOD = 250;

const getWidget = () => document.getElementById("jsd-widget");

const useToggleWidget = (
    shouldBeVisible: boolean,
    intervalPeriod = DEFAULT_INTERVAL_PERIOD
) => {
    const [hasToggled, setHasToggled] = useState(false);

    useEffect(() => {
        if (hasToggled) return;

        const timeoutId = setTimeout(() => {
            const widget = getWidget();
            if (widget) {
                const expectedDisplay = shouldBeVisible ? "1" : "0";
                if (widget.style.opacity !== expectedDisplay) {
                    widget.style.opacity = expectedDisplay;
                    setHasToggled(true);
                }
            }
        }, intervalPeriod);

        return () => clearTimeout(timeoutId);
    }, [shouldBeVisible, intervalPeriod, hasToggled]);
};

export const useBegoneWidget = (intervalPeriod = DEFAULT_INTERVAL_PERIOD) => {
    useToggleWidget(false, intervalPeriod);
};

export const useReturnWidget = (intervalPeriod = DEFAULT_INTERVAL_PERIOD) => {
    useToggleWidget(true, intervalPeriod);
};
