import React from "react";
import {
    ShiftReportPromptsItem,
    ShiftReportPromptsAnswerItem,
} from "@interfaces/index";
import { Space, Row, Col } from "antd";
import ShiftPromptsItem from "./ShiftPromptsItem";
import { LocationContainer, TableTitle } from "../CashupReportStyled";

interface ShiftPromptsProps {
    promptsData: ShiftReportPromptsItem[];
}

const ShiftPrompts: React.FC<ShiftPromptsProps> = ({ promptsData }) => {
    return (
        <>
            <Space
                size="middle"
                direction="vertical"
                style={{
                    width: "100%",
                }}
            >
                {promptsData.map((item: ShiftReportPromptsItem) => (
                    <div key={`${item.category}`}>
                        {item.data.length > 0 ? (
                            <Row gutter={[20, 2]} key={`${item.category}`}>
                                <Col xs={24}>
                                    <LocationContainer>
                                        <TableTitle
                                            style={{
                                                marginBottom: "15px",
                                            }}
                                        >
                                            {item.category}
                                        </TableTitle>
                                        {item.data.map(
                                            (
                                                promptItem: ShiftReportPromptsAnswerItem
                                            ) => (
                                                <div
                                                    key={`${item.category}-${promptItem.prompt}`}
                                                    style={{
                                                        marginBottom: "15px",
                                                    }}
                                                >
                                                    <ShiftPromptsItem
                                                        key={`${item.category}-${promptItem.prompt}`}
                                                        promptsItemData={promptItem}
                                                    />
                                                </div>
                                            )
                                        )}
                                    </LocationContainer>
                                </Col>
                            </Row>
                        ) : null}
                    </div>
                ))}
            </Space>
        </>
    );
};

export default ShiftPrompts;
