// New Detailed Report Queries
import { gql, useQuery } from "@apollo/client";
import { GraphQLClient } from "graphql-request";
import { LocationType } from "Pages/CashupReport/CashupReportModel";
import { useEffect, useMemo, useState } from "react";

// `Point of Sales - Split by Class Type - All POS Locations`
const POS_SPLIT_BY_CLASS_TYPE = gql`
    query CubeQuery($startDate: String!, $endDate: String!, $venueId: String!) {
        cube(
            where: {
                location: { locationType: { equals: 1 } }
                account: { salesAccount: { equals: "true" } }
                splits: {
                    venueId: { equals: $venueId }
                    splitDate: { inDateRange: [$startDate, $endDate] }
                }
            }
        ) {
            splits(orderBy: { splitDate: asc }) {
                sumTotalIncTax
                splitDate {
                    day
                }
            }
            account {
                name
            }
            class {
                classType
                name
            }
        }
    }
`;

// Point of Sales - Split by Class Type For a specific Parent location
const POINT_OF_SALE_SPLIT_BY_CLASS_TYPE = gql`
    query CubeQuery(
        $startDate: String!
        $endDate: String!
        $parentLocationId: String!
        $venueId: String!
    ) {
        cube(
            where: {
                location: { parentId: { equals: $parentLocationId } }
                account: { salesAccount: { equals: "true" } }
                splits: {
                    venueId: { equals: $venueId }
                    splitDate: { inDateRange: [$startDate, $endDate] }
                }
            }
        ) {
            splits(orderBy: { splitDate: asc }) {
                sumTotalIncTax
                splitDate {
                    day
                }
            }
            account {
                name
            }
            class {
                classType
                name
            }
        }
    }
`;

type CashForParentLocationData = {
    cube: Array<{
        splits: {
            sumTotalIncTax: number;
            splitDate: {
                day: string;
            };
        };
    }>;
};

// Cash - by parent location
const CASH_BY_PARENT_LOCATION = gql`
    query CubeQuery(
        $startDate: String!
        $endDate: String!
        $parentLocationId: String!
        $venueId: String!
    ) {
        cube(
            where: {
                location: {
                    parentId: { equals: $parentLocationId }
                    locationType: { equals: 1 }
                }
                account: { cashAccount: { equals: "true" } }
                splits: {
                    venueId: { equals: $venueId }
                    splitDate: { inDateRange: [$startDate, $endDate] }
                }
                transactions: { transactionType: { equals: 1 } }
            }
        ) {
            splits(orderBy: { splitDate: asc }) {
                sumTotalIncTax
                splitDate {
                    day
                }
            }
        }
    }
`;

// Cash - Totals for all POS Locations
const CASH_TOTALS_FOR_ALL_POS_LOCATIONS = gql`
    query CubeQuery($startDate: String!, $endDate: String!, $venueId: String!) {
        cube(
            where: {
                account: { cashAccount: { equals: "true" } }
                splits: {
                    venueId: { equals: $venueId }
                    splitDate: { inDateRange: [$startDate, $endDate] }
                }
                location: { locationType: { equals: 1 } }
                transactions: { transactionType: { equals: 1 } }
            }
        ) {
            splits(orderBy: { splitDate: asc }) {
                sumTotalIncTax
                splitDate {
                    day
                }
            }
        }
    }
`;

// Card - Total for POS
const CARD_TOTALS_FOR_POS = gql`
    query CubeQuery($startDate: String!, $endDate: String!, $venueId: String!) {
        cube(
            where: {
                account: { cardAccount: { equals: "true" } }
                splits: {
                    venueId: { equals: $venueId }
                    splitDate: { inDateRange: [$startDate, $endDate] }
                }
                location: { locationType: { equals: 1 } }
                transactions: { transactionType: { equals: 1 } }
            }
        ) {
            splits(orderBy: { splitDate: asc }) {
                sumTotalIncTax
                splitDate {
                    day
                }
            }
        }
    }
`;

type CardForParentLocationData = {
    cube: Array<{
        splits: {
            sumTotalIncTax: number;
            splitDate: {
                day: string;
            };
        };
    }>;
};

// Card - for Parent Location
const CARD_TOTALS_FOR_PARENT_LOCATION = gql`
    query CubeQuery(
        $startDate: String!
        $endDate: String!
        $parentLocationId: String!
        $venueId: String!
    ) {
        cube(
            where: {
                location: {
                    parentId: { equals: $parentLocationId }
                    locationType: { equals: 1 }
                }
                account: { cardAccount: { equals: "true" } }
                splits: {
                    venueId: { equals: $venueId }
                    splitDate: { inDateRange: [$startDate, $endDate] }
                }
                transactions: { transactionType: { equals: 1 } }
            }
        ) {
            splits(orderBy: { splitDate: asc }) {
                sumTotalIncTax
                splitDate {
                    day
                }
            }
        }
    }
`;

// Cash Variance - All POS Locations
const CASH_VARIANCE_FOR_ALL_POS_LOCATIONS = gql`
    query CubeQuery($startDate: String!, $endDate: String!, $venueId: String!) {
        cube(
            where: {
                account: { cashAccount: { equals: "true" } }
                transactions: { transactionType: { equals: 4 } }
                location: { locationType: { equals: 1 } }
                splits: {
                    splitDate: { inDateRange: [$startDate, $endDate] }
                    venueId: { equals: $venueId }
                }
            }
        ) {
            splits(orderBy: { splitDate: asc }) {
                sumTotalIncTax
                splitDate {
                    day
                }
            }
        }
    }
`;

type CashVarianceForParentLocationData = {
    cube: Array<{
        splits: {
            sumTotalIncTax: number;
            splitDate: {
                day: string;
            };
        };
    }>;
};
// Cash Variance - Parent Location
const CASH_VARIANCE_FOR_PARENT_LOCATION = gql`
    query CubeQuery(
        $startDate: String!
        $endDate: String!
        $parentLocationId: String!
        $venueId: String!
    ) {
        cube(
            where: {
                account: { cashAccount: { equals: "true" } }
                location: { parentId: { equals: $parentLocationId } }
                transactions: { transactionType: { equals: 4 } }
                splits: {
                    splitDate: { inDateRange: [$startDate, $endDate] }
                    venueId: { equals: $venueId }
                }
            }
        ) {
            splits(orderBy: { splitDate: asc }) {
                sumTotalIncTax
                splitDate {
                    day
                }
            }
        }
    }
`;

// Petty Cash
const PETTY_CASH = gql`
    query CubeQuery($startDate: String!, $endDate: String!, $venueId: String!) {
        cube(
            where: {
                transactions: { paymentType: { equals: 2 } }
                account: { tenderType: { equals: "false" } }
                splits: {
                    splitDate: { inDateRange: [$startDate, $endDate] }
                    venueId: { equals: $venueId }
                }
            }
        ) {
            splits(orderBy: { totalIncTax: asc }) {
                splitId
                totalIncTax
                splitDate {
                    day
                }
            }
            transactions {
                memo
            }
        }
    }
`;

// Deposits
const DEPOSITS = gql`
    query CubeQuery($startDate: String!, $endDate: String!, $venueId: String!) {
        cube(
            where: {
                transactions: { transactionType: { equals: 5 } }
                account: { tenderType: { equals: "true" } }
                splits: {
                    splitDate: { inDateRange: [$startDate, $endDate] }
                    venueId: { equals: $venueId }
                }
            }
        ) {
            splits(orderBy: { totalIncTax: asc }) {
                totalIncTax
                splitDate {
                    day
                }
            }
            transactions {
                memo
                paymentType
                transactionType
            }
            account {
                name
            }
            class {
                name
            }
        }
    }
`;

// Transfers
const TRANSFERS = gql`
    query CubeQuery($startDate: String!, $endDate: String!, $venueId: String!) {
        cube(
            where: {
                transactions: { transactionType: { equals: 3 } }
                splits: {
                    splitDate: { inDateRange: [$startDate, $endDate] }
                    venueId: { equals: $venueId }
                }
            }
        ) {
            splits(orderBy: { splitDate: asc }) {
                splitDate {
                    day
                }
                totalIncTax
            }
            transactions {
                memo
                id
                paymentType
                transactionType
            }
        }
    }
`;

// Gaming Stats
const GAMING_STATS = gql`
    query CubeQuery($startDate: String!, $endDate: String!, $venueId: String!) {
        cube(
            where: {
                gamingStats: {
                    date: { inDateRange: [$startDate, $endDate] }
                    venueId: { equals: $venueId }
                }
            }
        ) {
            gamingStats(orderBy: { date: asc }) {
                date {
                    value
                    day
                }
                turnover
                totalWins
                netProfit
                returnToHouse
            }
        }
    }
`;

// Gaming Variances
const GAMING_VARIANCES = gql`
    query CubeQuery($startDate: String!, $endDate: String!, $venueId: String!) {
        cube(
            where: {
                account: { cashAccount: { equals: "true" } }
                location: { locationType: { equals: 2 } }
                transactions: { transactionType: { equals: 4 } }
                splits: {
                    splitDate: { inDateRange: [$startDate, $endDate] }
                    venueId: { equals: $venueId }
                }
            }
        ) {
            splits(orderBy: { splitDate: asc }) {
                sumTotalIncTax
                splitDate {
                    day
                }
            }
        }
    }
`;

// Gaming Payouts
const GAMING_PAYOUTS = gql`
    query CubeQuery($startDate: String!, $endDate: String!, $venueId: String!) {
        cube(
            where: {
                transactions: { paymentType: { equals: 3 } }
                location: { locationType: { equals: 2 } }
                account: { payoutTenderType: { equals: "true" } }
                splits: {
                    splitDate: { inDateRange: [$startDate, $endDate] }
                    venueId: { equals: $venueId }
                }
            }
        ) {
            splits(orderBy: { splitDate: asc }) {
                sumTotalIncTax
                splitDate {
                    day
                }
            }
        }
    }
`;

const SAFE_QUERY = gql`
    query CubeQuery($startDate: String!, $endDate: String!, $venueId: String!) {
        cube(
            where: {
                location: { locationType: { equals: 6 } }
                cashup: { shiftDate: { inDateRange: [$startDate, $endDate] } }
                venue: { venueId: { equals: $venueId } }
            }
        ) {
            cashup(orderBy: { cashupId: asc }) {
                cashupId
                shiftDate {
                    day
                }
                safeDataTotal
                safeDataVariance
                safeSignedOff
            }
            location {
                name
                locationType
            }
        }
    }
`;

const ATM_QUERY = gql`
    query CubeQuery($startDate: String!, $endDate: String!, $venueId: String!) {
        cube(
            where: {
                location: { locationType: { equals: 8 } }
                cashup: { shiftDate: { inDateRange: [$startDate, $endDate] } }
                venue: { venueId: { equals: $venueId } }
            }
        ) {
            cashup(orderBy: { cashupId: asc }) {
                shiftDate {
                    day
                }
                cashCountCashVariance
                atmDataRefills
                atmDataWithdrawls
            }
            location {
                name
                locationType
            }
        }
    }
`;
const CRT_QUERY = gql`
    query CubeQuery($startDate: String!, $endDate: String!, $venueId: String!) {
        cube(
            where: {
                location: { locationType: { equals: 10 } }
                cashup: { shiftDate: { inDateRange: [$startDate, $endDate] } }
                venue: { venueId: { equals: $venueId } }
            }
        ) {
            cashup(orderBy: { cashupId: asc }) {
                shiftDate {
                    day
                }
                cashCountCashVariance
                atmDataRefills
                atmDataWithdrawls
            }
            location {
                name
                locationType
            }
        }
    }
`;

const WAGERING_QUERY = gql`
    query CubeQuery($startDate: String!, $endDate: String!, $venueId: String!) {
        cube(
            where: {
                location: { locationType: { in: [3, 9] } }
                cashup: { shiftDate: { inDateRange: [$startDate, $endDate] } }
                venue: { venueId: { equals: $venueId } }
            }
        ) {
            cashup(orderBy: { cashupId: asc }) {
                shiftDate {
                    day
                }
                wageringDataSalesTotal
                wageringDataCommission
                cashCountCashVariance
            }
            location {
                name
                locationType
            }
        }
    }
`;

const WAGERING_PAYOUTS_QUERY = gql`
    query CubeQuery($startDate: String!, $endDate: String!, $venueId: String!) {
        cube(
            where: {
                transactions: { paymentType: { equals: 3 } }
                location: { locationType: { in: [3, 9] } }
                account: { payoutTenderType: { equals: "true" } }
                splits: {
                    splitDate: { inDateRange: [$startDate, $endDate] }
                    venueId: { equals: $venueId }
                }
            }
        ) {
            splits(orderBy: { splitDate: asc }) {
                sumTotalIncTax
                splitDate {
                    day
                }
            }
            location {
                name
                locationType
            }
        }
    }
`;

const BANKABLE_TRADE_TILL_QUERY = gql`
    query CubeQuery($startDate: String!, $endDate: String!, $venueId: String!) {
        cube(
            where: {
                location: { locationType: { equals: 4 } }
                cashup: { shiftDate: { inDateRange: [$startDate, $endDate] } }
                venue: { venueId: { equals: $venueId } }
            }
        ) {
            cashup(orderBy: { cashupId: asc }) {
                shiftDate {
                    day
                }
                cashCountExpectedCash
                cashCountCashVariance
            }
            location {
                name
                locationType
            }
        }
    }
`;

const BANKABLE_TAB_TRADE_TILL_QUERY = gql`
    query CubeQuery($startDate: String!, $endDate: String!, $venueId: String!) {
        cube(
            where: {
                location: { locationType: { equals: 12 } }
                cashup: { shiftDate: { inDateRange: [$startDate, $endDate] } }
                venue: { venueId: { equals: $venueId } }
            }
        ) {
            cashup(orderBy: { cashupId: asc }) {
                shiftDate {
                    day
                }
                cashCountExpectedCash
                cashCountCashVariance
            }
            location {
                name
                locationType
            }
        }
    }
`;

const BANKABLE_KENO_TRADE_TILL_QUERY = gql`
    query CubeQuery($startDate: String!, $endDate: String!, $venueId: String!) {
        cube(
            where: {
                location: { locationType: { equals: 13 } }
                cashup: { shiftDate: { inDateRange: [$startDate, $endDate] } }
                venue: { venueId: { equals: $venueId } }
            }
        ) {
            cashup(orderBy: { cashupId: asc }) {
                shiftDate {
                    day
                }
                cashCountExpectedCash
                cashCountCashVariance
            }
            location {
                name
                locationType
            }
        }
    }
`;

type POS_SPLIT_BY_CLASS_TYPE = {
    cube?: Array<{
        __typename: string;
        splits: {
            __typename: string;
            sumTotalIncTax: number;
            splitDate: {
                __typename: string;
                day: string;
            };
        };
        account: {
            __typename: string;
            name: string;
        };
        class: {
            __typename: string;
            classType: number;
            name: string;
        };
    }>;
};

type CARD_TOTAL_FOR_POS_TYPE = {
    cube?: Array<{
        __typename: string;
        splits: {
            __typename: string;
            sumTotalIncTax: number;
            splitDate: {
                __typename: string;
                day: string;
            };
        };
    }>;
};

type TOTAL_SALES_FOR_POS_PARENT_WITH_CLASS = {
    cube: Array<{
        splits: {
            sumTotalIncTax: number;
            splitDate?: {
                day: string;
            };
        };
        account: {
            name: string;
        };
        class: {
            classType: number;
            name: string;
        };
    }>;
};

type CASH_TOTALS_FOR_ALL_POS_LOCATIONS_TYPE = {
    cube?: Array<{
        __typename: string;
        splits: {
            __typename: string;
            sumTotalIncTax: number;
            splitDate: {
                __typename: string;
                day: string;
            };
        };
    }>;
};

type CASH_VARIANCE_FOR_ALL_POS_LOCATIONS_TYPE = {
    cube?: Array<{
        __typename: string;
        splits: {
            __typename: string;
            sumTotalIncTax: number;
            splitDate: {
                __typename: string;
                day: string;
            };
        };
    }>;
};

type GamingVarianceResponse = {
    cube: Array<{
        __typename: string;
        splits: {
            __typename: string;
            sumTotalIncTax: number;
            splitDate: {
                __typename: string;
                day: string;
            };
        };
    }>;
};

type GamingPayoutResponse = {
    cube: Array<{
        __typename: string;
        splits: {
            __typename: string;
            sumTotalIncTax: number;
            splitDate: {
                __typename: string;
                day: string;
            };
        };
    }>;
};

type GamingStatsResponse = {
    cube: Array<{
        __typename: string;
        gamingStats: {
            __typename: string;
            date: {
                __typename: string;
                value: string;
                day: string;
            };
            turnover: number;
            totalWins: number;
            netProfit: number;
            returnToHouse: number;
        };
    }>;
};

type TransfersDataResponse = {
    cube: Array<{
        __typename: string;
        splits: {
            __typename: string;
            splitDate: {
                __typename: string;
                day: string;
            };
            totalIncTax: number;
        };
        transactions: {
            __typename: string;
            memo: string;
            id: string;
        };
    }>;
};

type AtmDataResponse = {
    cube: Array<{
        __typename: string;
        cashup: {
            __typename: string;
            shiftDate: {
                __typename: string;
                day: string;
            };
            cashCountCashVariance: number;
            atmDataRefills: number;
            atmDataWithdrawls: number;
        };
        location: {
            __typename: string;
            name: string;
            locationType: LocationType;
        };
    }>;
};

type SafeDataResponse = {
    cube: Array<{
        __typename: string;
        cashup: {
            __typename: string;
            shiftDate: {
                __typename: string;
                day: string;
            };
            safeDataTotal: number;
            safeDataVariance: number;
            safeSignedOff: string;
        };
        location: {
            __typename: string;
            name: string;
            locationType: LocationType;
        };
    }>;
};

type WageringDataResponse = {
    cube: Array<{
        __typename: string;
        cashup: {
            __typename: string;
            shiftDate: {
                __typename: string;
                day: string;
            };
            wageringDataSalesTotal: number;
            wageringDataCommission: number;
            cashCountCashVariance: number;
        };
        location: {
            __typename: string;
            name: string;
            locationType: LocationType;
        };
    }>;
};

type CrtDataResponse = {
    cube: Array<{
        __typename: string;
        cashup: {
            __typename: string;
            shiftDate: {
                __typename: string;
                day: string;
            };
            cashCountCashVariance: number;
            atmDataRefills: number | null;
            atmDataWithdrawls: number;
        };
        location: {
            __typename: string;
            name: string;
            locationType: LocationType;
        };
    }>;
};

type WageringPayoutResponse = {
    cube: Array<{
        __typename: string;
        splits: {
            __typename: string;
            sumTotalIncTax: number;
            splitDate: {
                __typename: string;
                day: string;
            };
        };
        location: {
            __typename: string;
            name: string;
            locationType: LocationType;
        };
    }>;
};

type BankableTradeTillResponse = {
    cube: Array<{
        __typename: string;
        cashup: {
            __typename: string;
            shiftDate: {
                __typename: string;
                day: string;
            };
            cashCountExpectedCash: number;
            cashCountCashVariance: number;
        };
        location: {
            __typename: string;
            name: string;
            locationType: LocationType;
        };
    }>;
};

type BankableTradeTillKenoResponse = {
    cube: Array<{
        __typename: string;
        cashup: {
            __typename: string;
            shiftDate: {
                __typename: string;
                day: string;
            };
            cashCountExpectedCash: number;
            cashCountCashVariance: number;
        };
        location: {
            __typename: string;
            name: string;
            locationType: LocationType;
        };
    }>;
};

type BankableTradeTillTabResponse = {
    cube: Array<{
        __typename: string;
        cashup: {
            __typename: string;
            shiftDate: {
                __typename: string;
                day: string;
            };
            cashCountExpectedCash: number;
            cashCountCashVariance: number;
        };
        location: {
            __typename: string;
            name: string;
            locationType: LocationType;
        };
    }>;
};

type DepositResponse = {
    cube?: Array<{
        __typename: string;
        splits: {
            __typename: string;
            totalIncTax: number;
            splitDate: {
                __typename: string;
                day: string;
            };
        };
        transactions: {
            __typename: string;
            memo: string;
        };
        account: {
            __typename: string;
            name: string;
        };
    }>;
};

type PettyCash = {
    cube?: Array<{
        __typename: string;
        splits: {
            __typename: string;
            totalIncTax: number;
            splitDate: {
                __typename: string;
                day: string;
            };
        };
        transactions: {
            __typename: string;
            memo: string;
        };
    }>;
};

const fillInEmptyPosParentLocations = (
    posParentLocationsData: {
        [parentLocationId: string]: {
            sales: TOTAL_SALES_FOR_POS_PARENT_WITH_CLASS;
            card: CashVarianceForParentLocationData;
            cashVariance: CardForParentLocationData;
            cash: CashForParentLocationData;
        };
    },
    parentLocations: string[]
) => {
    const posParentLocationsDataCopy = { ...posParentLocationsData };
    parentLocations.forEach((parentLocationId) => {
        if (!posParentLocationsDataCopy[parentLocationId]) {
            posParentLocationsDataCopy[parentLocationId] = {
                sales: {
                    cube: [
                        {
                            splits: {
                                sumTotalIncTax: 0,
                            },
                            account: {
                                name: "",
                            },
                            class: {
                                name: "",
                                classType: 0,
                            },
                        },
                    ],
                },
                card: { cube: [] },
                cashVariance: { cube: [] },
                cash: { cube: [] },
            };
        }
    });
    return posParentLocationsDataCopy;
};

/**
 * @param {string} startDate
 * @param {string} endDate
 * @param {string} venueId
 */
const useDetailedReport = ({
    startDate,
    endDate,
    venueId,
    parentLocations,
    cubejsToken,
}: {
    startDate?: string;
    endDate?: string;
    venueId?: string;
    parentLocations?: string[];
    cubejsToken?: string;
}) => {
    const [posParentLocationsData, setPosParentLocationsData] = useState<{
        [parentLocationId: string]: {
            sales: TOTAL_SALES_FOR_POS_PARENT_WITH_CLASS;
            card: CashVarianceForParentLocationData;
            cashVariance: CardForParentLocationData;
            cash: CashForParentLocationData;
        };
    }>({});

    const isPosParentLocationsDataLoading = useMemo(() => {
        if (parentLocations && parentLocations.length > 0) {
            const allQueriesHaveRun = parentLocations.every((parentLocation) => {
                return posParentLocationsData[parentLocation];
            });

            return !allQueriesHaveRun;
        }
    }, [parentLocations, posParentLocationsData]);

    const runAllQueries = async () => {
        const token = localStorage.getItem("cubejsToken") ?? cubejsToken;
        const graphQLClient = new GraphQLClient(
            process.env.REACT_APP_CUBEJS_BACKEND_API_URL?.replace(
                "/v1",
                "/graphql"
            ) as string,
            {
                headers: {
                    authorization: `Bearer ${token}`,
                },
                cache: "no-store",
            }
        );
        const results: {
            [parentLocationId: string]: {
                sales: TOTAL_SALES_FOR_POS_PARENT_WITH_CLASS;
                card: CashVarianceForParentLocationData;
                cashVariance: CardForParentLocationData;
                cash: CashForParentLocationData;
            };
        } = {};
        if (!parentLocations) return;

        return Promise.all(
            parentLocations.map(async (currentPosLocation) => {
                results[currentPosLocation] = {
                    sales: { cube: [] },
                    card: { cube: [] },
                    cashVariance: { cube: [] },
                    cash: { cube: [] },
                };
                // Majority of the data
                await graphQLClient
                    .request<TOTAL_SALES_FOR_POS_PARENT_WITH_CLASS>(
                        POINT_OF_SALE_SPLIT_BY_CLASS_TYPE,
                        {
                            startDate,
                            endDate,
                            venueId,
                            parentLocationId: currentPosLocation,
                        }
                    )
                    .then((result) => {
                        // @ts-ignore
                        results[currentPosLocation].sales = result;
                    })
                    .catch((error) => {
                        console.log("POS locations", { error });
                    });

                // Cash variance data
                await graphQLClient
                    .request<CardForParentLocationData>(
                        CASH_VARIANCE_FOR_PARENT_LOCATION,
                        {
                            startDate,
                            endDate,
                            venueId,
                            parentLocationId: currentPosLocation,
                        }
                    )
                    .then((result) => {
                        // @ts-ignore
                        results[currentPosLocation].cashVariance = result;
                    })
                    .catch((error) => {
                        console.log("POS locations", { error });
                    });

                // Card data
                await graphQLClient
                    .request<CashVarianceForParentLocationData>(
                        CARD_TOTALS_FOR_PARENT_LOCATION,
                        {
                            startDate,
                            endDate,
                            venueId,
                            parentLocationId: currentPosLocation,
                        }
                    )
                    .then((result) => {
                        // @ts-ignore
                        results[currentPosLocation].card = result;
                    })
                    .catch((error) => {
                        console.log("POS locations", { error });
                    });

                // Cash data
                await graphQLClient
                    .request<CashForParentLocationData>(CASH_BY_PARENT_LOCATION, {
                        startDate,
                        endDate,
                        venueId,
                        parentLocationId: currentPosLocation,
                    })
                    .then((result) => {
                        // @ts-ignore
                        results[currentPosLocation].cash = result;
                    })
                    .catch((error) => {
                        console.log("POS locations", { error });
                    });
            })
        ).then(() => {
            return {
                results,
                filledResults: fillInEmptyPosParentLocations(
                    results,
                    parentLocations
                ),
            };
        });
    };

    useEffect(() => {
        if (parentLocations) {
            runAllQueries().then((result) => {
                if (result) {
                    setPosParentLocationsData(result.filledResults);
                }
            });
        }
    }, [parentLocations, startDate, endDate, venueId]);

    const {
        data: posSplitByClassTypeData,
        loading: postSplitByClassTypeDataLoading,
    } = useQuery<POS_SPLIT_BY_CLASS_TYPE>(POS_SPLIT_BY_CLASS_TYPE, {
        fetchPolicy: "network-only",
        variables: {
            startDate,
            endDate,
            venueId,
        },
    });

    const {
        data: cashVarianceForAllPosLocationsData,
        loading: cashVarianceForAllPosLocationsDataLoading,
    } = useQuery<CASH_VARIANCE_FOR_ALL_POS_LOCATIONS_TYPE>(
        CASH_VARIANCE_FOR_ALL_POS_LOCATIONS,
        {
            fetchPolicy: "network-only",
            variables: {
                startDate,
                endDate,
                venueId,
            },
        }
    );

    const {
        data: cashTotalsForAllPosLocationsData,
        loading: cashTotalsForAllPosLocationsDataLoading,
    } = useQuery<CASH_TOTALS_FOR_ALL_POS_LOCATIONS_TYPE>(
        CASH_TOTALS_FOR_ALL_POS_LOCATIONS,
        {
            fetchPolicy: "network-only",
            variables: {
                startDate,
                endDate,
                venueId,
            },
        }
    );

    const { data: cardTotalsForPosData, loading: cardTotalsForPosDataLoading } =
        useQuery<CARD_TOTAL_FOR_POS_TYPE>(CARD_TOTALS_FOR_POS, {
            fetchPolicy: "network-only",
            variables: {
                startDate,
                endDate,
                venueId,
            },
        });

    const { data: pettyCashData, loading: pettyCashDataLoading } =
        useQuery<PettyCash>(PETTY_CASH, {
            fetchPolicy: "network-only",
            variables: {
                startDate,
                endDate,
                venueId,
            },
        });

    const { data: depositsData, loading: depositsDataLoading } =
        useQuery<DepositResponse>(DEPOSITS, {
            fetchPolicy: "network-only",
            variables: {
                startDate,
                endDate,
                venueId,
            },
        });

    const { data: transfersData, loading: transfersDataLoading } =
        useQuery<TransfersDataResponse>(TRANSFERS, {
            fetchPolicy: "network-only",
            variables: {
                startDate,
                endDate,
                venueId,
            },
        });

    const { data: gamingStatsData, loading: gamingStatsDataLoading } =
        useQuery<GamingStatsResponse>(GAMING_STATS, {
            fetchPolicy: "network-only",
            variables: {
                startDate,
                endDate,
                venueId,
            },
        });

    const { data: gamingVarianceData, loading: gamingVarianceDataLoading } =
        useQuery<GamingVarianceResponse>(GAMING_VARIANCES, {
            fetchPolicy: "network-only",
            variables: {
                startDate,
                endDate,
                venueId,
            },
        });

    const { data: gamingPayoutsData, loading: gamingPayoutsDataLoading } =
        useQuery<GamingPayoutResponse>(GAMING_PAYOUTS, {
            fetchPolicy: "network-only",
            variables: {
                startDate,
                endDate,
                venueId,
            },
        });

    const { data: safeData, loading: safeDataLoading } = useQuery<SafeDataResponse>(
        SAFE_QUERY,
        {
            fetchPolicy: "network-only",
            variables: {
                startDate,
                endDate,
                venueId,
            },
        }
    );

    const { data: crtData, loading: crtDataLoading } = useQuery<CrtDataResponse>(
        CRT_QUERY,
        {
            fetchPolicy: "network-only",
            variables: {
                startDate,
                endDate,
                venueId,
            },
        }
    );

    const { data: atmData, loading: atmDataLoading } = useQuery<AtmDataResponse>(
        ATM_QUERY,
        {
            fetchPolicy: "network-only",
            variables: {
                startDate,
                endDate,
                venueId,
            },
        }
    );

    // Cash / Commission / Cash Variance
    const { data: wageringData, loading: wageringDataLoading } =
        useQuery<WageringDataResponse>(WAGERING_QUERY, {
            fetchPolicy: "network-only",
            variables: {
                startDate,
                endDate,
                venueId,
            },
        });

    // Keno/Tab - Payouts
    const { data: wageringPayoutsData, loading: wageringPayoutsLoading } =
        useQuery<WageringPayoutResponse>(WAGERING_PAYOUTS_QUERY, {
            fetchPolicy: "network-only",
            variables: {
                startDate,
                endDate,
                venueId,
            },
        });

    const { data: bankableTradeTillData, loading: bankableTradeTillDataLoading } =
        useQuery<BankableTradeTillResponse>(BANKABLE_TRADE_TILL_QUERY, {
            fetchPolicy: "network-only",
            variables: {
                startDate,
                endDate,
                venueId,
            },
        });

    const {
        data: bankableTradeTillTabData,
        loading: bankableTradeTillTabDataLoading,
    } = useQuery<BankableTradeTillTabResponse>(BANKABLE_TAB_TRADE_TILL_QUERY, {
        fetchPolicy: "network-only",
        variables: {
            startDate,
            endDate,
            venueId,
        },
    });

    const {
        data: bankableTradeTillKenoData,
        loading: bankableTradeTillKenoDataLoading,
    } = useQuery<BankableTradeTillKenoResponse>(BANKABLE_KENO_TRADE_TILL_QUERY, {
        fetchPolicy: "network-only",
        variables: {
            startDate,
            endDate,
            venueId,
        },
    });

    const isLoading =
        postSplitByClassTypeDataLoading ||
        cashVarianceForAllPosLocationsDataLoading ||
        pettyCashDataLoading ||
        depositsDataLoading ||
        transfersDataLoading ||
        gamingStatsDataLoading ||
        gamingVarianceDataLoading ||
        gamingPayoutsDataLoading ||
        cardTotalsForPosDataLoading ||
        cashTotalsForAllPosLocationsDataLoading ||
        safeDataLoading ||
        crtDataLoading ||
        atmDataLoading ||
        wageringDataLoading ||
        wageringPayoutsLoading ||
        bankableTradeTillDataLoading ||
        bankableTradeTillTabDataLoading ||
        bankableTradeTillKenoDataLoading ||
        isPosParentLocationsDataLoading;

    const data = {
        posSplitByClassTypeData,
        cashVarianceForAllPosLocationsData,
        pettyCashData,
        depositsData,
        transfersData,
        gamingStatsData,
        gamingVarianceData,
        gamingPayoutsData,
        cashTotalsForAllPosLocationsData,
        cardTotalsForPosData,
        safeData,
        crtData,
        atmData,
        wageringData,
        wageringPayoutsData,
        bankableTradeTillData,
        bankableTradeTillTabData,
        bankableTradeTillKenoData,
        posParentLocationsData,
    };

    return useMemo(
        () => ({
            isLoading,
            data,
        }),
        [isLoading, data]
    );
};

export { useDetailedReport };
