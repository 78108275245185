import { PanelHOCComponentProps } from "../../Pages/CashupHome/CashupTabs/POS/utils/PanelHOC";
import React, { FC } from "react";
import { StyledTable } from "./Table.style";
import { TableBody, TableRows } from "./TableBody";
import { TableFooter } from "./TableFooter";
import { TableConfigs, TableHeader } from "./TableHeader";
import { Maybe } from "types";

interface Props extends PanelHOCComponentProps {
    onCellValueChanged?: (params: {
        row: number;
        propertyPath: string;
        updatedValue: number;
    }) => void;
    dataSources: TableRows[];
    tableConfigs: TableConfigs;
    onSelectedCellChange?: (params: Maybe<{ row: number; col: number }>) => void;
    evenDistribution?: boolean;
    totalRow?: TableRows;
    totalRowFromBackend?: boolean;
    tableWithoutFooter?: boolean;
}

export const Table: FC<Props> = ({
    onCellValueChanged,
    dataSources,
    tableConfigs,
    isInFocus,
    onClickHandler,
    onSelectedCellChange,
    evenDistribution,
    totalRow,
    totalRowFromBackend,
    tableWithoutFooter,
}) => (
    <StyledTable>
        <TableHeader
            tableConfigs={tableConfigs}
            evenDistribution={evenDistribution}
        />
        <TableBody
            key={window.location.pathname}
            onCellValueChanged={onCellValueChanged}
            tableConfigs={tableConfigs}
            dataSources={dataSources}
            onClickHandler={onClickHandler}
            isInFocus={isInFocus}
            onSelectedCellChange={onSelectedCellChange}
            evenDistribution={evenDistribution}
        />
        {!tableWithoutFooter && (
            <TableFooter
                tableConfigs={tableConfigs}
                dataSources={dataSources}
                evenDistribution={evenDistribution}
                totalRowValue={totalRow}
                totalRowFromBackend={totalRowFromBackend}
            />
        )}
    </StyledTable>
);
