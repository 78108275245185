import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState = {
    gamingFileDownload: false,
};
const integrationSlice = createSlice({
    name: "integrationSlice",
    initialState,
    reducers: {
        changeGamingFileDownloadState(
            state,
            payload: PayloadAction<{ newState: boolean }>
        ) {
            state.gamingFileDownload = payload.payload.newState;
        },
    },
});

export const { changeGamingFileDownloadState } = integrationSlice.actions;
export default integrationSlice.reducer;
