import { prependDollarSign } from "@Constants";
import { StyledTable } from "V2/Table/Table.style";
import { TableBody, TableRows } from "V2/Table/TableBody";
import { TableConfigs, TableHeader } from "V2/Table/TableHeader";
import React from "react";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import { ExtendedClassItem } from "Redux/StateSlices/GroupData/ClassesAPI";
import {
    ClassTypes,
    PosTotal,
    TenderTypes,
} from "../../Pages/CashupHome/CashupTabs/POS/@types";
import { initialiseCashColumn, initialiseColumnNames } from "./POSPanel";
import { PanelHOCComponentProps } from "../../Pages/CashupHome/CashupTabs/POS/utils/PanelHOC";

interface Props extends PanelHOCComponentProps {
    classesData: ExtendedClassItem[];
    accountsData: ExtendedAccountItem[];
    posTotal?: PosTotal;
    pos_totals: PosTotal;
}

export const prepareStringToNumber = (data: string | number) =>
    isNaN(Number(data)) ? 0 : Number(data);
const generateTotalForClassSplitItems = (
    classesData: ExtendedClassItem[],
    posTotals: PosTotal,
    posTotal?: PosTotal
) => {
    const ClassSplitColumns: TableRows = [];
    classesData
        .filter((currentClassItem) => currentClassItem.name !== ClassTypes.GAMING)
        .forEach((currentClassItem) => {
            if (currentClassItem.name === ClassTypes.FOOD) {
                ClassSplitColumns.push({
                    value: posTotal
                        ? prepareStringToNumber(posTotal.ALL.class_split.Food)
                        : prepareStringToNumber(posTotals.ALL.class_split.Food),
                    readOnly: true,
                });
            } else if (currentClassItem.name === ClassTypes.BEVERAGE) {
                ClassSplitColumns.push({
                    value: posTotal
                        ? prepareStringToNumber(posTotal.ALL.class_split.Beverage)
                        : prepareStringToNumber(posTotals.ALL.class_split.Beverage),
                    readOnly: true,
                });
            }
        });
    ClassSplitColumns.push({
        value: posTotal
            ? prepareStringToNumber(posTotal.ALL.class_split.Other)
            : prepareStringToNumber(posTotals.ALL.class_split.Other),
        readOnly: true,
    });
    return ClassSplitColumns;
};

const generateTotalForTenderSplitItems = (
    accountsData: ExtendedAccountItem[],
    posTotals: PosTotal,
    posTotal?: PosTotal
) => {
    const TenderSplitColumns: TableRows = [];
    accountsData
        .filter(
            (currentTenderSplitItem) =>
                currentTenderSplitItem.tender_type === true ||
                currentTenderSplitItem.name === TenderTypes.EFTPOS
        )
        .forEach((currentTenderSplitItem) => {
            if (currentTenderSplitItem.name === TenderTypes.EFTPOS) {
                TenderSplitColumns.push({
                    value: posTotal
                        ? prepareStringToNumber(posTotal.ALL.account_split.Eftpos)
                        : prepareStringToNumber(posTotals.ALL.account_split.Eftpos),
                    readOnly: true,
                });
            } else if (currentTenderSplitItem.name == TenderTypes.VISA) {
                TenderSplitColumns.push({
                    value: posTotal
                        ? prepareStringToNumber(posTotal.ALL.account_split.Visa)
                        : prepareStringToNumber(posTotals.ALL.account_split.Visa),
                    readOnly: true,
                });
            }
        });
    TenderSplitColumns.push({
        value: posTotal
            ? prepareStringToNumber(posTotal.ALL.account_split.Other)
            : prepareStringToNumber(posTotals.ALL.account_split.Other),
        readOnly: true,
    });
    const cashTenderItem = accountsData.find(
        (currentTenderSplitItem) =>
            currentTenderSplitItem.tender_type === true &&
            currentTenderSplitItem.name === TenderTypes.CASH
    );
    if (cashTenderItem !== undefined) {
        TenderSplitColumns.push({
            value: posTotal
                ? prepareStringToNumber(posTotal.ALL.account_split.Cash)
                : prepareStringToNumber(posTotals.ALL.account_split.Cash),
            readOnly: true,
        });
    }
    return TenderSplitColumns;
};

export const CollapsePOSTotal: React.FC<Props> = ({
    classesData,
    accountsData,
    onClickHandler,
    isInFocus,
    posTotal,
    pos_totals,
}) => {
    const tableConfigs: TableConfigs = [
        {
            columnTitle: "",
        },
        {
            columnTitle: "Float",
        },

        ...initialiseColumnNames(accountsData, classesData, {
            columnTitle: "Sales total",
            separateColumn: true,
        }),
        ...initialiseCashColumn(accountsData),
        {
            columnTitle: "Tenders total",
        },

        {
            columnTitle: "Transactions",
        },
        {
            columnTitle: "Total variance",
            separateColumn: true,
        },
        {
            columnTitle: "Cash count",
        },
        {
            columnTitle: "Cash variance",
        },
    ];
    const dataSources: TableRows[] = [];
    dataSources.push([
        {
            value: "Total",
            readOnly: true,
        },
        {
            value: posTotal
                ? posTotal.ALL.location_float_value
                : pos_totals.ALL.location_float_value,
            readOnly: true,
            isExplicitlyNotTitleCell: true,
        },
        ...generateTotalForClassSplitItems(classesData, pos_totals, posTotal),
        {
            value: posTotal
                ? prependDollarSign(prepareStringToNumber(posTotal.ALL.sales_total))
                : prependDollarSign(
                      prepareStringToNumber(pos_totals.ALL.sales_total)
                  ),
            readOnly: true,
            isExplicitlyNotTitleCell: true,
        },
        ...generateTotalForTenderSplitItems(accountsData, pos_totals, posTotal),
        {
            value: posTotal
                ? prependDollarSign(prepareStringToNumber(posTotal.ALL.tender_total))
                : prependDollarSign(
                      prepareStringToNumber(pos_totals.ALL.tender_total)
                  ),
            readOnly: true,
            isExplicitlyNotTitleCell: true,
        },
        {
            value: posTotal
                ? posTotal.ALL.transactions
                : Number(pos_totals.ALL.transactions),
            readOnly: true,
        },
        {
            value: posTotal
                ? prependDollarSign(
                      prepareStringToNumber(posTotal.ALL.total_variance)
                  )
                : prependDollarSign(
                      prepareStringToNumber(pos_totals.ALL.total_variance)
                  ),
            readOnly: true,
            statusField: () => null,
        },
        {
            value: posTotal
                ? prependDollarSign(
                      prepareStringToNumber(posTotal.ALL.cash_count_actual)
                  )
                : prependDollarSign(
                      prepareStringToNumber(pos_totals.ALL.cash_count_actual)
                  ),
            readOnly: true,
            isExplicitlyNotTitleCell: true,
        },
        {
            value: posTotal
                ? prependDollarSign(
                      prepareStringToNumber(posTotal.ALL.cash_count_cash_variance)
                  )
                : prependDollarSign(
                      prepareStringToNumber(pos_totals.ALL.cash_count_cash_variance)
                  ),
            readOnly: true,
            isExplicitlyNotTitleCell: true,
        },
    ]);
    return (
        <>
            <StyledTable>
                <TableHeader tableConfigs={tableConfigs} />
                <TableBody
                    tableConfigs={tableConfigs}
                    dataSources={dataSources}
                    onClickHandler={onClickHandler}
                    isInFocus={isInFocus}
                />
            </StyledTable>
        </>
    );
};
