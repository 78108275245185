import { useCallback, useMemo, useState } from "react";

interface useTransactionModalProps {
    onModalClose?: () => void;
}

const useTransactionModal = ({ onModalClose }: useTransactionModalProps) => {
    const [isVisible, setIsVisible] = useState(false);

    const [isFormNeedToSave, setIsFormNeedToSave] = useState<
        Array<{ [name: string]: boolean }>
    >([]);

    const setFormNeedToSave = useCallback(
        (category: string, isToAdd: boolean) => {
            const needsToSave = isFormNeedToSave.some((form) =>
                Object.keys(form).includes(category)
            );

            if (isToAdd) {
                if (!needsToSave) {
                    setIsFormNeedToSave([...isFormNeedToSave, { [category]: true }]);
                }

                return;
            }

            if (!needsToSave) return;

            const index = isFormNeedToSave.findIndex(
                (form) => Object.keys(form)[0] === category
            );
            const isFormNeedToSaveCopy = [...isFormNeedToSave];
            isFormNeedToSaveCopy.splice(index, 1);
            setIsFormNeedToSave(isFormNeedToSaveCopy);
        },
        [isFormNeedToSave, setIsFormNeedToSave]
    );

    const formNamesNeedToSave = useMemo(() => {
        const forms = isFormNeedToSave.filter((item) => Object.values(item)[0]);

        const result = forms.reduce((prev, curr) => {
            return `${prev}${Object.keys(curr)[0]} `;
        }, "");

        return result;
    }, [isFormNeedToSave]);

    const closeModal = useCallback(() => {
        const needsToSave = isFormNeedToSave.some(
            (formNeedToSave) => Object.values(formNeedToSave)[0]
        );

        if (needsToSave) {
            setIsVisible(true);
            return;
        }

        if (onModalClose) {
            onModalClose();
        }
    }, [isFormNeedToSave, onModalClose]);

    return {
        isFormNeedToSave,
        setFormNeedToSave,
        formNamesNeedToSave,
        closeModal,
        isVisible,
        setIsVisible,
    };
};

export default useTransactionModal;
