import { Divider, Form, FormInstance, Input, Select } from "antd";
import { usePlacesWidget } from "react-google-autocomplete";
import { FieldData } from "@interfaces";
import React, { useCallback, useMemo } from "react";
import {
    StyledActionContainer,
    StyledCancelButton,
    StyledSaveButton,
} from "./SalesTotalForm";
import { IdDoucumentType } from "@generated/models/EntityData";
import { parseStringToCapitalStart } from "./TransactionsFormPOS";
import { useForm } from "antd/lib/form/Form";
import { Field, FieldType } from "./TransactionForms/SalesCountModalFormV2";

export const CustomerInformationModal = ({
    formFields,
    setFormFields,
    closeModal,
    isTenderTypeCheque,
}: {
    formFields: FieldData[];
    setFormFields: React.Dispatch<React.SetStateAction<FieldData[]>>;
    closeModal: () => void;
    isTenderTypeCheque?: boolean;
}) => {
    const { ref: antRef } = usePlacesWidget({
        apiKey: process.env.GOOGLE_MAPS_API_KEY,
        onPlaceSelected: (place) => {
            setFormFields((prev) => {
                const match = prev.filter((item) => {
                    if (Array.isArray(item.name)) {
                        return (
                            item.name[0] !== "address" && item.name[0] !== "place_id"
                        );
                    } else {
                        return item.name !== "address" && item.name !== "place_id";
                    }
                });
                if (place?.formatted_address) {
                    match.push(
                        {
                            name: "address",
                            value: place?.formatted_address,
                        },
                        { name: "place_id", value: place?.place_id }
                    );
                } else {
                    match.push({
                        name: "address",
                        value: place?.name,
                    });
                }
                return match;
            });
        },
        options: {
            types: [],
            fields: ["all"],
            componentRestrictions: { country: "au" },
        },
    });

    const identificationType = useMemo(() => {
        const result = [];
        for (const idTypeOption in IdDoucumentType) {
            if (typeof IdDoucumentType[idTypeOption] === "number") {
                result.push({
                    value: IdDoucumentType[idTypeOption],
                    label: parseStringToCapitalStart(idTypeOption, "_", " "),
                });
            }
        }
        return result;
    }, []);
    const customerInformationFields: Field[] = useMemo(() => {
        const initialFields = [
            {
                name: "first_name",
                label: "First Name",
                required: true,
                type: {
                    name: "Text",
                } as FieldType,
            },
            {
                name: "last_name",
                label: "Last Name",
                required: true,
                type: {
                    name: "Text",
                } as FieldType,
            },
            {
                name: "address",
                label: "Address",
                required: true,
                type: {
                    name: "Text",
                } as FieldType,
            },
            {
                name: "id_document_type",
                label: "ID type",
                required: true,
                type: {
                    name: "Dropdown",
                    options: identificationType.map((idTypeOption) => ({
                        value: idTypeOption.value,
                        label: idTypeOption.label,
                    })),
                } as FieldType,
            },
            {
                name: "id_number",
                label: "ID number",
                required: true,
                type: {
                    name: "Text",
                } as FieldType,
            },
            {
                name: "place_id",
                required: false,
                type: {
                    name: "Text",
                } as FieldType,
                display: false,
            },
        ];
        if (isTenderTypeCheque) {
            initialFields.push({
                name: "cheque_no",
                label: "Cheque number",
                required: true,
                type: {
                    name: "Text",
                } as FieldType,
            });
        }
        return initialFields;
    }, [identificationType, isTenderTypeCheque]);

    const [form] = useForm();
    // const inputRef = useRef<InputRef>(null);
    const clickHandler = useCallback(async () => {
        form.validateFields()
            .then(() => {
                closeModal();
            })
            .catch((error) => {
                console.log(error);
                return;
            });
    }, [closeModal, form]);

    const onClearHelper = useCallback(
        (form: FormInstance<any>) => {
            const resetFormField: { [name: string]: string | undefined } = {};
            customerInformationFields.forEach((field) => {
                resetFormField[field.name] = undefined;
            });
            form.setFieldsValue(resetFormField);
        },
        [customerInformationFields]
    );
    return (
        <Form
            name="customer_information"
            fields={formFields}
            onFieldsChange={(_, allFields) => {
                setFormFields(allFields);
            }}
            layout="vertical"
            form={form}
        >
            <Form.Item
                name="first_name"
                label="First Name"
                rules={[
                    {
                        required: true,
                        message: "A first name is required!",
                    },
                ]}
            >
                <Input placeholder="First Name" />
            </Form.Item>
            <Form.Item
                name="last_name"
                label="Last Name"
                rules={[
                    {
                        required: true,
                        message: "A last name is required!",
                    },
                ]}
            >
                <Input placeholder="Last Name" />
            </Form.Item>
            <Form.Item
                name="address"
                label="Address"
                rules={[
                    {
                        required: true,
                        message: "Address is required!",
                    },
                ]}
            >
                <Input
                    placeholder="Address"
                    ref={(c) => {
                        if (c) {
                            //@ts-ignore
                            antRef.current = c.input;
                        }
                    }}
                />
            </Form.Item>
            <Form.Item
                name="id_document_type"
                label="ID type"
                rules={[
                    {
                        required: true,
                        message: "ID type is required!",
                    },
                ]}
            >
                <Select placeholder="ID type">
                    {identificationType.map((idTypeOption, index) => (
                        <Select.Option value={idTypeOption.value} key={index}>
                            {idTypeOption.label}
                        </Select.Option>
                    ))}
                </Select>
            </Form.Item>
            <Form.Item
                name="id_number"
                label="ID number"
                rules={[
                    {
                        required: true,
                        message: "ID number is required!",
                    },
                ]}
            >
                <Input placeholder="ID number" />
            </Form.Item>
            {isTenderTypeCheque && (
                <Form.Item name="cheque_no" label="Cheque Number">
                    <Input placeholder="Cheque Number" />
                </Form.Item>
            )}
            <Form.Item style={{ display: "none" }} name="place_id">
                <Input placeholder="ID number" />
            </Form.Item>

            <Divider style={{ marginTop: 0 }} />
            <StyledActionContainer>
                <StyledCancelButton onClick={() => onClearHelper(form)}>
                    Clear
                </StyledCancelButton>
                <StyledSaveButton htmlType="submit" onClick={clickHandler}>
                    Save
                </StyledSaveButton>
            </StyledActionContainer>
        </Form>
    );
};
