import { DebouncedFunc } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { getWindowDimensions } from "./getWindowDimensions";

export function useWindowDimensions({
    fetchDimensions,
}: {
    fetchDimensions: DebouncedFunc<() => { width: number; height: number }>;
}) {
    const [windowDimensions, setWindowDimensions] = useState<{
        width: number;
        height: number;
    }>({ width: 1080, height: 1920 });

    useEffect(() => {
        setWindowDimensions(getWindowDimensions());
    }, []);

    useEffect(() => {
        function handleResize() {
            const result = fetchDimensions();
            if (result) setWindowDimensions(result);
        }

        window.addEventListener("resize", handleResize);
        return () => window.removeEventListener("resize", handleResize);
    }, [fetchDimensions]);

    return useMemo(() => windowDimensions, [windowDimensions]);
}
