import auth0 from "auth0-js";
import { openNotificationWithIcon } from "Components/Notification";
import jwtDecode from "jwt-decode";
import { refreshAuthToken } from "./auth0API";
import { config } from "./config";
import { AuthTokens, Auth0Result } from "./types";

export class Auth {
    auth0 = new auth0.WebAuth({
        domain: config.auth0Domain!,
        clientID: config.auth0ClientId!,
        audience: config.auth0Audience,
        scope: config.auth0Scope,
        responseType: "token id_token",
    });
    silentauth0 = new auth0.WebAuth({
        domain: config.auth0Domain!,
        clientID: config.auth0ClientId!,
        audience: config.auth0Audience,
        scope: config.auth0Scope,
        responseType: "token id_token",
        redirectUri: window.location.origin + window.location.pathname,
    });
    constructor() {
        this.silentAuth = this.silentAuth.bind(this);
        this.setSession = this.setSession.bind(this);
        this.isIdPInitiatedSSO = this.isIdPInitiatedSSO.bind(this);
        this.signin = this.signin.bind(this);
    }
    signin(username: string, password: string): Promise<Auth0Result | Error> {
        return new Promise((resolve, reject) => {
            this.auth0.client.login(
                { realm: config.auth0Realm!, username, password },
                (err, authResult) => {
                    if (err) {
                        console.log(err);
                        return reject(err);
                    }

                    setSession(authResult);
                    return resolve(authResult);
                }
            );
        });
    }
    silentAuth(): Promise<Auth0Result | Error> {
        return new Promise((resolve, reject) => {
            this.silentauth0.checkSession({}, (err, authResult) => {
                if (err) {
                    console.log("Silent authentication failed:", err);
                    return reject(err);
                }
                console.log("Silent authentication successful:", authResult);
                setSession(authResult);
                return resolve(authResult);
            });
        });
    }
    interactiveAuth = () => {
        this.auth0.authorize();
    };
    isIdPInitiatedSSO(): boolean {
        const hash = window.location.hash;
        console.log("id_token" + hash);
        return hash.includes("access_token") || hash.includes("id_token");
    }

    async setSession(accessToken: string, refreshToken?: string) {
        setSession({ accessToken, refreshToken });
        await refreshAuthToken();
    }
}
export const getUserToken = (idType: AuthTokens): string | null => {
    return localStorage.getItem(idType);
};

/**
 * If the expiry time is not explicitly set we assume the default case (24hr expiry)
 *
 */
const setExpiryTime = () => {
    const currentDate = new Date();
    currentDate.setHours(currentDate.getHours() + 23);
    return currentDate.getTime();
};

export const setSession = (oldAuthResult: Auth0Result): void => {
    const authResult = { ...oldAuthResult };

    if (authResult.id_token) {
        authResult["idToken"] = authResult.id_token;
    }

    if (authResult.access_token) {
        authResult["accessToken"] = authResult.access_token;
    }

    try {
        jwtDecode(authResult.accessToken!);
    } catch (e) {
        openNotificationWithIcon({
            type: "error",
            title: "Invalid access token",
            key: "NO_ACCESS_TOKEN",
        });
    }

    // Set the time that the access token will expire at
    const expiresAt = authResult.expiresIn
        ? JSON.stringify(authResult.expiresIn * 1000 + new Date().getTime())
        : setExpiryTime();

    if (authResult.accessToken) {
        localStorage.setItem(AuthTokens.accessToken, authResult.accessToken);
    } else {
        throw new Error("accessToken missing");
    }

    if (authResult.idToken) {
        localStorage.setItem(AuthTokens.idToken, authResult.idToken);
    }

    localStorage.setItem(AuthTokens.expiresIn, expiresAt.toString());

    if (authResult.idToken) {
        localStorage.setItem(
            AuthTokens.email,
            (jwtDecode(authResult.idToken!) as any).email
        );
        localStorage.setItem(
            AuthTokens.userId,
            (jwtDecode(authResult.idToken!) as any).sub
        );
    }

    if (authResult.refreshToken) {
        localStorage.setItem(AuthTokens.refreshToken, authResult.refreshToken!);
    }
};

export const isAuthenticated = (): boolean => {
    // Check whether the current time is past the access token's expiry time
    const expiresAt = parseInt(localStorage.getItem(AuthTokens.expiresIn)!);
    return new Date().getTime() < expiresAt;
};
