import React, { useEffect, useState } from "react";
import ReactApexChart from "react-apexcharts";
import styled from "styled-components";
import { customPalette } from "../const";

interface DataItem {
    name: string;
    data: number[];
}

interface GroupedDataItem {
    graphData: DataItem[];
    labels: string[];
}

interface BarChartProps {
    data: GroupedDataItem;
}

const GraphContainer = styled.div`
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    height: 400px;
    padding: 10px;
`;

const CashupReportBarChart: React.FC<BarChartProps> = ({ data }) => {
    const [tableData, setTableData] = useState([] as DataItem[]);

    useEffect(() => {
        setTableData(data.graphData);
    }, [data]);

    const chartOptions = {
        chart: {
            type: "bar" as const,
            width: "100%",
            toolbar: {
                show: false,
            },
        },
        colors: customPalette,
        plotOptions: {
            bar: {
                horizontal: false,
                columnWidth: "55%",
                endingShape: "rounded",
            },
        },
        legend: {
            show: true,
        },
        dataLabels: {
            enabled: false,
        },
        theme: {
            palette: "palette6",
        },
        tooltip: {
            enabled: true,
        },
        xaxis: {
            categories: data.labels,
        },
        yaxis: {
            title: {
                text: "Amount $",
                style: {
                    fontSize: "16px",
                    fontWeight: "600 !important",
                },
            },
        },
    };

    return (
        <GraphContainer>
            <ReactApexChart
                options={chartOptions}
                series={tableData}
                type="bar"
                //width="100%"
                height={370}
            />
        </GraphContainer>
    );
};

export default CashupReportBarChart;
