import { AccountItemSplit } from "@generated";
import { KeyValuePair } from "@types";
import { Typography } from "antd";
import React, { useMemo } from "react";
import { ExtendedAccountItem } from "Redux/StateSlices/GroupData/AccountsAPI";
import styled from "styled-components";
import { level2Spacing, level3Spacing } from "utils/style-utils";
import { ModalFormV2 } from "./ModalFormV2";
import { Field } from "./TransactionForms/SalesCountModalFormV2";

interface Props {
    onModalClose?: () => void;
    onSubmit: (data: KeyValuePair) => void;
    OtherTenderSplitItems: ExtendedAccountItem[];
    TenderSplitData?: AccountItemSplit[] | undefined;
    disabled?: boolean;
}

const StyledMoreTenderForm = styled.div`
    min-width: 400px;
    & > div.ant-row {
        padding-left: ${level3Spacing}px !important;
        padding-right: ${level3Spacing}px !important;
    }
`;

export const TenderSplitFormV2: React.FC<Props> = ({
    onModalClose,
    onSubmit,
    OtherTenderSplitItems,
    TenderSplitData,
    disabled,
}) => {
    const TenderSplitItems: Field[] = useMemo(
        () =>
            OtherTenderSplitItems.map((tenderItem) => {
                return {
                    name: tenderItem.name,
                    colSpan: 8,
                    type: { name: "Money" },
                    key: tenderItem.account_id,
                    defaultValue:
                        TenderSplitData?.find(
                            (currentTenderAccountItem) =>
                                currentTenderAccountItem.account_id ===
                                tenderItem.account_id
                        )?.amount?.toString() ?? "0",
                    disabled,
                };
            }),
        [OtherTenderSplitItems, TenderSplitData]
    );
    return (
        <StyledMoreTenderForm>
            <Typography
                style={{
                    color: "#626E84",
                    margin: level3Spacing,
                    marginBottom: level2Spacing,
                    fontWeight: "bold",
                }}
            >
                Tender types
            </Typography>
            <ModalFormV2
                onModalClose={onModalClose}
                formName="TenderSplitForm"
                fields={TenderSplitItems}
                onSubmission={onSubmit}
                disabled={disabled}
            />
        </StyledMoreTenderForm>
    );
};
