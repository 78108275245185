import React, { useState, useMemo } from "react";
import { Row, Col, Space } from "antd";
import { GroupReportItem } from "@interfaces/index";
import {
    LocationContainer,
    TableTitle,
    TotalTableContainer,
    CustomDataTable,
    CustomTotalDataTable,
} from "../../CashupReportStyled";
import CashupReportBarChart from "../../CashupReportBarChart";
import {
    sortDataBasedOnKeys,
    getAllUniqueKeys,
    posGroupColumnGenerator,
    totalsForAllLocations,
    preparePosGroupBarGraphData,
    dynamicColumnWidth,
} from "../../../utils";

interface GroupShiftReportClassesTendersProps {
    reportData: GroupReportItem[];
    type: string;
    groupName: string;
    downloading?: boolean;
}

const GroupShiftReportClassesTenders: React.FC<
    GroupShiftReportClassesTendersProps
> = ({ reportData, type, groupName, downloading }) => {
    const [sortedColumn, setSortedColumn] = useState<string>("");
    const [sortDirection, setSortDirection] = useState<"ascend" | "descend" | null>(
        null
    );

    //sort pos report data
    const sortedData = useMemo(() => {
        if (reportData.length === 0) {
            return [];
        }
        return sortDataBasedOnKeys(reportData);
    }, [reportData]);

    //extract unique keys from report data in sorted format
    const allKeys: string[] = useMemo(() => {
        if (sortedData.length === 0 || !sortedData[0].data) {
            return [];
        }
        return (getAllUniqueKeys(sortedData[0].data) as string[]).sort();
    }, [sortedData]);

    //prepare columns
    const columns = useMemo(
        () => posGroupColumnGenerator(allKeys, true, sortedColumn, sortDirection),
        [allKeys, sortedColumn, sortDirection]
    );

    //prepare totalcolumns
    const totalColumns = useMemo(
        () => posGroupColumnGenerator(allKeys, false, null, null),
        [allKeys]
    );

    //compute totals for each column
    const totals: Record<string, number> = useMemo(
        () => (sortedData.length > 0 ? totalsForAllLocations(sortedData) : {}),
        [sortedData]
    );

    const totalRow: Record<string, number | string> = {
        venue: "Total",
        ...totals,
    };

    //prepare bar chart data
    const barChartData: {
        graphData: {
            name: string;
            data: number[];
        }[];
        labels: string[];
    } = useMemo(() => {
        if (sortedData.length === 0 || !sortedData[0].data) {
            return null;
        }
        return preparePosGroupBarGraphData(sortedData);
    }, [sortedData]);

    const totalColumnWidth = useMemo(() => {
        return dynamicColumnWidth(columns);
    }, [columns]);

    //handle sorting
    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setSortedColumn(sorter.columnKey);
        setSortDirection(sorter.order as "ascend" | "descend" | null);
    };

    const paginationConfig = {
        pageSize: 10,
        total:
            sortedData.length > 0 && sortedData[0].data
                ? sortedData[0].data.length
                : 0,
        showSizeChanger: false,
        showQuickJumper: false,
    };

    return (
        <>
            <Space
                size="middle"
                direction="vertical"
                style={{
                    width: "100%",
                }}
            >
                <Row gutter={[20, 20]}>
                    <Col md={24} lg={24} xs={24}>
                        <LocationContainer>
                            <TableTitle
                                style={{
                                    marginBottom: "15px",
                                }}
                            >{`${type} - ${groupName}`}</TableTitle>
                            <CashupReportBarChart data={barChartData} />
                        </LocationContainer>
                    </Col>
                </Row>
                {sortedData.map((item: GroupReportItem) => (
                    <Row gutter={[20, 2]} key={`${item.location_type}`}>
                        <Col xs={24}>
                            <LocationContainer>
                                <TableTitle>{`${type} - ${groupName}`}</TableTitle>
                                <TotalTableContainer>
                                    <CustomDataTable
                                        rowKey="venue"
                                        dataSource={item.data}
                                        columns={columns}
                                        size="small"
                                        scroll={{ x: totalColumnWidth }}
                                        tableLayout="fixed"
                                        pagination={
                                            downloading ? false : paginationConfig
                                        }
                                        onChange={handleTableChange}
                                    />
                                </TotalTableContainer>
                                <TotalTableContainer>
                                    <CustomTotalDataTable
                                        dataSource={[totalRow]}
                                        columns={totalColumns}
                                        size="small"
                                        scroll={{ x: totalColumnWidth }}
                                        tableLayout="fixed"
                                        pagination={false}
                                    />
                                </TotalTableContainer>
                            </LocationContainer>
                        </Col>
                    </Row>
                ))}
            </Space>
        </>
    );
};

export default GroupShiftReportClassesTenders;
