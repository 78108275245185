import { ResultStatusType } from "antd/es/result";
import Modal from "Components/ModalContext";
import React from "react";
import {
    Context,
    createContext,
    PropsWithChildren,
    ReactElement,
    useContext,
    useMemo,
    useRef,
} from "react";

export interface ModalOptions {
    status: ResultStatusType;
    timed?: boolean;
    timeout?: number;
    buttons: Array<ReactElement>;
}

export interface ModalContextProps {
    openModal: (title: React.ReactNode | string, options: ModalOptions) => void;
    closeModal: (callback?: () => void) => void;
}
const defaultValue = Symbol("context default value");
type DefaultValue = symbol;
export function createSafeContext<ContextValue>() {
    return createContext<ContextValue | DefaultValue>(defaultValue);
}

export function useSafeContext<T>(TheContext: Context<T | DefaultValue>): T {
    const value = useContext(TheContext);
    if (value === defaultValue) {
        throw new Error("no value provided for context");
    }
    return value as T;
}
const ModalContext = createSafeContext<ModalContextProps | DefaultValue>();

export const ModalProvider = (props: PropsWithChildren<{}>) => {
    const { children } = props;
    const modalRef = useRef<ModalContextProps>(null);
    const value = useMemo<ModalContextProps>(
        () => ({
            openModal: (title: React.ReactNode | string, options: ModalOptions) =>
                modalRef.current?.openModal(title, options),
            closeModal: (callback?: () => void) =>
                modalRef.current?.closeModal(callback),
        }),
        [modalRef]
    );

    return (
        <ModalContext.Provider value={value}>
            {children}
            <Modal ref={modalRef} />
        </ModalContext.Provider>
    );
};

export const useContextModal = () => useSafeContext(ModalContext);
