import { ApiCall } from "ApiV2/apiCall";

export const getLastTransaction = async (venueId: string, selectedDate: string) => {
    return await ApiCall(
        undefined,
        "AUTHENTICATED",
        "LASTTRANSACTION",
        "list",
        "GET",
        {},
        `?venue_id=${venueId}&shift_date=${selectedDate}`
    );
};
