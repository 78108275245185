import React, { useEffect, useMemo, useState } from "react";
import { CentredSpinner } from "Components/Misc/Loading/CentredSpinner";
import { ColumnsType } from "antd/lib/table";
import { Card, Checkbox, Input, Spin, Table, Typography } from "antd";
import styled from "styled-components";
import {
    useGetVarianceReviewDataQuery,
    usePatchVarianceReviewDataMutation,
} from "Redux/StateSlices/VarianceReviewDataAPI";
import { SubmissionStatus } from "Redux/StateSlices/GroupData/VenuesAPI";
import {
    useGetNotificationDataQuery,
    usePatchNotificationDataMutation,
} from "Redux/StateSlices/NotificationDataAPI";
import { NotificationDataType } from "@types";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";

interface DataType {
    id: string;
    location_type: string;
    location_name: string;
    parent_location_name: string;
    comments: string;
    status: number;
    status_name: string;
    variance_data: {
        [key: string]: number;
    };
}

const NotificationContainer = styled(Card)`
    width: 100%;
`;
const VarianceContainer = styled(Card)`
    width: 100%;
`;
const ContainerTitle = styled(Typography.Title)`
    margin-top: 2px;
`;
const TableContainer = styled.div`
    border: 1px solid #f0f0f0;
    border-radius: 8px;
`;
export const CommentContainer = styled.div`
    cursor: pointer !important;
`;
const StyledInput = styled(Input)`
    &:focus {
        border-color: white;
        background: white;
        border: 1px solid #1a81c7;
        box-shadow: 0 0 1px 2px #dbe7fa;
        border-radius: 4px;
    }
`;

const VarianceSummary: React.FC = () => {
    const [sortedInfo, setSortedInfo] = useState<{
        columnKey: string | null;
        order: "ascend" | "descend" | null;
    }>({
        columnKey: null,
        order: null,
    });
    const [varianceList, setVarianceList] = useState<DataType[]>([]);
    const [editingKey, setEditingKey] = useState("" as string);
    const [selectedRow, setSelectedRow] = useState("" as string);
    const [shouldRerender, setShouldRerender] = useState(false as boolean);

    const [notificationList, setNotificationList] = useState<NotificationDataType[]>(
        []
    );
    const [notificationEditingKey, setNotificationEditingKey] = useState(
        "" as string
    );
    const [notificationSelectedRow, setNotificationSelectedRow] = useState(
        "" as string
    );
    const [notificationShouldRerender, setNotificationShouldRerender] = useState(
        false as boolean
    );
    const { venueId, shiftDate } = useVenueSelection();
    /*
     * api call to get the variance data
     */
    const {
        data: varianceReviewData,
        isLoading: varianceReviewDataLoading,
        refetch,
    } = useGetVarianceReviewDataQuery(
        {
            venue_id: venueId!,
            shift_date: shiftDate!,
        },
        { refetchOnMountOrArgChange: true }
    );

    /*
     * api call to get the notification data
     */
    const { data: notificationData, refetch: refetchNotification } =
        useGetNotificationDataQuery(
            {
                venue_id: venueId!,
                shift_date: shiftDate!,
            },
            { refetchOnMountOrArgChange: true }
        );

    /*
     * api call to update the variance data (comments and accept status)
     */
    const [mutate, { isLoading: patchLoading }] =
        usePatchVarianceReviewDataMutation();

    /*
     * api call to update the notification data (comments and accept status)
     */
    const [mutateNotification, { isLoading: patchNotificationLoading }] =
        usePatchNotificationDataMutation();

    /**
     * get cashup status from context
     */
    const { shiftStatus } = useVenueSelection();

    /**
     * define table disabled stauts, when cashup submitted or approved, disable table
     */
    const disabled = useMemo(
        () =>
            shiftStatus === SubmissionStatus.APPROVED ||
            shiftStatus === SubmissionStatus.SUBMITTED,
        [shiftStatus]
    );

    /**
     * set variance list data
     */
    useEffect(() => {
        if (varianceReviewData && varianceReviewData.variance_list) {
            setVarianceList(varianceReviewData.variance_list as DataType[]);
        }
        setShouldRerender(false);
    }, [varianceReviewData, shouldRerender]);

    /**
     * set notification list data
     */
    useEffect(() => {
        if (notificationData && notificationData.notification_list) {
            setNotificationList(
                notificationData.notification_list as NotificationDataType[]
            );
        }
        setNotificationShouldRerender(false);
    }, [notificationData, notificationShouldRerender]);

    /**
     * update variance data for the edited row
     */
    useEffect(() => {
        if (selectedRow.length > 0) {
            const modifiedRowItem = varianceList.find(
                (item) => item.id === selectedRow
            );
            if (modifiedRowItem) {
                handlePatch(modifiedRowItem);
            }
        }
    }, [selectedRow]);

    /**
     * update notification data for the edited row
     */
    useEffect(() => {
        if (notificationSelectedRow.length > 0) {
            const modifiedRowItem = notificationList.find(
                (item) => item.id === notificationSelectedRow
            );
            if (modifiedRowItem) {
                handlePatchNotification(modifiedRowItem);
            }
        }
    }, [notificationSelectedRow]);

    /**
     * update notification data when submitstatus changed
     */
    useEffect(() => {
        if (
            shiftStatus === SubmissionStatus.APPROVED ||
            shiftStatus === SubmissionStatus.UNSUBMITTED
        )
            refetchNotification();
    }, [shiftStatus]);
    /**
     * set the row key to allow editing of input
     * @param id
     * @returns
     */
    const isEditing = (id: string) => id === editingKey;

    /**
     * set the row key to allow editing of notification input
     * @param id
     * @returns
     */
    const isNotificationEditing = (id: string) => id === notificationEditingKey;

    /**
     * sort table data based on accept status
     * @param pagination
     * @param filters
     * @param sorter
     */
    const handleTableChange = (pagination: any, filters: any, sorter: any) => {
        setSortedInfo({
            columnKey: sorter.columnKey,
            order: sorter.order,
        });
    };

    /**
     * method to update status and comments for selected variance
     * @param rowItemID
     */
    const handlePatch = async (rowItem: DataType) => {
        try {
            const result = await mutate({
                rowItem: rowItem,
            });
            //future implementation : we might have to refetch the variance api on successful patch
            if ("error" in result) {
                console.error("Error during mutation:", result.error);
                // refetch variance data and rerender
                refetch();
                setShouldRerender(true);
            }
            setSelectedRow("");
        } catch (error) {
            console.error("Error:", error);
        }
    };

    /**
     * method to update status and comments for selected notification
     * @param rowItemID
     */
    const handlePatchNotification = async (rowItem: NotificationDataType) => {
        try {
            const result = await mutateNotification({
                rowItem: rowItem,
            });
            //future implementation : we might have to refetch the notification api on successful patch
            if ("error" in result) {
                console.error("Error during notification mutation:", result.error);
                // refetch notification data and rerender
                refetchNotification();
                setNotificationShouldRerender(true);
            }
            setNotificationSelectedRow("");
        } catch (error) {
            console.error("Error:", error);
        }
    };

    /**
     * handle checkbox for individual row - update state with the updated checked state
     * @param status
     * @param rowItem
     */
    const onChangeCheckBox = (checkBoolean: boolean, rowItem: DataType) => {
        setVarianceList((prevArray) =>
            prevArray.map((item) =>
                item.id === rowItem.id
                    ? {
                          ...item,
                          status: checkBoolean ? 2 : 1,
                          status_name: checkBoolean ? "Approved" : "Pending",
                      }
                    : item
            )
        );
        setSelectedRow(checkBoolean ? rowItem.id : rowItem.id);
    };

    /**
     * handle checkbox for notification row - update state with the updated checked state
     * @param status
     * @param rowItem
     */
    const onChangeNotificationCheckBox = (
        checkBoolean: boolean,
        rowItem: NotificationDataType
    ) => {
        setNotificationList((prevArray) =>
            prevArray.map((item) =>
                item.id === rowItem.id
                    ? {
                          ...item,
                          status: checkBoolean ? 2 : 1,
                      }
                    : item
            )
        );
        setNotificationSelectedRow(checkBoolean ? rowItem.id : rowItem.id);
    };

    /**
     * handle comment for individual row - update state with the updated comment
     * @param comment
     * @param rowItem
     */
    const handleInputChange = (comment: string, rowItem: DataType) => {
        setVarianceList((prevArray) =>
            prevArray.map((item) =>
                item.id === rowItem.id ? { ...item, comments: comment } : item
            )
        );
    };

    /**
     * handle comment for notification row - update state with the updated comment
     * @param comment
     * @param rowItem
     */
    const handleNotificationInputChange = (
        comment: string,
        rowItem: NotificationDataType
    ) => {
        setNotificationList((prevArray) =>
            prevArray.map((item) =>
                item.id === rowItem.id ? { ...item, comments: comment } : item
            )
        );
    };

    /**
     * handle double click on input to allow editing
     * @param id
     */
    const handleDoubleClick = (id: string) => {
        setEditingKey(id);
    };

    /**
     * handle double click on input to allow editing of notification
     * @param id
     */
    const handleNotificaitonDoubleClick = (id: string) => {
        setNotificationEditingKey(id);
    };

    /**
     * set edited row on press enter
     * @param e
     * @param rowItem
     */
    const handleBlur = (
        e: React.KeyboardEvent<HTMLInputElement>,
        rowItem: DataType
    ) => {
        setEditingKey("");
        setSelectedRow(rowItem.id);
    };

    /**
     * set edited notification row on press enter
     * @param e
     * @param rowItem
     */
    const handleNotificationBlur = (
        e: React.KeyboardEvent<HTMLInputElement>,
        rowItem: NotificationDataType
    ) => {
        setNotificationEditingKey("");
        setNotificationSelectedRow(rowItem.id);
    };

    /**
     * setting columns type for the variance table
     */
    const columns: ColumnsType<DataType> = [
        {
            title: "Tab",
            dataIndex: "location_type",
        },
        {
            title: "Location",
            dataIndex: "location_name",
            render: (_, record) => (
                <div>{`${record.parent_location_name} - ${record.location_name}`}</div>
            ),
        },
        {
            title: "Entry Variance",
            dataIndex: "variance_data",
            align: "center",
            render: (variance_data: { [key: string]: any }) => (
                <div>
                    {variance_data.entry_variance === 0
                        ? `-`
                        : `$${variance_data.entry_variance.toFixed(2)}`}
                </div>
            ),
        },
        {
            title: "Cash Variance",
            dataIndex: "variance_data",
            align: "center",
            render: (variance_data: { [key: string]: any }) => (
                <div>
                    {variance_data.cash_variance === 0
                        ? `-`
                        : `$${variance_data.cash_variance.toFixed(2)}`}
                </div>
            ),
        },
        {
            title: "Comments",
            dataIndex: "comments",
            render: (_, record) => (
                <CommentContainer onClick={() => handleDoubleClick(record.id)}>
                    {isEditing(record.id) ? (
                        <StyledInput
                            placeholder="Add comment"
                            value={record.comments}
                            onChange={(e) =>
                                handleInputChange(e.target.value, record)
                            }
                            onPressEnter={(e) => handleBlur(e, record)}
                        />
                    ) : (
                        <Input
                            placeholder="Add comment"
                            value={record.comments}
                            disabled={disabled}
                            onClick={() => handleDoubleClick(record.id)}
                        />
                    )}
                </CommentContainer>
            ),
        },
        {
            title: "Accept",
            key: "status",
            align: "center",
            sorter: (a: DataType, b: DataType) => a.status - b.status,
            sortOrder: sortedInfo.columnKey === "status" ? sortedInfo.order : null,
            render: (_, record) => (
                <Checkbox
                    checked={record.status === 2 ? true : false}
                    disabled={disabled}
                    onChange={(e) => {
                        onChangeCheckBox(e.target.checked, record);
                    }}
                />
            ),
        },
    ];

    /**
     * setting columns type for the notification table
     */
    const notificaitonColumns: ColumnsType<NotificationDataType> = [
        {
            title: "Tab",
            dataIndex: "location_type",
        },
        {
            title: "Message",
            dataIndex: "display_message",
            align: "left",
            render: (message: string) => <div>{message}</div>,
        },
        {
            title: "Comments",
            dataIndex: "comments",
            render: (_, record) => (
                <CommentContainer
                    onClick={() => handleNotificaitonDoubleClick(record.id)}
                >
                    {isNotificationEditing(record.id) ? (
                        <StyledInput
                            placeholder="Add comment"
                            value={record.comments}
                            onChange={(e) =>
                                handleNotificationInputChange(e.target.value, record)
                            }
                            onPressEnter={(e) => handleNotificationBlur(e, record)}
                            disabled={disabled}
                        />
                    ) : (
                        <Input
                            placeholder="Add comment"
                            value={record.comments}
                            disabled={disabled}
                            onClick={() => handleNotificaitonDoubleClick(record.id)}
                        />
                    )}
                </CommentContainer>
            ),
        },
        {
            title: "Accept",
            key: "status",
            align: "center",
            sorter: (a: NotificationDataType, b: NotificationDataType) =>
                a.status - b.status,
            sortOrder: sortedInfo.columnKey === "status" ? sortedInfo.order : null,
            render: (_, record) => (
                <Checkbox
                    checked={record.status === 2 ? true : false}
                    disabled={disabled}
                    onChange={(e) => {
                        onChangeNotificationCheckBox(e.target.checked, record);
                    }}
                />
            ),
        },
    ];

    const paginationConfig = {
        pageSize: 10,
        total: varianceList.length,
        showSizeChanger: false,
        showQuickJumper: false,
    };

    const notificationPaginationConfig = {
        pageSize: 10,
        total: varianceList.length,
        showSizeChanger: false,
        showQuickJumper: false,
    };

    if (varianceReviewDataLoading) {
        return <CentredSpinner size="large" />;
    }

    return (
        <>
            <NotificationContainer>
                <ContainerTitle level={4}>Notifications</ContainerTitle>
                <Spin
                    spinning={patchNotificationLoading}
                    size="large"
                    tip="Updating..."
                >
                    <TableContainer>
                        <Table
                            columns={notificaitonColumns}
                            dataSource={notificationList}
                            rowKey="id"
                            pagination={notificationPaginationConfig}
                            size="small"
                            onChange={handleTableChange}
                        />
                    </TableContainer>
                </Spin>
            </NotificationContainer>
            <VarianceContainer style={{ marginTop: "10px" }}>
                <ContainerTitle level={4}>Variances</ContainerTitle>
                <Spin spinning={patchLoading} size="large" tip="Updating...">
                    <TableContainer>
                        <Table
                            columns={columns}
                            dataSource={varianceList}
                            rowKey="id"
                            pagination={paginationConfig}
                            size="small"
                            onChange={handleTableChange}
                        />
                    </TableContainer>
                </Spin>
            </VarianceContainer>
        </>
    );
};

export default VarianceSummary;
