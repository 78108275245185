import { ApiCall } from "../apiCall";

export async function getClassesQuery(venueId: string) {
    return await ApiCall(
        undefined,
        "AUTHENTICATED",
        "CLASSES",
        "list",
        "GET",
        {},
        `?venue_id=${venueId}`
    )
        .then((res) => res.json())
        .then((res) => res.data.classes);
}
