import { ClassItem } from "@generated";
import { KeyValuePair } from "@types";
import { Typography } from "antd";
import React, { useMemo } from "react";
import { ExtendedClassItem } from "Redux/StateSlices/GroupData/ClassesAPI";
import styled from "styled-components";
import { level2Spacing, level3Spacing } from "utils/style-utils";
import { ModalFormV2 } from "./ModalFormV2";
import { Field } from "./TransactionForms/SalesCountModalFormV2";

interface Props {
    onModalClose?: () => void;
    onSubmit: (data: KeyValuePair) => void;
    OtherClassSplitItems: ExtendedClassItem[];
    ClassSplitData?: ClassItem[] | undefined;
    disabled: boolean;
}

const StyledMoreTenderForm = styled.div`
    min-width: 400px;
    & > div.ant-row {
        padding-left: ${level3Spacing}px !important;
        padding-right: ${level3Spacing}px !important;
    }
`;
export const ClassSplitFormV2: React.FC<Props> = ({
    onModalClose,
    onSubmit,
    OtherClassSplitItems,
    ClassSplitData,
    disabled,
}) => {
    const ClassSplitItems: Field[] = useMemo(
        () =>
            OtherClassSplitItems.map((classItem) => {
                return {
                    name: classItem.name,
                    colSpan: 12,
                    type: { name: "Money" },
                    key: classItem.class_id,
                    defaultValue:
                        ClassSplitData?.find(
                            (currentClassItem) =>
                                currentClassItem.class_id === classItem.class_id
                        )?.amount ?? "0",
                    disabled,
                };
            }),
        [OtherClassSplitItems, ClassSplitData, disabled]
    );
    return (
        <StyledMoreTenderForm>
            <Typography
                style={{
                    color: "#626E84",
                    margin: level3Spacing,
                    marginBottom: level2Spacing,
                    fontWeight: "bold",
                }}
            >
                Class types
            </Typography>
            <ModalFormV2
                onModalClose={onModalClose}
                formName="ClassSplitForm"
                fields={ClassSplitItems}
                onSubmission={onSubmit}
                disabled={disabled}
            />
        </StyledMoreTenderForm>
    );
};
