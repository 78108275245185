import GamingTab from "./GamingTab";
import CRTTab from "../CRT/CRTTab";
import CCUTab from "../CCU/CCUTab";
import React from "react";
import useGamingTab from "./hooks/useGamingTab";

interface Props {
    isGamingUploadEnabled: boolean;
    setGamingDownloadStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

export const GamingTabContents: React.FC<Props> = ({
    setGamingDownloadStatus,
    isGamingUploadEnabled,
}) => {
    const { ccu, crt, gaming, loading } = useGamingTab();

    return (
        <>
            <GamingTab
                setGamingDownloadStatus={setGamingDownloadStatus}
                isGamingUploadEnabled={isGamingUploadEnabled}
                loading={loading}
                locations={gaming}
            />
            <CRTTab loading={loading} locations={crt} />
            <CCUTab loading={loading} locations={ccu} />
        </>
    );
};
