import { SubmitButton } from "../StyledComponents";
import { DownloadOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { fetchWrapper } from "../../API";
import dayjs from "dayjs";
import { dayjsFormat } from "../../utils/date-utils";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { getLoggedInUser } from "../../ApiV2/Helpers/getLoggedInUser";

const DownloadJournalButton = () => {
    const { shiftDate, venueId, venueName } = useVenueSelection();
    const [isLoading, setIsLoading] = useState(false);
    getLoggedInUser;

    async function downloadCSV() {
        setIsLoading(true);
        const response = await fetchWrapper(
            `/netsuite/journal?from_date=${dayjs(shiftDate, "DD-MM-YYYY").format(
                dayjsFormat.default.format
            )}&to_date=${dayjs(shiftDate, "DD-MM-YYYY")
                .add(1, "day")
                .format(
                    dayjsFormat.default.format
                )}&venue_id=${venueId}&content_type=csv`,
            "GET"
        );

        // Check if the response is OK (status code 200-299)
        if (response.ok) {
            const csvData = await response.text();
            const blob = new Blob([csvData], { type: "text/csv" });
            const url = URL.createObjectURL(blob);
            const a = document.createElement("a");
            a.href = url;
            a.download = `${venueName.replace(/ /g, "-")}-${shiftDate}.csv`; // Specify the name for the downloaded file
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
            URL.revokeObjectURL(url);
        } else {
            console.error("Failed to download CSV:", response.statusText);
        }
        setIsLoading(false);
    }

    return (
        <SubmitButton
            icon={<DownloadOutlined />}
            backgroundcolor="#00225D"
            bordcolor="#000"
            size="large"
            disabled={isLoading}
            type="primary"
            style={{
                marginRight: 8,
                paddingRight: "15px",
                paddingLeft: "15px",
                width: "60px",
            }}
            onClick={downloadCSV}
            numberofitems={1}
        ></SubmitButton>
    );
};
export default DownloadJournalButton;
