import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { getAuthTokens } from "utils/auth0/auth0API";
import { ExtendedUserType } from "../../../@types/reduxStore";

// Note: The API is currently inconsistent with what Content-Type header it expects
// For most cases the Content-Type header will be passed if none are passed it will assumed application/json as the default case.
// TODO: Refactor into it's own file / related directory
export const AppendContentTypeHeader = (headers: Headers) => {
    const contentTypeHeader = headers.get("Content-Type");
    if (contentTypeHeader === null) {
        headers.set("Content-Type", "application/json");
    }
};

export const usersAPI = createApi({
    reducerPath: "UserAPI",
    baseQuery: fetchBaseQuery({
        baseUrl: `${process.env.REACT_APP_BACKEND_API_URL}/group`,
        prepareHeaders: async (headers) => {
            const data = await getAuthTokens();
            const access_token = data?.access_token;
            // TODO Implement a logging solution to emit no-token events.
            if (access_token) {
                headers.set("authorization", `Bearer ${access_token}`);
                AppendContentTypeHeader(headers);
            }
            return headers;
        },
    }),
    endpoints: (builder) => ({
        getUsers: builder.query<ExtendedUserType[], null>({
            query: () => ({
                url: "/users",
                method: "GET",
                headers: { "Content-Type": "*/*" },
            }),
        }),
        updateUser: builder.mutation<
            ExtendedUserType,
            Partial<{
                first_name: string;
                last_name: string;
                user_id: string;
                is_admin: boolean;
                is_active: boolean;
            }>
        >({
            query(body) {
                return {
                    url: `/users/${body.user_id}`,
                    method: "PUT",
                    body,
                    headers: { "Content-Type": "application/json" },
                };
            },
        }),
        deleteUser: builder.mutation<ExtendedUserType, Partial<{ user_id: string }>>(
            {
                query(body) {
                    return {
                        url: `/users/${body.user_id}`,
                        method: "DELETE",
                        // body,
                        headers: { "Content-Type": "application/json" },
                    };
                },
            }
        ),
        createUser: builder.mutation<
            ExtendedUserType,
            Partial<{
                // name: string;
                // email: string;
                is_admin: boolean;
                // send_email: boolean;
                first_name: string;
                last_name: string;
                username: string;
                email: string;
                is_active: boolean;
            }>
        >({
            query(body) {
                return {
                    url: "/users",
                    method: "POST",
                    body,
                    headers: { "Content-Type": "application/json" },
                };
            },
        }),
    }),
});

export const {
    useGetUsersQuery,
    useCreateUserMutation,
    useDeleteUserMutation,
    useUpdateUserMutation,
} = usersAPI;
