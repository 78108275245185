import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import dayjs from "dayjs";
import en from "dayjs/locale/en";

interface ShiftReportFields {
    selectedRange?: { start: string; end: string };
    selectedVenueId?: string;
    selectedDate?: string;
    selectedReport: "Detailed" | "Summary" | "Bankable" | "Gaming Report";
}
dayjs.locale({
    ...en,
    weekStart: 1,
});

const initialState = {
    selectedRange: {
        start: dayjs().startOf("week").toString(),
        end: dayjs().endOf("week").toString(),
    },
    selectedVenueId: window.localStorage.getItem("selectedVenue")
        ? JSON.parse(window.localStorage.getItem("selectedVenue")!)
        : undefined,
    selectedDate: undefined,
    selectedReport: "Detailed",
} as ShiftReportFields;

const shiftReportSlice = createSlice({
    name: "shiftReportSlice",
    initialState,
    reducers: {
        changeRange(
            state,
            payload: PayloadAction<{ newRange: { start: string; end: string } }>
        ) {
            state.selectedRange = payload.payload.newRange;
        },
        changeDate(state, payload: PayloadAction<{ newDate: string }>) {
            state.selectedDate = payload.payload.newDate;
        },
        changeVenue(state, payload: PayloadAction<{ newVenue: string }>) {
            state.selectedVenueId = payload.payload.newVenue;
        },
        resetVenue(state) {
            state.selectedVenueId = undefined;
        },
        updateDetailedShiftReportStore(
            state,
            payload: PayloadAction<{
                newVenue: string;
                newDate: string;
            }>
        ) {
            state.selectedVenueId = payload.payload.newVenue;
            state.selectedDate = payload.payload.newDate;
        },
        changeSelectedReport(
            state,
            payload: PayloadAction<{
                newReport: "Detailed" | "Summary" | "Bankable" | "Gaming Report";
            }>
        ) {
            state.selectedReport = payload.payload.newReport;
        },
    },
});

export const {
    changeRange,
    changeDate,
    changeVenue,
    updateDetailedShiftReportStore,
    changeSelectedReport,
    resetVenue,
} = shiftReportSlice.actions;
export default shiftReportSlice.reducer;
