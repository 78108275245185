export enum TransactionEnum {
    Sale = 1,
    Payment = 2,
    Transfer = 3,
    "Cash Count" = 4,
    "Deposit In" = 5,
    "Deposit Out" = 6,
    "Tender Count" = 7,
    "Sales Count" = 8,
    "TITO" = 9,
}

export enum PaymentTypeEnum {
    Expense = 1,
    "Petty cash" = 2,
    "Refund" = 3,
    // "Deposit Refund" = 4,
}

export enum PaymentTypeEnumWithPayout {
    Expense = 1,
    "Petty cash" = 2,
    "Payout" = 3,
}

export const getPaymentTypesArrayWithPayout = () => {
    const PaymentTypes = [];

    for (const paymentOption in PaymentTypeEnumWithPayout) {
        if (typeof PaymentTypeEnumWithPayout[paymentOption] === "string")
            PaymentTypes.push({
                name: PaymentTypeEnumWithPayout[paymentOption],
                value: paymentOption,
            });
    }
    return PaymentTypes;
};

export const getPaymentTypesArray = () => {
    const PaymentTypes = [];

    for (const paymentOption in PaymentTypeEnum) {
        if (typeof PaymentTypeEnum[paymentOption] === "string")
            PaymentTypes.push({
                name: PaymentTypeEnum[paymentOption],
                value: paymentOption,
            });
    }
    return PaymentTypes;
};
