import { ApiCall } from "ApiV2/apiCall";

export const postGamingUpload = async (data: FormData) => {
    return await ApiCall(
        data,
        "AUTHENTICATED",
        "GAMINGUPLOAD",
        "post",
        "POST",
        {},
        ``
    ).then((res) => res.json());
};
