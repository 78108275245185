import React from "react";
import { CollapsibleTable } from "../../Pages/CashupHome/CashupTabs/POS/utils/Panel";
import { Space } from "antd";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { ExtendedLocationItemWithChildren } from "../../Redux/StateSlices/GroupData/LocationsAPI";
import { CRTPanel } from "./CRTPanel";
import { LocationType } from "Pages/CashupReport/CashupReportModel";

interface Props {
    loading: boolean;
    locations: ExtendedLocationItemWithChildren[];
}

const CRTTab = ({ loading, locations = [] }: Props) => {
    const { formattedDate, shiftStatus, setActivePanel } = useVenueSelection();

    if (loading) {
        return <></>;
    }

    return (
        <Space direction="vertical" style={{ width: "100%", marginTop: "20px" }}>
            {locations
                .filter((crt) => crt.location_type === LocationType.crt)
                .map((currentLocation, index) => {
                    const panelId = `crt-${formattedDate}-${index}`;

                    return (
                        <CollapsibleTable
                            name={currentLocation.name}
                            panelId={panelId}
                            key={panelId}
                        >
                            <CRTPanel
                                key={currentLocation.name}
                                name={currentLocation.name}
                                //@ts-ignore
                                cashupIds={currentLocation.cashup_ids}
                                //@ts-ignore
                                subLocations={currentLocation.sub_locations}
                                locations={locations}
                                hierarchicalLocations={[
                                    currentLocation,
                                    ...locations.filter(
                                        (crt) =>
                                            crt.location_type === LocationType.office
                                    ),
                                ]}
                                onClickHandler={(tableIndex: number | undefined) => {
                                    setActivePanel(`${panelId}-${tableIndex}`);
                                }}
                                submitted={shiftStatus !== "UNSUBMITTED"}
                                panelId={panelId}
                            />
                        </CollapsibleTable>
                    );
                })}
        </Space>
    );
};
export default CRTTab;
