import React, { useEffect, useState, useMemo } from "react";
import ReactApexChart from "react-apexcharts";
import styled from "styled-components";
import dayjs from "dayjs";
import { customPalette } from "../const";

interface DataItem {
    name: string;
    data: number[];
}

interface LineChartProps {
    data: DataItem[];
    labels: string[];
    chartType: string;
}

const GraphContainer = styled.div`
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    height: 400px;
    padding: 10px;
`;

const CashupReportLineChart: React.FC<LineChartProps> = ({
    data,
    labels,
    chartType,
}) => {
    const [graphData, setGraphData] = useState([] as DataItem[]);

    useEffect(() => {
        setGraphData(data);
    }, [data]);

    const formattedXLabels: string[] = useMemo(
        () => labels.map((item) => dayjs(item, "DD/MM/YYYY").format("DD MMM")),
        [labels]
    );

    const chartOptions = {
        chart: {
            width: "100%",
            toolbar: {
                show: false,
            },
        },
        colors: customPalette,
        legend: {
            show: true,
            showForSingleSeries: true,
        },
        dataLabels: {
            enabled: false,
        },
        theme: {
            palette: "palette6",
        },
        tooltip: {
            enabled: true,
        },
        xaxis: {
            categories: formattedXLabels,
        },
        yaxis: {
            title: {
                text: "Amount $",
                style: {
                    fontSize: "16px",
                    fontWeight: "600 !important",
                },
            },
        },
        title: {
            text: chartType,
            align: "center" as const,
        },
        stroke: {
            width: 2,
        },
        markers: {
            size: 4,
            shape: "circle" as const,
        },
        responsive: [
            {
                breakpoint: 768,
                options: {
                    markers: {
                        size: 3, // Adjust marker size for smaller screens
                    },
                },
            },
        ],
    };

    return (
        <GraphContainer>
            <ReactApexChart
                options={chartOptions}
                series={graphData}
                type="line"
                height={370}
            />
        </GraphContainer>
    );
};

export default CashupReportLineChart;
