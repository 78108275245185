import { ColumnsType } from "antd/lib/table";
import dayjs from "dayjs";
import { getDates } from "./getDates";
import { LocationType } from "../CashupReportModel";

export function DepositDetailedReportTransformer(
    detailedReportData: {
        posSplitByClassTypeData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            account: { __typename: string; name: string };
                            class: {
                                __typename: string;
                                classType: number;
                                name: string;
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        cashVarianceForAllPosLocationsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        pettyCashData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                totalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            transactions: { __typename: string; memo: string };
                        }[]
                      | undefined;
              }
            | undefined;
        depositsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                totalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                            transactions: { __typename: string; memo: string };
                            account: { __typename: string; name: string };
                        }[]
                      | undefined;
              }
            | undefined;
        transfersData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          splitDate: { __typename: string; day: string };
                          totalIncTax: number;
                      };
                      transactions: { __typename: string; memo: string; id: string };
                  }[];
              }
            | undefined;
        gamingStatsData:
            | {
                  cube: {
                      __typename: string;
                      gamingStats: {
                          __typename: string;
                          date: { __typename: string; value: string; day: string };
                          turnover: number;
                          totalWins: number;
                          netProfit: number;
                          returnToHouse: number;
                      };
                  }[];
              }
            | undefined;
        gamingVarianceData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                  }[];
              }
            | undefined;
        gamingPayoutsData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                  }[];
              }
            | undefined;
        cashTotalsForAllPosLocationsData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        cardTotalsForPosData:
            | {
                  cube?:
                      | {
                            __typename: string;
                            splits: {
                                __typename: string;
                                sumTotalIncTax: number;
                                splitDate: { __typename: string; day: string };
                            };
                        }[]
                      | undefined;
              }
            | undefined;
        safeData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          safeDataTotal: number;
                          safeDataVariance: number;
                          safeSignedOff: string;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        crtData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountCashVariance: number;
                          atmDataRefills: number | null;
                          atmDataWithdrawls: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        atmData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountCashVariance: number;
                          atmDataRefills: number;
                          atmDataWithdrawls: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        wageringData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          wageringDataSalesTotal: number;
                          wageringDataCommission: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        wageringPayoutsData:
            | {
                  cube: {
                      __typename: string;
                      splits: {
                          __typename: string;
                          sumTotalIncTax: number;
                          splitDate: { __typename: string; day: string };
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillTabData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        bankableTradeTillKenoData:
            | {
                  cube: {
                      __typename: string;
                      cashup: {
                          __typename: string;
                          shiftDate: { __typename: string; day: string };
                          cashCountExpectedCash: number;
                          cashCountCashVariance: number;
                      };
                      location: {
                          __typename: string;
                          name: string;
                          locationType: LocationType;
                      };
                  }[];
              }
            | undefined;
        posParentLocationsData: {
            [parentLocationId: string]: {
                sales: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate?: { day: string };
                        };
                        account: { name: string };
                        class: { classType: number; name: string };
                    }[];
                };
                card: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
                cashVariance: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
                cash: {
                    cube: {
                        splits: {
                            sumTotalIncTax: number;
                            splitDate: { day: string };
                        };
                    }[];
                };
            };
        };
    },
    selectedRange: { start: string; end: string } | undefined
) {
    if (!detailedReportData || !selectedRange) {
        return {};
    }

    const DataMap: { [day: string]: any[] } = {};

    detailedReportData.depositsData?.cube?.forEach((depositItem) => {
        const { splits, transactions, account } = depositItem;
        const { totalIncTax, splitDate } = splits;
        const { memo } = transactions;
        const { name } = account;

        const { day } = splitDate;

        if (!DataMap[day]) {
            DataMap[day] = [];
            DataMap[day].push({
                Amount: totalIncTax,
                Description: memo,
                Account: name,
            });
        } else {
            DataMap[day].push({
                Amount: totalIncTax,
                Description: memo,
                Account: name,
            });
        }
    });

    const filledInDates = getDates(selectedRange.start, selectedRange.end);

    const filledDataMap = filledInDates.reduce((acc, date) => {
        const tempDateObject = date.toDate();
        tempDateObject.setUTCHours(0, 0, 0, 0);
        const dateString = tempDateObject.toISOString();
        if (DataMap[dateString]) {
            acc[dateString] = DataMap[dateString];
        } else {
            acc[dateString] = [
                {
                    Amount: 0,
                    Description: "",
                    Account: "",
                },
            ];
        }
        return acc;
    }, {} as any);

    const tableData: any = [];

    Object.keys(filledDataMap).forEach((currentDate) => {
        filledDataMap[currentDate].forEach((item: any) => {
            tableData.push({ currentDate, ...item });
        });
    });

    const tableColumns: ColumnsType<any> = [
        {
            title: "Date",
            dataIndex: "currentDate",
            key: "currentDate",
            fixed: "left",
            width: 140,
            render: (date: string) => {
                return dayjs(date).format("ddd DD/MM/YYYY");
            },
        },
        {
            title: "Amount",
            dataIndex: "Amount",
            key: "Amount",
            render: (value: number) => {
                return value ? `$${value.toFixed(2)}` : "-";
            },
        },
        {
            title: "Description",
            dataIndex: "Description",
            key: "Description",
        },
        { title: "Tender", dataIndex: "Account", key: "Account" },
    ];

    return { DataMap, tableData, tableColumns };
}
