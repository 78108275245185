import { ApiCall } from "../apiCall";

export const putShiftPrompts = async (
    venueId: string,
    promptId: string,
    data: FormData
) => {
    const requestUrl = `/${venueId}/venue-shift-report-prompts/${promptId}/venue-shift-report-prompt-record`;
    return await ApiCall(
        data,
        "AUTHENTICATED",
        "VENUES",
        "list",
        "PUT",
        {},
        requestUrl
    );
};
