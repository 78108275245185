import { useCallback, useEffect, useMemo, useState } from "react";
import { getAuthTokens } from "utils/auth0/auth0API";

export const useGetUserEmail = (
    isWebViewCheckLoading?: boolean
): {
    auth0User: string | undefined;
    loaded: boolean;
} => {
    const [auth0User, setAuth0User] = useState<undefined | string>(undefined);
    const [loaded, setLoaded] = useState(false);

    const getUserEmail = useCallback(async () => {
        const data = await getAuthTokens();
        return data?.email;
    }, []);

    useEffect(() => {
        if (isWebViewCheckLoading === true) return;
        getUserEmail().then((userEmail) => {
            userEmail && setAuth0User(userEmail);
            setLoaded(true);
        });
    }, [isWebViewCheckLoading, getUserEmail]);

    return useMemo(() => ({ auth0User, loaded }), [auth0User, loaded]);
};
