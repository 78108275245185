import React, { useEffect, useMemo, useState } from "react";
import { Col, Row, Space, Spin, Statistic, Tooltip, Typography } from "antd";
import { FilePdfOutlined } from "@ant-design/icons";
import { useQuery } from "react-query";
import CountUp from "react-countup";
import { FormatConfig, Formatter, valueType } from "antd/es/statistic/utils";
import { CentredSpinner } from "Components/Misc/Loading/CentredSpinner";
import GroupShiftReportMetrics from "./GroupShiftReportMetrics";
import GroupShiftReportClassesTendersProps from "./GroupShiftReportClassesTenders";
import { getShiftGroupReport } from "../../../../../ApiV2/Helpers/getShiftGroupReport";
import { getCashupPosClassesGroupReport } from "../../../../../ApiV2/Helpers/getPosClassesGroupReport";
import { getCashupPosTendersGroupReport } from "../../../../../ApiV2/Helpers/getPosTendersGroupReport";
import { contactSupport } from "utils/utilities";
import {
    DownloadLoadingContainer,
    KeyNumberContainer,
    LoadingContainer,
    NoShiftDataContainer,
    ShiftMetricsNoDataContainer,
    TableTitle,
} from "../../CashupReportStyled";
import { checkDateRange, checkZeroValues, downloadPdfReport } from "../../../utils";
import { keyNumbers } from "../../../const";

const { Title } = Typography;

interface GroupShiftReportProps {
    reportType: string;
    date: { start_date: string; end_date: string };
    group: string;
    groupName: string;
    areaName: string;
    calendarStatus: boolean;
}

const CashupGroupShiftReport: React.FC<GroupShiftReportProps> = ({
    reportType,
    date,
    group,
    groupName,
    calendarStatus,
}) => {
    const [download, setDownload] = useState(false as boolean);

    //get shift report data
    const fetchShiftReportData = async () => {
        if (!calendarStatus && !checkDateRange(date, 7)) {
            const response = await getShiftGroupReport(
                date.start_date,
                date.end_date,
                group
            );
            return response.json();
        }
    };

    const {
        data: shiftReportData = [],
        isFetching: shiftDataLoading,
        error: shiftReportError,
        refetch: refetchShift,
    } = useQuery(["groupShiftRepotData"], fetchShiftReportData, {
        enabled:
            date.start_date.length > 0 &&
            date.end_date.length > 0 &&
            !checkDateRange(date, 7),
        refetchOnWindowFocus: false,
    });

    useEffect(() => {
        refetchShift();
    }, [refetchShift]);

    //get pos report data
    const fetchClassesTendersReportData = async (
        reportType: "classes" | "tenders"
    ) => {
        if (!calendarStatus && !checkDateRange(date, 7)) {
            const response =
                reportType === "classes"
                    ? await getCashupPosClassesGroupReport(
                          date.start_date,
                          date.end_date,
                          group
                      )
                    : await getCashupPosTendersGroupReport(
                          date.start_date,
                          date.end_date,
                          group
                      );
            return response.json();
        }
    };

    const {
        data: groupClassesReportData = [],
        isFetching: classesReportDataLoading,
        error: classesReportError,
        refetch: refetchClasses,
    } = useQuery(
        ["groupPosClassesReportData"],
        () => fetchClassesTendersReportData("classes"),
        {
            enabled:
                date.start_date.length > 0 &&
                date.end_date.length > 0 &&
                !checkDateRange(date, 7),
            refetchOnWindowFocus: false,
        }
    );

    useEffect(() => {
        refetchClasses();
    }, [refetchClasses]);

    const {
        data: groupTendersReportData = [],
        isFetching: tendersReportDataLoading,
        error: tendersReportError,
        refetch: refetchTenders,
    } = useQuery(
        ["groupPosTendersReportData"],
        () => fetchClassesTendersReportData("tenders"),
        {
            enabled:
                date.start_date.length > 0 &&
                date.end_date.length > 0 &&
                !checkDateRange(date, 7),
            refetchOnWindowFocus: false,
        }
    );

    useEffect(() => {
        refetchTenders();
    }, [refetchTenders]);

    //check 0 values in shift sales revenue data
    const salesNullCheck: boolean = useMemo(() => {
        if (
            shiftReportData &&
            shiftReportData.sales &&
            Array.isArray(shiftReportData.sales.data)
        ) {
            return shiftReportData.sales.data.length > 0
                ? checkZeroValues(shiftReportData.sales.data)
                : false;
        }
        return false;
    }, [shiftReportData]);

    //check 0 values in shift gaming data
    const gamingNullCheck: boolean = useMemo(() => {
        if (
            shiftReportData &&
            shiftReportData.sales &&
            Array.isArray(shiftReportData.gaming_data.data)
        ) {
            return shiftReportData.gaming_data.data.length > 0
                ? checkZeroValues(shiftReportData.gaming_data.data)
                : false;
        }
        return false;
    }, [shiftReportData]);

    const combinedStatus =
        shiftDataLoading || classesReportDataLoading || tendersReportDataLoading;

    const formatter: Formatter = (value: valueType, formatConfig?: FormatConfig) => {
        if (typeof value === "string") {
            return value;
        }
        return <CountUp end={value} separator="," decimals={2} duration={1} />;
    };

    //download pdf report
    const onClick = async () => {
        setDownload(true);
        await new Promise((resolve) => setTimeout(resolve, 0));
        await downloadPdfReport(
            "Shift",
            "shift-group-report",
            groupName,
            date,
            true
        );
        setDownload(false);
    };

    //open quantaco support modal
    const supportClick = () => {
        contactSupport();
    };

    if (date.start_date.length < 1 && date.end_date.length < 1) {
        return (
            <Title level={5}>
                Cashup data is not available for the selected combination of filters.
                Please review and update your selection.
            </Title>
        );
    }

    if (checkDateRange(date, 7)) {
        return (
            <Title level={5}>
                Cashup reports are limited to a 7-day date range. Please adjust your
                date range selection.
            </Title>
        );
    }

    return (
        <>
            <div id="shift-group-report" style={{ backgroundColor: "#f5f5f5" }}>
                <Space
                    size="middle"
                    direction="vertical"
                    style={{
                        width: "100%",
                    }}
                >
                    <Row
                        gutter={[20, 2]}
                        justify="space-between"
                        align="middle"
                        className="hide-for-pdf"
                    >
                        <Col>
                            <Title level={4}>{`${reportType}`}</Title>
                        </Col>
                        <Col>
                            {!combinedStatus ? (
                                <div style={{ display: "flex", gap: "15px" }}>
                                    <Tooltip title="Download PDF">
                                        <FilePdfOutlined
                                            style={{
                                                fontSize: "28px",
                                                color: "#113469",
                                            }}
                                            onClick={onClick}
                                        />
                                    </Tooltip>
                                </div>
                            ) : null}
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        {keyNumbers &&
                            keyNumbers.map((item: string) => (
                                <Col md={12} lg={6} xs={24} key={item}>
                                    <KeyNumberContainer>
                                        {shiftDataLoading ? (
                                            <CentredSpinner size="large" />
                                        ) : shiftReportError ? (
                                            <>
                                                <TableTitle>
                                                    There was an error when trying to
                                                    retrieve shift report data.
                                                    Please contact{" "}
                                                    <a onClick={supportClick}>
                                                        Quantaco Support
                                                    </a>{" "}
                                                    for assistance.
                                                </TableTitle>
                                            </>
                                        ) : (
                                            <>
                                                <Statistic
                                                    valueStyle={{
                                                        fontSize: 28,
                                                        fontWeight: "700 !important",
                                                        color: "#113469",
                                                    }}
                                                    value={
                                                        shiftReportData.key_numbers
                                                            ? shiftReportData
                                                                  .key_numbers[item]
                                                            : 0
                                                    }
                                                    precision={2}
                                                    prefix={"$"}
                                                    formatter={formatter}
                                                />
                                                <div
                                                    style={{
                                                        fontSize: 16,
                                                        fontWeight: "SemiBold",
                                                    }}
                                                >
                                                    {item === "Variance"
                                                        ? "Cash Variance"
                                                        : item}
                                                </div>
                                            </>
                                        )}
                                    </KeyNumberContainer>
                                </Col>
                            ))}
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col xs={24}>
                            {shiftDataLoading ? (
                                <KeyNumberContainer>
                                    <CentredSpinner size="large" />
                                </KeyNumberContainer>
                            ) : shiftReportError ? null : (
                                <>
                                    {!salesNullCheck &&
                                    shiftReportData.sales &&
                                    shiftReportData.sales.data.length > 0 ? (
                                        <GroupShiftReportMetrics
                                            revenueData={shiftReportData.sales}
                                            chartLabal={"All Venues"}
                                        />
                                    ) : (
                                        <ShiftMetricsNoDataContainer>
                                            <TableTitle>
                                                Revenue data is not available for the
                                                selected date range.
                                            </TableTitle>
                                        </ShiftMetricsNoDataContainer>
                                    )}
                                </>
                            )}
                        </Col>
                    </Row>
                    <Row gutter={[20, 20]}>
                        <Col xs={24}>
                            {shiftDataLoading ? (
                                <KeyNumberContainer>
                                    <CentredSpinner size="large" />
                                </KeyNumberContainer>
                            ) : shiftReportError ? null : (
                                <>
                                    {!gamingNullCheck &&
                                    shiftReportData.gaming_data &&
                                    shiftReportData.gaming_data.data.length > 0 ? (
                                        <GroupShiftReportMetrics
                                            revenueData={shiftReportData.gaming_data}
                                            chartLabal={"All Venues"}
                                        />
                                    ) : (
                                        <ShiftMetricsNoDataContainer>
                                            <TableTitle>
                                                Gaming data is not available for the
                                                selected date range.
                                            </TableTitle>
                                        </ShiftMetricsNoDataContainer>
                                    )}
                                </>
                            )}
                        </Col>
                    </Row>
                    {classesReportDataLoading ? (
                        <Row gutter={[20, 2]}>
                            <Col xs={24}>
                                <LoadingContainer>
                                    <CentredSpinner size="large" />
                                </LoadingContainer>
                            </Col>
                        </Row>
                    ) : classesReportError ? (
                        <>
                            <NoShiftDataContainer>
                                <TableTitle>
                                    There was an error when trying to retrieve pos
                                    classes data. Please contact{" "}
                                    <a onClick={supportClick}>Quantaco Support</a>{" "}
                                    for assistance.
                                </TableTitle>
                            </NoShiftDataContainer>
                        </>
                    ) : (
                        <>
                            {groupClassesReportData[0]?.data &&
                            groupClassesReportData[0].data.length > 0 ? (
                                <GroupShiftReportClassesTendersProps
                                    reportData={groupClassesReportData}
                                    type={"Classes"}
                                    groupName={groupName}
                                    downloading={download}
                                />
                            ) : (
                                <NoShiftDataContainer>
                                    <TableTitle>
                                        Classes data is not available for the
                                        selected date range.
                                    </TableTitle>
                                </NoShiftDataContainer>
                            )}
                        </>
                    )}
                    {tendersReportDataLoading ? (
                        <Row gutter={[20, 2]}>
                            <Col xs={24}>
                                <LoadingContainer>
                                    <CentredSpinner size="large" />
                                </LoadingContainer>
                            </Col>
                        </Row>
                    ) : tendersReportError ? (
                        <>
                            <NoShiftDataContainer>
                                <TableTitle>
                                    There was an error when trying to retrieve pos
                                    tenders data. Please contact{" "}
                                    <a onClick={supportClick}>Quantaco Support</a>{" "}
                                    for assistance.
                                </TableTitle>
                            </NoShiftDataContainer>
                        </>
                    ) : (
                        <>
                            {groupTendersReportData[0]?.data &&
                            groupTendersReportData[0].data.length > 0 ? (
                                <GroupShiftReportClassesTendersProps
                                    reportData={groupTendersReportData}
                                    type={"Tenders"}
                                    groupName={groupName}
                                    downloading={download}
                                />
                            ) : (
                                <NoShiftDataContainer>
                                    <TableTitle>
                                        Tenders data is not available for the
                                        selected date range.
                                    </TableTitle>
                                </NoShiftDataContainer>
                            )}
                        </>
                    )}
                </Space>
            </div>
            {download && (
                <DownloadLoadingContainer>
                    <Spin tip="Generating Report... Please wait" />
                </DownloadLoadingContainer>
            )}
        </>
    );
};

export default CashupGroupShiftReport;
