import { CollapsibleTable } from "../../Pages/CashupHome/CashupTabs/POS/utils/Panel";
import { GamingPanel } from "./GamingPanel";
import { GamingLocation } from "../../Redux/StateSlices/GroupData/VenuesAPI";
import { Space } from "antd";
import React from "react";
import { useVenueSelection } from "../../Context/VenueSelectionContextConstants";
import { useQuery } from "react-query";
import { ExtendedLocationItemWithChildren } from "../../Redux/StateSlices/GroupData/LocationsAPI";
import { getVenueById } from "../../ApiV2/Helpers/getVenueById";
import { CentredSpinner } from "../../Components/Misc/Loading/CentredSpinner";
import { LocationType } from "Pages/CashupReport/CashupReportModel";

interface Props {
    setGamingDownloadStatus: React.Dispatch<React.SetStateAction<boolean>>;
    isGamingUploadEnabled: boolean;
    locations: ExtendedLocationItemWithChildren[];
    loading: boolean;
}

const GamingTab: React.FC<Props> = ({
    setGamingDownloadStatus,
    isGamingUploadEnabled,
    loading,
    locations,
}) => {
    const { venueId, formattedDate, shiftStatus, activePanel, setActivePanel } =
        useVenueSelection();

    const { data: venueData, isLoading: venueDataIsLoading } = useQuery(
        ["venueData", venueId, formattedDate],
        () => getVenueById(venueId)
    );

    if (venueDataIsLoading || loading) {
        return (
            <CentredSpinner
                style={{
                    marginTop: "50px",
                    position: "absolute",
                    textAlign: "center",
                    left: "50%",
                }}
                size={"large"}
            />
        );
    }

    return (
        <Space direction="vertical" style={{ width: "100%", marginTop: "20px" }}>
            {locations
                .filter((gl) => gl.location_type === LocationType.gaming)
                .map((currentLocation, index) => {
                    const panelId = `${currentLocation.location_id}-${formattedDate}-${index}`;
                    return (
                        <CollapsibleTable
                            name={currentLocation.name}
                            panelId={panelId}
                            key={panelId}
                        >
                            <GamingPanel
                                key={currentLocation.location_id}
                                name={currentLocation.name}
                                subLocations={
                                    currentLocation.sub_locations as unknown as GamingLocation[]
                                }
                                venueData={venueData}
                                transferLocations={[
                                    ...locations.filter(
                                        (gl: ExtendedLocationItemWithChildren) =>
                                            gl.location_type === LocationType.office
                                    ),
                                    currentLocation,
                                ]}
                                onClickHandler={(tableIndex?: number) =>
                                    setActivePanel(`${panelId}-${tableIndex}`)
                                }
                                currentTableInFocus={activePanel}
                                panelId={panelId}
                                submitted={shiftStatus !== "UNSUBMITTED"}
                                isGamingUploadEnabled={isGamingUploadEnabled}
                                setGamingDownloadStatus={setGamingDownloadStatus}
                            />
                        </CollapsibleTable>
                    );
                })}
        </Space>
    );
};
export default GamingTab;
