import React, { useEffect } from "react";
import { QuantacoLoader } from "Components/QuantacoLoader/QuantacoLoader";
import { CashupDetailedReport } from "./Components/CashupDetailedReport";
import { Space } from "StyledComponents/Space";
import { CashupReportSelector } from "./Components/CashupReportSelector";
import { useIntializeCashupReport } from "./Hooks/useIntializeCashupReport";
import { useAppSelector } from "Redux/TypedReduxFunctions";
import { CashupReportTable } from "./Components/CashupReportTable";
import { CashupBankableReport } from "./Components/CashupBankableReport";
import { CashupGamingReport } from "./Components/CashupGamingReport";

export const CashupReportSummaryPage = () => {
    const { ready, groupData } = useIntializeCashupReport();

    const { selectedReport } = useAppSelector((state) => state.shiftReportSlice);

    useEffect(() => {
        document.title = "Cashup - Summary Report";
    }, []);
    return (
        <>
            <Space $fluid size="large" direction="vertical" style={{ padding: 24 }}>
                {groupData && (
                    <CashupReportSelector
                        groupData={groupData}
                        shouldShowReportSelector
                    />
                )}
                {!groupData || !ready ? (
                    <QuantacoLoader size={100} />
                ) : selectedReport === "Detailed" ? (
                    <CashupDetailedReport groupData={groupData} />
                ) : selectedReport === "Summary" ? (
                    <CashupReportTable groupData={groupData} />
                ) : selectedReport === "Bankable" ? (
                    <CashupBankableReport groupData={groupData} />
                ) : (
                    <CashupGamingReport />
                )}
            </Space>
        </>
    );
};
