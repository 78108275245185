import { useMemo } from "react";
import { useQuery } from "react-query";
import { CubeName, CubeQueryBuilder, useCubejs } from "utils/cubejs-utils";
import { formatForBackend, QTCRange } from "utils/date-utils";
import { Cashup, RemoteCashup } from "../CashupReportModel";
import { transformCashupDetailForClient } from "../CashupReportUtils";
import { GroupDataState } from "../../../Redux/StateSlices/GroupData";

interface GetCashupParams {
    venueId?: string;
    range?: QTCRange;
    disabled?: boolean;
}

const buildQuery = ({ venueId, range, disabled }: GetCashupParams) => {
    if (!venueId || !range || disabled) return;

    return CubeQueryBuilder({
        dimensions: [
            `${CubeName.cashup}.cashupId`,
            `${CubeName.cashup}.body`,
            `${CubeName.cashup}.date`,
            `${CubeName.cashup}.timePeriod`,
            `${CubeName.location}.locationType`,
            `${CubeName.location}.name`,
            `${CubeName.location}.locationId`,
        ],
        filters: [
            {
                member: `${CubeName.cashup}.date`,
                operator: "inDateRange",
                values: [formatForBackend(range.start), formatForBackend(range.end)],
            },
            {
                member: `${CubeName.location}.venueId`,
                operator: "equals",
                values: [venueId],
            },
        ],
    }).getResult();
};

export const useGetCashups = (
    params: GetCashupParams,
    groupData: GroupDataState
) => {
    const { venueId, range } = params;
    const { cubejsApi } = useCubejs();
    const query = buildQuery({ venueId, range });

    const { isLoading, data } = useQuery(
        ["CASHUP", query],
        () => cubejsApi!.load(query!),
        {
            enabled: Boolean(cubejsApi) && Boolean(query) && Boolean(groupData),
            select: (result) => {
                const remoteCashups = result
                    .rawData()
                    .filter((d) => d[`${CubeName.cashup}.body`])
                    .map((d) => ({
                        id: d[`${CubeName.cashup}.cashupId`],
                        date: d[`${CubeName.cashup}.date`],
                        body: d[`${CubeName.cashup}.body`],
                        timePeriod: d[`${CubeName.cashup}.timePeriod`],
                        locationType: d[`${CubeName.location}.locationType`],
                        locationName: d[`${CubeName.location}.name`],
                        locationId: d[`${CubeName.location}.locationId`],
                    })) as RemoteCashup[];

                return remoteCashups;
            },
        }
    );

    const cashups = useMemo<Cashup[]>(() => {
        return (
            data?.map(({ body, ...others }) => {
                return {
                    body: transformCashupDetailForClient(body),
                    ...others,
                };
            }) ?? []
        );
    }, [data]);

    return { isLoading, cashups };
};
