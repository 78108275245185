import styled from "styled-components";
import { Table } from "antd";

export const LocationContainer = styled.div`
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    padding: 20px;
`;

export const TotalTableContainer = styled.div`
    border: 1px solid #f0f0f0;
    width: 100%;
    margin-top: 15px;
    border-radius: 8px 8px 0 0;
`;

export const TableTitle = styled.div`
    font-weight: 600;
    font-size: 16px;
    line-height: 1.5;
`;

export const PromptTitle = styled.div`
    font-weight: 550;
    font-size: 14px;
    line-height: 1.4;
`;

export const PromptAnswer = styled.div`
    font-weight: 500;
    font-size: 12px;
    line-height: 1.4;
`;

export const CustomTotalDataTable = styled(Table)`
    .ant-table .ant-table-column-title {
        font-weight: bold;
    }
    .ant-table-tbody > tr > td,
    .ant-table-thead > tr > th {
        background-color: #eceff1;
    }
`;

export const CustomDataTable = styled(Table)`
    .ant-table .ant-table-column-title {
        font-weight: bold;
    }
`;

export const ShiftMetricsNoDataContainer = styled.div`
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    padding: 10px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const ShiftMetricsTableContainer = styled.div`
    border: 1px solid #f0f0f0;
    width: 100%;
    margin-top: 15px;
    border-radius: 8px 8px 0 0;
    overflow-y: auto;
`;

export const KeyNumberContainer = styled.div`
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    padding: 10px;
    height: 100px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

export const LoadingContainer = styled.div`
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    padding: 10px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
`;
export const LoadingContainerWhole = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(255, 255, 255, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1100;
`;
export const NoShiftDataContainer = styled.div`
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    background-color: #fff;
    width: 100%;
    padding: 10px;
    height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

export const DownloadLoadingContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(255, 255, 255, 0.8);
    z-index: 1000;
`;

export const TableWrapCell = styled.div`
    white-space: normal !important;
    word-break: break-word;
`;

export const GroupLoadingContainer = styled.div`
    width: 100%;
    height: 400px;
    margin: 0 auto;
    display: flex;
    justify-content: center;
    align-items: center;
`;
