import { Button, Input, Modal, Statistic } from "antd";
import styled from "styled-components";
import { WHITE } from "../@Constants/theme/constants";
import { CentredSpinner } from "../Components/Misc/Loading/CentredSpinner";

export const StyledSubmitButton = styled(Button)`
    min-width: 90px;
`;

export const StyledButton = styled(Button)<ColumnWidthDistribution>`
    width: 100%;
    ${({ centerText }) => (centerText ? `text-align: center;` : ``)}
`;
export const TopBannerContent = styled.div`
    display: flex;
    background-color: ${WHITE};
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    width: 100%;
    align-items: center;
    padding: 8px 4px 8px 28px;
    justify-content: space-between;

    @media (max-width: 808px) {
        flex-direction: column-reverse;
    }
`;

export const StatisticSection = styled.div`
    display: flex;
    justify-content: space-around;
    flex: 1;
    width: 100%;
    @media (max-width: 808px) {
        padding-bottom: 12px;
        justify-content: space-around;
        width: 100%;
    }
`;
export const SelectSection = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @media (max-width: 808px) {
        padding-bottom: 12px;
        justify-content: space-between;
        width: 100%;
    }
    @media (max-width: 1280px) {
        flex-direction: column;
    }
`;

export const SubmitSection = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    width: 100%;
    @media (max-width: 808px) {
        padding-bottom: 12px;
        justify-content: space-around;
        width: 100%;
    }
`;

export const SubmitButton = styled(StyledButton)<{
    backgroundcolor: string;
    bordcolor: string;
}>`
    background-color: ${(props) => props.backgroundcolor};
    border: 2px solid ${(props) => props.bordcolor};
`;

interface ColumnWidthDistribution {
    numberofitems: number;
    borderRight?: boolean;
    centerText?: boolean;
    evenDistribution?: boolean;
}

export const StyledContainer = styled.div<ColumnWidthDistribution>`
    padding: 4px 12px;
    flex-basis: calc(
        ${({ numberofitems }) => 100 / numberofitems}% -
            ${({ borderRight }) => (borderRight ? 2 : 0)}px
    );
    width: calc(
        ${({ numberofitems }) => 100 / numberofitems}% -
            ${({ borderRight }) => (borderRight ? 2 : 0)}px
    );

    ${({ centerText }) => (centerText ? `text-align: center;` : ``)}
    height: 100%;
    display: flex;
    align-items: center;
    border-radius: 4px;
    position: relative;
`;

export const StyledTextArea = styled(Input.TextArea)<ColumnWidthDistribution>`
    width: 100%;
    background: #f9fafb;
    height: 100%;
    color: #353940;
    display: flex;
    align-items: center;
    border-radius: 4px;

    textarea {
        background: #f9fafb;
    }

    &:focus {
        border: 1px solid #1a81c7;
        box-shadow: 0 0 1px 2px #dbe7fa;
    }
`;

export const EmptyDiv = styled.div<ColumnWidthDistribution>`
    font-weight: bold;
    padding: 4px 12px;
    display: flex;
    justify-content: start;
    align-items: center;
    align-content: center;
    flex-basis: calc(
        ${({ numberofitems }) => 100 / numberofitems}% -
            ${({ borderRight }) => (borderRight ? 2 : 0)}px
    );
    width: calc(
        ${({ numberofitems }) => 100 / numberofitems}% -
            ${({ borderRight }) => (borderRight ? 2 : 0)}px
    );

    ${({ centerText }) => (centerText ? `text-align: center;` : ``)}
`;

export const StyledModal = styled(Modal)`
    .ant-modal-content {
        width: fit-content;
    }
`;

export const StyledButtonFullWidth = styled.button`
    background: #f9fafb;
    border: 1px solid #dadfe7;
    border-radius: 4px;
    height: 32px;
    display: flex;
    align-items: center;
    padding-left: 6px;
    padding-right: 8px;
    width: 100%;

    &:focus {
        border: 1px solid #1a81c7;
        box-shadow: 0 0 1px 2px #dbe7fa;
    }
`;
export const TabSpinner = styled(CentredSpinner)`
    margin-top: 50px;
    position: absolute;
    text-align: center;
    left: "50%";
`;

export const StyledModalDiv = styled.div<{ numberofitems: number }>`
    padding: 4px 12px;
    flex-basis: calc(${({ numberofitems }) => 100 / numberofitems}%);
    width: calc(${({ numberofitems }) => 100 / numberofitems}%);
    border-radius: 4px;
    height: 32px;
    display: flex;
    align-items: center;

    width: 100%;

    &:focus {
        border: 1px solid #1a81c7;
        box-shadow: 0 0 1px 2px #dbe7fa;
    }
`;
export const StyledStatistic = styled(Statistic)`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin: 0px 20px 0 0;
    padding: 0 8px;
    border: 2px solid transparent;
    border-radius: 8px;
`;
export const UpdateSection = styled.div`
    display: flex;
    justify-content: flex-end;
    align-items: center;
    flex: 1;
    width: 100%;
    min-width: 300px;
    padding: 0 0 8px 0;
    @media (max-width: 768px) {
        padding-bottom: 12px;
        justify-content: space-around;
    }
`;
